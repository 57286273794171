import React from "react";
import Colors from "../../themes/Colors";

export const TraditionalSvg = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>megafono-03</title>
        <path fill={Colors.primary} d="M20.983 15.77c0.079 0.898-0.335 1.408-1.067 1.773-1.314 0.64-2.737 1.067-3.964 1.897-0.471 0.322-0.397 0.499-0.243 1.067 0.335 1.222 1.346 1.828 1.963 2.773 0.414 0.64 0.175 1.28-0.427 1.771-0.555 0.452-1.171 0.612-1.707 0.068-0.821-0.853-1.837-1.449-2.699-2.234-0.226-0.22-0.534-0.356-0.875-0.356-0.118 0-0.233 0.016-0.341 0.047l0.009-0.002c-0.201 0.047-0.432 0.075-0.67 0.075-1.161 0-2.17-0.649-2.684-1.605l-0.008-0.016c-0.64-1.197-0.337-2.812 0.563-3.642 1.71-1.581 3.021-3.569 3.781-5.81l0.029-0.099c0.661-1.963 1.826-2.347 3.787-1.449 1.941 0.888 3.448 2.447 4.247 4.374l0.020 0.055c0.192 0.416 0.388 0.868 0.286 1.314zM12.962 12.521c-0.802 2.108-2.059 3.772-3.55 5.274-1.067 1.067-1.082 2.421-0.070 3.441s2.377 0.992 3.441-0.058c0.987-0.983 2.084-1.858 3.269-2.604l0.078-0.046c0.559-0.346 1.239-0.465 1.852-0.971-2.409-0.994-4.036-2.652-5.020-5.037zM16.226 12.094c0.994 0.107 1.632 0.678 2.080 1.551 0.388 0.757 0.098 1.306-0.427 1.85-0.949 0.994-0.939 1.003 0.395 1.442q0.183 0.058 0.365 0.109c1.107 0.309 1.89-0.489 1.632-1.628-0.518-2.278-2.082-3.703-4.002-4.764-0.512-0.282-1.163-0.659-1.871-0.39-0.941 0.358-1.111 1.175-0.57 2.56 0.224 0.576 0.401 0.806 0.937 0.213 0.382-0.414 0.681-1.071 1.461-0.947zM15.309 13.218c-0.119 0.57-0.753 0.597-0.529 1.052 0.318 0.598 0.777 1.083 1.334 1.424l0.016 0.009c0.427 0.269 0.578-0.032 0.787-0.239 0.335-0.331-0.060-0.474-0.226-0.557-0.604-0.309-0.971-0.802-1.382-1.694zM17.894 14.24c-0.307-0.64-0.7-1.182-1.421-1.438-0.213-0.070-0.444-0.139-0.459 0.1-0.036 0.602 0.452 0.93 0.806 1.331 0.194 0.213 0.463 0.188 0.685 0.305s0.326-0.083 0.388-0.303zM14.767 22.56c-0.267 0.115-0.506 0.367-0.286 0.64 0.452 0.55 0.922 1.111 1.57 1.446 0.162 0.085 0.469 0.145 0.471-0.233 0.002-0.256-1.5-1.862-1.756-1.865zM14.622 21.641c-0.096-0.314-0.348-0.64-0.557-0.595-0.469 0.107-0.809 0.521-0.994 0.947-0.077 0.177 0.192 0.602 0.489 0.555 0.501-0.092 0.717-0.595 1.062-0.917z"></path>
        <path fill={Colors.primary} d="M19.063 11.142c-0.352-0.115-0.258-0.446-0.134-0.599 0.678-0.836 1.393-1.647 2.11-2.449 0.062-0.056 0.144-0.090 0.235-0.090 0.080 0 0.153 0.027 0.212 0.072l-0.001-0.001c0.059 0.042 0.097 0.111 0.097 0.188 0 0.059-0.022 0.113-0.059 0.154l0-0q-1.114 1.35-2.253 2.677c-0.032 0.041-0.119 0.032-0.207 0.049zM23.298 10.867c0.068-0.053 0.23-0.139 0.105-0.305s-0.247-0.25-0.403-0.177c-0.939 0.427-1.869 0.87-2.803 1.306-0.183 0.085-0.179 0.241-0.173 0.401 0 0.241 0.194 0.194 0.427 0.213 0.913-0.444 1.95-0.785 2.848-1.438zM17.719 10.103c0.262 0.058 0.324-0.198 0.386-0.427 0.241-0.866 0.506-1.726 0.749-2.592 0.013-0.037 0.020-0.080 0.020-0.125 0-0.161-0.094-0.3-0.231-0.365l-0.002-0.001c-0.314-0.158-0.427 0.179-0.461 0.314-0.262 0.853-0.476 1.741-0.685 2.537 0.015 0.305-0.045 0.599 0.224 0.659zM22.445 14.033c0.427 0 0.836 0.013 1.252 0 0.001 0 0.002 0 0.002 0 0.201 0 0.365-0.163 0.365-0.365 0-0.005-0-0.010-0-0.016l0 0.001c0-0.001 0-0.003 0-0.004 0-0.169-0.135-0.307-0.303-0.311h-0c-0.8-0.038-1.602-0.068-2.404-0.058-0.228 0.019-0.406 0.209-0.406 0.44 0 0.010 0 0.020 0.001 0.030l-0-0.001c0.021 0.275 0.297 0.284 0.527 0.284 0.322 0 0.64 0 0.966 0z"></path>
        <path fill={Colors.primary} d="M26.75 26.441c-2.726 2.804-6.534 4.544-10.749 4.544-8.276 0-14.985-6.709-14.985-14.985s6.709-14.985 14.985-14.985c4.061 0 7.744 1.615 10.443 4.238l-0.004-0.003c2.801 2.728 4.538 6.536 4.538 10.75 0 4.060-1.613 7.743-4.233 10.444l0.004-0.004zM6.010 6.295c-2.436 2.505-3.938 5.93-3.938 9.705 0 7.693 6.236 13.929 13.929 13.929s13.929-6.236 13.929-13.929c0-3.918-1.617-7.457-4.221-9.988l-0.003-0.003c-2.507-2.432-5.93-3.932-9.704-3.932-3.916 0-7.455 1.615-9.988 4.215l-0.003 0.003z"></path>
    </svg>
);
