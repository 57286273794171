import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreenCardHolder = styled.div`
  cursor: pointer;

  .screenCard {
    position: relative;
    width: 100%;
    height: 185px;
    border-radius: 15px;
    margin: 0 0 15px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s;
    .slick-arrow {
      display: none !important;
    }
  }
  .screenCard:hover {
    transform: translate3d(0, -5px, 0);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    .slick-arrow {
      display: block !important;
    }
  }
  .screenCardImage {
    width: 100%;
    height: 185px;
    object-fit: cover;
  }
  .screenCardContent {
    position: absolute;
    bottom: 0;
    background-color: rgba(42, 42, 42, 0.6);
    width: 100%;
    padding: 10px 15px 7px;
    border-radius: 0 0 15px 15px;
  }
  .screenCardContentCircuit {
    position: absolute;
    bottom: 0;
    background-color: rgba(42, 42, 42, 0.6);
    width: 100%;
    padding: 20px 15px 7px;
    border-radius: 0 0 15px 15px;
  }
  .screenCardContentCurrent {
    position: absolute;
    bottom: 0;
    background-color: ${Colors.primary};
    width: 100%;
    padding: 18px 15px 10px;
    border-radius: 0 0 15px 15px;
  }
  .screenCardPrice {
    margin: 0 0 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
  }
  .screenCardTitle {
    color: #fff;
    font-size: 16px;
    line-height: 110%;
  }
  .screenCardInfo {
    color: #fff;
    font-size: 12px;
  }
  .changeSpotWarning {
    padding: 20px;
    font-size: 14px;
  }
  .screenCardButton {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -16px;
    right: 15px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenCardCloseButton {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -16px;
    right: 15px;
    background-color: ${Colors.snow};
    border-color: ${Colors.snow};
    color: ${Colors.red};
  }
  .screenContentButton{
    position: absolute
  }
  .screenCardCamTrafficView {
    z-index: 1;
    top: 10px;
    display: inline-block;
    margin-left: 7px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenCardCamCertification {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    top: 10px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenCardAudit {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    top: 10px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenWarningNoAutomateView {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${Colors.snow};
    border-color: #ffc107;
    color: ${Colors.snow};
  }
  .circuit {
    width: 90px;
    border-radius: 25px;
    z-index: 1;
    position: absolute;
    top: 87px;
    left: 10px;
    background-color: ${Colors.snow};
    border-color: #926dde;
    color: #926dde;
  }
  .doubleScreen {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    top: 10px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .slick-list {
    border-radius: 15px;
  }
`;

export default ScreenCardHolder;
