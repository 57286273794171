import styled from "styled-components";
import Colors from "../../themes/Colors";


const ImpressionManagementHolder = styled.div`
.ant-row-flex {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.text-impression {
    margin-left: 5px;
}
.ant-list-item {
    padding-left: 10px !important;
    padding-right: 5px !important;
}
.text-impression-disabled {
    color: rgba(0, 0, 0, 0.85);
}
.text-impression-disabled-important {
    color: ${Colors.primary};
}
.row-info {
    display: flex;
    margin: 10px;
    flex-direction: row-reverse;
}
.row-info-name {
    margin-left: 10px;
    color: ${Colors.primary};
}
.col-info {
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 10px;
}
.col-info h3 {
    margin-top: 5px;
}
.ant-input-number {
    width: 85%;
}
.action-button {
    margin-top: 5px;
    margin-bottom: 15px;
    padding-right: 30px;
}
.action-button Button {
    margin-left: 10px;
}
.content-list {
  overflow-y: auto;
  max-height: 620px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
}
`;

export default ImpressionManagementHolder;