import React from 'react';
import { useTranslation } from 'react-i18next';
import { BundleSvg } from "../images/customIcons/BundleSvg";
import { Icon, Modal } from "antd";

const DeleteBundleModalComponent = (props) => {
  const { t } = useTranslation();
  const { deleteModalVisible, matchBundle , handleDeleteModal} = props;

  const removeDisplayInBundlerFromCart = (matchBundle) => {
    const { cart , listBundle, removeBundlesFromCart} = props;
    let matchingDisplays = [];
    matchingDisplays = [];

    // Search for the displays that have the same bundle for delete
    cart.forEach(displayCart => {
      if (displayCart.bundles.length > 0){
        if (displayCart.bundles.some(item => matchBundle.some(b => b.id === item.id))){
          matchingDisplays.push(displayCart.id);
        }
      }
    });
    // Delete the displays that have the same bundle
    const listNewBundles = listBundle.filter(item => !matchBundle.some(b => b.id === item));
    removeBundlesFromCart(matchingDisplays, listNewBundles);
    handleDeleteModal();
    return;
  }

  return (
    <Modal
        bodyStyle={{ padding: 20 }}
        title={t('Atention!')}
        visible={deleteModalVisible}
        okText={t('Ok')}
        cancelText={t('Cancel')}
        onOk={() => removeDisplayInBundlerFromCart(matchBundle)}
        onCancel={handleDeleteModal}>
            <p>
             <Icon style={{marginRight:"10px"}} type="exclamation-circle" />
             {t('When deleting this screen, the packages to which it belongs, as well as the screens associated with those packages, will be automatically removed from the cart.')}
            </p>
            <div>
                <div style={{color: "#342f2f91",marginBottom: "5px"}}>
                    <b>{t('Belongs to the following packages')}</b>
                </div>
                {matchBundle && matchBundle.map((bundle, index) => (
                <li key={index} style={{marginBottom: "10px"}}>
                  <Icon component={BundleSvg} style={{width:"20px", height:"20px", marginRight: "5px"}} className="screenCardImage"/>
                  <span>{bundle.name}</span>
                </li>
                ))}
            </div>
    </Modal>
  )
}
export default DeleteBundleModalComponent;
