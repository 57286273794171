import { connect } from 'react-redux'
import ScreenTypePreviewComponent from '../components/ScreenTypePreviewComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ userAccount, display }) => ({
  user: userAccount.user,
  token: userAccount.token,
  customContents: display.customContents,
  defaultContent: display.defaultContent,
  cart: display.cart,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  realAmount: display.realAmount,
  defaultRules: display.defaultRules,
  date_from: display.date_from,
  date_to: display.date_to,
  loadingUploadFile: display.loadingUploadFile,
  fileNew: display.fileNew,
  canva: display.canva,
  audience: display.audience,
  loadingAudience: display.loadingAudience,
  editCampaignActive: display.editCampaignActive,
  reportSingleData: display.reportSingleData,
  totalPromisedShows: display.totalPromisedShows,
  currentCampaign: display.currentCampaign,
  currentDisplay: display.currentDisplay,
  currency: userAccount.currency,
  fetching: display.fetching,
  client: display.client,
  external_dsp_id: display.external_dsp_id,
  client: display.client,
  audienceHoursOfDay: display.audienceHoursOfDay,
  audienceDaysOfWeek: display.audienceDaysOfWeek,
})

const mapStateToDispatch = (dispatch) => ({
  addCustomContent: (display, displayId, relationId, typePreview) => dispatch(DisplayActions.addCustomContent(display, displayId, relationId, typePreview)),
  toggleFillScreen: (displayId, relationId, value, typePreview) => dispatch(DisplayActions.toggleFillScreen(displayId, relationId, value, typePreview)),
  updateSubsidizedPrice: (relationId, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "subsidized_price", typePreview)),
  updatePromisedShowsSubsidized: (relationId, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "promised_shows_subsidized", typePreview)),
  setActive: (relationId, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "active", typePreview)),
  updateContentName: (relationId, content, value, typePreview) => dispatch(DisplayActions.updateFieldInContent(relationId, content, value, "content_name", typePreview)),
  updateDefaultContent: (content) => dispatch(DisplayActions.updateDefaultContent(content)),
  updateRulesRelationScreen: (displayId, relationId, newRules) => dispatch(DisplayActions.updateRulesRelationScreen(displayId, relationId, newRules)),
  cloneRulesRelationScreen: (rulesToClone) => dispatch(DisplayActions.cloneRulesRelationScreen(rulesToClone)),
  updateImpressionsDisplay: (displayId, relationId, impressions, promised_shows_update_in_transfer, add_max_in_transfer) => dispatch(DisplayActions.updateImpressionsDisplay(displayId, relationId, impressions, promised_shows_update_in_transfer, add_max_in_transfer)),
  updateCustomContentRequest: (id, displayId, relationId, typePreview) => dispatch(DisplayActions.updateCustomContentRequest(id, displayId, relationId, typePreview)),
  cloneSpotsRelationScreen: (promisedShowsToClone) => dispatch(DisplayActions.cloneSpotsRelationScreen(promisedShowsToClone)),
  updateRealAmount: (realAmount) => dispatch(DisplayActions.updateRealAmount(realAmount)),
  removeRelationFromCustomContents: (displayId) => dispatch(DisplayActions.removeRelationFromCustomContents(displayId)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  removeRelationFromDisplay: (displayId, relationId) => dispatch(DisplayActions.removeRelationFromDisplay(displayId, relationId)),
  editSingleReport: (contentIdOld, contentIdNew, displayId) => dispatch(DisplayActions.editSingleReport(contentIdOld, contentIdNew, displayId)),
  addRelationDisplay: (displayId, relationId, impressions) => dispatch(DisplayActions.addRelationDisplay(displayId, relationId, impressions)),
  uploadFileRequest: (file) => dispatch(DisplayActions.uploadFileRequest(file)),
  setBudget: (amountBudget) => dispatch(DisplayActions.setBudget(amountBudget)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  getDisplayInfoRequest: (id, clientId, currency, invoice_issuing_country, external_dsp_id) => dispatch(DisplayActions.getDisplayInfoRequest(id, clientId, currency, invoice_issuing_country, external_dsp_id)),
  updatePriceCartByDiscount: (displayId, price) => dispatch(DisplayActions.updatePriceCartByDiscount(displayId, price)),
  removeDisplaysCart: () => dispatch(DisplayActions.removeDisplaysCart()),
  getAudienceHoursOfDayRequest: (params) => dispatch(DisplayActions.getAudienceHoursOfDayRequest(params)),
  getAudienceDaysOfWeekRequest: (params) => dispatch(DisplayActions.getAudienceDaysOfWeekRequest(params)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreenTypePreviewComponent)
