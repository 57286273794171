import styled from 'styled-components';

const GenerateSkeletonHolder = styled.div`
    .general-skeleton {
        flex-wrap: nowrap;
        gap: 20px;
        margin: 0px !important;
    }
    .skeletonCard li{
        height: 100%;
    }
    .skeletonCard .ant-skeleton-title {
        display: none;
    }
    .skeletonCard .ant-skeleton-paragraph {
        margin: 0px;
    }

    @keyframes pulse {
        0% {
            background-color: #f0f0f0;
        }
        50% {
            background-color: #e0e0e0;
        }
        100% {
            background-color: #f0f0f0;
        }
    }
    .skeleton-bar {
        width: 100%;
        margin-bottom: 8px;
    }

    .graph-skeleton-container {
        height: ${props => props.height}px;
        position: relative;
        background: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05);
    }

    .y-axis {
        position: absolute;
        left: 0;
        top: 20px;
        bottom: 40px;
        width: 1px;
        background-color: #e8e8e8;
    }

    .y-axis-mark {
        position: absolute;
        left: -5px;
        width: 10px;
        height: 1px;
        background-color: #e8e8e8;

        &:nth-child(1) { bottom: 0%; }
        &:nth-child(2) { bottom: 33%; }
        &:nth-child(3) { bottom: 66%; }
        &:nth-child(4) { bottom: 100%; }
    }

    .x-axis {
        position: absolute;
        left: 0;
        right: 20px;
        bottom: 40px;
        height: 1px;
        background-color: #e8e8e8;
    }

    .bars-container {
        position: absolute;
        left: 30px;
        right: 20px;
        bottom: 40px;
        top: 20px;
        display: flex;
        align-items: flex-end;
        gap: 16px;
    }

    .skeleton-bar {
        flex: 1;
        min-width: 30px;
        background: #f0f0f0;
        animation: pulse 1.5s infinite;
        border-radius: 4px 4px 0 0;
        transition: height 0.3s ease;
    }

    @keyframes pulse {
        0% { background-color: #f0f0f0; }
        50% { background-color: #e0e0e0; }
        100% { background-color: #f0f0f0; }
    }
`;

export default GenerateSkeletonHolder;