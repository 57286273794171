import styled from "styled-components";
import Colors from "../../themes/Colors";

const VideoPlayerHolder = styled.div`
position: absolute;
inset: 0px;

.video-container {
  position: relative;
  width: fit-content;
  margin: auto;
}
.thumbnail-wrapper {
  position: relative;
  display: inline-block;
}

.thumbnail-wrapper img {
  display: block;
  max-width: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.screen {
  background-color: ${Colors.black};
  border: 4px solid #6f6f70;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenPreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.screenPreviewFilled {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

`;

export default VideoPlayerHolder;