import styled from "styled-components";
import Colors from "../../themes/Colors";

const SearchBoxHolder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin: 20px 0 15px;
    color: ${Colors.snow};
    font-size: 25px;
    font-weight: 600;
  }
  .datePicker {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    right: 1px;
    z-index: 2;

    @media (min-width: 480px) {
      flex-direction: row;
    }
    @media (max-width: 575px) {
      margin: 0 0 15px 0;
    }
  }
  .searchBox {
    margin-top: 70px;
    z-index: 11;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
  .zonePicker {
    margin: 0 0 15px 0px;

    @media (max-width: 480px) {
      margin: 0 0 15px 0px;
    }
  }
  .ant-input {
    height: 60px;
    border-right-width: 1px;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #dddddd;
    border-radius: 10px 0 0 10px;
    z-index: 1;
    font-family: "Poppins", sans-serif;
    padding-top: 25px;
    font-size: 14px;

    @media (max-width: 767px) {
      border-bottom-right-radius: 35px;
      border-top-right-radius: 35px;
    }
  }
  .ant-input::placeholder {
    color: #9c9c9c;
  }
  .ant-input:hover {
    border-color: ${Colors.snow};
  }
  .ant-input:focus {
    border-color: ${Colors.snow};
    box-shadow: none;
  }
  .ant-input-group-addon {
    border-bottom-left-radius: 35px;
    border-top-left-radius: 35px;
    border: 0px;
    background-color: #ffffff;
    padding-right: 0px;
    color: ${Colors.coal};
  }
  .ant-input-group-addon i {
    margin: 0 8px 0 7px;
    font-size: 15px;
  }
  .zoneTitle {
    left: 54px;
    top: 10px;
    position: absolute;
    z-index: 2;
    font-size: 12px;
    color: #424242;
  }
  /* DATA RANGE */

  .calendarIcon {
    left: 21px;
    top: 10px;
    position: absolute;
    z-index: 1;
    font-size: 15px;
    color: #424242;
    padding-top: 9px;
  }
  .dateStartTitle {
    left: 54px;
    top: 10px;
    position: absolute;
    z-index: 2;
    font-size: 12px;
    color: #424242;
  }
  .dateEndTitle {
    left: 50.2%;
    top: 10px;
    position: absolute;
    z-index: 2;
    font-size: 12px;
    color: #424242;
  }

  .DateRangePickerInput_calendarIcon_svg {
    fill: ${Colors.coal};
  }

  .DateRangePickerInput_calendarIcon:focus {
    outline: none;
  }

  .DateRangePicker {
    width: 100%;
    border-radius: 35px;
  }
  .DateRangePickerInput__withBorder {
    border: 0px;
    width: 100%;
    border-radius: 35px;
  }
  .DateInput_input::placeholder {
    color: #9c9c9c;
  }
  .DateInput {
    width: calc(50% - 12px);
    border-radius: 35px;
    height: 60px;
  }
  #your_unique_start_date_id {
    padding-left: 54px;
    padding-top: 25px;
    padding-bottom: 6px;
    height: 60px;
    font-size: 14px;

    @media (max-width: 767px) {
      border-bottom-left-radius: 35px;
      border-top-left-radius: 35px;
    }
  }
  #your_unique_end_date_id {
    border-left: 1px solid #ddd;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding-top: 25px;
    padding-bottom: 6px;
    height: 60px;
    font-size: 14px;
  }
  .DateInput_input {
    border-bottom: 0px;
    color: ${Colors.coal};
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }
  .DateInput_input__focused {
    border-bottom: 0px;
  }
  /* CALENDAR DAY */
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${Colors.primary};
    border: 1px solid ${Colors.primary};
    color: #fff;
  }

  .CalendarDay__selected_span {
    background: ${Colors.lightPrimary};
    border: 1px solid ${Colors.lightPrimary};
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${Colors.lightPrimary};
    border: 1px solid ${Colors.lightPrimary};
    color: #fff;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${Colors.lightPrimary};
    border: 1px solid ${Colors.lightPrimary};
    color: #fff;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid ${Colors.primary};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid ${Colors.secondary};
  }

  .DayPicker_transitionContainer.DayPicker_transitionContainer__vertical {
    height: 350px !important;
  }

  .companySelect {
    margin-right: 15px !important;
    width: 95%;
    margin: 0 0 0;
    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
  .brandSelect {
    width: 95%;
    margin: 0 0 0;
    @media (max-width: 575px) {
      margin-right: 0px !important;
      width: 100%;
    }
  }
  .custom-container {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    width: 97%;
  }

  .addon-before-content {
    position: relative;
    padding: 0 11px;
    color: #424242;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border-bottom-left-radius: 35px;
    border-top-left-radius: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .custom-container .ant-select-selection--single {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  .order-programmatic{
    display: flex;
    order: 0;
    border: 1px solid transparent;
    margin: 0px 2px;
    @media (max-width: 575px) {
      display: grid;
    }
  }
  .order-data{
    @media (max-width: 575px) {
      order: 2;
    }
  }
  .order-data-col{
    width: 95%;
    @media (max-width: 575px) {
      width: 100%;
      margin-top: 15px;
    }
  }
  .order-budget{
    @media (max-width: 575px) {
      order: 1;
    }

  }
  .order-budget-no-user {
    @media (max-width: 575px) {
      order: 1;
    }
    margin-left: 7px;
    width: 49%;
  }
  .typePurchaseRadioButton {
    margin-right: 4% !important;
    margin: 0 0 15px;
    width: 100%;
    height: 43px;
  }
  .typePurchaseRadioButtonTraditional {
    text-align: center;
    width: 50%;
    padding-top: 5px;
    margin: 0 0 15px;
    height: 43px;
    border-radius: 35px 0px 0px 35px;
  }
  .typePurchaseRadioButtonProgrammatic {
    text-align: center;
    width: 50%;
    padding-top: 5px;
    margin: 0 0 15px;
    height: 43px;
    border-radius: 0px 35px 35px 0px;
  }
  .budgetForm {
    .ant-form-explain {
      color: #ffffff;
    }
    .ant-input:not([disabled]):hover {
      color: #ffffff;
    }
  }
  .budgetInput {
    margin-right: 15px !important;
    margin: 0 0 0;
    @media (max-width: 575px) {
      margin-right: 0px !important;
      width: 100%;
      ${'' /* margin-bottom: 15px; */}
    }
    .ant-input {
      height: 43px;
      border-radius: 0px 35px 35px 0px;
      padding-top: 3px;
      border-style: solid solid solid hidden;
      border-color: #e8e8e8;
      border-width: 1px;
    }
    .ant-input-group-addon{
      border-style: solid hidden solid solid;
      border-color: #e8e8e8;
      border-width: 1px;
    }
  }
  .ant-select-selection--single {
    height: 43px;
    border-radius: 35px;
  }
  .ant-select-selection__rendered {
    padding: 0px 11px;
    line-height: 42px;
  }
  .ant-select-selection__placeholder {
    padding: 0px 11px;
    font-size: 16px;
    color: #bfbfbf;
  }

  .searchButton {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 7px;
    margin: 0 0 15px 10px;
    height: 46px;
    width: 46px;
    font-weight: 600;
    font-size: 20px;
    border-radius: 30px;
    padding: 0 12px !important;
    color: #fff;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};

    @media (max-width: 480px) {
      margin: 0 0 15px 10px;
    }
  }
  .searchButton:hover {
    color: #fff;
    background-color:${Colors.lightPrimary};
    border-color: ${Colors.primary};;
  }

`;

export default SearchBoxHolder;
