import styled from 'styled-components'
import Colors from '../../themes/Colors'

const CheckoutDetailsHolder = styled.div`
  h3{
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }
  h4{
    color: ${Colors.coal};
    font-weight: 600;
  }
  .detailsCard{
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: 0 0 20px;
  }
  .screenRow{
    display: flex;
    justify-content: space-between;
    color: ${Colors.coal};
  }
  .subTotal{
    display: flex;
    justify-content: flex-end;
    color: ${Colors.coal};
    border-top: 2px solid #a3a3a3;
    margin: 10px 0 0;
    padding: 10px 0 0;
  }
  .subTotalText{
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0;
    margin-bottom: 3px;
  }
  .subTotalText h3{
    color: ${Colors.green} !important;
    font-weight: 400;
  }
  .total{
    display: flex;
    justify-content: flex-end;
    color: ${Colors.coal};
  }
  .total .taxTooltip{
    font-size: 16px;
    margin-right: 5px;
  }
  .total h2{
    margin-bottom: 3px;
    color: ${Colors.green};
    font-weight: 600;
  }
  .iva {
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }
  .iva span{
    font-size: 11px !important;
  }
  .discount {
    font-weight: 600;
  }
  .itemContainer {
    height: 35px;
  }
  .itemName {
    float: left;
  }
  .itemPrice {
    float: right;
  }
  .payerCosts {
    border-top: 2px solid #a3a3a3;
    padding-top: 10px
    display: -webkit-box;
  }
  .cardInfoError {
    color: #f5222d;
  }
  .iconCloseDiscount {
    color: #f5222d;
  }
  .checkoutButton{
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
  }
`;

export default CheckoutDetailsHolder;
