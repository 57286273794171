import styled from "styled-components";
import Colors from "../../themes/Colors";

const MapHolder = styled.div`
  margin-top: 120px
  position: fixed;
  width: 100%;

  .pulseLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listContainer {
    padding: 10px 5px 0px 10px;
    background-color: ${Colors.background};
  }
  .positionAbs {
    position: absolute;
    z-index: 101;
    width: 96%;
    background-color: #fafafa;
    box-shadow: 0px 7px 19px -7px rgb(39 140 242 / 58%), 0px 10px 15px -38px rgb(0 0 0 / 12%);
    border-radius: 0px 16px 16px;
  }

  .typePurchaseRadioButton {
    margin-right: 4% !important;
    width: 100%;
    height: 43px;
  }
  .typePurchaseRadioButtonTraditional {
    text-align: center;
    width: 50%;
    padding-top: 5px;
    height: 43px;
    border: 0;
    background: #dfeeff9c !important;
    border-radius:8px 0 0 8px;
  }

  .typePurchaseRadioButtonProgrammatic {
    text-align: center;
    width: 50%;
    padding-top: 5px;
    height: 43px;
    border: 0;
    background: #dfeeff9c !important;
    border-radius:  0 8px 8px 0;
  }
  .programmaticFilterButton {
    height: 16px;
    border: 0px;
    padding: 0px;
    background-color: #f1f2f2;
    color: ${Colors.primary};
  }
  .buttonSegmentCheck {
    color: ${Colors.primary};
    border-color: ${Colors.primary};
  }
  .buttonSegmentUncheck {
    color: #636363;
    border-color: #dadada;
  }
  .budgetForm {
    margin: 0 0 15px 15px;
    @media (max-width: 768px) {
      margin: 0 0 15px 0;
    }
  }
  .budgetInput {
    height: 43px;
    margin: 0 0 15px 0px;
    .ant-input {
      border-radius: 8px;
    }
    @media (max-width: 768px) {
      margin: 0 0 15px 0;
    }
  }
  .searchInput {
    .ant-input {
      border-radius: 8px;
    }
    ${"" /* @media (max-width: 768px) {
      margin: 0 0 15px 0;
    } */}
  }
  .programmaticSection {
    width:100%;
  }

  /* ZONE PICKER */

  .resultMapContainer {
    border-radius: 16px;
    padding: 10px 10px 10px 5px;
    position: relative;
  }

  .resultMapContainerMobile {
    padding: 5px 10px 10px 10px;
  }

  .customCol{
    margin-bottom: 8px;
  }

  .mapFiltersContainer{
    z-index: 98;
    position: absolute;
    padding: 10px 30px 0 10px;
    width: 100%;

    ${
      "" /* @media only screen and (min-width: 1441px) {
      left: calc(680px + 90px + 28px);
    } */
    }
  }

  .zonePickerContainer {
    z-index: 98;
    position: absolute;
    top: 20px;
    right: 30px;
    margin-left: 20px;
    @media only screen and (min-width: 1441px) {
      left: calc(680px + 90px + 28px);
    }


  }
  .zonePicker .ant-input {
    height: 48px !important;
    border-color: ${Colors.snow};
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "Poppins", sans-serif;
  }
  .zonePicker .ant-input:hover {
    border-color: ${Colors.snow};
  }
  .zonePicker .ant-input:focus {
    border-color: ${Colors.snow};
    box-shadow: none;
  }
  .zonePicker .ant-input-group-addon {
    height: 47px !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid white;
    background-color: #ffffff;
    padding-right: 0px;
    color: ${Colors.coal};
  }
  .zonePicker .ant-input-group-addon i {
    margin: 0 8px 0 7px;
  }

  /*
 SCROLLBAR
 */

 .screensQuantity {
    color: ${Colors.primary};
    font-weight: 600;
    font-size: 18px;
  }

  .rowSearch {
    text-align: end;
  }

  .allDisplaysSwitch {
    color: ${Colors.primary};
  }

  .listContent::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .listContent::-webkit-scrollbar {
    width: 1px;
  }

  .listContent::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #424242;
  }

  .listContent h2 {
    color: ${Colors.primary};
    font-weight: 600;
    font-size: 18px;
  }

  .listContent .screensQuantity {
    color: ${Colors.primary};
    font-weight: 600;
    font-size: 18px;
  }
  .listContent .rowSearch {
    text-align: end;
  }
  .listContent .allDisplaysSwitch {
    color: ${Colors.primary};
  }

  .listContent {
    margin-top: 155px;
    background-color: ${Colors.background};

    height: calc(100vh - 85px - 44px - 750px);

    @media (min-width: 576px) {
      height: calc(90vh - 85px - 77px - 50px);
    }

    @media (max-width: 576px) {
      margin-top: 155px;
      padding: 0px 15px 10px 15px;
    }
  }
  .lineSepareteIcon {
    font-size: 30px;
    // position: absolute;
    text-align: center;
  }

  .mapHeight {
    height: calc(100vh - 75px - 63px);

    @media (min-width: 992px) {
      height: calc(100vh - 65px - 77px);
    }
  }

  .mapHeightMobile {
    height: 480px;

    @media (max-height: 660px) {
      height: 380px;
    }
  }

  .listContent h2 {
    color: ${Colors.primary};
    font-weight: 600;
    font-size: 18px;
  }
  .paginationContainer {
      width: 32%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 10px;
      background-color: ${Colors.background};
      padding: 10px 0;
      z-index: 100;
  }
  @media (max-width: 575px) {
    .paginationContainer {
        width: 100%;
        left: 0;
    }
  }
  @media (min-width: 1160px) {
    .paginationContainer {
        width: 33%;
        left: 0;
    }
  }
  .ant-pagination-item-active {
    border-color: ${Colors.primary};
  }
  .ant-pagination-item-active a {
    color: ${Colors.primary};
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: ${Colors.primary};
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: ${Colors.primary};
  }

  /*screenCardMap Map*/

  .infoBox {
    margin-left: -100px;
    width: 200px;
    ${"" /* height: 290px; */}
    position: static;
    border-radius: 8px;
  }

  .screenCardMap {
    margin-top: 18px;
    margin-bottom: 1px;
    position: relative;
    background-color: ${Colors.snow};
    display: flex;
    width: 195px;
    height: 100%;
    padding: 15px 0px 8px 0px;
    overflow: hidden;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  }

  .screenCardMap:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .screenCardMap:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 14px;
    margin-left: -190px;
  }
  .screenCardMap:before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 14px;
    margin-left: -14px;
  }

  .screenCardMapImage {
    border-radius: 8px;
    width: 150px;
    height: 80px;
    object-fit: cover;
  }
  .screenImageCampaign {
    max-height: 280px;
  }
  .screenCardMapContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    ${"" /* height: 160px; */}
    ${"" /* width: 100px; */}
    padding: 0px;
  }
  .ant-badge-count {
    box-shadow: none;
    right: unset;
    left: -5px;
    top: unset;
    bottom: -8px;
  }
  ${
    "" /* .screenCardContentInImage {
    position: absolute;
    display: flex;
    right: 0px;
    left: 0px;
    justify-content: center;
    ${"" /* background-color: rgba(42, 42, 42, 0.6); */
  }
    width: 100%;
    ${"" /* padding: 8px 10px 8px 10px; */}
    max-height: 55px;
  } */}
  .screenCardMapPrice {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 6px;
    color: #636363;
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    color: ${Colors.primary};
    font-weight: "bold";
  }
  .screenCardMapTitle {
    font-family: "Poppins", sans-serif !important;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    font-size: 12px;
    line-height: 110%;
    margin-bottom: 0;
  }
  .screenCardMapDownInfo {
    font-family: "Poppins", sans-serif !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
  }
  .screenCardMapInfo {
    margin: 0px 0 4px;
    font-size: 13px;
    font-weight: 600;
    b {
      color: ${Colors.primary};
    }
  }
  .screenCardButtonsContainer {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 8px;
  }
  .screenCardMapButton {
    margin: 0 5px;
    padding-left: 0;
    padding-right: 0;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenCardButtonsContainer .anticon-info-circle {
    color: ${Colors.primary} !important;
  }
  .screenCardMapButtonErase {
    margin: 0 5px;
    padding-left: 0;
    padding-top: 5px;
    padding-right: 5px;
    background-color: ${Colors.snow};
    border-color: ${Colors.primary};
    color: ${Colors.primary};
  }
  .screenCardMapDetailsButton {
    border-color: ${Colors.primary};
    color: ${Colors.primary};
  }
  .screenCardMapCloseButton {
    position: absolute;
    top: 0px;
    z-index: 7;
    right: 10px;
    background-color: ${Colors.snow};
    border-color: ${Colors.snow};
    color: ${Colors.primary};
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  }
  .ant-input-group-addon {
    border-color: ${Colors.primary} !important;
    border-right-style: none !important;
  }
  .ant-input-lg {
    border-color: ${Colors.primary} !important;
    border-left-style: none !important;
  }

  .searchSection {
    padding-bottom: 3%;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 32px;
    justify-items: center;
    align-items: center;
    z-index: 101;
  }
  .searchResultsContainer{
    @media only screen and (max-width: 576px) {
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  }
  .cardList {
    padding-top: 2%;
    padding-bottom: 4%;
    height: inherit;
    overflow-y: scroll;
   @media only screen and (max-width: 576px) {
      height: fit-content;
      padding-bottom: 30%;
    }
  }
  .cardList::-webkit-scrollbar{
    width: 2px;
  }

   .customHeader .ant-collapse-header {
    padding: 5px 0px 5px 0px !important;
    padding-left: 0px;
    text-align: center;
    bottom: 10px;
    font-family: "Poppins", sans-serif !important;
    transition: color 0.3s ease-in-out;

    &:hover {
    color: ${Colors.primary};
    }

  }
  .customContent .ant-collapse-content-box {
    padding:  5px 10px 20px 10px !important;
    text-align: center;
  }

  @media (min-width: 768px) {
    .gmnoprint button{
      height: 50px;
      width: 50px;
    }
    
    .gmnoprint button span {
      display: flex !important;
      justify-content: center;
    }

    .gmnoprint button[aria-checked="true"] {
      border: 1px #9fcef5 solid !important;
    }
  
    .gmnoprint button[title="Dejar de dibujar"] {
      border-bottom-left-radius: 15px !important;
      border-top-left-radius: 15px !important;
    }
  
    .gmnoprint button:is([title="Dibujar forma"],[title="Trazar una forma"]) {
      border-bottom-right-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }

    .gmnoprint button span {
      transform: scale(1.25)
    }
  }
  /* setting css for the checkbox search when move map */
  .customCheckStyle {
    border-radius: 8px;
    border-color: ${Colors.primary};
    border-width: 1px;
    margin-top: 5px;
    margin-bottom: 12px;
  }

  .customCheckStyle .ant-card-body {
      padding: 8px !important;
  }

  /* Styling the Checkbox to look like a radius*/
  .customCheckStyle .ant-checkbox-wrapper {
    display: inline-block;
    line-height: unset;
    margin-right: 8px;
  }

  .customCheckStyle .ant-checkbox-inner {
    border-radius: 50% !important;
    border-color: ${Colors.primary};
  }

  .customCheckStyle .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  .addIconBundle {
    color: var(--primary);
    font-size: 16px;
    padding-left: 3px;
    padding-top: 12px;
    bottom: 21px;
    left: 16px;
    position: absolute;
  }
  .minusIconBundle {
    color: #d16060;
    font-size: 16px;
    padding-left: 3px;
    padding-top: 5px;
    bottom: 5px;
    left: 21px;
    position: absolute;
  }
`;

export default MapHolder;
