import styled from "styled-components";
import Colors from "../../themes/Colors";

const AudienceGraphHolder = styled.div`
    .audience-graph {
        padding: 5px;
        margin: 10px;
    }

    h3 {
        font-size: 16px;
        font-weight: 600;
    }

    h5 {
        font-size: 14px;
        font-weight: 400;
    }

    .audience-graph-container {
        margin: 10px;
        height: 300px;
        max-height: 300px;
    }

    select {
        width: 100%;
        height: 120px;
        padding: 5px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
    }

    .card-container {
        min-height: 100px;
        padding: 15px;
        text-align: center;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .data-title {
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }

    .card-container-principal {
        min-height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

`;

export default AudienceGraphHolder;
