export const markerCircuitOutdoor = () => {
  const lightPrimary = getComputedStyle(document.documentElement).getPropertyValue('--lightPrimaryPins').trim();
  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
          .cls-1 {
            stroke-width: .79px;
          }

          .cls-1, .cls-2, .cls-3 {
            stroke-miterlimit: 10;
          }

          .cls-1, .cls-2, .cls-4, .cls-5 {
            fill: #fff;
            stroke: #192a39;
          }

          .cls-2, .cls-4, .cls-5 {
            stroke-linecap: round;
          }

          .cls-2, .cls-5 {
            stroke-width: .79px;
          }

          .cls-3 {
            fill: ${lightPrimary}
            stroke: #1d71b8;
            stroke-width: .74px;
          }

          .cls-4 {
            stroke-width: .79px;
          }

          .cls-4, .cls-5 {
            stroke-linejoin: round;
          }
        </style>
      </defs>
      <g id="Group_14">
        <g id="Group_13">
          <path id="Path_8" class="cls-3" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
        </g>
      </g>
      <g>
        <line class="cls-4" x1="37.14" y1="35.74" x2="35.46" y2="39.13"/>
        <line class="cls-4" x1="13.62" y1="35.74" x2="15.3" y2="39.13"/>
      </g>
      <line class="cls-4" x1="27.11" y1="22.7" x2="23.65" y2="22.72"/>
      <g>
        <g>
          <line class="cls-5" x1="37.5" y1="28.91" x2="37.5" y2="21.78"/>
          <rect class="cls-1" x="30.52" y="15.99" width="13.96" height="8.34" rx="1.63" ry="1.63"/>
        </g>
        <g>
          <line class="cls-5" x1="13.26" y1="28.91" x2="13.26" y2="21.78"/>
          <rect class="cls-1" x="6.28" y="15.99" width="13.96" height="8.34" rx="1.63" ry="1.63"/>
        </g>
      </g>
      <g>
        <line class="cls-2" x1="25.38" y1="49.72" x2="25.38" y2="42.59"/>
        <rect class="cls-1" x="18.4" y="36.79" width="13.96" height="8.34" rx="1.63" ry="1.63"/>
      </g>
    </svg>`;
  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};