import React from 'react';

class HideWhenCampaignActiveComponent extends React.Component {

  isContentInCurrentCampaign = (contentId, displayId = null) => {
    const { currentContentsDisplays, editCampaignActive, isPaymentStatusApproved } = this.props;
    if (!editCampaignActive) return false;
    if (!isPaymentStatusApproved) return false;
    if (currentContentsDisplays.length === 0) return false;
    if (!displayId) {
      return currentContentsDisplays.some(content => content.contentId === contentId);
    }
    if (displayId) {
      return currentContentsDisplays.some(content => content.contentId === contentId && content.displayId === displayId);
    }
  };

  render() {
    const { contentId, displayId, children } = this.props;
    const shouldRender = !this.isContentInCurrentCampaign(contentId, displayId);

    if (!shouldRender) return null;

    return (
      <>
        {children}
      </>
    );
  }
}

export default HideWhenCampaignActiveComponent;