import styled from "styled-components";

const AudienceProviderInfoHolder = styled.div`
    .audience-provider-info-component {
        background-color: #f3f7fa;
        border: 1px solid #eee;
        padding: 5px;
        margin: 10px;
    }

    .audience-provider-info-component .small-provider-image {
        max-width: 18px;
        max-height: 18px;
        border-radius: 100%;
        margin-right: 5px;
        margin-left: 5px;

    }

    .audience-provider-info-component .provider-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }


    .audience-provider-info-component .provider-container .provider-image {
        max-height: 35px;
        max-width: 35px;
        margin-right: 10px;
        border-radius: 100%;
    }

`;

export default AudienceProviderInfoHolder;
