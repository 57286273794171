import React, { useEffect, useRef, useState } from "react";
import { Input, Progress, Row } from "antd";
import { useTranslation } from "react-i18next";
import SearchInputHolder from "./Styles/searchInputStyle";

const SearchInput = ({ id, name, value, defaultValue, disabled, loading,
  type = "sync", autoFocus, onChange, onEnter }) => {
  const { t } = useTranslation();

  const debounceTimeout = useRef(null);

  const [inputValue, setInputValue] = useState(value);
  const [loadingTimeout, setLoading] = useState(false);

  const handleChange = (e) => {
    e.persist();
    const newValue = e.target.value;
    setInputValue(newValue);
    if (!onChange) return;
    if (type === "async") {
      clearTimeout(debounceTimeout.current);

      setLoading(true);
      debounceTimeout.current = window.setTimeout(() => {
        onChange(e);
        setLoading(false);
      }, 1000);
      return;
    }

    onChange(e);
  };

  const handleBlur = (e) => {
    if (type !== "async" && onChange) return;

    clearTimeout(debounceTimeout.current);
    setLoading(false);
    onChange(e);
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    if (type === "sync") return onEnter();

    handleBlur(e);
  };


  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <SearchInputHolder>
      <Row
        type="flex"
        style={{ flexDirection: "column" }}
      >
        <Input
          id={id || "search"}
          name={name || "search"}
          value={inputValue || null}
          defaultValue={defaultValue || null}
          placeholder={t("Search")}
          maxLength={150}
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        <Progress
          percent={100}
          status="active"
          showInfo={false}
          style={{
            visibility: (loadingTimeout || loading) ? "visible" : "hidden",
          }}
        />
      </Row>
    </SearchInputHolder>
  );
}

export default SearchInput;
