import { connect } from 'react-redux'
import CampaignsComponent from '../components/CampaignsComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ display, userAccount }) => ({
  campaigns: display.campaigns,
  user: userAccount.user,
  fetching: display.fetching,
  loadingDeleteCampaign: display.loadingDeleteCampaign,
  errors: display.errors,
  loadingReport: display.loadingReport,
  reportToken: display.reportToken,
  proposalToken: display.proposalToken,
  loadingProposal: display.loadingProposal,
  totalCampaigns: display.totalCampaigns,
  pageCampaigns: display.pageCampaigns,
  loadingArchiveCampaign: display.loadingArchiveCampaign,
})

const mapStateToDispatch = (dispatch) => ({
  getCampaigns: (params) => dispatch(DisplayActions.getCampaignsRequest(params)),
  setPageCampaigns: pageCampaigns => dispatch(DisplayActions.setPageCampaigns(pageCampaigns)),
  deleteCampaign: (campaignId) => dispatch(DisplayActions.deleteCampaignRequest(campaignId)),
  archiveCampaign: (campaignId, params) => dispatch(DisplayActions.archiveCampaignRequest(campaignId, params)),
  getReportRequest: (reportData) => dispatch(DisplayActions.getReportRequest(reportData)),
  getProposalRequest: (proposalData) => dispatch(DisplayActions.getProposalRequest(proposalData)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  createPurchaseOrdersRequest: (companyId, campaignId) => dispatch(DisplayActions.createPurchaseOrdersRequest(companyId, campaignId)),
})

export default connect(mapStateToProps, mapStateToDispatch)(CampaignsComponent)
