import React from 'react';
import { Modal, Alert } from 'antd';
import { withTranslation } from 'react-i18next';


const VerifiedAgencyInfoModal = ({ isVisible, hide, message = null, t }) => {

    return (
        <>
            <Modal
                title={t("Limited functionality")}
                visible={isVisible}
                onOk={hide}
                onCancel={hide}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <div style={{margin:"24px"}}>
                    {message ? <p>{message}</p> : <></>}
                    <p>{t("Verified agencies text")} <a href='https://help.latinad.com/faq-agencias/#hfaq-post-4111' target="_blank">help.latinad.com</a></p>
                </div>
            </Modal>
        </>
    )
};

export default withTranslation()(VerifiedAgencyInfoModal);