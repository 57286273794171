export const markerOutdoorOnCart= () => {
  const primary = getComputedStyle(document.documentElement).getPropertyValue('--primaryPins').trim();

  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
        .cls-1 {
          fill: #fff;
        }

        .cls-1, .cls-2 {
          stroke-miterlimit: 10;
        }

        .cls-1, .cls-3 {
          stroke: #192a39;
          stroke-width: 1.56px;
        }

        .cls-2 {
          fill: ${primary};
          stroke: #1d71b8;
        }

        .cls-3 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
        </style>
      </defs>
      <path id="Path_8" class="cls-2" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
      <line class="cls-3" x1="25.38" y1="43.96" x2="25.38" y2="29.64"/>
      <rect class="cls-1" x="11.36" y="18" width="28.04" height="16.75" rx="3.27" ry="3.27"/>
    </svg>`;
  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};