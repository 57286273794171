import React, { Component } from "react";
import {
  Button,
  Icon,
  Tooltip,
  Typography,
  Badge,
  Modal,
  Row,
  Col,
  Collapse,
} from "antd";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import NumberFormat from "./NumberFormat";
import ScreenContainer from "../containers/ScreenContainer";
import { Provider } from "react-redux";
import { store } from "../redux";
import placeholder from "../images/bg.jpg";
import { withTranslation } from 'react-i18next';
import { BundleSvg } from "../images/customIcons/BundleSvg";
import { checkIfBundle } from "../services/display";

const Panel = Collapse.Panel;

const { Paragraph } = Typography;

class MapScreenInfoBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScreenDetail: false,
      defaultTabScreenDetail: null,
      displaysAudience: [],
    };
  }

  getSizeType(type) {
    const { t } = this.props;

    if (type === "small") {
      return t("Small");
    }
    if (type === "medium") {
      return t("Medium");
    }
    if (type === "big") {
      return t("Big");
    }
    if (type === "giant") {
      return t("Giant");
    }
  }

  countDisplays(display) {
    let count = 0;
    display.forEach((display) => {
      count += display.mirror_screens_count;
    });
    return count;
  }

  handleShowScreenDetail = (defaultTabScreenDetail) => {
    this.setState((prevState) => ({
      showScreenDetail: !prevState.showScreenDetail,
    }))
    if (defaultTabScreenDetail != "close"){
      this.setState(() => ({
        defaultTabScreenDetail: defaultTabScreenDetail,
      }));
    }
  };

  render() {
    const {
      t,
      display,
      onCart,
      handleClose,
      paymentStatus,
      addDisplayToCart,
      removeDisplayFromCart,
      listBundle,
    } = this.props;

    const { showScreenDetail , defaultTabScreenDetail} = this.state;
    const customPanelStyle = {
      background: "transparent",
      border: 0,
      width: "100%",
      justifyContent: "center",
    };
  
    let countBundlesAdded = checkIfBundle (display.bundles, listBundle)
    let infoCircuit = display.name_secondary_display ? display.name : null
    let countSecondaryDisplays = this.countDisplays(display.secondary_displays);
    let capFirstLetter = (display.name_secondary_display ? display.name_secondary_display : display.name)[0].toUpperCase();
    let restOfName = (display.name_secondary_display ? display.name_secondary_display : display.name).slice(1);
    const modalWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth > 992 ? 900 : window.innerWidth;

    return (
      <InfoBox
        onCloseClick={() => handleClose()}
        options={{
          closeBoxURL: ``,
          enableEventPropagation: true,
          disableAutoPan: true,
          boxStyle: {},
        }}
      >
        <>
          <Button
            className="screenCardMapCloseButton"
            onClick={() => handleClose()}
            shape="circle"
            icon="close"
          />
          <div className="screenCardMap">
            <div className="screenCardMapContent">
              <Row type={"flex"} style={{ width: "100%" }}>
                <Col className="screenCardMapPrice">
                  <NumberFormat
                    value={
                      this.props.programmatic === "programmatic"
                        ? (display.smart_campaign_cpm / 1000) * 100
                           / 100
                        : display.price_per_day
                    }
                    currency={display.price_currency}
                    include_currency={true}
                  />
                  {this.props.programmatic === "programmatic"
                    ? " / " + t("Print")
                    : " / " + t("Day")}
                </Col>
              </Row>
              <Row
                type={"flex"}
                style={{ width: "100%", justifyContent: "center" }}
              >
                <Collapse
                  bordered={false}
                  style={{
                    backgroundColor: "transparent",
                    padding: "5px",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  className="customContent"
                >
                  <Panel
                    header={capFirstLetter + restOfName.toLowerCase()}
                    key="1"
                    showArrow={false}
                    style={customPanelStyle}
                    className="customHeader"
                  >
                    <img
                      src={
                        display.pictures.length > 0
                          ? display.pictures[0].url
                              .split(".")
                              .slice(0, -1)
                              .join(".") + "-thumb.jpg"
                          : placeholder
                      }
                      className="screenCardMapImage"
                      alt=""
                    />
                  </Panel>
                  { infoCircuit ? (
                      <>
                        <p style={{textAlign: "center", paddingRight: "15px", paddingLeft: "15px"}}>
                          {t("Screen belonging to the circuit")} <strong> {infoCircuit} </strong>
                          {t("of count_secondary_displays screens").replace("count_secondary_displays", countSecondaryDisplays)}
                        </p>
                        <p style={{textAlign: "center", fontSize: "10px", color: "#ed3d11a6", marginBottom: "10px"}}>
                          {t("It is not possible to purchase these screens individually.")}
                        </p>
                      </>
                    ) : null
                  }
                </Collapse>
              </Row>
              <div className="screenCardButtonsContainer" align="center">
                {display.display_type === "main" ? (
                  <Tooltip
                    placement="top"
                    title={t("Circuit of n_screen screens").replace(
                      "n_screen",
                      this.countDisplays(display.secondary_displays)
                    )}
                  >
                    <Button shape="circle" className="screenCardMapButton">
                      <Icon
                        style={{
                          fontSize: "20px",
                          paddingTop: "3px",
                        }}
                        type="deployment-unit"
                        theme="outlined"
                      />
                    </Button>
                  </Tooltip>
                ) : null}
                <span style={{ marginRight: "5px" }}>
                  <Tooltip placement="top" title={t("Screen detail")}>
                    <Button
                      shape="circle"
                      onClick={() => this.handleShowScreenDetail(("info"))}
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: "20px",
                          paddingTop: "3px",
                          color: "#3996f3",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </span>
                <Modal
                  title={t("Screen detail")}
                  visible={showScreenDetail}
                  onCancel={() =>this.handleShowScreenDetail("close")}
                  footer={[
                    <Button key="back" onClick={() =>this.handleShowScreenDetail("close")}>
                      OK
                    </Button>,
                  ]}
                  width={modalWidth}
                  style={{ top: "10px" }}
                >
                  <Provider store={store}>
                    <ScreenContainer
                      display={display}
                      screenDetail={showScreenDetail}
                      popup={true}
                      // Tab for bundles
                      defaultTab={defaultTabScreenDetail}
                      editCart={true}
                      closeModal={() => this.handleShowScreenDetail("close")}
                    />
                  </Provider>
                </Modal>
                { display.bundles && display.bundles.length > 0 ?
                    <Tooltip placement="top" title={t("Sales bundle details")}>
                      <Button
                        style={{marginLeft: "1px", marginRight: "3px"}}
                        shape="circle"
                        onClick={() => this.handleShowScreenDetail("bundles")}
                      >
                       <Badge
                          count={
                            <>
                              {display.bundles.length > 0 && (
                                countBundlesAdded !== 0 && countBundlesAdded < display.bundles.length ? (
                                  <>
                                    <Icon type="plus-circle" className="addIconBundle" theme="filled"/>
                                    <Icon type="minus-circle" className="minusIconBundle" theme="filled"/>
                                  </>
                                ) : (
                                  countBundlesAdded === display.bundles.length ?
                                    <Icon type="minus-circle" className="minusIconBundle" theme="filled"/>:
                                    <Icon type="plus-circle" className="addIconBundle" theme="filled"/>
                                )
                              )}
                            </>
                          }
                      >
                      <Icon component={BundleSvg} style={{ width: '30px'}}/>
                      </Badge>
                      </Button>
                    </Tooltip>
                  :null}
                {!(
                  paymentStatus === "approved" && this.props.editCampaignActive
                ) && !display.bundle_only ? (
                  <Button
                    shape="circle"
                    className={
                      onCart
                        ? "screenCardMapButtonErase"
                        : "screenCardMapButton"
                    }
                    onClick={() => {
                      if (onCart) {
                        removeDisplayFromCart(display.id);
                      } else {
                        addDisplayToCart(display);
                      }
                      // onCart = !onCart;
                    }}
                  >
                    {onCart ? (
                      <Badge
                        count={
                          <Icon
                            type="minus-circle"
                            theme="twoTone"
                            style={{
                              color: "#f5222d",
                              fontSize: "14px",
                              paddingTop: "2px",
                              paddingRight: "2px",
                              bottom: "-3px",
                              left: "15px",
                            }}
                          />
                        }
                      >
                        <Icon
                          style={{ fontSize: "15px" }}
                          type="plus"
                          theme="outlined"
                        />
                      </Badge>
                    ) : (
                      <Icon
                        style={{
                          fontSize: "16px",
                          paddingTop: "3px",
                        }}
                        type="plus"
                        theme="outlined"
                      />
                    )}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      </InfoBox>
    );
  }
}

export default withTranslation()(MapScreenInfoBox);
