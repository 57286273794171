import { connect } from 'react-redux'
import OnboardingComponent from '../components/OnboardingComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount  }) => ({
  user: userAccount.user,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  automovilFilter: display.automovilFilter,
  shoppingFilter: display.shoppingFilter,
  servicesFilter: display.servicesFilter,
  trainingFilter: display.trainingFilter,
  educationFilter: display.educationFilter,
  bankFilter: display.bankFilter,
  transportFilter: display.transportFilter,
  governmentFilter: display.governmentFilter,
  hotelFilter: display.hotelFilter,
  foodFilter: display.foodFilter,
  healthyFilter: display.healthyFilter,
  genderWomanFilter: display.genderWomanFilter,
  genderManFilter: display.genderManFilter,
  audience: display.audience,
  loadingAudience: display.loadingAudience,
})

const mapStateToDispatch = (dispatch) => ({
  setAutomovilFilter: (automovilFilter) => dispatch(DisplayActions.setAutomovilFilter(automovilFilter)),
  setShoppingFilter: (shoppingFilter) => dispatch(DisplayActions.setShoppingFilter(shoppingFilter)),
  setServicesFilter: (servicesFilter) => dispatch(DisplayActions.setServicesFilter(servicesFilter)),
  setTrainingFilter: (trainingFilter) => dispatch(DisplayActions.setTrainingFilter(trainingFilter)),
  setEducationFilter: (educationFilter) => dispatch(DisplayActions.setEducationFilter(educationFilter)),
  setBankFilter: (bankFilter) => dispatch(DisplayActions.setBankFilter(bankFilter)),
  setTransportFilter: (transportFilter) => dispatch(DisplayActions.setTransportFilter(transportFilter)),
  setGovernmentFilter: (governmentFilter) => dispatch(DisplayActions.setGovernmentFilter(governmentFilter)),
  setHotelFilter: (hotelFilter) => dispatch(DisplayActions.setHotelFilter(hotelFilter)),
  setFoodFilter: (foodFilter) => dispatch(DisplayActions.setFoodFilter(foodFilter)),
  setHealthyFilter: (healthyFilter) => dispatch(DisplayActions.setHealthyFilter(healthyFilter)),
  setGenderWomanFilter: (genderWomanFilter) => dispatch(DisplayActions.setGenderWomanFilter(genderWomanFilter)),
  setGenderManFilter: (genderManFilter) => dispatch(DisplayActions.setGenderManFilter(genderManFilter)),
})

export default connect(mapStateToProps, mapStateToDispatch)(OnboardingComponent)
