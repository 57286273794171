import { combineReducers } from 'redux';
import configureStore from './CreateStore'
import rootSaga from '../sagas'
import { persistReducer, persistStore } from 'redux-persist';
import { createBrowserHistory as createHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { resettableReducer } from 'reduxsauce'
import storage from 'redux-persist/lib/storage'
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';
import { reducer as UserAccountReducer } from './UserAccountRedux';
import { reducer as DisplayReducer } from './DisplayRedux';

const resettable = resettableReducer('RESET')
const history = createHistory();

const transformerConfig = {
  blacklistPerReducer: {
    display: [
      'creatingPayment',
      'creatingQuote',
      'fetchingCampaign',
      'fetchingPayments',
      'deletingPayment',
      'errors',
    ],
    userAccount: [
      'fetching',
      'errors',
    ]
  },
};

const persistConfig = {
  key: 'primary',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)],
  timeout: null,
};

const appReducer = combineReducers({
  router: connectRouter(history),
  userAccount: resettable(UserAccountReducer),
  display: resettable(DisplayReducer),
})

const store = configureStore(
  persistReducer(persistConfig, appReducer),
  rootSaga,
  history,
);

const persistor = persistStore(store)

export { store, history, persistor }
