import { useEffect, useState } from 'react';

const useBreakpoint = () => {
  const breakpoints = {
    0: 'xs',
    576: 'sm',
    768: 'md',
    992: 'lg',
    1200: 'xl',
    1600: 'xxl',
  };

  const [breakpoint, setBreakPoint] = useState('');

  const handleResize = () => {
    const width = window.innerWidth;

    if (width >= 0 && width < 576) {
      setBreakPoint(breakpoints[0]);
    } else if (width >= 576 && width < 768) {
      setBreakPoint(breakpoints[576]);
    } else if (width >= 768 && width < 992) {
      setBreakPoint(breakpoints[768]);
    } else if (width >= 992 && width < 1200) {
      setBreakPoint(breakpoints[992]);
    } else if (width >= 1200 && width < 1600) {
      setBreakPoint(breakpoints[1200]);
    } else if (width >= 1600) {
      setBreakPoint(breakpoints[1600]);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
