import styled from "styled-components";
import Colors from "../../themes/Colors";

const HomeHolder = styled.div`

  .NotFoundContainer {
    display: flex;
    align-items: left;
    justify-content: left;
    background-image: linear-gradient(100deg,#1e88e5,#42a5f5);
    color: ${Colors.snow};

    h1 {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
      color: ${Colors.snow};
    }

    p {
      font-size: 35px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 25px;
      margin-top: 25px;
      line-height: 1.2;
    }
  }
  .logLatinadWhite {
    width: 300px;

    @media (max-width: 980px) {
      display: none;
    }
  }
  .imageBg {
    width: 90%;
  }
  .goToHome {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    border-color: ${Colors.snow};
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;
  }
  .leftSideContainer {
    padding-top: 30%;
    padding-left: 20%;
    @media (max-width: 930px) {
      padding-top: 25%;
    }
    @media (max-width: 700px) {
      padding-top: 15%;
      padding-left: 15%;
      padding-right: 15%;
      p {
        font-size: 30px;
      }
    }
  }

`;

export default HomeHolder;
