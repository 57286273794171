import React , {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PaymentCampaignHolder  from './Styles/paymentCampaignStyle';
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  Radio,
  Alert,
  Divider,
  Tooltip,
  Button
} from "antd";
import { Skeleton } from 'antd';
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import MaskedInput from "react-text-mask";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
import countryList from 'react-select-country-list'
import { getDomain } from "../services/utils";
import CardListSummaryComponent from "./CardListSummaryComponent";
import CardInfoTotalPriceComponent from './CardInfoTotalPriceComponent';
import { getUrl } from '../services/utils';
import {
 getIdentificationTypes,
 initMercadoPago,
 createCardToken,
 getInstallments
} from '@mercadopago/sdk-react'
import { history } from '../redux';
import { set } from 'lodash';

const INITIAL_CREDIT_CARD_DETAILS = {
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        focused: "number",
        // paymentMethodId: "visa",
        paymentMethodId: "cash",
        installments: 1,
        docType: "DNI"
      };

function CreditCardInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      showMask
      guide={false}
    />
  );
}

function ExpiryInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, "/", /\d/, /\d/]}
      showMask
      guide={false}
    />
  );
}

const PaymentCampaignComponent = (props) => {

  const {
    user,
    match,
    campaignCreated,
    isSuperUser,
    paymentMethods,
    getMercadoPagoPaymentMethods,
    lastBillingInfo,
    fetchingCampaign,
    getCampaign,
    campaign,
    createPayment,
    creatingPayment,
    getProposalRequest,
    getMpInitPointRequest,
    loadingProposal,
    proposalToken,
    currency,
    creatingCampaign,
    paymentData
  } = props;

  const { t , i18n } = useTranslation();
  const [ paymentMethodSelected, setPaymentMethodSelected ] = useState("other");
  const [ creditCardDetails, setCreditCardDetails ] = useState({...INITIAL_CREDIT_CARD_DETAILS});
  const [ payerCosts, setPayerCosts ] = useState([]);
  const [ errors, setErrors] = useState({});
  const [ identificationTypes, setIdentificationTypes] = useState([]);
  const [ phoneGeneral, setPhoneGeneral] = useState(user && user.phone ? user.phone : '');
  const [ creatingToken, setCreatingToken] = useState(false);
  const [ viewProposal, setViewProposal] = useState(false);
  const [ paymentMethodsAllowedBasedOnTotalPrice, setPaymentMethodsAllowedBasedOnTotalPrice] = useState([]);
  const [ createdPaymentAuthomatic, setCreatedPaymentAuthomatic] = useState(false);

  const [billingInformation, setBillingInformation] = useState({
    doc_number: (lastBillingInfo.billing_information_doc_number ? lastBillingInfo.billing_information_doc_number : ''),
    address: (lastBillingInfo.billing_information_address_street ? lastBillingInfo.billing_information_address_street : ''),
    name: (lastBillingInfo.billing_information_first_name ? lastBillingInfo.billing_information_first_name : '')
  });

  const domain  = getDomain(window.location.hostname);
  const [bankData, setBankData] = useState([]);

  const selectPaymentMethodSelected = (value) => {
    let paymentMethod;
    if (value === "card") {
       paymentMethod = paymentMethods.find(pm => pm.id === "credit_account");
      // paymentMethod = paymentMethods.find(pm => pm.id === "visa");
    }

    if(value === "other" && (user && hasAccessRole(user.roles,[UserRoleEnum.agencyMember, UserRoleEnum.companyMember]))){
      paymentMethod = paymentMethods.find(pm => pm.id === "credit_account");
    }

    if(paymentMethod == undefined){
      // paymentMethod = paymentMethods.find(pm => pm.id === "transfer");
      paymentMethod = paymentMethods.find(pm => pm.id === "cash");
    }
    setPaymentMethodSelected(value);
    setCreditCardDetails({ ...INITIAL_CREDIT_CARD_DETAILS, paymentMethodId: paymentMethod.id });
    setPayerCosts([]);
  };


  const handleSelectPaymentMethod = paymentMethodId => {
    if (paymentMethodId !== 'credit_account') {
      setCreditCardDetails({ ...creditCardDetails, paymentMethodId: paymentMethodId });
    } else {
      setCreditCardDetails({ ...creditCardDetails, paymentMethodId: 'credit_account' });
    }
  };

  const renderPaymentMethods = (type) => {
    const totalPrice = campaign.total_price_with_profit;
    // let paymentMethods = paymentMethods;
    let paymentMethodsAux = paymentMethods.filter(pm => {
      return (
          pm.type !== "credit_card"
      );
    });

    paymentMethodsAux = paymentMethodsAux.filter(pm => {
      return (
        pm.type === type
      );
    });

    // Filter payment methods based on max and min allowed amount
    paymentMethodsAux = paymentMethodsAux.filter(pm => {
      return (
        pm.min_allowed_amount <= totalPrice &&
        pm.max_allowed_amount >= totalPrice
      );
    });

    // If the user is advertiser, remove credit account option
    if (user && !user.roles.find(role => (role === "agency-member" || role === "company-member"))){
        paymentMethodsAux = paymentMethodsAux.filter(pm => {
          return (
            pm.id !== "credit_account"
          )
        });
      }

    //Delete option transfer when is not latinad or duoprint dsp
    // if(domain != "latinad" && domain != "duoPrint"){
        paymentMethodsAux = paymentMethodsAux.filter(pm => {
          return (
            pm.id !== "transfer"
          )
        });
      // }

    // return paymentMethodsAux.map(pm => {
    //   return (
    //     <Select.Option key={pm.id} value={pm.id}>
    //       {
    //       pm.id === 'transfer' ? t('Transfer') :
    //       pm.id === 'cash' ? t('Cash') :
    //       pm.id === 'cheque' ? t('Check') :
    //       pm.id === 'credit_account' ? t('Current account') : pm.name
    //       }
    //     </Select.Option>
    //   );
    // });
    return paymentMethodsAux.map(pm => {
      return (
        <Select.Option key={pm.id} value={pm.id}>
          {
          pm.id === 'cash' ? t('Cash') :
          pm.id === 'cheque' ? t('Check') :
          pm.id === 'credit_account' ? t('Current account') : pm.name
          }
        </Select.Option>
      );
    });
  };

  // Setting the card payment method
  const cardPlaceholders = {
    name: t('NAME LAST NAME')
  };

  const cardLocale = {
    valid: ""
  };

  const handleCreditCardNumberChange = value => {
    setCreditCardDetails({ ...creditCardDetails,number: value });

    let bin = value.replace(/[ .-]/g, "").slice(0, 6);
    if (bin.length >= 6) {
      getMercadoPagoPaymentMethods(
        {
          bin: bin
        },
        (status, response) => {
          if (status === 200) {
            getInstallments(
              {
                bin: bin,
                amount: (parseFloat(campaign.total_price_with_profit) + (parseFloat(campaign.total_price_with_profit) * parseFloat(campaign.tax) / 100))
              },
              (status, installmentInfo) => {
                this.setState((state, props) => {
                  return {
                    creditCardDetails: {
                      ...state.creditCardDetails,
                      paymentMethodId: response[0].id
                    },
                    payerCosts: installmentInfo[0]
                    ? installmentInfo[0].payer_costs
                    : []
                  };
                });
              }
            );
          }
        }
      );
    } else {
      setPayerCosts([]);
    }
  };

  const renderIdentificationTypes = () => {
    return identificationTypes.map(e => {
      return (
        <Select.Option key={e.id} value={e.id}>
          {e.name}
        </Select.Option>
      );
    });
  };

  const optionsCountry = countryList().getData()
  const renderCountries = () => {
    return optionsCountry.map(e => {
      return (
        <Select.Option key={e.value} value={e.value}>
          {e.label}
        </Select.Option>
      );
    });
  };

  const getProposal = () => {
    setViewProposal(true);
    getProposalRequest({
      campaign_id: campaign.id
    })
  }

  const makePayment = () => {
      const payWithCard = paymentMethodSelected === "card";
      const errors = {};

      // not verify when is external dsp
      // the method of payment is transfer for campaign is with external dsp
      let hidden_for_external_dsp = (campaign.external_dsp_id != null && !!campaign.smart_campaign);

      if (paymentMethodSelected === "other" && !hidden_for_external_dsp) {
        if (!phoneGeneral) {
          errors["phone_general"] = t('Can not be blank');
        }
      }

      if (payWithCard) {
        if (!creditCardDetails["number"]) {
          errors["creditCardNumber"] = t('Can not be blank');
        }

        if (!creditCardDetails["name"]) {
          errors["name"] = t('Can not be blank');
        }

        if (!creditCardDetails["expiry"]) {
          errors["expiry"] = t('The month / year')+' '+ t('Can not be blank');
        }

        if (!creditCardDetails["cvc"]) {
          errors["cvc"] = t('Can not be blank');
        }

        if (!creditCardDetails["documentType"]) {
          errors["documentType"] = t('Can not be blank');
        }

        if (!creditCardDetails["doc_number"]) {
          errors["doc_number"] = t('Can not be blank');
        }
      }

      if (!billingInformation["doc_number"] && !hidden_for_external_dsp ) {
        errors["doc_number"] = t('Can not be blank');
      }

      if (Object.keys(errors).length > 0) {
        setCreatingToken(false);
        setErrors(errors);
        return;
      }

      // If campaigns have external dsp the id has to be transfer
      let paymentByDsp = paymentMethodSelected === "transfer" ? paymentMethodSelected : creditCardDetails.paymentMethodId

      const publinetPaymentMethods = ["cash", "cheque", "transfer", "credit_account"];
      const payWithPublinet = publinetPaymentMethods.indexOf(paymentByDsp) >= 0;

      if (!payWithCard) {
        if (paymentMethodSelected === "other") {
          createPayment(campaign.id, {
            payment_method_id: paymentByDsp,
            gateway: payWithPublinet ? "publinet" : "mercadopago_custom",
            payer_phone: phoneGeneral,
            billing_information: billingInformation
          });
        }

        if (paymentMethodSelected === "mp") {
          getMpInitPointRequest(campaign.id, {
            billing_information: {
              ...billingInformation
            }
          });
        }
        return;
      }

      const month = creditCardDetails.expiry.split("/")[0];
      const year = `20${creditCardDetails.expiry.split("/")[1]}`;
      setCreatingToken(true);

      createCardToken (
        {
          cardNumber: creditCardDetails.number.replace(/\s/gi, ""),
          securityCode: creditCardDetails.cvc,
          cardExpirationMonth: month,
          cardExpirationYear: year,
          cardholderName: creditCardDetails.name,
          docType: creditCardDetails.documentType,
          docNumber: creditCardDetails.doc_number,
          installments: 1
        },
        (status, response) => {
          if (status === 200) {
            createPayment(campaign.id, {
              payment_method_id: creditCardDetails.paymentMethodId,
              token: response.id,
              installments: creditCardDetails.installments,
              gateway: "mercadopago_custom",
              billing_information: billingInformation
            });
            setCreatingToken(false);
            setErrors({});
            return;
          }

          if (response.cause.length > 0) {
            if (response.cause.some(e => e.code === "205")) {
              errors["creditCardNumber"] = t('Can not be blank');
            }

            if (response.cause.some(e => e.code === "208")) {
              errors["expiry"] = t('The month / year')+' '+ t('Can not be blank');
            }

            if (response.cause.some(e => e.code === "214")) {
              errors["doc_number"] = t('Can not be blank');
            }

            if (response.cause.some(e => e.code === "221")) {
              errors["name"] = t('Can not be blank');
            }

            if (response.cause.some(e => e.code === "325")) {
              errors["expiry"] = t('The month / year')+' '+ t('Its not valid');
            }

            if (response.cause.some(e => e.code === "326")) {
              errors["expiry"] = t('The month / year')+' '+ t('Its not valid');
            }

            if (response.cause.some(e => e.code === "E301")) {
              errors["creditCardNumber"] = t('Its not valid');
            }

            if (response.cause.some(e => e.code === "E302")) {
              errors["cvc"] = t('Its not valid');
            }

            if (response.cause.some(e => e.code === "212")) {
              errors["documentType"] = t('Its not valid');
            }

            if (response.cause.some(e => e.code === "324")) {
              errors["doc_number"] = t('Its not valid');
            }
          }
          setCreatingToken(false);
          setErrors(errors);
        }
      );
  };

  useEffect(() => {
    if ( (paymentData.external_resource_url || paymentData.init_point)) {
      window.open( paymentData.external_resource_url || paymentData.init_point);
      history.push(`/campaign/${campaign.id}`);
    }
  }, [paymentData])

  useEffect(() => {
    if (!creatingPayment && !creatingCampaign && (paymentData && paymentData.id ))
      history.push(`/campaign/${campaign.id}`);

  }, [creatingPayment, paymentData])

  useEffect(() => {
    const mercadoPagoPublicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
    if (campaign){
      (async () => {
        try {
          initMercadoPago(mercadoPagoPublicKey);

          const identificationTypes = await getIdentificationTypes();
          setIdentificationTypes(identificationTypes);
          setCreditCardDetails({ ...creditCardDetails, documentType: identificationTypes[0].id });
        } catch (error) {
          console.error("Error fetching identification types:", error);
        }
      })();
    }
      if (paymentMethods.length === 0) {
        try {
          getMercadoPagoPaymentMethods();
        } catch (error) {
          console.error("Error fetching payment methods:", error);
        }
      }
      // Get for the campaign again because the data is being calculated in the API
      const fetchData = async () => {
        try {
          // Get currentCampaign
          if (match && match.params && match.params.id) {
            await getCampaign(match.params.id);
          } else if (campaignCreated && campaignCreated.id) {
            await getCampaign(campaignCreated.id);
          }
        } catch (error) {
          console.error("Error fetching campaign:", error);
        }
      };

      fetchData().then(() => {
          // Defualt payment method
          if (domain == "latinad" && ( campaign.currency !== 'ARS') || (!campaign.currency && (currency && currency !== "ARS"))) {
            handleSelectPaymentMethod("transfer")
          }
          if(domain != "latinad") {
            handleSelectPaymentMethod("cash")
          }

          // If the campaign is with an external DSP and it is not a super, an automatic payment is created.
          // In transfer mode
          if(
            campaign.external_dsp_id != null
            && !!campaign.smart_campaign
            && !isSuperUser
            && campaign.payment_status == "pending"
            && !createdPaymentAuthomatic ) {
              setCreatedPaymentAuthomatic(true);
              setPaymentMethodSelected("transfer");
              makePayment();
          } else {
            // Define the payment method
            let paymentMethodSelectedAux =(campaign.currency !== 'ARS' || currency !== "ARS") ? "other" : 'other';
            setPaymentMethodSelected(paymentMethodSelectedAux);
          }
      });
  }, [creatingCampaign]);

  useEffect(() => {
    if (paymentMethods.length < 1 || !campaign ) return;
    let paymentMethodsAllowedAux = []
    paymentMethodsAllowedAux = paymentMethods
      .filter(pm => {
        return (
          pm.payment_type_id !== "credit_card" &&
          pm.payment_type_id !== "debit_card"
        );
      })
      .filter(pm => {
        return (
          pm.min_allowed_amount <= campaign.total_price_with_profit &&
          pm.max_allowed_amount >= campaign.total_price_with_profit
        );
      });
     setPaymentMethodsAllowedBasedOnTotalPrice(paymentMethodsAllowedAux);
  }, [paymentMethods, creatingCampaign]);

  useEffect(() => {
    let data = [];
    if (domain === "duoPrint") {
      data = "\n Banco Pichincha \n Cuenta Corriente: 3470813004 \n email: cobranzas@duoprint.com.ec \n\n\n  Banco Internacional \n Cuenta Corriente: 1400612058 \n email: cobranzas@duoprint.com.ec".split(/\n/);
    } else {
      if (campaign &&
          campaign.invoice_issuing &&
          campaign.invoice_issuing.business &&
          campaign.invoice_issuing.business.bank_details) {
        data = campaign.invoice_issuing.business.bank_details.split(/\n/)
      }
    }
    setBankData(data);
  }, [domain, campaign]);

  useEffect(() => {
    if (!loadingProposal && viewProposal) {
      let lenguageParse
      switch (i18n.language) {
        case "es":
          lenguageParse = "esLA";
          break;
        case "es_ar":
          lenguageParse = "es";
          break;
        default:
          break;
      }
      setViewProposal(false);
      const parameter = `lenguage=${lenguageParse || i18n.language}&token=${proposalToken.token}`
      window.open(getUrl(window.location, "proposal.html", parameter));
    }
  }, [loadingProposal]);

  return (
    <PaymentCampaignHolder>
        { (fetchingCampaign || creatingCampaign ) ?
          // Skeleton when creating the campaign
          <Row type="flex" className="campaingInfoCard customPanelStyleDisplays">
            <div>
              <div className="ant-collapse-header" style={{marginTop: "10px"}}>
                {t('Campaign payment')}
              </div>
              <div className="ant-collapse-sub-header" style={{paddingLeft:"10px"}}>
                {t('Payment method')}
              </div>
            </div>
            <Skeleton paragraph={{ rows: 5}} />
            <Divider style={{marginTop:"20px", marginTop:"29px"}} className="divider-payment"/>
            <div
              className="ant-collapse-sub-header"
              style={{marginTop: "30px"}}>
              {t('Fill in the following information')}
              </div>
            <Skeleton paragraph={{ rows: 5}} />
          </Row>
          :
          (<>
            <Row>
              <CardListSummaryComponent campaign={campaign}/>
              <CardInfoTotalPriceComponent campaign={campaign}/>
            </Row>
            <Row type="flex" className="campaingInfoCard customPanelStyleDisplays">

            {/* Float button to continue */}
            {user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]) ? (
              <>
                  <Tooltip placement="bottom" title={t('Generate proposal')}>
                    <Button
                      className="finishedButtons proposalButton"
                      style={{marginRight: "10px"}}
                      loading={loadingProposal}
                      onClick={getProposal}
                    >
                      {t('Proposal')}
                    </Button>
                  </Tooltip>
                { (campaign.external_dsp_id == null || !campaign.smart_campaign) || (isSuperUser) ?
                    <Tooltip placement="bottom" title={t('Finish process for subsequent approval')}>
                      <Button
                        loading={creatingToken}
                        className="finishedButtons"
                        type="primary"
                        onClick={makePayment}
                      >
                        {t('Finish')}
                      </Button>
                    </Tooltip>
                  :
                    <Button
                      loading={this.props.creatingToken}
                      className="finishedButtons"
                      type="primary"
                      onClick={() => history.push("/campaign/" + campaign.id)}>
                    {t('Go to my campaign')}
                    </Button>
                }
              </>
            ) : (
              <Button loading={this.props.creatingToken} className="checkoutButton" type="primary" onClick={makePayment}>
                {this.props.paymentMethod === 'mp' ? t('Go Mercado Pago') : t('Make payment')}
              </Button>
            )}
            {( campaign.external_dsp_id == null || (!campaign.smart_campaign && !isSuperUser)) || isSuperUser ?
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="leftContainer">
                <div>
                  <div className="container">
                    <div className="ant-collapse-header" style={{marginTop: "10px"}}>
                      {t('Campaign payment')}
                    </div>
                  </div>
                  <div className="ant-collapse-sub-header" style={{paddingLeft:"10px"}}>
                    {t('Payment method')}
                  </div>
                  <Form layout="inline">
                    <Row type="flex" gutter={6} className="content-row">
                      <Col xs={20}>
                        <Radio.Group
                          style={{ marginBottom: 20 }}
                          onChange={e => selectPaymentMethodSelected(e.target.value)}
                          value={paymentMethodSelected}
                        >
                          {/* <Radio
                            value={"card"}
                            disabled={(campaign.currency !== 'ARS') || (!campaign.currency && this.props.currency !== "ARS")}>
                            {t('Pay by credit / debit card')}
                          </Radio> */}
                          {paymentMethodsAllowedBasedOnTotalPrice.length > 0 && (
                            <Radio value={"other"}>
                              {t('Pay with other means of payment')}
                            </Radio>
                          )}
                          {/* <Radio
                            value={"mp"}
                            disabled={(campaign.currency !== 'ARS' || (!campaign.currency && this.props.currency !== "ARS")) || campaign.total_price_with_profit > 1000000} >
                              {t('Pay with Mercado Pago')}
                          </Radio> */}
                        </Radio.Group>
                      </Col>
                      {paymentMethodSelected === "other" && (
                        <Col xs={6}>
                          <Select
                            //value={creditCardDetails.paymentMethodId == "visa" ? t('transfer') && this.handleSelectPaymentMethod("transfer") : creditCardDetails.paymentMethodId }
                            value={creditCardDetails.paymentMethodId == "visa" ? t('transfer') : creditCardDetails.paymentMethodId }
                            onChange={value =>
                              handleSelectPaymentMethod(value)
                            }
                          >
                            {renderPaymentMethods("other")}
                          </Select>
                        </Col>
                      )}
                    </Row>
                  </Form>
                  {creditCardDetails &&
                    ( creditCardDetails.paymentMethodId === "cash" ||
                      creditCardDetails.paymentMethodId === "cheque" || creditCardDetails.paymentMethodId === "transfer") &&
                      paymentMethodSelected === "other" && (
                    <Row type="flex" align="top" justify="start" className="content-row">
                      <Col
                        className="card"
                        xs={18}
                        sm={18}
                        md={18}
                        lg={18}
                        xl={18}
                        type="flex"
                        align="top"
                        justify="start"
                      >
                        <div className='ant-collapse-sub-header'>
                          {domain == "duoPrint" ?
                            t('Information for direct cash payments with Duoprint') :
                            t('Information for direct cash payments with Publinet')
                          }
                        </div>
                        <p>
                          { domain == "duoPrint" ?
                            t('Please complete the form with your billing information and your contact telephone number, a Duoprint collector will contact you to coordinate the collection.') :
                            t('Please complete the form with your billing information and your contact telephone number, a Publinet collector will contact you to coordinate the collection.')
                          }
                        </p>
                      </Col>{" "}
                      <Col xs={8}>
                      <p className='title-form'>{t('Telephone contact')}</p>
                        <Form.Item
                          validateStatus={errors["phone_general"] ? "error" : ""}
                          help={errors["phone_general"]}
                        >
                          <Input
                            placeholder={t('Telephone contact')}
                            autoComplete="tel"
                            onChange={e => setPhoneGeneral(e.target.value)}
                            value={phoneGeneral}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {campaign.id && creditCardDetails.paymentMethodId  && creditCardDetails.paymentMethodId  === "transfer" && paymentMethodSelected === "other" && (
                    <Row type="flex" align="top" justify="start" className="content-row">
                      <Divider className="divider-payment"/>
                      <Col
                        className="card"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        type="flex"
                        align="start"
                        justify="start"
                      >
                        <div className="ant-collapse-sub-header" style={{paddingLeft:"10px"}}>{t('Bank data')}</div>
                        <p style={{marginLeft: "10px"}}>
                          {t('Please make a transfer to the following account, and put the number as a reference')}:{" "}
                          <b>{campaign.id}</b>.<br /><br />
                          <div>
                            {domain == "duoPrint" ? (
                              "DUOPRINT-G S.A. - R.U.C.: 0992670835001" ) :
                              (campaign.invoice_issuing.business.name +  " - CUIT: " + campaign.invoice_issuing.business.tax_id)
                            }
                          </div>
                          <div>
                          <br/>
                            {t('Bank data')}:
                            <div style={{marginLeft:"10px"}}>
                              {bankData.map(data => {
                                return (
                                  <span>{data}<br /></span>
                                )
                              })}
                            </div>
                          </div>
                        </p>
                      </Col>
                    </Row>
                  )}

                  { creditCardDetails.paymentMethodId && creditCardDetails.paymentMethodId === "credit_account" && paymentMethodSelected !== "mp" && (
                    <Row type="flex" align="top" justify="start" className="content-row">
                      <Col
                        className="card"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        type="flex"
                        align="start"
                        justify="start"
                      >
                        {domain == "duoPrint" ?
                          <div className='ant-collapse-sub-header'>
                            {t('Information for direct current account payments with DuoPrint')}
                          </div>:
                            <div className='ant-collapse-sub-header'>
                            {t('Information for direct current account payments with Publinet')}
                          </div>
                        }
                        <p>
                          {t('Once the payment has been made using this method, your media manager will be able to approve the campaign.')}
                        </p>
                      </Col>
                      <Col xs={8}>
                        <p className='title-form'>{t('Telephone contact')}</p>
                        <Form.Item
                          validateStatus={!!errors["phone_general"] && "error"}
                          help={errors["phone_general"]}
                        >
                          <Input
                            placeholder={t('Telephone contact')}
                            autoComplete="tel"
                            onChange={e => setPhoneGeneral(e.target.value)}
                            value={phoneGeneral}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  { paymentMethodSelected === "card" && (
                    <>
                    <Row type="flex" align="top" justify="start" className="content-row">
                      <Col className="card" xs={16} sm={16} md={16} lg={16} xl={16} type="flex" align="start">
                        <Form >
                          <Row type="flex">
                            <Col xs={24}>
                              <p className='title-form'>{t('Card number')}</p>
                              <Form.Item
                                validateStatus={!!errors["creditCardNumber"] && "error"}
                                help={errors["creditCardNumber"]}
                                //className='fieldForm'
                              >
                                <CreditCardInput
                                  id="number"
                                  className="ant-input"
                                  placeholder={t('Name as it appears on the card')}
                                  autoComplete="cc-number"
                                  value={creditCardDetails.number}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "number"})}
                                  onChange={e => handleCreditCardNumberChange(e.target.value)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                            <p className='title-form'>{t('Name')}</p>
                              <Form.Item
                                validateStatus={!!errors["name"] && "error"}
                                help={errors["name"]}
                                // className='fieldForm'
                              >
                                <Input
                                  placeholder={t('Name')}
                                  autoComplete="cc-name"
                                  value={creditCardDetails.name}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "name"})}
                                  onChange={e => setCreditCardDetails({...creditCardDetails, name: e.target.value})}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={11}>
                              <p className='title-form'>{t('Date expiry')}</p>
                              <Form.Item
                                validateStatus={!!errors["expiry"] && "error"}
                                help={errors["expiry"]}
                                style={{ paddingRight: "15px"}}
                              >
                                <ExpiryInput
                                  id="expiry"
                                  className="ant-input"
                                  placeholder="MM/AA"
                                  autoComplete="cc-exp"
                                  value={creditCardDetails.expiry}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "expiry"})}
                                  onChange={e => setCreditCardDetails({...creditCardDetails, expiry: e.target.value})
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={13}>
                              <p className='title-form' style={{ paddingLeft: "15px"}}>{t('Security code')}</p>
                              <Form.Item
                                validateStatus={!!errors["cvc"] && "error"}
                                help={errors["cvc"]}
                                style={{ paddingLeft: "15px"}}
                              >
                                <Input
                                  id="cvc"
                                  placeholder={t('Security code')}
                                  autoComplete="cc-csc"
                                  value={creditCardDetails.cvc}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "cvc"})}
                                  onChange={e => setCreditCardDetails({...creditCardDetails, cvc: e.target.value})
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={6}>
                              <p className='title-form'>{t('Document type')}</p>
                              <Form.Item
                                validateStatus={!!errors["documentType"] && "error"}
                                help={errors["documentType"]}
                                // className='fieldForm'
                              >
                                <Select
                                  placeholder={t('Document type')}
                                  style={{width: '200px'}}
                                  value={creditCardDetails.documentType}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "number"})}
                                  onChange={value => setCreditCardDetails({...creditCardDetails, documentType: value})}
                                >
                                  { renderIdentificationTypes() }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={18}>
                              <p className='title-form'>{t('Document number')}</p>
                              <Form.Item
                                validateStatus={!!errors["doc_number"] && "error"}
                                help={errors["doc_number"]}
                                // className='fieldForm'
                              >
                                <Input
                                  id="identification_number"
                                  placeholder={t('Document number')}
                                  onFocus={() => setCreditCardDetails({...creditCardDetails, focused: "number"})}
                                  onChange={e => setCreditCardDetails({...creditCardDetails, doc_number: e.target.value})}
                                  value={creditCardDetails.doc_number}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                      <Col
                        className="card"
                        xs={8} sm={8} md={8} lg={8} xl={8}
                        type="flex"
                        align="start"
                        style={{paddingLeft: "40px", paddingRight: "40px"}}>
                        <div className='content-card'>
                          <Cards
                            number={creditCardDetails.number}
                            name={creditCardDetails.name}
                            expiry={creditCardDetails.expiry}
                            cvc={creditCardDetails.cvc}
                            focused={creditCardDetails.focused}
                            placeholders={cardPlaceholders}
                            locale={cardLocale}
                          />
                        </div>
                      </Col>
                    </Row>
                    </>
                  )}
                  <Form >
                    <Divider className="divider-payment"/>
                    <Row type="flex" xl={16} style={{ marginBottom:"50px"}}>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                          <div
                            className="ant-collapse-sub-header"
                            style={{marginTop: "30px"}}>
                            {t('Fill in the following information')}
                          </div>
                          {user && user.company && (
                            <Radio.Group
                              style={{ marginBottom: 20 }}
                              onChange={e => {
                                let tax_id;
                                let address;
                                let state;
                                let name;
                                if (e.target.value === "client") {
                                  tax_id = campaign.client.billing_information_tax_id;
                                  address = campaign.client.billing_information_address;
                                  name = campaign.client.billing_information_name;
                                } else if (e.target.value === "company") {
                                  tax_id = user.company.billing_information_tax_id;
                                  address = user.company.address;
                                  state = user.company.state;
                                  name = user.company.billing_information_name;
                                } else if (e.target.value === "lastBilling") {
                                  tax_id = lastBillingInfo.billing_information_doc_number;
                                  address = lastBillingInfo.billing_information_address_street;
                                  name = lastBillingInfo.billing_information_first_name;
                                }
                                setBillingInformation(billingInformation => ({
                                  ...billingInformation,
                                  doc_number: tax_id,
                                  address_street: address,
                                  address_state: state,
                                  first_name: name
                                }));
                              }}
                            >
                              <Radio value={"client"}>
                                {t('Billing to client')}
                              </Radio>
                              <Radio value={"company"}>
                                {t('Billing to agency')}
                              </Radio>
                              <Radio value={"lastBilling"}>
                                {t('Last billing')}
                              </Radio>
                            </Radio.Group>
                          )}
                          <Col xs={24}>
                            <p className='title-form'> DNI / CUIT / RUT / NIT </p>
                            <Form.Item
                              validateStatus={errors["doc_number"] ? "error" : ""}
                              help={errors["doc_number"]}
                            >
                              <Input
                                placeholder="XXX XXX XXX"
                                value={ billingInformation.doc_number }
                                defaultValue={ billingInformation && billingInformation.doc_number && billingInformation.doc_number !== '' ?
                                                billingInformation.doc_number :
                                              (lastBillingInfo.billing_information_doc_number ?lastBillingInfo.billing_information_doc_number :
                                              '')}
                                onChange = {e => setBillingInformation({ ...billingInformation, doc_number: e.target.value })}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <p className='title-form'> {t('Social name')} </p>
                            <Form.Item>
                              <Input
                                placeholder={t('Social name')}
                                autoComplete="address-line1"
                                value={billingInformation.first_name}
                                defaultValue={lastBillingInfo.billing_information_first_name ? lastBillingInfo.billing_information_first_name : ''}
                                onChange = {e => setBillingInformation({ ...billingInformation, first_name: e.target.value })}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={11}>
                            <p className='title-form'> {t('Billing Country')} </p>
                            <Form.Item>
                              <Select
                                placeholder={t('Billing Country')}
                                defaultValue={campaign.invoice_issuing ? campaign.invoice_issuing.alpha_2_code : ''}
                                onChange = {value => setBillingInformation({ ...billingInformation, address_country: value })}
                                style={{width: '100%', paddingRight: "15px"}}
                              >
                                <Select.Option disabled key={""} value={""}>
                                  <span style={{color: "#9fa2a3"}}>{t('Select country')}</span>
                                </Select.Option>
                                { renderCountries() }
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={13}>
                            <p className='title-form'> {t('Province')} </p>
                            <Form.Item>
                              <Input
                                placeholder={t('Province')}
                                style={{paddingRight: "15px"}}
                                autoComplete="address-level1"
                                value={billingInformation.address_state}
                                defaultValue={lastBillingInfo.billing_information_address_state ? lastBillingInfo.billing_information_address_state : ''}
                                onChange = {e => setBillingInformation({ ...billingInformation, address_state: e.target.value })}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <p className='title-form'> {t('Address')} </p>
                            <Form.Item>
                              <Input
                                placeholder={t('Address')}
                                autoComplete="address-line1"
                                value={billingInformation.address_street}
                                defaultValue={lastBillingInfo.billing_information_address_street ? lastBillingInfo.billing_information_address_street : ''}
                                onChange = {e => setBillingInformation({ ...billingInformation, address_street: e.target.value })}
                              />
                            </Form.Item>
                          </Col>
                        </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            :<Col xs={24} sm={24} md={12} lg={12} xl={12} className="leftContainer">
              <h1>{t('Campaign payment')}</h1>
              <p>{t('info_payment_first')} (<strong>{campaign.external_dsp.name}</strong>) {t('info_payment_second')}</p> <br/>
              <Alert description={t('info_payment_third')} type="info" showIcon />
            </Col>}
            </Row>
          </>)
        }
    </PaymentCampaignHolder>
  )
}

export default PaymentCampaignComponent;