import styled from "styled-components";
import Colors from "../../themes/Colors";

const MapFiltersHolder = styled.div`
    .mobileOrderComponent {
        @media only screen and (max-width: 1200px) {
            text-align: left !important;
            margin-top: 30px !important;
        }
    }
`;

export default MapFiltersHolder;
