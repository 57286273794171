import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import GenerateSkeletonHolder from './Styles/generateSkeletonStyle';

const GenerateSkeletonComponent = ({
    count,
    height = 220,
    width = 120,
    type = 'card'
}) => {
    if (type === 'graph') {
        return (
            <GenerateSkeletonHolder height={height}>
                <div className="graph-skeleton-container">
                    <div className="y-axis">
                        {[0, 1, 2, 3].map((_, index) => (
                            <div key={index} className="y-axis-mark" />
                        ))}
                    </div>
                    <div className="x-axis" />
                    <div className="bars-container">
                        {Array.from({ length: 7 }).map((_, index) => (
                            <div
                                key={index}
                                className="skeleton-bar"
                                style={{
                                    height: `${Math.random() * 60 + 20}%`,
                                }}
                            />
                        ))}
                    </div>
                </div>
            </GenerateSkeletonHolder>
        );
    }

    return (
        <GenerateSkeletonHolder>
            <Row className="general-skeleton" type="flex" gutter={16}>
                {Array.from({ length: count }).map((_, index) => (
                    <Col key={index} span={24 / count} style={{ padding: 0 }}>
                        <Skeleton
                            title={{ width: 0 }}
                            className='skeletonCard'
                            active
                            paragraph={{ rows: 1, width, style: { height } }}
                        />
                    </Col>
                ))}
            </Row>
        </GenerateSkeletonHolder>
    );
};

export default GenerateSkeletonComponent;