import React, { useState, useEffect } from 'react';
import { Button, Modal, message, Tooltip, Alert, Row, Col } from 'antd';
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from './customComponents/CurrencyInput/CurrencyInput';
import { formatNumber } from "../services/utils";
import { getDomain } from "../services/utils";



const RequestSpecialPrice = (props) => {
    const { displayId, originalBasePrice, agreedBasePrice, requestingSpecialPrice, requestSpecialPriceSuccessful, requestSpecialPrice, currency, i18n, errors, t } = props;

    // State to manage modal visibility
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [price, setPrice] = useState(0); // State to store the entered price

    const domain = getDomain(window.location.hostname);

    const agreedPriceText = domain === "duoPrint"
    ? t('You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and the system will notify you when the set price is reached to continue with your purchase.')
    : t('You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and LatinAd will notify you when the set price is reached to continue with your purchase.');

    const messageBody = <>
        <ul style={{ margin: 0, padding: 0 }}>
            <li>{t("The agreed-upon price does not include local or international taxes.")} </li>
            <li>{t("Final prices may include platform fees and may vary depending on the selected client.")}</li>
            <li>{t("In the event that the screen price is in a different currency than the one being used for the purchase, final prices may vary due to conversion.")}</li>
        </ul>
    </>;

    // Function to handle button click and show the modal
    const showModal = () => {
        setPrice(originalBasePrice ? parseFloat(originalBasePrice).toFixed(2) : 0);
        setIsModalVisible(true);
    };

    // Function to handle modal close
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Function to handle the OK button click
    const handleOk = () => {
        if (price <= 0) {
            message.error(t('The agreed price must be greater than 0'));
            return;
        }
        if (price >= originalBasePrice) {
            message.error(t('The agreed price must be less than the original price'));
            return;
        }
        requestSpecialPrice(displayId, price, currency);
    };

    useEffect(() => {
        if (!requestingSpecialPrice && isModalVisible) {
            if (requestSpecialPriceSuccessful) {
                message.success(t('Request sent successfully'));
                setIsModalVisible(false);
            } else {
                const msg = errors && errors.message ? errors.message : 'Error';
                message.error(t(msg));
            }
        }
    }, [requestingSpecialPrice]);

    return (
        <div>
            <Tooltip placement="top" title={t("Request agreed price")} >
                <Button type="primary" shape="circle" onClick={showModal} size="small" style={{ marginLeft: "5px"}}>
                    <FontAwesomeIcon icon={faHandshake} size="20px" style={{ color: "white" }} className='icoinversenEdit' />
                </Button>
            </Tooltip>
            {isModalVisible &&
                <Modal
                    title={t("Agreed prices")}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            {t("Cancel")}
                        </Button>,
                        <Button key="ok" type="primary" onClick={handleOk} loading={requestingSpecialPrice}>
                            {t("Send request")}
                        </Button>
                    ]}
                >
                    <div style={{ padding: "25px" }}>
                        <p>{agreedPriceText}</p>
                        <p>{`${t("The original price is")} ${formatNumber(originalBasePrice, i18n.language)} ${currency} ${t("per day.")}`}</p>
                        {!!agreedBasePrice &&
                            <p style={{ fontWeight: "600" }}>
                                {`${t("You already have an agreed price")}: ${formatNumber(agreedBasePrice, i18n.language)} ${currency} ${t("per day.")}`}
                            </p>
                        }
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={8}>
                                <label style={{}}>{t("Agreed price")}: </label>
                            </Col>
                            <Col span={16}>
                                <CurrencyInput price={price} setPrice={setPrice} currency={currency} language={i18n.language}></CurrencyInput>
                            </Col>
                        </Row>
                        <br />
                        <Alert message={t("To keep in mind") + ":"} description={messageBody} type="info" showIcon />
                    </div>
                </Modal>
            }
        </div>
    );
};

export default withTranslation()(RequestSpecialPrice);
