import styled from "styled-components";
import Colors from "../../themes/Colors";

const VideoUploadHolder = styled.div`
  padding: 120px 0;

  .videoUploadContainer {
    padding: 25px 15px 10px;
    @media only screen and (min-width: 768px) {
      padding: 25px 0 10px;
    }
  }

  h3 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }

  p {
    color: ${Colors.coal};
    font-size: 15px;
  }

  .warningCard {
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    background-color: #f74f48;
    border: 1px solid #e8e8e8;
  }
  .warningTextCard {
    color: white;
  }

  .campaingInfoCard {
    backgroundColor: ${Colors.snow};
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }

  .successCard {
    margin: 0 0 15px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .successTextCard {
    color: ${Colors.primary};
  }

  .formInput {
    ${
      "" /* border: 2px solid #424242;
    border-radius: 8px;
    background-color: #fff;
    color: #424242;
    height: 40px;
    margin: 0 0 15px; */
    }
  }

  .formInput.ant-input::placeholder {
    color: #4242428f;
  }

  .formInput.ant-input:hover,
  .formInput.ant-input:focus {
    border-right-width: 2px !important;
  }

  .formInput input:focus,
  .formInput input[type="text"]:focus,
  .formInput input[type="password"]:focus,
  .formInput input[type="email"]:focus,
  .formInput input[type="number"]:focus,
  .formInput textarea:focus {
    border: 2px solid #424242;
    color: #424242;
  }

  .formInput.ant-input:focus {
    box-shadow: none;
  }

  .draggerContainer {
    margin: 0 0 15px;
  }

  .draggerContainerHelp {
    line-height: 100%;
  }

  .button {
    height: 46px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    padding: 0 25px;
  }

  .clearButton {
    margin-top: 20px;
  }
  .addGeneralRuleButton {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .canvaButton {
    margin-top: 20px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .clientBrandTag {
    color: ${Colors.primary};
    height: 35px;
    padding: 15px;
    margin-right: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 768px) {
      padding: 15px 20px;
    }

    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: ${Colors.lightPrimary};
  }

  .videoUploadEmpty {
    border: dashed;
    border-radius: 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-color: #e0e0e0;
    padding-left: 10% !important;
    padding-right: 10% !important;
    min-height: 200px;
    left: 10px;
  }
  .actuallyBudget {
    margin-right: 2%;
    border: solid;
    border-radius: 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
    color: #1e5285;
  }
  .info-format-file {
    color: #a1a1a1;
    font-size: 13px;
  }
  .textArea {
    margin-bottom: 1%;
  }
  .imprSubsidize {
    color: rgb(252, 189, 118);
    margin-left: 10px;
    font-size: 15px;
  }
  .titleField {
    margin-bottom: 5px !important;
  }
  .selectCategories {
    width: 200px;
    margin-bottom: 24px
  }
`;

export default VideoUploadHolder;