import { connect } from "react-redux";
import UserSettingsComponent from "../components/UserSettingsComponent";
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ userAccount }) => ({
  user: userAccount.user,
  emailUpdated: userAccount.emailUpdated,
  passwordUpdated: userAccount.passwordUpdated,
  closeModal: userAccount.closeModal,
  errors: userAccount.errors,
  fetching: userAccount.fetching,
});

const mapStateToDispatch = dispatch => ({
  userEmailUpdateRequest: (id, first_name, last_name, username, email) => dispatch( UserAccountActions.userEmailUpdateRequest(id, first_name, last_name, username, email)),
  userPasswordUpdateRequest: (id, old_password, password, password_confirmation) => dispatch(UserAccountActions.userPasswordUpdateRequest(id, old_password, password, password_confirmation)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(UserSettingsComponent);
