import styled from "styled-components";
import Colors from "../../themes/Colors";

const UserSettingsHolder = styled.div`
  
  padding: 130px 0px;
  
  .userSettingsContainer {
    padding: 25px 0 0;
  }
  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }
  .settingsCard {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 0 15px;
  }
  .cardInfo {
    font-size: 15px;
    font-weight: 600;
    color: ${Colors.coal};
    padding: 0 0 15px;
  }
  .cardInfoError {
    font-size: 15px;
    font-weight: 600;
    color: ${Colors.red};
    padding: 0 0 15px;
  }
  .cardInfoLink {
    font-size: 15px;
    font-weight: 600;
    color: ${Colors.primary};
    padding: 10px 0 0;
  }
  .cardInfoPrimary {
    font-size: 13px;
    color: ${Colors.primary};
    font-weight: 600;
  }
`;

export default UserSettingsHolder;
