import React from 'react';
import { connect } from "react-redux";
import MapComponent from "../components/MapComponent";
import DisplayActions from "../redux/DisplayRedux";
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,

  lat_sw: display.lat_sw,
  lng_sw: display.lng_sw,
  lat_ne: display.lat_ne,
  lng_ne: display.lng_ne,
  mapZoom: display.mapZoom,

  date_from: display.date_from,
  date_to: display.date_to,
  price_min: display.price_min,
  price_max: display.price_max,
  shows_per_day_min: display.shows_per_day_min,
  location_type: display.location_type,
  location_sub_type: display.location_sub_type,
  size_type: display.size_type,
  isCircuit: display.isCircuit,
  page: display.page,

  perPage: display.perPage,
  total: display.total,
  displays: display.displays,

  cart: display.cart,
  fetching: display.fetching,

  redirectTarget: userAccount.redirectTarget,

  errors: display.errors,

  client: display.client,
  brand: display.brand,
  loadingBrands: display.loadingBrands,

  countries: display.countries,

  programmatic: display.programmatic,
  amountBudget: display.amountBudget,

  poi_filters: display.poi_filters,

  audience: display.audience,
  excelDisplay: display.excelDisplay,
  loadingExcel:display.loadingExcel,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  currentDisplay: display.currentDisplay,
  currency: userAccount.currency,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  dsp: display.dsp,
  dsps: display.dsps,
  listBundle: display.listBundle,
});

const mapStateToDispatch = dispatch => ({
  setBounds: (bounds, zoom) => dispatch(DisplayActions.setBounds(bounds, zoom)),
  setCoords: coords => dispatch(DisplayActions.setCoords(coords)),
  setPage: page => dispatch(DisplayActions.setPage(page)),
  setParamsFromUrl: params => dispatch(DisplayActions.setParamsFromUrl(params)),
  setMapZoom: zoom => dispatch(DisplayActions.setMapZoom(zoom)),
  getDisplays: params => dispatch(DisplayActions.getDisplaysRequest(params)),
  getDisplaysCart: params => dispatch(DisplayActions.getDisplaysCartRequest(params)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  setSizeType: (sizes) => dispatch(DisplayActions.setSizeType(sizes)),
  setLocationType: (types) => dispatch(DisplayActions.setLocationType(types)),
  setLocationSubType: (subTypes) => dispatch(DisplayActions.setLocationSubType(subTypes)),

  setProgrammatic: (programmatic) => dispatch(DisplayActions.setProgrammatic(programmatic)),
  setBudget: (amountBudget) => dispatch(DisplayActions.setBudget(amountBudget)),
  setCountries: (countries) => dispatch(DisplayActions.setCountries(countries)),

  setPoiFilters: (poiFilters) => dispatch(DisplayActions.setPoiFilters(poiFilters)),
  resetPoiFilters: () => dispatch(DisplayActions.resetPoiFilters()),

  addDisplayToCart: display =>dispatch(DisplayActions.addDisplayToCart(display)),
  addListDisplayToCart: displays => dispatch(DisplayActions.addListDisplayToCart(displays)),
  setDsp: (dsp) => dispatch(DisplayActions.setDsp(dsp)),
  removeDisplayFromCart: displayId =>
    dispatch(DisplayActions.removeDisplayFromCart(displayId)),

  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  getDisplayInfoRequest: (id, clientId) => dispatch(DisplayActions.getDisplayInfoRequest(id, clientId)),
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
});

export default connect(mapStateToProps, mapStateToDispatch)(MapComponent)
