/*
  This file, apiErrorHandlers.js, contains utility functions for handling errors related to API calls.
  By centralizing error handling in this file, we ensure consistency and reusability throughout the application.
*/

// Predefined messages for common errors
const errorMessages = {
  networkError: 'Network error. Please check your connection and try again.',
  serverError: 'Server error. Please try again later.',
  unknownError: 'An unknown error occurred. Please contact technical support.'
};

/**
 * Makes a generic API call.
 * @param {Function} apiCall - API function to call.
 * @param {Array} args - Arguments for the API function.
 * @returns {Promise} - Promise resolving with the API response or throwing an error.
 */
export async function makeApiCall(apiCall, ...args) {
  try {
    const resp = await apiCall(...args);
    if (resp.ok) {
      return resp;
    } else {
      throw new Error((resp.data && resp.data.message) || errorMessages.serverError);
    }
  } catch (error) {
    throw new Error(error.message || errorMessages.unknownError);
  }
}