import React from 'react';
import { InputNumber, Col, Row } from 'antd';
import { formatNumber } from '../../../services/utils';

const CurrencyInput = ({ price, setPrice, currency, language }) => {

    const numberParser = value => {
        let parsedNumber;
        parsedNumber = value.replace(`..`, '.') // Prevents entering more than one dot
            .replace(`,,`, ',') // Prevents entering more than one comma

        // Let user keep writing the number until it is valid
        if (value && (value.toString().slice(-1) === '.' || value.toString().slice(-1) === ',')) {
            return parsedNumber;
        }
        // Parse language specific number format
        if (language === 'es') {
            parsedNumber = value.replace(/\./g, '').replace(/,/g, '.');
        } else {
            parsedNumber = value.replace(/,/g, '');
        }
        return parsedNumber;
    };

    return (
        <Row type="flex" justify="space-around" align="middle">
            <Col span={18}>
                <InputNumber
                    style={{
                        width: '100%',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                    }}
                    min={0}
                    value={price}
                    onChange={setPrice}
                    formatter={value => formatNumber(value, language)}
                    parser={value => numberParser(value)}
                />
            </Col>
            <Col span={6}>
                <span style={{
                    width: '100%',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fafafa',
                    justifyContent: 'center',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                    borderBottom: '1px solid #d9d9d9',
                    borderTop: '1px solid #d9d9d9',
                    borderRight: '1px solid #d9d9d9',
                    borderLeft: '0px ',
                }}>
                    {currency}
                </span>
            </Col>
        </Row>
    )
};

export default CurrencyInput;