export const markerPosOnCart= () => {
  const primary = getComputedStyle(document.documentElement).getPropertyValue('--primaryPins').trim();

  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
          .cls-1 {
            fill: ${primary};
            stroke: #1d71b8;
            stroke-miterlimit: 10;
          }

          .cls-2 {
            fill: #fafafa;
          }

          .cls-3 {
            fill: #fefefe;
          }
        </style>
      </defs>
      <g id="Group_14">
        <g id="Group_13">
          <path id="Path_8" class="cls-1" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
        </g>
      </g>
      <g>
        <path d="M25.38,41.54c-4.91,0-9.81,0-14.72,0-.58,0-.72-.14-.72-.71,0-.85,0-1.7,0-2.55,0-.58.14-.72.71-.72,1.8,0,3.61,0,5.41,0,.31,0,.36-.09.36-.37-.02-.92-.02-1.85,0-2.77,0-.27-.04-.36-.34-.36-1.5.02-3.01,0-4.51,0-1.01,0-1.62-.62-1.62-1.63,0-2.24,0-4.48,0-6.72,0-.99.62-1.61,1.61-1.61,4.23,0,8.46,0,12.69,0,1.01,0,1.62.61,1.62,1.63,0,.21.01.42,0,.62-.02.19.07.2.22.17.54-.11,1.08-.21,1.62-.31.13-.02.17-.08.16-.21,0-.31-.01-.62,0-.93,0-.14-.07-.2-.17-.26-1.44-.95-2.21-2.28-2.32-4,0-.14-.03-.18-.18-.21-.74-.12-1.25-.65-1.31-1.33-.07-.69.31-1.29,1.02-1.56.18-.07.13-.13.08-.24-.25-.53-.57-1.03-.69-1.62-.27-1.42.44-2.85,1.78-3.41.44-.19.82-.43,1.19-.71.9-.69,1.93-1.04,3.06-1.06.65-.01,1.28.09,1.88.35.56.24,1.12.49,1.67.75,1.99.96,2.83,3.18,1.99,5.22-.08.2-.25.41-.23.6.02.19.33.17.49.28.5.33.78.93.68,1.5-.11.64-.59,1.12-1.24,1.23-.19.03-.24.08-.25.28-.11,1.67-.87,2.98-2.26,3.91-.17.11-.24.22-.22.42.02.32-.1.72.06.94.16.22.59.16.9.22,1.38.27,2.76.53,4.15.79,1.64.31,2.85,1.7,2.86,3.36.02,3.47,0,6.95,0,10.42,0,.42-.19.59-.65.59-4.93,0-9.85,0-14.78,0Z"/>
        <path class="cls-3" d="M17.9,25.11c2.09,0,4.19,0,6.28,0,.55,0,.7.15.7.7,0,2.32,0,4.64,0,6.97,0,.24-.06.3-.3.3-1.64-.01-3.28-.01-4.91,0-.21,0-.28-.05-.27-.27.01-1.19,0-2.38,0-3.58,0-.47-.17-.64-.63-.64-.58,0-1.16,0-1.74,0-.44,0-.61.18-.61.63,0,1.19,0,2.38,0,3.58,0,.24-.07.28-.29.28-1.51-.01-3.03,0-4.54,0-.49,0-.65-.17-.65-.65,0-2.22,0-4.44,0-6.66,0-.49.16-.66.65-.66,2.1,0,4.21,0,6.31,0Z"/>
        <path class="cls-3" d="M26.38,19.41c0-.16.02-.31,0-.47-.11-.85.14-1.6.57-2.32.11-.18.16-.17.32-.06,2,1.46,4.21,1.46,6.2,0,.18-.13.26-.11.32.09,0,0,0,.02.01.03.46.7.62,1.46.55,2.29-.06.76.05,1.53-.06,2.3-.28,1.9-1.98,3.35-3.93,3.35-1.94,0-3.64-1.45-3.93-3.35-.09-.62-.02-1.24-.05-1.86Z"/>
        <path class="cls-3" d="M23.87,40.54c-4.2,0-8.4,0-12.6,0-.27,0-.35-.06-.34-.33.03-.49.01-.97,0-1.46,0-.16.04-.23.21-.2.04,0,.08,0,.12,0,8.41,0,16.82,0,25.23,0,.27,0,.35.06.34.33-.03.45-.02.91,0,1.37.01.24-.06.3-.3.3-3.14,0-6.28,0-9.43,0h-3.24Z"/>
        <path class="cls-3" d="M30.33,33.08c-1.4,0-2.8,0-4.2,0-.2,0-.26-.04-.26-.25,0-1.67,0-3.34,0-5.01,0-.17.03-.26.22-.29.61-.1,1.22-.22,1.83-.34.13-.03.19,0,.27.12,1.09,1.72,3.25,1.72,4.33,0,.07-.12.13-.15.27-.13.62.13,1.24.24,1.86.35.15.03.2.09.2.25,0,1.69,0,3.38,0,5.07,0,.22-.09.22-.25.22-1.42,0-2.84,0-4.26,0Z"/>
        <path class="cls-3" d="M30.36,37.56c-2.06,0-4.13,0-6.19,0-.23,0-.29-.05-.29-.29.01-.98.01-1.97,0-2.96,0-.2.05-.25.25-.25,4.15,0,8.3,0,12.44,0,.2,0,.26.05.25.25-.01,1-.01,1.99,0,2.99,0,.2-.05.25-.25.25-2.07,0-4.15,0-6.22,0Z"/>
        <path class="cls-3" d="M34.91,16.67c-.2-.39-.38-.75-.57-1.12-.23-.45-.51-.52-.92-.21-.66.5-1.31,1.01-2.15,1.19-1.15.26-2.2.05-3.17-.59-.3-.2-.58-.43-.87-.64-.35-.25-.65-.18-.84.2-.21.4-.41.81-.61,1.22-.79-1.03-.64-2.16.04-2.89.74-.8,2-.89,2.91-.22.24.18.44.38.59.63.1.18.2.17.33.05.04-.03.08-.06.13-.09q.55-.37.1-.88c-.36-.41-.79-.73-1.3-.93-.09-.04-.21-.03-.28-.15,1.13-.63,2.31-.77,3.52-.31.6.23,1.18.5,1.75.79,1.43.73,2.02,2.47,1.34,3.93Z"/>
        <path class="cls-3" d="M37.82,28.17c1.17.23,1.99,1.22,1.99,2.43,0,3.11,0,6.22,0,9.33q0,.62-.63.62c-.37,0-.75-.01-1.12,0-.21.01-.25-.06-.25-.26,0-1.61,0-3.21,0-4.82v-7.3Z"/>
        <path class="cls-3" d="M17.41,33.57c0-1.24,0-2.49,0-3.73,0-.2.05-.26.25-.25.75,0,.75,0,.75.75,0,2.31,0,4.62,0,6.94,0,.23-.06.29-.29.29-.71-.01-.71,0-.71-.72v-3.27Z"/>
        <path class="cls-3" d="M20.9,35.79c0-.51,0-1.02,0-1.52,0-.15.04-.2.19-.19.54,0,1.08,0,1.62,0,.15,0,.19.04.19.19,0,1.04,0,2.07,0,3.11,0,.15-.04.19-.19.19-.54,0-1.08-.01-1.62,0-.19,0-.19-.08-.19-.22,0-.52,0-1.04,0-1.56Z"/>
        <path class="cls-3" d="M28.87,25.38c1,.3,1.98.3,2.99,0-.02.38.04.74-.06,1.1-.19.72-.88,1.2-1.61,1.12-.73-.09-1.3-.7-1.32-1.45,0-.25,0-.49,0-.76Z"/>
        <path class="cls-3" d="M35.83,30.41c0-.79,0-1.57,0-2.36,0-.2,0-.32.26-.23.23.08.58,0,.7.18.12.17.04.48.04.73,0,1.37,0,2.73,0,4.1,0,.2-.05.26-.25.26-.75,0-.75,0-.75-.75v-1.93Z"/>
        <path class="cls-3" d="M19.41,35.78c0-.5.01-1,0-1.49,0-.22.09-.22.26-.22.16,0,.26,0,.25.22-.01,1.02-.01,2.03,0,3.05,0,.22-.09.22-.26.22-.16,0-.26,0-.25-.22.02-.52,0-1.04,0-1.56Z"/>
        <path class="cls-2" d="M25.38,19.14c0,.06.01.13,0,.18-.02.1.08.26-.06.29-.13.03-.25-.07-.33-.18-.1-.14-.14-.3-.07-.46.07-.18.21-.33.38-.31.18.03.05.23.09.35.01.04,0,.08,0,.12Z"/>
        <path class="cls-2" d="M35.34,19.14c0-.06-.01-.13,0-.18.02-.1-.08-.26.06-.29.13-.03.25.07.33.18.1.14.14.3.07.46-.07.18-.21.33-.38.31-.18-.03-.05-.23-.09-.35-.01-.04,0-.08,0-.12Z"/>
      </g>
      <rect class="cls-3" x="13.39" y="27.23" width="9.06" height="5.85"/>
    </svg>`;
  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};