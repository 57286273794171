import { connect } from 'react-redux'
import SelectionFiltersComponent from '../components/SelectionFiltersComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  date_from: display.date_from,
  date_to: display.date_to,
  price_min: display.price_min,
  price_max: display.price_max,
  shows_per_day_min: display.shows_per_day_min,
  location_type: display.location_type,
  location_sub_type: display.location_sub_type,
  size_type: display.size_type,
  clients: display.clients,
  client: display.client,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  brands: display.brands,
  brand: display.brand,
  countries: display.countries,
  loadingClients: display.braloadingClientsnd,
  loadingBrands: display.loadingBrands,
  displays: display.displays,
  currency: userAccount.currency,
  isCircuit: display.isCircuit,
  dsp: display.dsp,
  dsps: display.dsps,
  editCampaignActive: display.editCampaignActive,
  isSuperUser: userAccount.isSuperUser,
  conversionRates: display.conversionRates,
});

const mapStateToDispatch = (dispatch) => ({
  setProgrammatic: (programmatic) =>
    dispatch(DisplayActions.setProgrammatic(programmatic)),
  setPrice: (prices) => dispatch(DisplayActions.setPrice(prices)),
  setShowsPerDayMin: (shows_per_day_min) =>
    dispatch(DisplayActions.setShowsPerDayMin(shows_per_day_min)),
  setLocationType: (types) => dispatch(DisplayActions.setLocationType(types)),
  getExternalDsps: () => dispatch(DisplayActions.getExternalDspsRequest()),
  setDsp: (dsp) => dispatch(DisplayActions.setDsp(dsp)),
  clearDsp: () => dispatch(DisplayActions.clearDsp()),
  setBudget: (amountBudget) => dispatch(DisplayActions.setBudget(amountBudget)),
  setDates: (from, to) => dispatch(DisplayActions.setDates(from, to)),
  setSizeType: (sizes) => dispatch(DisplayActions.setSizeType(sizes)),
  clearBrands: (id) => dispatch(DisplayActions.clearBrands(id)),
  getClients: (userId) => dispatch(DisplayActions.getClientsRequest(userId)),
  setClient: (client) => dispatch(DisplayActions.setClient(client)),
  getBrands: (clientId) => dispatch(DisplayActions.getBrandsRequest(clientId)),
  setBrands: (brands) => dispatch(DisplayActions.setBrands(brands)),
  setCountries: (countries) => dispatch(DisplayActions.setCountries(countries)),
  setBrand: (brand) => dispatch(DisplayActions.setBrand(brand)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  cleanAudience: () => dispatch(DisplayActions.cleanAudience()),
  updatePriceCartByDiscount: (displayId, price) => dispatch(DisplayActions.updatePriceCartByDiscount(displayId, price)),
  getConversionRates: () => dispatch(DisplayActions.getConversionRatesRequest()),
});

export default connect(mapStateToProps, mapStateToDispatch)(SelectionFiltersComponent)
