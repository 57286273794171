import { connect } from "react-redux";
import { CanvaModal } from "../components/CanvaModal";
import DisplayActions from "../redux/DisplayRedux";

const mapStateToProps = ({ display }) => ({
  canva: display.canva,
});

const mapStateToDispatch = dispatch => ({
  uploadFileRequest: (file) => dispatch(DisplayActions.uploadFileRequest(file)),
  setCanvaInit: (canva) => dispatch(DisplayActions.setCanvaInit(canva)),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CanvaModal);