import styled from "styled-components";
import Colors from "../../themes/Colors";

const InformativeHeader = styled.div`
  min-height: 85px;
  padding: 15px 56px;

  @media only screen and (max-width: 980px) {
    padding: 15px 35px;
  }

  .row {
    gap: 20px;
  }

  .backButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    border-color: ${Colors.snow};
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;
  }

  .campaignInfo {
    font-size: 16px;
    color: ${Colors.primary};
    margin-bottom: 5px;
    background-color: ${Colors.snow};
    border-radius: 8px;
    border: 1px solid ${Colors.lightPrimary};
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
  }

  .campaignTitle {
    font-size: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .campaignSubTitle {
    font-size: 12px;
  }

  .mapButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media only screen and (min-width: 992px) {
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
`;
export default InformativeHeader;