import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreenPreviewHolder = styled.div`
  .pulseLoaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .screenContainer {
    width: 200px;
    max-width: 100%;
    margin: 5px 0;
  }

  .screenTypeCard {
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
  .screenTypeCard .ant-card-body {
    padding: 10px 16px 10px;
  }

  .titleTypeSize {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .titelScreen{
    font-size: 16px;
    color: ${Colors.primary}
  }

  .dataScreenColumn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .viewAllScreens {
    text-align: center;
  }

  .screen {
    position: relative;
    background-color: ${Colors.black};
    border: 4px solid #6f6f70;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time {
    position: absolute;
    top: 6px;
    right: 10px;
    color: ${Colors.snow};
    background-color: ${Colors.primary};
    border-radius: 30px;
    padding: 0 5px 0 5px;
    font-size: 12px;
  }

  .playVideoIcon {
    position: absolute;
    font-size: 50px;
    color: #3a96f3;
  }

  .screenPreviewType {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .screenPreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .screenPreviewFilled {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .singleScreen {
    border-bottom: 1px solid ${Colors.primary};
    padding: 40px 0 5px;
    margin: 0 0 15px;
  }
  .toggleButton {
    height: 16px;
    border: 0px;
    padding: 0px;
    color: ${Colors.primary};
  }

  .screenExpandSwitch {
    margin-left: 5px;
  }

  .singleScreen .ant-upload-list-item {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .addRuleButton {
    height: 37px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 0px 10px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .removeAllRulesButton {
    height: 37px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 0px 10px;
    margin-top: 10px;
    margin-right: 10px;
  }
  .cloneAllSpotButton {
    height: 25px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 0px 10px;
    margin-top: 10px;
    bottom: 2px;
  }
  .cloneAllRulesButton {
    height: 37px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    padding: 0px 10px;
    margin-top: 10px;
  }

  .closeRuleButton {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .slick-next:before {
    color: #c8c8c8
  }
  .slick-prev:before {
    color: #c8c8c8
  }
  .amountCampaignPerDisplay {
    border: solid;
    border-radius: 7px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
    border-color: #c8c8c8;
    margin-right: 10px;
  }
  .screenWarningNoAutomateView {
    background-color: ${Colors.snow};
    border-color: #ffc107;
    color: ${Colors.snow};
  }
  .screenItemContainer {
    margin-bottom: 15px;
  }
  .screenCardContent {
    padding: 24px;
  }
  .primary {
    color: ${Colors.primary};
  }
  .alertMsg{
    margin-top: 5px;
    margin-bottom: 5px;
    color: #f5222d;
  }
  .msgHoursOperation{
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }
  .displayTextArea{
    margin-top: 10px;
  }
  .alertCPM{
    padding-top: 6px;
    padding-right: 15px;
    padding-bottom: 6px;
    padding-left: 15px;
    margin-top: 8px;
  }
  .textDiscount {
    padding-left: 5px;
    padding-top: 5px;
  }
  .anticon.aimIcon {
    vertical-align: sub;
  }
  .cardsContainer button{
    margin-top: 5px;
    margin-right: 2px
    padding: 0 4px
  }
  .cardsContainer button span{
    margin-left: 4px
  }
`;

export default ScreenPreviewHolder;
