import styled from "styled-components";
import Colors from "../../themes/Colors";

const QuotesHolder = styled.div`
  .quoteContainer {
    padding: 130px 0 0;
  }
  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }
  .row-search{
    padding-bottom: 15px;
    padding-top: 0px;
  }
  .customBody {
    padding: 14px 14px;
    background-color: ${Colors.snow};
  }
  .icon-archived {
    padding-right: 10px;
  }
`;

export default QuotesHolder;
