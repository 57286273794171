import styled from "styled-components";

const CardOdcModalHolder = styled.div`
max-height: 350px !important;
overflow-y: auto !important;

.contentTable{
    padding-right: 5% !important;
    padding-left: 5% !important;
    padding-top: 2%;
    padding-bottom: 4%;
}
.textContent{
    text-align: center;
    margin-top: 10px;
}
`;
export default CardOdcModalHolder;