import React, { useState, useEffect } from 'react';
import "../icons/font-awesome/font-awesome.min.css";
import "../icons/ionicons/ionicons.min.css";
import "../icons/material-design/material-design.min.css";

const CategoryIcon = ({ category }) => {
    const categoryIcons = [
        {
            id: 1,
            icon: "fa-automobile"
        },
        {
            id: 123,
            icon: "fa-trophy"
        },
        {
            id: 132,
            icon: "fa-graduation-cap"
        },
        {
            id: 150,
            icon: "fa-institution"
        },
        {
            id: 163,
            icon: "fa-gift"
        },
        {
            id: 186,
            icon: "fa-users"
        },
        {
            id: "1KXCLD",
            icon: "fa-suitcase"
        },
        {
            id: 210,
            icon: "ion-pizza"
        },
        {
            id: 211,
            icon: "fa-glass"
        },
        {
            id: 216,
            icon: "fa-cutlery"
        },
        {
            id: 217,
            icon: "fa-birthday-cake"
        },
        {
            id: 218,
            icon: "ion-fork"
        },
        {
            id: 222,
            icon: "fa-coffee"
        },
        {
            id: 223,
            icon: "fa-heartbeat"
        },
        {
            id: 239,
            icon: "fa-headphones"
        },
        {
            id: 243,
            icon: "fa-Music"
        },
        {
            id: 247,
            icon: "ion-easel"
        },
        {
            id: 248,
            icon: "fa-paint-brush"
        },
        {
            id: 261,
            icon: "ion-bookmark"
        },
        {
            id: 264,
            icon: "fa-video-camera"
        },
        {
            id: 269,
            icon: "fa-puzzle-piece"
        },
        {
            id: 274,
            icon: "fa-home"
        },
        {
            id: 286,
            icon: "fa-stethoscope"
        },
        {
            id: 324,
            icon: "ion-android-film"
        },
        {
            id: 338,
            icon: "fa-music"
        },
        {
            id: 386,
            icon: "ion-ribbon-b"
        },
        {
            id: 391,
            icon: "fa-credit-card"
        },
        {
            id: 42,
            icon: "fa-book"
        },
        {
            id: 422,
            icon: "fa-paw"
        },
        {
            id: 441,
            icon: "fa-building"
        },
        {
            id: 453,
            icon: "md-flare"
        },
        {
            id: 464,
            icon: "fa-flask"
        },
        {
            id: 473,
            icon: "fa-tag"
        },
        {
            id: 483,
            icon: "fa-soccer-ball-o"
        },
        {
            id: 52,
            icon: "fa-bar-chart"
        },
        {
            id: 552,
            icon: "ion-tshirt"
        },
        {
            id: 596,
            icon: "fa-lightbulb-o"
        },
        {
            id: 640,
            icon: "ion-videocamera"
        },
        {
            id: 653,
            icon: "fa-plane"
        },
        {
            id: 680,
            icon: "fa-gamepad"
        },
        {
            id: "80DV8O",
            icon: "fa-globe"
        },
        {
            id: "8VZQHL",
            icon: "fa-ticket"
        },
        {
            id: "JLBCU7",
            icon: "ion-mic-b"
        },
        {
          id: 467,
          icon: "md-landscape"
        },
        {
          id: 320,
          icon: "fa-medkit"
        },
        {
          id: 179,
          icon: "ion-android-restaurant"
        },
        {
          id: 154,
          icon: "md-mall"
        },
        {
          id: 108,
          icon: "md-accounts-outline"
        }
    ];

    const noIcon = { icon: "fa-ban" };

    const [iconName, setIconName] = useState(getCategoryIcon(category));

    function getCategoryIcon(cat) {
        return cat ? categoryIcons.find(x => x.id == cat.id).icon : noIcon.icon;
    }

    useEffect(() => {
        setIconName(getCategoryIcon(category));
    }, [category]);

    return (
        <i className={`icon ${iconName}`} aria-hidden="true"></i>
    );
};

export default CategoryIcon;