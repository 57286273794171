const typeScreenEnum = [
    { "key": "outdoor",
      "value": ['301', '302','303']},
    { "key": "indoor",
      "value": ['205', '101', '105', '106', '201', '203', '207', '401', '402', '501', '601', '602', '701', '801', '804', '805', '1001' ] },
    { "key": "buses",
      "value": ['102', '103', '104', '10202'] },
    { "key": "pos",
      "value": ['202', '203', '207', '402', '204', '805', '806'] },
];

export default typeScreenEnum;