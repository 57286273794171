import { connect } from 'react-redux'
import NotFoundComponent from '../components/NotFoundComponent'
import DisplayActions from "../redux/DisplayRedux";

const mapStateToProps = ({ display, userAccount  }) => ({
  user: userAccount.user,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  audience: display.audience
})

const mapStateToDispatch = (dispatch) => ({
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
})

export default connect(mapStateToProps, mapStateToDispatch)(NotFoundComponent)
