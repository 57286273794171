import { put, call } from "redux-saga/effects";
import UserAccountActions from "../redux/UserAccountRedux";
import storage from "redux-persist/lib/storage";
import DisplayActions from "../redux/DisplayRedux";

export function* userLoginRequest(api, action) {
  const { email, password, code2FA } = action;

  const resp = yield call(api.userLogin, email, password, code2FA);

  if (resp.status === 206)
    return yield put(UserAccountActions.userLoginRequire2fa(true));

  if (resp.ok) {
    const user = resp.data.user;
    storage.setItem("user", JSON.stringify(user));

    yield storage.setItem(
      "authHeaders",
      JSON.stringify({
        authorization: `Bearer ${resp.data.token}`
      })
    );

    yield call(api.setHeaders, {
      authorization: `Bearer ${resp.data.token}`
    });

    yield put(UserAccountActions.userLoginSuccess(user, resp.data.token));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      if (!Array.isArray(resp.data) && resp.data !== null) {
        errors = [{ message: JSON.stringify(resp.data) }];
      } else {
        errors = [resp.data];
      }    
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userLoginFailure(errors));
  }
}

export function* userLoginFromCmsRequest(api, action) {

  const { token } = action;

  yield storage.setItem(
    "authHeaders",
    JSON.stringify({
      authorization: `Bearer ${token}`
    })
  );

  yield call(api.setHeaders, {
    authorization: `Bearer ${token}`
  });

  const resp = yield call(api.getUserInfo);

  if (resp.ok) {

    const user = resp.data;
    storage.setItem("user", JSON.stringify(user));
    yield put(UserAccountActions.userLoginSuccess(user, token));

  } else {

    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userLoginFailure(errors));
  }
}

export function* userRegisterRequest(api, action) {
  const { user } = action;

  const resp = yield call(api.userRegister, user);

  if (resp.ok) {
    yield put(UserAccountActions.userRegisterSuccess());
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    storage.removeItem("user");

    yield call(api.setHeaders, {
      authorization: null
    });

    yield put(UserAccountActions.userRegisterFailure(errors));
  }
}

export function* userResetPasswordRequest(api, action) {
  const { user } = action;

  const resp = yield call(api.userResetPassword, user);

  if (resp.ok) {
    yield put(UserAccountActions.userResetPasswordSuccess(resp.data));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userResetPasswordFailure(errors));
  }
}

export function* userForgotPasswordRequest(api, action) {
  const { email } = action;

  const resp = yield call(api.userForgotPassword, email);

  if (resp.ok) {
    yield put(UserAccountActions.userForgotPasswordSuccess());
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userForgotPasswordFailure(errors));
  }
}

export function* resendVerificationEmailRequest(api, action) {
  const { email } = action;

  const resp = yield call(api.resendVerificationEmail, email);

  if (resp.ok) {
    yield put(UserAccountActions.resendVerificationEmailSuccess());
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.resendVerificationEmailFailure(errors));
  }
}

export function* userEmailUpdateRequest(api, action) {
  const { id, first_name, last_name, username, email } = action;

  const resp = yield call(
    api.userEmailUpdate,
    id,
    first_name,
    last_name,
    username,
    email
  );
  if (resp.ok) {
    const user = resp.data;

    yield put(UserAccountActions.userEmailUpdateSuccess(user.email));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [(resp.data.errors && resp.data.errors.email) || resp.data.message];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userEmailUpdateFailure(errors));
  }
}

export function* userPasswordUpdateRequest(api, action) {
  const { id, old_password, password, password_confirmation } = action;

  const resp = yield call(
    api.userPasswordUpdate,
    id,
    old_password,
    password,
    password_confirmation
  );
  if (resp.ok) {
    const user = resp.data;

    yield storage.setItem(
      "authHeaders",
      JSON.stringify({
        authorization: `Bearer ${user.new_token}`
      })
    );

    yield call(api.setHeaders, {
      authorization: `Bearer ${user.new_token}`
    });

    yield put(UserAccountActions.userPasswordUpdateSuccess(user.email));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [(resp.data.errors && resp.data.errors.password) || resp.data.message];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userPasswordUpdateFailure(errors));
  }
}


export function* userLogout(api, action) {
  storage.removeItem("user");
  storage.removeItem("authHeaders");

  yield call(api.setHeaders, {
    authorization: null,
    "X-User-Email": null,
    "X-User-Token": null
  });

  yield put(DisplayActions.displayReset());
  yield put(UserAccountActions.userReset());
}

export function* companyRegisterRequest(api, action) {
  const { company } = action;

  const resp = yield call(api.companyRegister, company);

  if (resp.ok) {
    yield storage.setItem(
      "authHeaders",
      JSON.stringify({
        authorization: `Bearer ${resp.data.token}`
      })
    );

    yield call(api.setHeaders, {
      authorization: `Bearer ${resp.data.token}`
    });

    const messageOk = resp.data;
    yield put(UserAccountActions.companyRegisterSuccess(messageOk, resp.data.token));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield call(api.setHeaders, {
      authorization: null
    });

    yield put(UserAccountActions.companyRegisterFailure(errors));
  }
}

export function* getCountriesRequest(api) {
  // const { company } = action;

  const resp = yield call(api.getCountries)
  if (resp.ok) {
    const countries = resp.data
    yield put(UserAccountActions.getCountriesRequestSuccess(countries))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(UserAccountActions.getCountriesRequestFailure(errors))
  }
}

export function* getLocationRequest(api) {
  // const { company } = action;

  const resp = yield call(api.getLocation)
  if (resp.ok) {
    const countries = resp.data
    yield put(UserAccountActions.getLocationRequestSuccess(countries))
  } else {
    let errors
    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }else {
      errors = [resp.problem]
    }

    yield put(UserAccountActions.getLocationRequestFailure(errors))
  }
}