import { connect } from 'react-redux'
import LanguageCurrencyModal from '../components/LanguageCurrencyModal'
import UserAccountRedux from '../redux/UserAccountRedux'

const mapStateToProps = ({ userAccount , display} ) => ({
    countries: userAccount.countries,
    currency: userAccount.currency,
    billingCountry: userAccount.billingCountry,
    location: userAccount.location,
    user: userAccount.user,
    editCampaignActive: display.editCampaignActive,
    currentCampaign: display.currentCampaign,
    dsp: display.dsp,
    programmatic: display.programmatic,
    isSuperUser: userAccount.isSuperUser,
})

const mapStateToDispatch = (dispatch) => ({
    getCountries: () => dispatch(UserAccountRedux.getCountriesRequest()),
    setBilling: (currency, country, codeCountry)=> dispatch(UserAccountRedux.setBilling(currency, country, codeCountry)),
    getlocation: () => dispatch(UserAccountRedux.getLocationRequest()),
})

export default connect(mapStateToProps, mapStateToDispatch)(LanguageCurrencyModal)