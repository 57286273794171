import { connect } from 'react-redux'
import ContentsOfCampaignComponent from '../components/ContentsOfCampaignComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  libraryContents: display.libraryContents,
  cart: display.cart,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  loadingListOfContentsInCampaign: display.loadingListOfContentsInCampaign,
  listResolutionWithContent: display.listResolutionWithContent,
  customContents: display.customContents,
  toleranceResolution: display.toleranceResolution,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  isSuperUser: userAccount.isSuperUser,
  editCampaignActive: display.editCampaignActive,
  currentCampaign: display.currentCampaign,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  toleranceResolutionInit: display.toleranceResolutionInit
})

const mapStateToDispatch = (dispatch) => ({
  clearCustomContents: () => dispatch(DisplayActions.clearCustomContents()),
  setListOfContentsInCampaign: (listOfContentsInCampaign) => dispatch(DisplayActions.setListOfContentsInCampaign(listOfContentsInCampaign)),
  updateCustomContents: (customContents) => dispatch(DisplayActions.updateCustomContents(customContents)),
  deleteContentFromCampaign: (contentId) => dispatch(DisplayActions.deleteContentFromCampaign(contentId)),
  cleanListOfContentsInCampaign: () => dispatch(DisplayActions.cleanListOfContentsInCampaign()),
  remplaceContentForUniqueVersion: (contentId, listDisplay, contentToReplace ) => dispatch(DisplayActions.remplaceContentForUniqueVersion(contentId, listDisplay, contentToReplace )),
  removeRelationFromDisplay: (relation) => dispatch(DisplayActions.removeRelationFromDisplay(null, relation)),
  setListResolutionWithContent: (listResolutionWithContent) => dispatch(DisplayActions.setListResolutionWithContent(listResolutionWithContent)),
  updateListOfContentsInCampaign: (listOfContentsInCampaign) => dispatch(DisplayActions.updateListOfContentsInCampaign(listOfContentsInCampaign)),
  setToleranceResolution: (toleranceResolution) => dispatch(DisplayActions.setToleranceResolution(toleranceResolution)),
  removeRelationsOnCampaign: (displayId, contentId) => dispatch(DisplayActions.removeRelationsOnCampaign(displayId, contentId)),
  updateImpressionsDisplay: (displayId, contentId, impressions) => dispatch(DisplayActions.updateImpressionsDisplay(displayId, contentId, impressions)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ContentsOfCampaignComponent)