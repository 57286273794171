import React, { Component } from "react";
import PropTypes from "prop-types";
import ScreenHolder from "./Styles/screenComponentStyle";
import { Card, Icon, Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Tabs } from "antd";
import { metabaseUrl } from "../services/utils";
import chartsEnum from "../constants/AudienceChartEnum";
import moment from "moment";
import {getRefinedAudience} from "../services/display";
import  AudienceProviderInfo  from "./AudienceProviderInfo";
import AudienceGraphResumenComponent from "./AudienceGraphResumenComponent";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";

const { TabPane } = Tabs;

class AudienceSummaryComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    parametersMeta: "",
    parametersMapMeta: "",
    tokenMeta: "",
  };

  componentWillMount() {
    this.setParameterUrlMeta();
    this.setTokenUrlMeta();
  }

  setParameterUrlMeta = (nextProps) => {
    const { start_date, end_date, displays, customContents, view } = nextProps || this.props;
    const dateTo = moment(end_date).format('YYYY-MM-DD');
    const dateFrom = moment(start_date).format('YYYY-MM-DD');

    //old version the dates
    const parametersDates = `${dateFrom}~${dateTo}`;
    let parametersDisplays = "";
    displays.forEach((display) =>
      parametersDisplays = `displayid=${display.id}&${parametersDisplays}`
    );
    //new version the dates
    const parametersDateFrom = `date_from=${dateFrom}&date_to=${dateTo}`;

    const contentDisplay = customContents && customContents.map(content => ({
      displayId: content.displayId,
      relationId: content.relationId,
      rules: content.rules || []
    }));
    const parametersContent = customContents && encodeURIComponent(JSON.stringify(contentDisplay))

    //set different urls
    this.setState({
      parametersMapMeta: `${parametersDisplays}&dates=${parametersDates}#hide_parameters=displayid,dates`
    })
    if (view !== 'cartAudienceVideoUpload')
      return this.setState({
        parametersMeta: `${parametersDisplays}&dates=${parametersDates}#hide_parameters=displayid,dates`
      })
    this.setState({
      parametersMeta: `${parametersDateFrom}&content_display=${parametersContent}#hide_parameters=content_display,date_from,date_to`,
    })
  }
  getTypeAudience = (noRefined, refined) => {
    const { user, campaign} = this.props;
    let audienceRefined = getRefinedAudience( user, campaign ? campaign.programmatic_provider_id: null )
    return audienceRefined ? refined : noRefined;
  }
  setTokenUrlMeta = () => {
    const { view, user, campaign} = this.props;
    let audienceRefined = getRefinedAudience( user, campaign ? campaign.programmatic_provider_id: null);
    if (view !== 'cartAudienceVideoUpload') {
      return this.setState({
        tokenMeta: audienceRefined ? chartsEnum.audienceQuantitativeRefined : chartsEnum.audienceQuantitative
      })
    }
    this.setState({
      tokenMeta: audienceRefined ? chartsEnum.audienceWithRulesRefined : chartsEnum.audienceWithRules
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.resetAudience !== this.props.resetAudience) {
      if(!nextProps.resetAudience){
        this.setState({
          parametersMeta:"",
          parametersMapMeta:"",
          tokenMeta:"",
        })
        return
      }else{
        this.setParameterUrlMeta();
        this.setTokenUrlMeta();
      }
    }
  }
  UNSAFE_componentWillUpdate(nextProps){
    if (nextProps.audience && this.props.audience && nextProps.audience.total_audience !== this.props.audience.total_audience)
      this.setParameterUrlMeta(nextProps);
  }

  componentDidMount() {
    const isRefined = getRefinedAudience(this.props.user, this.props.campaign ? this.props.campaign.programmatic_provider_id : null);

    if (isRefined) {
      this.fetchAudienceData();
    }
  }

  componentDidUpdate(prevProps) {
    const isRefined = getRefinedAudience(this.props.user, this.props.campaign ? this.props.campaign.programmatic_provider_id : null);

    if (isRefined && this.shouldRefreshData(prevProps)) {
      this.fetchAudienceData();
    }
  }

  shouldRefreshData(prevProps) {
    const { start_date, end_date, displays, campaign } = this.props;
    return prevProps.start_date !== start_date ||
           prevProps.end_date !== end_date ||
           prevProps.displays !== displays ||
           prevProps.campaign !== campaign;
  }

  fetchAudienceData() {
    const { campaign, start_date, end_date, displays, getProposalAudience } = this.props;

    if (campaign) {
      // Campaign mode
      getProposalAudience({
        campaign_id: campaign.id
      });
    } else {
      // Cart/proposal mode
      const contentDisplays = displays.map(display => ({
        display_id: display.id,
        rules: display.rules || []
      }));

      getProposalAudience({
        contentDisplays,
        startDate: start_date,
        endDate: end_date
      });
    }
  }

  render() {
    const { t, user, campaign, displays, proposalAudience} = this.props;
    const { parametersMeta, parametersMapMeta, tokenMeta } = this.state;
    let refined = getRefinedAudience(user, campaign ? campaign.programmatic_provider_id: null)
    console.log(refined)

    return (
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <Icon type="edit" />
              {t("Audience")}
            </span>
          }
          key="1"
        >
          <ScreenHolder>
            <Row
              className="screenContainer"
              type="flex"
              justify="center"
              align="middle"
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <AudienceProviderInfo displays={displays} user={user}/>
                {refined && proposalAudience ? (
                  <AudienceGraphResumenComponent proposalAudience={ proposalAudience }></AudienceGraphResumenComponent>
                ) : refined && !proposalAudience ? (
                  <GenerateSkeletonComponent
                    count={1}
                    height={400}
                    width="100%"
                    type="graph"
                  />
                ) : (
                  <Card className="screenCard">
                    {tokenMeta !== "" ?
                        <iframe
                          id="audience-quantitative"
                          title="audience-quantitative"
                          src={`${metabaseUrl}${tokenMeta}?${parametersMeta}`}
                          frameBorder="0"
                          scrolling="yes"
                          width="100%"
                          height="600px"
                        ></iframe>:
                      <div style={{height:"600px"}}></div>
                    }
                  </Card>
                )}
              </Col>
            </Row>
          </ScreenHolder>
        </TabPane>
        {!refined && (
          <TabPane
            tab={
              <span>
                <Icon type="environment" />
                {t("Map")}
              </span>
            }
            key="2"
          >
            <ScreenHolder>
              <Row
                className="screenContainer"
                type="flex"
                justify="center"
                align="middle"
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card className="screenCard">
                    <iframe
                      id="audience-map"
                      title="audience-map"
                      src={`${metabaseUrl}${this.getTypeAudience(chartsEnum.audienceMap, chartsEnum.audienceMapRefined)}?${parametersMapMeta}`}
                      frameBorder="0"
                      scrolling="no"
                      width="100%"
                      height="600px"
                    ></iframe>
                  </Card>
                </Col>
              </Row>
            </ScreenHolder>
          </TabPane>
        )}
      </Tabs>
    );
  }
}

AudienceSummaryComponent.contextTypes = {
  router: PropTypes.object,
};

const Extended = withTranslation()(AudienceSummaryComponent);
Extended.static = AudienceSummaryComponent.static;

export default AudienceSummaryComponent;
