import React, { Component } from "react";
import InfoHolder from "./Styles/infoStyle";
import { Modal, Col, Card } from "antd";
import YouTube from "react-youtube";
import imagePlayVideo from "../images/videoYoutube.png";
import { withTranslation } from 'react-i18next';

const { Meta } = Card;

class Info extends Component {
  state = {
    videoLatinadESVisible: false,
    videoLatinadENVisible: false
  };

  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  showModalVideoLatinadES = () => {
    this.setState({
      videoLatinadESVisible: true
    });
    if (this.video.current) this.video.current.internalPlayer.playVideo();
  };

  handleOkVideoLatinadES = e => {
    this.setState({
      videoLatinadESVisible: false
    });
  };

  handleCancelVideoLatinadES = e => {
    this.setState({
      videoLatinadESVisible: false
    });
    this.video.current.internalPlayer.pauseVideo();
  };

  showModalVideoLatinadEN = () => {
    this.setState({
      videoLatinadENVisible: true
    });
    if (this.video.current) this.video.current.internalPlayer.playVideo();
  };

  handleOkVideoLatinadEN = e => {
    this.setState({
      videoLatinadENVisible: false
    });
  };

  handleCancelVideoLatinadEN = e => {
    this.setState({
      videoLatinadENVisible: false
    });
    this.video.current.internalPlayer.pauseVideo();
  };

  render() {
    const { t , i18n } = this.props;
    return (
      <InfoHolder>
        <Modal
          className="videoModal"
          visible={this.state.videoLatinadESVisible}
          onOk={this.handleOkVideoLatinadES}
          onCancel={this.handleCancelVideoLatinadES}
          footer={null}
        >
          <YouTube
            ref={this.video}
            videoId="iPcQAzAH8nI"
            opts={{
              height: "100%",
              width: "100%",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                showinfo: 0,
                modestbranding: 0,
                rel: 0
              }
            }}
          />
        </Modal>
        <Modal
          className="videoModal"
          visible={this.state.videoLatinadENVisible}
          onOk={this.handleOkVideoLatinadEN}
          onCancel={this.handleCancelVideoLatinadEN}
          footer={null}
        >
          <YouTube
            ref={this.video}
            videoId="OuK39XikxpY"
            opts={{
              height: "100%",
              width: "100%",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                showinfo: 0,
                modestbranding: 0,
                rel: 0
              }
            }}
          />
        </Modal>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} justify="center" align="middle" >
            {(i18n.language == 'es' || i18n.language == 'es_ar') ? (
                <img
                  src={imagePlayVideo}
                  className="imageVideo"
                  onClick={this.showModalVideoLatinadES}
                  alt=""
                />
              ) : (
                <img
                  src={imagePlayVideo}
                  className="imageVideo"
                  onClick={this.showModalVideoLatinadEN}
                  alt=""
                />
              )
            }
        </Col>
      </InfoHolder>
    );
  }
}
export default withTranslation()(Info);
