const UserRoleEnum = {
    companyMember: 'company-member',
    companyMemberAdmin: 'company-member-admin',
    companyMemberCommercial: 'company-member-commercial',
    agencyMember: 'agency-member',
    superAdmin: 'super-admin',
    superModeratorAgency: 'super-moderator-agency',
    superModeratorMediaCompany: 'super-moderator-media-company',
    planner: 'planner',
  };

  export default UserRoleEnum;
