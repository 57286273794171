import styled from "styled-components";
import Colors from "../../themes/Colors";

const ExpansionHolder = styled.div`
  .expansionContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    background-image: white;
    color: ${Colors.snow};
    padding: 65px 100px 35px 100px;

    h1 {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
      color: ${Colors.primary};
    }

    h2 {
      font-size: "20px";
      color: ${Colors.primary};
      padding-left: "20px";
    }

    p {
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 25px;
      line-height: 1.2;
    }

    @media (min-width: 1480px) {
      padding: 65px 270px 35px 270px;
    }
    @media (max-width: 576px) {
      padding: 65px 10px 35px 10px;
    }
  }
  .imageBg {
    position: absolute;
    width: 515px;
    right: 0;
    top: 0;

    @media (max-width: 980px) {
      width: 440px;
      right: 0;
      top: 0;
    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 1480px) {
      width: 550px;
      right: 0;
      top: 0;
    }
  }

`;

export default ExpansionHolder;
