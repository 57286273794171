import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardListSummaryStyleHolder = styled.div`
  .cardListSummary {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 10px;
    height: auto;
    width: 210px;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    margin-bottom: 10px;

    @media only screen and (max-width: 575px) {
      position: relative;
      bottom: 0px;
      top: 0px;
      left: 0px;
    }
    @media only screen and (min-width: 1000px) {
      top: calc(120px + 350px + 12px);
      width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      width: 11%;
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      width: 100%;
    }

  }

  .text-title {
    text-align: left;
    font-size: 20px;
    opacity: 0px;
    margin-top: 15px;
    margin-left: 15px;
  }

  .titleInfo {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .infoDate {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: right;
    color: #D7EAFD;
    margin-right: 15px;
  }
  .infoDateTwo {
    margin-bottom: 20px;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #D7EAFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    padding-left: 20px;
  }
  .listContent {
    overflow-y: scroll;
    margin-top: 15px;
    height: 250px;
  }
  .listContent::-webkit-scrollbar {
    display: none;
  }
  .content:last-child .ant-divider {
    display: none;
  }
  .ant-divider {
    min-width: unset;
    width: 70%;
    margin: 0 auto;
    background-color: #D7EAFD;
  }
}`;

export default CardListSummaryStyleHolder;