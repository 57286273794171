/* global google */
import React, { Component } from "react";
import { Button, Icon, Tooltip, notification, Typography, Input } from "antd";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import InfoWindow from "react-google-maps/lib/components/InfoWindow";
import { convertBounds } from "../services/display";

import markerLatinad from "../images/marker-latinad.png";

import markerPin from "../images/markerPin.png";
import { getIconType } from "../services/display"

import placeholder from "../images/bg.jpg";
import NumberFormat from "./NumberFormat";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { calculateHoursForImpressions } from "../services/utils";
import MapScreenInfoBox from "./MapScreenInfoBox";
import Colors from "../themes/Colors";
import {getFilterDisplaysForContains} from "../services/display";
import { forEach } from "lodash";
import logger from "redux-logger";
const IsPointIn = require("@pelevesque/is-point-in");

const Search = Input.Search;

const { Paragraph } = Typography;

export const ResultsMap = withTranslation()(
  compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDz82Ts-JAvLq2f-tLy5z9Gkrx-DCfVLfM&v=3.exp&libraries=geometry,drawing,places",
    }),
    withScriptjs,
    withGoogleMap
  )(
    class ResultsMapComponent extends Component {
      constructor(props) {
        super(props);
        this.shapes = [];
        this.onOverlayComplete = this.onOverlayComplete.bind(this);
        this.deleteShapes = this.deleteShapes.bind(this);
        this.state = {
          isMapFit: false,
          displaysAudience: [],
          screenDetail: false,
          displaysAction: null,
          searchResult: [],
          showingInfoWindow: false,
          activeMarker: {},
          selectedPlace: {},
          overlays: [],
        };
      }

      getSizeType(type) {
        if (type === "small") {
          return this.props.t("Small");
        }
        if (type === "medium") {
          return this.props.t("Medium");
        }
        if (type === "big") {
          return this.props.t("Big");
        }
        if (type === "giant") {
          return this.props.t("Giant");
        }
      }

      componentDidMount() {
        if (this.map && !this.state.isMapFit) {
          // Set street view controls position
          this.map.getStreetView().setOptions({
            addressControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_CENTER,
            },
          });

          this.map.panToBounds(this.props.bounds);
          this.setState({ isMapFit: true });

          if (this.props.cart && this.props.cart.length === 0) {
            this.openZoomAlert("bottomRight");
          }
        }
      }

      componentDidUpdate(prevProps) {
        const {searchPlace}= this.props;
        const { east, south } = this.props.bounds;
        if (this.map && !this.state.isMapFit) {
          this.map.panToBounds(this.props.bounds);
          this.setState({ isMapFit: true });
        }
        if (this.map && this.props.relocateMap) {
          this.map.panToBounds(this.props.bounds);
          this.props.mapRelocated();
        }
        if (this.map && this.props.searchLocation) {
          this.props.setBounds(convertBounds(this.map.getBounds()));
          // this.props.setBounds(this.props.bounds);
          this.props.searchLocationOff();
        }
        if (!(searchPlace === "" && prevProps.searchPlace === "") && ( prevProps.searchPlace !== searchPlace || east !== prevProps.bounds.east || south !== prevProps.bounds.south)) {
          this.onPlaceSearch(searchPlace);
        }
      }

      handlePlace = (place) => {
        this.setState({ place });
      };

      openZoomAlert = (placement) => {
        notification.info({
          message: this.props.t(
            "Remember to zoom out the map to see more results"
          ),
          description: this.props.t(
            "Only screens that are within the visible area of the map will be displayed."
          ),
          duration: 7,
          placement,
        });
      };

      screenDetail = () => {
        this.setState({
          screenDetail: true,
        });
      };
      okScreenDetail = (e) => {
        this.setState({
          screenDetail: false,
        });
      };
      cancelScreenDetail = (e) => {
        this.setState({
          screenDetail: false,
        });
      };

      callback = (results, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          this.setState({
            searchResult: results,
          });
        }
      };

      onPlaceSearch = (searchPlace) => {
        if(searchPlace === "") {
          this.setState({
            searchResult: [],
          });
        }
        const { east, north, south, west } = this.props.bounds;
        var map;
        var service;
        // Calculate center of coordinates
        var centerLat = (north + south) / 2;
        var centerLng = (west + east) / 2;
        var pyrmont = new google.maps.LatLng(centerLat, centerLng);

        map = new google.maps.Map(document.createElement('div'), {
          center: pyrmont,
          zoom: 15,
        });

        var request = {
          location: pyrmont,
          radius: "500",
          query: searchPlace,
        };

        service = new google.maps.places.PlacesService(map);
        service.textSearch(request, this.callback);
      };

      checkPoint = (long, lat, cX, cY, radius) => {
        IsPointIn.circle(long, lat, cX, cY, radius);
      };

      onOverlayComplete = async (event) => {
        // TODO:  implement circle overlay
        // Latitude is the Y axis, longitude is the X axis.
        // let coords = convertBounds(event.overlay.getBounds());
        // let center = event.overlay.getCenter();
        // let cX = (coords.lng_ne + coords.lng_sw) / 2;
        // let cY = (coords.lat_ne + coords.lat_ne) / 2;
        // let radius = event.overlay.getRadius();
        // this.checkPoint(display.longitude, display.latitude, cX, cY, radius)
        // To save drawn shapes
        const shape = event.overlay;
        shape.type = event.type;
        google.maps.event.addListener(shape, "click", () => {
          this.toggleSelection(shape);
        });
        this.toggleSelection(shape);
        this.shapes.push(shape);

        // To check if the shape contains the point
        // const { arrayPolygon } = this.state;
        const {
          addListDisplayToCart,
          updateArrayPolygon,
          displays,
          cart,
        } = this.props;
        const coords = event.overlay.getPath().getArray();
        const batchSize = 40; // count displays for add in cart
        const drawnPolygon = new google.maps.Polygon({ paths: coords });
        let listOfDisplays = [];

        // If displays are bundle only, don't add to cart whit polygon
        const displaysNoBundleOnly = displays.filter(display => display.bundle_only != true);
        for (let i = 0; i < displaysNoBundleOnly.length; i = i + batchSize) {
          listOfDisplays.push(displaysNoBundleOnly.slice(i, i + batchSize));
        }

        // Create promise for optmize performance
        let listPromise = [];
        for (let i = 0; i < listOfDisplays.length; i ++) {
          const listDisplays = listOfDisplays[i];
          if(listDisplays.length > 0)
            listPromise.push(this.onOverlayCompleteTask(drawnPolygon, listDisplays, cart));
        }

        const listResultPromise = await Promise.all(listPromise);
        let arr = [];
        listResultPromise.forEach((listResult) => {
          arr = [...arr, ...listResult];
        });

        addListDisplayToCart(arr)
        updateArrayPolygon(arr);
      };

      onOverlayCompleteTask (drawnPolygon, listDisplays, cart) {
        return new Promise((resolve) => {
          let arr = listDisplays.filter((display) => {
            const displayPosition = new google.maps.LatLng(
              display.latitude,
              display.longitude
            );
            const isDisplayIn = google.maps.geometry.poly.containsLocation(
              displayPosition,
              drawnPolygon
            );
            const  isOnCart = cart.find(d => d.id === display.id);
            if (isDisplayIn && !isOnCart) {
              return display;
            }
            return false;
          });
          resolve(arr);
        });
      }

      toggleSelection(shape) {
        if (shape.getEditable() === true) shape.setEditable(false);
        else shape.setEditable(true);
      }

      deleteShapes() {
        this.shapes.forEach((shape) => shape.setMap(null));
      }

      onMarkerClick = (props, marker, e) => {
        this.setState({
          selectedPlace: props,
          activeMarker: marker,
          showingInfoWindow: true,
        });
      };

      onInfoWindowClose = () => {
        this.setState({
          activeMarker: null,
          showingInfoWindow: false,
        });
      };

      displayMarkers = () => {
        const { searchResult } = this.state;
        if (searchResult.length > 0) {
          return searchResult.map((marker, i) => {
            return (
              <Marker
                key={i}
                position={marker.geometry.location}
                clickable={true}
                onClick={() => this.onMarkerClick(marker)}
                cursor="pointer"
                icon={{
                  url: markerPin,
                  size: { width: 30, height: 37 },
                  scaledSize: { width: 30, height: 37 },
                }}
              />
            );
          });
        }
      };

      // Show card when is selected
      checkDisplaySelected = (currentDisplay, display) => {
        if (!currentDisplay) return false;
        // If circuit is selected
        if (currentDisplay.id_secondary_display) {
          return currentDisplay.id_secondary_display === display.id_secondary_display
        }
        return currentDisplay && this.props.currentDisplay.id === display.id
      }

      render() {
        const { t, hoverDisplay, currentDisplay } = this.props;
        const { selectedPlace, showingInfoWindow } = this.state;
        return (
          <GoogleMap
            ref={(map) => (this.map = map)}
            defaultZoom={this.props.mapZoom}
            zoom={this.props.mapZoom}
            options={{
              clickableIcons: false, // not interactue with icons
              fullscreenControl: false,
              mapTypeControl: false,
              // Hide POI (Maker of google maps)
              styles: [
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
              streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM,
              },
              zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM,
              },
            }}
            // center={this.props.defaultCenter}
            defaultCenter={this.props.defaultCenter}
            loadingElement={this.props.loadingElement}
            containerElement={this.props.containerElement}
            mapElement={this.props.mapElement}
            onDragEnd={async () => {
              if (!this.map) {
                return;
              }
              const bounds = await this.map.getBounds();
              this.props.setBounds(convertBounds(bounds));
              // this.props.updateCenter(
              //   (coord.bounds.lat_sw + coord.bounds.lat_ne) / 2,
              //   (coord.bounds.lng_sw + coord.bounds.lng_ne) / 2
              // );
              // this.props.changeDragInMap(false);
              // this.props.refreshDisplays();
            }}
            onZoomChanged={async () => {
              if (!this.map) {
                return;
              }
              const bounds = await this.map.getBounds();
              this.props.setBounds(convertBounds(bounds));
              this.props.setMapZoom(this.map.getZoom());
              // this.props.refreshDisplays();
            }}
            onClick={this.deleteShapes}
          >
            {/* <TrafficLayer autoUpdate /> */}
            <DrawingManager
              defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.BOTTOM_CENTER,
                  drawingModes: [
                    // google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.POLYGON,
                    // google.maps.drawing.OverlayType.RECTANGLE,
                  ],
                },

                polygonOptions: {
                  fillColor: Colors.primary, //72BDD4
                  fillOpacity: 0.5,
                  strokeWeight: 1,
                  clickable: true,
                  editable: true,
                  zIndex: 1,
                },
              }}
              onOverlayComplete={(e) => {
                this.onOverlayComplete(e);
              }}
            />
            {this.props.displays.map((initDisplay) => {
              let onCart = this.props.cart.find((d) => d.id === initDisplay.id);

              let markerDisplay = markerLatinad;
              markerDisplay = getIconType(initDisplay.location_type, initDisplay.display_type, onCart, 'isPin');
              let listDisplays = [];
              if(initDisplay.is_main_display) {
                initDisplay.secondary_displays.forEach((secondaryDisplay) => {
                  let displayAux =  Object.assign({}, initDisplay);
                  displayAux.latitude = secondaryDisplay.latitude;
                  displayAux.longitude = secondaryDisplay.longitude;
                  displayAux["name_secondary_display"] = secondaryDisplay.name;
                  displayAux["id_secondary_display"] = secondaryDisplay.id;
                  listDisplays.push(displayAux);
                });
              } else{
                listDisplays.push(initDisplay);
              }

              return listDisplays.map((display, index) => {
                    return (
                      <span key={display.id + "-" + index}>
                        { getFilterDisplaysForContains (display, this.props.user) ? (
                          <Marker
                            zIndex={hoverDisplay === display || onCart ? 99 : 1}
                            key={display.id + "-"+index}
                            icon={{
                              url: markerDisplay,
                              size:
                                hoverDisplay === display || currentDisplay === display
                                  ? { width: 35, height: 42 }
                                  : { width: 30, height: 37 },
                              scaledSize:
                                hoverDisplay === display || currentDisplay === display
                                  ? { width: 35, height: 42 }
                                  : { width: 30, height: 37 },
                            }}
                            onClick={() => this.props.openDisplay(display)}
                            position={{
                              lat: parseFloat(display.latitude),
                              lng: parseFloat(display.longitude),
                            }}
                          >
                            {this.checkDisplaySelected(this.props.currentDisplay, display) && (
                                <MapScreenInfoBox
                                  display={display}
                                  handleClose={this.props.closeDisplay}
                                  onCart={onCart}
                                  paymentStatus={
                                    this.props.editCampaign.payment_status
                                  }
                                  addDisplayToCart={this.props.addDisplayToCart}
                                  removeDisplayFromCart={
                                    this.props.removeDisplayFromCart
                                  }
                                  getAudience={this.props.getAudience}
                                  cart={this.props.cart}
                                  programmatic={this.props.programmatic}
                                  date_from={this.props.date_from}
                                  date_to={this.props.date_to}
                                  editCampaignActive={this.props.editCampaignActive}
                                  listBundle={this.props.listBundle}
                                />
                              )}
                          </Marker>
                        ) : null}
                      </span>
                    )}
                    );
            })}
            {this.displayMarkers()}
            {showingInfoWindow && (
              <InfoWindow
                position={selectedPlace.geometry.location}
                visible={showingInfoWindow}
                onCloseClick={this.onInfoWindowClose}
                options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
              >
                <h5>{selectedPlace.name}</h5>
              </InfoWindow>
            )}
          </GoogleMap>
        );
      }
    }
  )
);
