import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import AudienceProviderInfoHolder from "./Styles/AudienceProviderInfoStyle";
import genericAudience from "../images/generic-audience-icon.png";

const AudienceProviderInfo = ({ displays, user, t }) => {

    const [providerList, setProviders] = useState([]);
    const [providerTypeList, setProviderTypeList] = useState([]);
    const [singleProviderType, setSingleProvider] = useState(false);

    const screenOwnerProviderId = 2;

    const getProviderType = (display) => {
        return !!display.audience_provider_id ? 'external' : 'calculated';
    };

    const removeDuplicates = (arr) => {
        return Array.from(new Set(arr.map(JSON.stringify)), JSON.parse);
    };

    const getProviderName = (provider) => {
        return provider.id === screenOwnerProviderId ? t('display owner') : provider.stylized_name;
    };

    const getProviderImg = (provider) => {
        return provider && provider.image ? provider.image : genericAudience;
    };

    const seeRefinedAudience = user && user.company && user.company.see_refined_audience == 1;

    useEffect(() => {
        if (seeRefinedAudience) {
            if (displays && displays.length > 0) {
                const providerTypes = displays.map(d => getProviderType(d));
                setProviderTypeList(providerTypes);
                setSingleProvider(providerTypes.every(p => p === providerTypes[0]));
                if (providerTypes.includes('external')) {
                    const uniqueProviders = removeDuplicates(displays.filter(d => d.audience_provider_id).map(d => d.audience_provider));
                    setProviders(uniqueProviders);
                }
            }
        } else {
            setProviderTypeList('calculated');
            setSingleProvider(true);
            setProviders([]);
        }
    }, [displays]);

    return (
        <>
            <AudienceProviderInfoHolder>
                <div className="audience-provider-info-component">
                    {singleProviderType && providerList.length < 2 && (
                        <div className="provider-container">
                            {providerTypeList[0] === 'external' ? (
                                <>
                                    <img src={getProviderImg(providerList[0])} className="provider-image" />
                                    <div>
                                        {displays && displays.length == 1 ? t("Audience data for this screen provided by") : t("Audience data for these screens provided by")} {' '}
                                        {providerList[0].url ? (
                                            <a href={providerList[0].url} target="_blank">{getProviderName(providerList[0])}</a>
                                        ) : (
                                            <span>{getProviderName(providerList[0])}</span>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <img src={getProviderImg()} className="provider-image" />
                                    <span>{t("Audience data are statistically calculated approximations")}</span>
                                </div>
                            )}
                        </div>
                    )}
                    {!(singleProviderType && providerList.length < 2) && (
                        <div className="provider-container">
                            {displays && displays.length == 1 ? t("Audience data for this screen provided by") : t("Audience data for these screens provided by")} {' '}:
                            <div className="external-provider-names">
                                {providerList.map((provider, index) => (
                                    <span key={index}>
                                        {index !== 0 && <span>&nbsp;/&nbsp;</span>}
                                        <img src={getProviderImg(provider)} className="small-provider-image" />
                                        {provider.url ? (
                                            <a href={provider.url} target="_blank">{getProviderName(provider)}</a>
                                        ) : (
                                            <span>{getProviderName(provider)}</span>
                                        )}
                                    </span>
                                ))}
                            </div>
                            {providerTypeList.includes('calculated') && (
                                <div className="external-provider-names">
                                    <span>&nbsp;/&nbsp;</span>
                                    <img src={genericAudience} className="small-provider-image" />
                                    {t("Statistically calculated approximations")}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </AudienceProviderInfoHolder>
        </>
    )
};

export default withTranslation()(AudienceProviderInfo);