import styled from "styled-components";
import Colors from "../../themes/Colors";

const PaymentHolder = styled.div`
  padding: 120px 0px 0px 0px;
  .checkoutContainer {
    padding: 25px 15px 10px;
    @media only screen and (min-width: 768px) {
      padding: 25px 0 10px;
    }
  }

  .leftContainer {
    padding-top: 10px;
    border-radius: 16px;
    background-color: ${Colors.snow};
    border: 1px solid #e8e8e8;
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
    height: "fit-content";
  }

  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }

  h3 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    color: ${Colors.coal};
    font-weight: 600;
  }

  .ant-input {
    width: 100%;
    border: 2px solid #424242;
    border-radius: 8px;
    background-color: #fff;
    color: #424242;
    height: 40px;
    margin-bottom: 10px;
  }

  .has-error {
    .ant-input {
      border-color: #f5222d !important;
    }
  }

  .ant-form-item {
    width: 100%;
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }

  .ant-select {
    margin-bottom: 10px;
  }

  .ant-select-selection {
    width: 100%;
    border: 2px solid #424242;
    border-radius: 8px;
    background-color: #fff;
    ${"" /* color: #424242; */}
    height: 40px;
    line-height: 1.5;
  }

  .ant-select-selection__rendered {
    height: 40px;
    line-height: 40px;
  }

  .ant-input-affix-wrapper {
    margin: 0 0 15px;
  }

  .ant-input::placeholder {
    color: #4242428f;
  }

  .ant-input:hover,
  .ant-input:focus {
    border-right-width: 2px !important;
  }

  .ant-select-selection:hover,
  .ant-select-selection:focus {
    border-right-width: 2px !important;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: ${Colors.primary};
    border-right-width: 2px !important;
  }

  input:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  textarea:focus {
    border: 2px solid ${Colors.primary};
    color: #424242;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border: 2px solid #424242;
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 35px;
  }

  .ant-input-affix-wrapper {
    font-size: 18px;
  }

  .card .rccs {
    margin: unset;
  }
`;

export default PaymentHolder;
