import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreenCardHolder = styled.div`
  cursor: pointer;
  .screenCard {
    position: relative;
    width: 100%;
    background-color: ${Colors.snow};
    border-radius: 16px;
    margin: 0 0 15px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s;
    .slick-arrow {
      display: none !important;
    }
  }

  .screenCard:hover {
    transform: translate3d(0, -5px, 0);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    .slick-arrow {
      display: block !important;
    }
  }

  .iconDiscount{
    color: ${Colors.primary} !important;
  }
  .borderTagDiscount .anticon{
    color: ${Colors.primary} !important;
  }

  .borderTagDiscount{
    display: inline-block;
    margin-left: 2px;
    text-align: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: ${Colors.primary} border-color: ${Colors.primary} color: ${Colors.snow} border: 1px solid transparent;
    border-top-color: rgb(217, 217, 217);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(217, 217, 217);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(217, 217, 217);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(217, 217, 217);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }
  .screenCardContent {
    padding: 10px;
    .cardTitle {
      font-size: 16px;
      font-weight: bold;
    }
    .cardSubTitle {
      padding: 0px 5px 0px 5px;
      font-size: 14px;
      color: rgb(0 0 0 / 65%);
    }
    .cardInfo {
      padding: 0px 5px 0px 5px;
      font-size: 14px;
      font-weight: lighter;
    }
  }

  .screenCardImage {
    border-radius: 16px;
    height: 163px;
    padding: 10px;
    object-fit: cover;
  }
  .icon-bundle {
    width: 26px;
    height: 26px;
    border-radius: 16px;
    margin-bottom: 2px;
    padding: 0px;
    object-fit: cover;
  }
  .screenCardContentCurrent {
    position: absolute;
    bottom: 0;
    background-color: ${Colors.primary};
    width: 100%;
    padding: 18px 15px 10px;
    border-radius: 0 0 15px 15px;
  }
  .screenCardPrice {
    margin: 3px 0 5px;
    padding: 0px 5px 0px 5px;
    color: ${Colors.lightPrimary};
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
    display: flex;
    align-items: center;
  }
  .screenCardSizeType {
    margin: 0 0 5px;
    padding: 0px 5px 0px 5px;
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: bold;
    line-height: 100%;
  }
  .screenCardInfo {
    color: #fff;
    font-size: 12px;
  }
  .changeSpotWarning {
    padding: 20px;
    font-size: 14px;
  }
  .screenCardAddButton {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-left: 7px;
    font-size: 16px;
    font-weight: bold;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .screenCardRemoveButton {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-left: 7px;
    background-color: ${Colors.snow};
    border-color: ${Colors.snow};
    color: ${Colors.red};
  }
  .screenContentButton {
    position: absolute;
  }

  .screenCardCamCertification {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
  }
  .screenCardAudit {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
  }
  .screenCardCamTrafficView {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
  }

  .screenCardInformation {
    z-index: 1;
    display: inline-block;
    margin-left: 7px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
  }

  .screenWarningNoAutomateView {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${Colors.snow};
    border-color: #ffc107;
    color: ${Colors.snow};
  }
  .circuit {
    width: 90px;
    border-radius: 16px;
    z-index: 1;
    background-color: ${Colors.snow};
    border-color: #926dde;
    color: #926dde;
  }
  .doubleScreen {
    z-index: 1;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .slick-list {
    border-radius: 15px;
  }
  .iconTypeScreen {
    margin-left: 5px;
    text-transform: capitalize;
    font-size: 12px;
  }
  .imagenTypeScreen {
    max-width: auto;
    max-height: 17px
  }
  .rowButton {
    marginBottom: "5px";
    border-right-width: 8px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
  }
  .colButtonFirst {
    padding-left: 10px;
    paleft: 12px;
    margin: 5px;
    alignItems: baseline;
  }
  .addIconBundle {
    color: var(--primary);
    font-size: 16px;
    padding-left: 3px;
    padding-top: 12px;
    bottom: 21px;
    left: 16px;
    position: absolute;
  }
  .minusIconBundle {
    color: #d16060c7;
    font-size: 16px;
    padding-left: 3px;
    padding-top: 5px;
    bottom: 5px;
    left: 21px;
    position: absolute;
  }
  `;

export default ScreenCardHolder;
