import React, { Component } from "react";
import {
  Modal,
  Button,
  Icon,
  DatePicker,
  Input
} from 'antd';
import moment from "moment";
import { withTranslation } from 'react-i18next';
import ReplicateCampaignHolder from "./Styles/replicateCampaignComponentStyle";
import Colors from "../themes/Colors";

const { RangePicker } = DatePicker;

class ReplicateCampaignComponent extends Component {
  state = {
    visible: false,
    nameError: false,
    nameCampaing: "",
    to_date: "",
    end_date: "",
    hasDateRules: false
  };

  showModal = () => {
    const { name, t, to_date, end_date} = this.props;
    this.checkDateRules();
    this.setState({ nameCampaing: name + " - " + t("Replicated")});
    this.setState({ to_date: moment(to_date).format("YYYY-MM-DD") });
    this.setState({ end_date: moment(end_date).format("YYYY-MM-DD") });
    this.setState({
      visible: true,
      nameError: false,
      toDateError: false,
      endDateError: false
    });
  };

  handleDateChange = (dateStrings) => {
    let startDate = moment(dateStrings[0]).format("YYYY-MM-DD");
    let endDate = moment(dateStrings[1]).format("YYYY-MM-DD");
    this.setState({ to_date: startDate});
    this.setState({ end_date: endDate});
  }

  handleOk = () => {
    const { replicateCampaign, currentCampaign, campaignId} = this.props;
    const { nameCampaing, to_date, end_date } = this.state;
    if (nameCampaing && to_date && end_date) {
      let params = {
        name: nameCampaing,
        start_date: to_date,
        end_date: end_date,
        new_ssp: currentCampaign.new_ssp,
      };
      replicateCampaign (campaignId, params)

    } else {
      this.setState({
        nameError: !nameCampaing,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  componentDidUpdate(prevProps) {
    const { replicateCampaignId } = this.props;
    if (prevProps.replicatingCampaign && !this.props.replicatingCampaign) {
      this.setState({ showModal: true });
      window.location.replace(`/campaign/${replicateCampaignId}`)
      this.setState({
        visible: false
      });
    }
  }

  checkDateRules = () => {
    const { currentCampaign } = this.props;
    if (!currentCampaign || !currentCampaign.contents_displays) return;

    for (const element of currentCampaign.contents_displays) {
      const contentsDisplays = element.content_child_contents || [element];
      for (const contentDisplay of contentsDisplays) {
        const rules = JSON.parse(contentDisplay.rules);
        if (!rules) continue;

        if (rules.some(rule => rule.conditions.some(cond => cond.type === "date"))) {
          this.setState({ hasDateRules: true });
          return;
        }
      }
    }
  };

  render() {

    const { t } = this.props;
    const {nameCampaing, to_date, end_date , nameError, hasDateRules } = this.state;

    return (
      <div className="contentModal">
        <Button
          className="backButtonReplicator"
          onClick={() => this.showModal()}
          style={{ color: Colors.primary }}
        >
          <Icon style={{ fontSize: "15px", color: Colors.primary }} type="copy" />
          {" "}
          {t('Replicate campaign')}
        </Button>
        <Modal
          title={t("Replicate campaign")}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
            footer={[
          <Button key="cancel" onClick={this.handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={this.handleOk}
            loading={this.props.replicatingCampaign}
          >
            {t("OK")}
          </Button>,
        ]}
        >
          <ReplicateCampaignHolder>

            <div className="content-input">
              <span className="Input">{t("Name")}</span>
              <Input
                placeholder=""
                onChange={e =>
                  this.setState({
                    nameCampaing: e.target.value,
                    nameError: false
                  })
                }
                value={nameCampaing}
                className={nameError ? "error" : ""}
              />
              {nameError && (
                <p className="error-message">
                  {t('The name is required')}
                </p>
              )}
            </div>
            <div>
              <span>{t("Exhibition date")}</span>
              <span className="">
                <RangePicker
                  value={[moment(to_date), moment(end_date)]}
                  dropdownAlign={{ offset: [0, 8] }}
                  onChange={this.handleDateChange}
                />
              </span>
            </div>
            <div className="content-input-info">
              <p>
                <Icon
                  style={{ fontSize: "15px", marginRight: "5px", color: "#faad14"}}
                  type="info-circle"/>
                  { t("A campaign with a pending payment status will be created, keeping the contents and screens that are still available for sale.") }
              </p>
            </div>
            { hasDateRules && (
                <div className="content-input-info">
                  <p>
                    <Icon
                      style={{ fontSize: "15px", marginRight: "5px", color: "#faad14"}}
                      type="info-circle"/>
                      { t("The original campaign has rules based on dates, these will be removed. Remember to configure them again.") }
                  </p>
                </div>)
            }
          </ReplicateCampaignHolder>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(ReplicateCampaignComponent);