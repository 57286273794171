import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import AudienceGraphHolder from "./Styles/audienceGraphStyle";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";
import { getDaysOfWeek } from '../services/utils';

const chartOptions = (t) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: t('Audience Average'),
            }
        },
        x: {
            beginAtZero: true,
        }
    },
    plugins: {
        legend: {
            display: false,
        },
    }
});

class AudienceGraphGeneralComponent extends Component {
    state = {
        audienceDaysData: null,
        audienceHoursData: null,
    };

    componentDidMount() {
        this.prepareAudienceData();
    }

    prepareAudienceData() {
        const { t, audienceDaysOfWeek, audiencehoursOfDay } = this.props;

        if (!audienceDaysOfWeek || !audiencehoursOfDay.data) return;

        const daysData = this.getAudienceDaysData(t, audienceDaysOfWeek);
        const hoursData = this.getAudienceHoursData(t, audiencehoursOfDay);

        this.setState({
            audienceDaysData: daysData,
            audienceHoursData: hoursData,
        });
    }

    getAudienceDaysData = (t, audienceDaysOfWeek) => ({
        labels: getDaysOfWeek(t).map(day => day.label),
        datasets: [
            {
                label: t('Audience by day of the week'),
                data: audienceDaysOfWeek.data.map(day => day.audience),
                backgroundColor: `rgba(247, 218, 100, 1)`,
                borderColor: `rgba(247, 218, 100, 1)`,
                borderWidth: 1,
                fill: true,
            }
        ]
    });

    getAudienceHoursData = (t, audiencehoursOfDay) => ({
        labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        datasets: [
            {
                label: t('Audience by time of day'),
                data: audiencehoursOfDay.data.map(hour => hour.audience),
                backgroundColor: `rgba(113, 114, 173, 1)`,
                borderColor: `rgba(113, 114, 173, 1)`,
                borderWidth: 1,
                fill: true,
            }
        ]
    });

    renderChart = (data, t, skeletonCount = 7) => (
        data ? (
            <Bar data={data} options={chartOptions(t)} />
        ) : (
            <GenerateSkeletonComponent count={skeletonCount} height={300} width="100%" />
        )
    );

    render() {
        const { t } = this.props;
        const { audienceDaysData, audienceHoursData } = this.state;

        return (
            <AudienceGraphHolder>
                <div className="audience-graph">
                    <h5>{t('Audience by day of the week')}</h5>
                    <div className="audience-graph-container">
                        {this.renderChart(audienceDaysData, t)}
                    </div>

                    <h5>{t('Audience by time of day')}</h5>
                    <div className="audience-graph-container">
                        {this.renderChart(audienceHoursData, t)}
                    </div>
                </div>
            </AudienceGraphHolder>
        );
    }
}

export default withTranslation()(AudienceGraphGeneralComponent);
