import React, { Component } from "react";
import PaymentStatusHolder from "./Styles/paymentStatusComponentStyle";
import CheckoutStepsHolder from "./Styles/checkoutStepsStyle";
import CheckoutDetailsHolder from './Styles/checkoutDetailsStyle'
import moment from "moment";
import {Upload, List, Button, Card, Row, Col, message, Icon, Skeleton, Modal, Tooltip } from "antd";
import Colors from "../themes/Colors";
import { withTranslation } from 'react-i18next';
import NumberFormat from "./NumberFormat";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;
class PaymentStatusComponent extends Component {

  state = {
    paymentData: {},
    paymentMessage: '',
    visibleSpotModal: false,
    modalDisplayName: '',
    modalContentFile: '',
    modalContentType: '',
    tax_info_text: '',
    menssageOdc: null,
    loadingOdcAgency: false,
  };

  componentDidMount() {
    this.props.getPayment(this.props.match.params.id);
  }

  getPaymentMethod(type) {
    if (this.state.paymentData.gateway === "publinet") {
      switch(type) {
        case "cash": return this.props.t('Cash');
        case "cheque": return this.props.t('Check');
        case "transfer": return this.props.t('Transfer');
        case "credit_account": return this.props.t('Current account');
      }
    } else if (this.state.paymentData.gateway === "mercadopago_basic") {
      if (this.state.paymentData.last_four_digits) {
        return  `${this.props.t('Payment made through Mercado Pago with Card ending in')} ${this.state.paymentData.last_four_digits}`;
      } else {
        return `${this.props.t('Payment made by Mercado Pago')} (${this.state.paymentData.payment_method_id})`;
      }
    } else {
      switch(type) {
        case "visa": return `${this.props.t('Card')} Visa ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "master": return `${this.props.t('Card')} Master ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "amex": return `${this.props.t('Card')} American Express ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "mercadopago_cc": return `${this.props.t('Card')} MercadoPago ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "naranja": return `${this.props.t('Card')} Naranja ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "nativa": return`${this.props.t('Card')} Nativa ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "tarshop": return `${this.props.t('Card')} Tarshop ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "cabal": return `${this.props.t('Card')} Cabal ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "cencosud": return `${this.props.t('Card')} Cencosud ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "diners": return `${this.props.t('Card')} Diners ${this.props.t('finished in')} ${this.state.paymentData.last_four_digits}`;
        case "pagofacil": return "Pago fácil";
        case "argencard": return `${this.props.t('Card')} Argencard`;
        case "maestro": return `${this.props.t('Card')} Maestro`;
        case "debmaster": return `${this.props.t('Card')} Débito Master`;
        case "debcabal": return `${this.props.t('Card')} Débito Cabal`;
        case "debvisa": return `${this.props.t('Card')} Débito Visa`;
        case "rapipago": return "Rapipago";
        case "redlink": return "RedLink";
        case "bapropagos": return "Bapro Pagos";
        case "cargavirtual": return "Carga Virtual";
        case "cordial": return "Cordial";
        case "cordobesa": return `${this.props.t('Card')} Cordobesa`;
        case "cmr": return `${this.props.t('Card')} CMR`;
        case "nevada": return `${this.props.t('Card')} Nevada`;
        case "cobroexpress": return "Cobro Express";
        case "giftcard_cencosud": return "Giftcard Cencosud";
        case "gift_card_cencosud": return "Giftcard Cencosud";
        default: return this.props.t('Unknown payment method');
      }
    }
  }

  getBillingInfo(type) {
    if (type === "cf") {
      return this.props.t('Final consumer');
    }
    if (type === "a") {
      return `${this.props.t('Invoice')} A`;
    }
    if (type === "b") {
      return `${this.props.t('Invoice')} B`;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.fetchingPayment && !nextProps.fetchingPayment) {
      if (nextProps.errors && nextProps.errors.message) {
        if (this.props.user) {
          this.props.history.push("/404");
        } else {
          this.props.setShouldPromptLogin(true, window.location.pathname);
          this.props.history.push("/");
        }
        return;
      }
      // Tax text for Tooltip
      var tax_info_object =  nextProps.payment.campaign &&
                            nextProps.payment.campaign.tax_info ?
                            Object.values(nextProps.payment.campaign.tax_info): [];
      var tax_info_text_prev = "";
      for (let index = 0; index < tax_info_object.length; index++) {
        if (tax_info_object[index].tax_name !== 'WITHHOLDING TAX') {
          tax_info_text_prev = tax_info_text_prev + tax_info_object[index].tax_name + ' (' + parseFloat(tax_info_object[index].tax_value) + '%). ';
        }
      }
      this.setState(() => {
        return {
          tax_info_text: tax_info_text_prev
        };
      });
    }
    if (this.props.fetchingOrders !== nextProps.fetchingOrders) {
      this.setState((state) => {
        return {
          menssageOdc: nextProps.fetchingOrders
        };
      });
    }
    this.setState((state) => {
      return {
        paymentData: nextProps.payment
      };
    });

    switch (nextProps.payment.status_detail) {
      case "approved_by_admin":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your payment has already been approved.')
          };
        });
        break;
      case "pending":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('The payment is pending accreditation.')
          };
        });
        break;
      case "pending_waiting_payment":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('The payment is pending payment.')
          };
        });
        break;
      case "accredited":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your payment has been credited successfully.')
          };
        });
        break;
      case "cancelled_by_user":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('The payment was canceled, you can generate another payment and choose the method you want.')
          };
        });
        break;
      case "pending_contingency":
      case "pending_review_manual":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('We are processing your payment. In less than two business days we will notify you of the result.')
          };
        });
        break;
      case "cc_rejected_bad_filled_card_number":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Check the card number.')
          };
        });
        break;
      case "cc_rejected_bad_filled_date":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Check the card number.')
          };
        });
        break;
      case "cc_rejected_bad_filled_date":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Check the card number.')
          };
        });
        break;
      case "cc_rejected_bad_filled_other":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Please review the data and try again.')
          };
        });
        break;
      case "cc_rejected_bad_filled_security_code":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Check the security code.')
          };
        });
        break;
      case "cc_rejected_blacklist":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('We were unable to process your payment. Please try another payment method.')
          };
        });
        break;
      case "cc_rejected_call_for_authorize":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Please call your card to authorize the payment and try again.')
          };
        });
        break;
      case "cc_rejected_card_disabled":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your card is not active. Please call the phone number on the back of your card.')
          };
        });
        break;
      case "cc_rejected_card_error":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('We were unable to process your payment. Please try another payment method.')
          };
        });
        break;
      case "cc_rejected_duplicated_payment":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t("You already made a payment for that value. If you need to pay again, use another card or another means of payment.")
          };
        });
        break;
      case "cc_rejected_high_risk":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your payment was declined. Choose another of the means of payment, we recommend using cash.')
          };
        });
        break;
      case "cc_rejected_insufficient_amount":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your card does not have sufficient funds to carry out the operation.')
          };
        });
        break;
      case "cc_rejected_invalid_installments":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('Your card cannot process installment payments.')
          };
        });
        break;
      case "cc_rejected_max_attempts":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('You have reached the limit of allowed attempts. Choose another card or other means of payment.')
          };
        });
        break;
      case "cc_rejected_other_reason":
        this.setState((state) => {
          return {
            paymentMessage: this.props.t('We were unable to process your payment. Please try another payment method.')
          };
        });
        break;
    }

  }

  renderColorSwitch = (status) => {
    switch(status) {
      case "approved": return Colors.green;
      case "pending": return Colors.grayMedium;
      case "cancelled": return Colors.red;
      default: return Colors.red;
    }
  }

  showSpotModal = (diplayName, contentFile, contentType) => {
    this.setState({
      visibleSpotModal: true,
      modalContentType: 'image',
      modalContentFile: '',
    });
    setTimeout(() => {
      this.setState({
        visibleSpotModal: true,
        modalDisplayName: diplayName,
        modalContentFile: contentFile,
        modalContentType: contentType,
      });
    }, 300)
  }

  okSpotModal = e => {
    this.setState({
      visibleSpotModal: false,
    });
  };

  cancelSpotModal = e => {
    this.setState({
      visibleSpotModal: false,
    });
  };

  setLoadingOdcAgency = (state) =>{
    this.setState({
      loadingOdcAgency: state,
    });
  }

  listODC = () =>{
    const {payment, t, createPurchaseOrdersRequest}= this.props;
    var contentsDisplaysBycompany = [];
    var campaign_id = payment.campaign_id;
    payment.campaign.contents_displays.map((contentDisplay) => {
      var index = contentsDisplaysBycompany.findIndex(item => item.id === contentDisplay.display_company_id);
      if(index === -1){
        let contents_displays = [];
        contents_displays.push(contentDisplay);
        contentsDisplaysBycompany.push({
          id: contentDisplay.display_company_id,
          status: contentDisplay.revision_status === 'approved',
          contents_displays: contents_displays
        });
      }else{
        contentsDisplaysBycompany[index].contents_displays.push(contentDisplay);
        contentsDisplaysBycompany[index].status = contentDisplay.revision_status === 'approved' || contentsDisplaysBycompany[index].status
      }
    });
    return <>
            {contentsDisplaysBycompany.map((companies) => (
              <List
                bordered
                size="small"
                style={{marginBottom: "10px",  display: "auto"}}
                footer={
                      <Button
                        disabled={!companies.status}
                        onClick={() => createPurchaseOrdersRequest(companies.id, campaign_id)}>
                          {t('Generate ODC')}
                      </Button>
                    }
                dataSource={companies.contents_displays}
                renderItem={item => (
                <List.Item>
                  {item.display_name} [{t(item.revision_status)}]
                  </List.Item>)}
               />))}
            </>
  }
  alertOdc = () => {
    let key = "Creating_ODC";
    const {menssageOdc} = this.state;
    const {t} = this.props;

    if(menssageOdc){
      message.loading({ content: t('Creating ODC') + "...", key });
    }else if(menssageOdc === false){
      if(this.props.errorsOdc){
        message.error({ content: t('Ups, problem for create ODC'), key });
      }else{
        message.success({ content: t('Created ODC'), key, duration: 2 });
      }
    }
  }
  generateOdcAgency = (payment) => {
      return {
        name: "file",
        accept: "pdf",
        action: `${apiUrl}/companies/${payment.campaign.company_id}/campaigns/${payment.campaign.id}/purchase_orders_agency`,
        multiple: true,
        showUploadList: false,
        beforeUpload(file) {
          const isLt2M = file.size / 1024 / 1024 < 10;
          if (!isLt2M) {
            message.error(this.props.t('The file cannot be larger than 10MB!'), 10);
          }
          return isLt2M;
        },
        onChange: async info => {
          const status = info.file.status;
          if (status === "uploading") {
            this.setLoadingOdcAgency(true)
          }
          if (status === "done") {
            this.setLoadingOdcAgency(false)
            message.success(`${info.file.name} ${this.props.t('successfully uploaded')}`);
          } else if (status === "error") {
            message.error(`${this.props.t('Upload error')} ${info.file.name}`);
          }
        },
        headers: { authorization: `Bearer ${this.props.token}` },
      };
  }
  render() {
    const { t, i18n, user} = this.props;
    const payment = this.state.paymentData;
    this.alertOdc()
    var uploadProps = null
    var {loadingOdcAgency} = this.state
    if(payment && payment.campaign){
      uploadProps = this.generateOdcAgency(payment)
    }
    moment.locale(i18n.language);
    if (this.props.fetchingPayment) {
      return (
        <PaymentStatusHolder>
          <CheckoutStepsHolder>
            <Row type="flex" gutter={[2,3]} align="top" justify="space-between">
              <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Button
                  className="backButton"
                  onClick={() => this.props.history.goBack()}
                >
                  <Icon
                    style={{ fontSize: "18px", color: Colors.primary }}
                    type="left"
                  />{" "}
                  {t('Go back')}
                </Button>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={4} className="checkoutInfo">
                {t('Start date')}
                <br />
                {payment.campaign ? (
                  <b>{i18n.language === 'en' ? (
                    moment(payment.campaign.start_date || new Date()).format("MMMM DD[,] YYYY")
                  ) : (
                    moment(payment.campaign.start_date || new Date()).format("DD [de] MMMM [de] YYYY")
                  )}</b>
                ) : null}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={4} className="checkoutInfo">
                {t('Finish date')}
                <br />
                {payment.campaign ? (
                  <b>{i18n.language === 'en' ? (
                    moment(payment.campaign.end_date || new Date()).format("MMMM DD[,] YYYY")
                  ) : (
                    moment(payment.campaign.end_date || new Date()).format("DD [de] MMMM [de] YYYY")
                  )
                  }</b>
                ) : null}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={4} className="checkoutInfo">
                {t('Number of screens')}
                <br />
                {payment.campaign ? (
                  <b>{payment.campaign.contents_displays_total}</b>
                ) : null}
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={4} className="checkoutInfo">
                {t('Total campaign price')}
                <br />
                {payment.campaign ? (
                  <b><NumberFormat
                      value={payment.campaign.total_price_with_profit}
                      currency={payment.campaign.currency}
                      tax={payment.campaign.invoice_issuing.alpha_2_code != 'F'}
                      />
                  </b>
                ) : null}
              </Col>
            </Row>
          </CheckoutStepsHolder>
          <Row type="flex" justify="center" align="center">
            <Col
              className="checkoutContainer"
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
            >
              <Row type="flex" justify="center" align="center" gutter={40}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ marginBottom: "20px" }}>
                  <Skeleton title paragraph active paragraph={{ rows: 4 }} />
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Skeleton title paragraph active paragraph={{ rows: 4 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </PaymentStatusHolder>
      );
    }

    return (
      <PaymentStatusHolder>
        <CheckoutStepsHolder>
          <Row type="flex" gutter={[2,3]} align="top" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
              <Button
                className="backButton"
                onClick={() => this.props.history.goBack()}
              >
                <Icon
                  style={{ fontSize: "18px", color: Colors.primary }}
                  type="left"
                />{" "}
                {t('Go back')}
              </Button>
            </Col>
            <Col xs={24} sm={11} md={11} lg={11} xl={4} className="checkoutInfo">
              {t('Start date')}
              <br />
              {payment.campaign ? (
                <b>{i18n.language === 'en' ? (
                  moment(payment.campaign.start_date || new Date()).format("MMMM DD[,] YYYY")
                ) : (
                  moment(payment.campaign.start_date || new Date()).format("DD [de] MMMM [de] YYYY")
                )}</b>
              ) : null}
            </Col>
            <Col xs={24} sm={11} md={11} lg={11} xl={4} className="checkoutInfo">
              {t('Finish date')}
              <br />
              {payment.campaign ? (
                <b>{i18n.language === 'en' ? (
                  moment(payment.campaign.end_date || new Date()).format("MMMM DD[,] YYYY")
                ) : (
                  moment(payment.campaign.end_date || new Date()).format("DD [de] MMMM [de] YYYY")
                )
                }</b>
              ) : null}
            </Col>
            <Col xs={24} sm={11} md={11} lg={11} xl={4} className="checkoutInfo">
              {t('Number of screens')}
              <br />
              {payment.campaign ? (
                <b>{payment.campaign.contents_displays_total}</b>
              ) : null}
            </Col>
            <Col xs={24} sm={11} md={11} lg={11} xl={4} className="checkoutInfo">
              {t('Total campaign price')}
              <br />
              {payment.campaign ? (
                <b><NumberFormat
                    value={payment.campaign.total_price_with_profit}
                    currency={payment.campaign.currency}
                    tax={payment.campaign.invoice_issuing.alpha_2_code != 'F'}
                    />
                </b>
              ) : null}
            </Col>
          </Row>
        </CheckoutStepsHolder>
        <Row type="flex" justify="center" align="center">
          <Col
            className="checkoutContainer"
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
          >
            <Row type="flex" justify="center" align="center" gutter={40}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginBottom: "20px" }}>
                <Card className="statusCard">
                    <div
                      className="cardHeader"
                      style={{
                        backgroundColor: this.renderColorSwitch(payment.status)
                      }}
                    >
                      <h2>
                        {(() => {
                          switch (payment.status) {
                            case "approved": return this.props.t('Successful payment');
                            case "pending": return this.props.t('Pending payment');
                            case "cancelled": return this.props.t('Payment canceled');
                            default: return this.props.t('Payment declined');
                          }
                        })()}
                      </h2>
                    </div>
                  <div className="cardContent">
                    {payment.status === 'approved' ? (
                      <div>
                        <div className="details">
                          {payment.campaign ? (
                            <h3>{t('Campaign')}: {payment.campaign.name}</h3>
                          ) : null}
                          <p>
                            {this.state.paymentMessage}
                          </p>
                        </div>
                        <div className="details">
                          <h3>{t('Payment')}</h3>
                          {payment.campaign ? (
                            <div>
                               <div>
                                {t('Payment method')}: {`${this.getPaymentMethod(payment.payment_method_id)}`}<br />
                                {t('Total without tax')}: <NumberFormat value={payment.campaign.total_price_with_profit} currency={payment.campaign.currency}/><br />
                                {t('Total with tax')}: <NumberFormat value={parseFloat(payment.campaign.total_price_with_profit) + (parseFloat(payment.campaign.total_price_with_profit) * parseFloat(payment.campaign.tax) / 100)} currency={payment.campaign.currency}/><br />
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="details">
                          <br /><h3>{t('Billing information')}</h3>
                          {payment.campaign && payment.campaign.billing_information_first_name ? (
                              <span>
                                {payment.campaign.billing_information_first_name}<br />
                               </span>
                            ) : null}
                            {payment.campaign && payment.campaign.billing_information_doc_number ? (
                              <span>
                                DNI/ CUIT/ RUT/ NIT: {payment.campaign.billing_information_doc_number}<br />
                               </span>
                            ) : null}
                            {payment.campaign && payment.campaign.billing_information_address_street ? (
                              <span>
                                {t('Address')}: {payment.campaign.billing_information_address_street}{payment.campaign.billing_information_address_street ? (", " + payment.campaign.billing_information_address_state): payment.campaign.billing_information_address_state}<br />
                               </span>
                            ) : null}
                        </div>
                          {uploadProps &&
                           payment.status != "cancelled" &&
                            (user && hasAccessRole(user.roles,
                            [ UserRoleEnum.agencyMember,
                              UserRoleEnum.superModeratorMediaCompany,
                              UserRoleEnum.superAdmin,
                              UserRoleEnum.planner,
                              UserRoleEnum.companyMember])) ?
                            <Upload {...uploadProps}>
                              <Button
                                loading={loadingOdcAgency}
                                type="primary"
                                icon="upload"
                                className="checkoutButton"
                                style={{marginTop: "10px"}}
                              >
                                {t("Upload ODC")}
                              </Button>
                            </Upload>
                          :null}
                      </div>
                    ) : (
                      <div>
                        {payment.campaign ? (
                          <h3>{t('Campaign')}: {payment.campaign.name}</h3>
                        ) : null}
                        <p>
                          <div className="details">
                            <h3>{t('Payment')}</h3>
                            {this.state.paymentMessage}
                            {payment.campaign ? (
                              <div>
                                {t('Payment method')}: {`${this.getPaymentMethod(payment.payment_method_id)}`}<br />
                                {t('Total without tax')}: <NumberFormat value={payment.campaign.total_price_with_profit} currency={payment.campaign.currency}/><br />
                                {t('Total with tax')}: <NumberFormat value={parseFloat(payment.campaign.total_price_with_profit) + (parseFloat(payment.campaign.total_price_with_profit) * parseFloat(payment.campaign.tax) / 100)} currency={payment.campaign.currency}/><br />
                              </div>
                            ) : null}
                          </div>
                          <div className="details">
                            <br /><h3>{t('Billing information')}</h3>
                            {payment.campaign && payment.campaign.billing_information_first_name ? (
                              <span>
                                {payment.campaign.billing_information_first_name}<br />
                               </span>
                            ) : null}
                            {payment.campaign && payment.campaign.billing_information_doc_number ? (
                              <span>
                                DNI/ CUIT/ RUT/ NIT: {payment.campaign.billing_information_doc_number}<br />
                               </span>
                            ) : null}
                            {payment.campaign && payment.campaign.billing_information_address_street ? (
                              <span>
                                {t('Address')}: {payment.campaign.billing_information_address_street}{payment.campaign.billing_information_address_street ? (", " + payment.campaign.billing_information_address_state): payment.campaign.billing_information_address_state}<br />
                               </span>
                            ) : null}
                          </div>
                          <br />
                          {t('If you want to pay with another means of payment, you can return to the list of payments in your campaign, delete the pending payment, and make a new payment.')}
                        </p>
                        <div className="buttonsContainer">
                          <Button className="goBackButton" type="primary" onClick={() => this.props.history.push("/campaign/" + payment.campaign_id)}>
                            {t('Go to my campaign')}
                          </Button>
                          {uploadProps &&
                           payment.status != "cancelled" &&
                            (user && hasAccessRole(user.roles,
                            [UserRoleEnum.agencyMember,
                            UserRoleEnum.superModeratorMediaCompany,
                            UserRoleEnum.superAdmin,
                            UserRoleEnum.planner])) ?
                            <Upload {...uploadProps}>
                              <Button
                                loading={loadingOdcAgency}
                                type="primary"
                                icon="upload"
                                className="checkoutButton"
                              >
                                {t('Upload ODC')}
                              </Button>
                            </Upload>
                          :null}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
                { user && hasAccessRole(user.roles,[UserRoleEnum.superModeratorAgency, UserRoleEnum.superAdmin]) && payment.campaign ?
                  <Card className="statusCard" style={{marginTop: "15px"}}>
                    <div
                      className="cardHeader"
                      style={{
                        backgroundColor: this.renderColorSwitch(payment.status)
                      }}
                    >
                      <h2>{t('Generate ODC')}</h2>
                    </div>
                    <div className="cardContent cardListOdc" style={{display: "flex", flexDirection: "column" , heightMax: "20px", overflow: "overlay"}}>
                      {this.listODC()}
                    </div>
                  </Card>:
                 null}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {payment.status === 'approved' ? (
                  <div>
                    <CheckoutDetailsHolder>
                      <Card className="detailsCard">
                        {/* <h3>{t('Summary')}</h3> */}

                        {payment.campaign.contents_displays.map((display) => {
                          return (
                            <div className="itemContainer">
                              <Col xs={18} style={{ paddingLeft: "0", paddingRight: "10px" }}>
                                <p className="itemName" style={{ marginBottom: "0px" }}>{display.display_name}</p><br />
                                <p className="itemName" style={{ marginBottom: "10px", fontSize: "11px" }}>{display.display_administrative_area_level_2}, {display.display_administrative_area_level_1} <span onClick={() => this.showSpotModal(display.display_name, display.content_file, display.content_type)}>({t('See spot')})</span></p>
                              </Col>
                              <Col xs={6} style={{ paddingLeft: "0", paddingRight: "0"}}>
                                <p className="itemPrice">${display.paid_amount_with_profit}</p>
                              </Col>
                            </div>
                          )
                        })}

                        <Modal
                          title={this.state.modalDisplayName}
                          visible={this.state.visibleSpotModal}
                          onOk={this.okSpotModal}
                          onCancel={this.cancelSpotModal}
                          footer={[
                            <Button key="back" onClick={this.cancelSpotModal}>
                              {t('Close')}
                            </Button>,
                          ]}
                        >
                          {this.state.modalContentType === 'video' ? (
                            <video controls style={{width: "100%"}}>
                              <source src={ this.state.modalContentFile } type="video/mp4" />
                            </video>
                          ) : (<img src={this.state.modalContentFile} style={{width: "100%"}} alt="" />)
                          }
                        </Modal>
                        <Col xs={24} style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <div class="subTotal"></div>
                           { payment.campaign.invoice_issuing.alpha_2_code != 'F' &&
                              <div className="subTotalText">
                                <h3>{t('Subtotal cost')}: <NumberFormat value={payment.campaign.total_price_with_profit} currency={payment.campaign.currency}/></h3>
                              </div>
                           }
                          <div className="total">
                            <span>
                              <h2>
                                {t('Total cost')}:
                                 <NumberFormat
                                    value={parseFloat(payment.campaign.total_price_with_profit) + (parseFloat(payment.campaign.total_price_with_profit) * parseFloat(payment.campaign.tax) / 100)}
                                    currency={payment.campaign.currency}/>
                                { payment.campaign.invoice_issuing.alpha_2_code != 'F' &&
                                  <Tooltip placement="topRight" title={t('Tax details') + ': ' + this.state.tax_info_text}>
                                    <Icon className="taxTooltip" type="info-circle" />
                                  </Tooltip>
                                }
                              </h2>
                            </span>
                          </div>
                        </Col>
                      </Card>
                      <Card className="finishCard">
                        <p>
                          {t('Your ads have been sent to the owners of each screen for their review and approval. This process can take up to 48 hours. We will send you a notification by mail when this process is complete.')}
                        </p>
                        <Button className="checkoutButton" type="primary" onClick={() => this.props.history.push("/campaign/" + payment.campaign_id)}>
                          {t('Go to my campaign')}
                        </Button>
                      </Card>
                    </CheckoutDetailsHolder>
                  </div>
                ) : (
                  <div />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </PaymentStatusHolder>
    );
  }
}
export default withTranslation()(PaymentStatusComponent);
