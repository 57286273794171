import React, { Component } from "react";
import PropTypes from "prop-types";
import CampaignManagerHolder from "./Styles/campaignManagerStyle";
import {
  Collapse,
  Steps,
  Button,
  Input,
  Row,
  Col,
  Icon,
  Form,
  message,
  Modal,
  notification,
  Tooltip,
  Switch } from "antd";
import { withRouter } from "react-router";
import { getAspectRatio, getSimilarAspectRatio } from "../services/display";
import { withTranslation } from 'react-i18next';
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole, validateCharLimit } from "../services/utils"
import VerifiedAgencyInfoModal from './VerifiedAgencyInfoModal';
import CartAudienceContainer from "../containers/CartAudienceContainer";
import ContentsLibraryContainer from "../containers/ContentsLibraryContainer";
import ContentsOfCampaignContainer from "../containers/ContentsOfCampaignContainer";
import CardGeneralContainer from "../containers/CardGeneralContainer";
import PaymentCampaignContainer from "../containers/PaymentCampaignContainer";
import { random, debounce } from "lodash";
import RulesContainer from "../containers/RulesContainer";
import ScreensGroupFilterContainer from "../containers/ScreensGroupFilterContainer";
import CartBudgetContainer from "../containers/CartBudgetContainer";
import ScreensCollectionsContainer from "../containers/ScreensCollectionsContainer";
import { getRelationId } from "../services/utils";

const { Step } = Steps;
const steps = [
  { title: 'Contents', content: 'defineContent'},
  { title: 'Configuration', content: 'defineDisplay'},
  { title: 'Make payment', content: 'payment'},
];

const { Panel } = Collapse;
const customPanelStyle = {
  background: '#FFFFFF',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
  boxShadow : "rgba(0, 0, 0, 0.25) 1px 2px 4px 0px",
};

const customPanelStyleContent = {
  borderTopWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
}

class CampaignManagerComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.debouncedGetAudience = debounce(this.props.getAudience, 500);
  }

  state = {
    errors: {},
    excessTraditionalVideoLength: false,
    excessProgrammaticVideoLength: false,
    visibleClearModal: false,
    generalRuleActive: false,
    rules: [
      {"conditions":[]}
    ],
    arrayTime: {
      "type":"hour",
      "from": "00:00",
      "to": "23:59",
    },
    arrayDays: {
      "type":"day",
      "value":[]
    },
    canva: {},
    uploadingFile: false,
    canvaModalVisible: false,
    rulesModalVisible: false,
    width: 300,
    height: 300,
    totalShows: 0,
    totalPromisedShows: 0,
    subsidizeTotalPromisedShows :0,
    checkNullRules: false,
    subsidized_all: false,
    inputCharLimit: {
      value: 0,
    },
    areaCharLimit: {
      value: 0,
    },
    arrContentChanged: [],
    isVerifiedAgencyModalVisible: false,

    // new videoUpload
    stepsCurrent: 0,
    settingFilter: "resolution",
    consumedAmountBudget: 0,
    creditAvailableOnTransferredImpressions: [],
  };

  calculatePromisedShow = (display, countRelation) => {
    const { cart, amountBudget, programmatic } = this.props;
    if (programmatic !== 'programmatic') return null;
    if(countRelation === 0) return 0;
    const result = Math.trunc(((parseFloat(amountBudget) / cart.length) / (display.smart_campaign_cpm / 1000)) / countRelation );
    return result ? result : 0;
  }

  addRelationDisplayContent = (customContentsLength, display, contentId, promised_show, index ) => {
    let relationId =  display.id + '_' + (customContentsLength + random(99999999))
    const content = this.props.listOfContentsInCampaign.find(content => content.id === contentId);
    // TO DO: Modify for update campaign
    var data = {
      relationId: relationId,
      indice_relation: index,
      active: 1,
      displayId: display.id,
      fill_screen: display.fill_screen,
      file_thumb: content.content_file,
      isContentMixed: false,
      promised_shows_subsidized: 0,
      promised_shows: promised_show,
      id: content.id,
      length: content.length,
      content: {...content},
      rules: content.rules && content.rules.length > 0 ? JSON.parse(content.rules) : [],
      content_version_name: content.content_version_name
    }

    this.props.addCustomContentFromCampaign(data.relationId,
                                            data.indice_relation,
                                            data.active,
                                            data.displayId,
                                            data.fill_screen,
                                            data.isContentMixed,
                                            data.promised_shows,
                                            data.length,
                                            data.content,
                                            data.rules,
                                            data.subsidized_price,
                                            data.promised_shows_subsidized,
                                            );
  };


  // Calcule the impreeions for the new relation depending of the total of contents in the display
  calculateAndApplyRelation = (customContentFilterByContent, customContentFilterByDisplay, displayData, listResolution, index, foundContent, addNewDisplay) => {
      const { customContents, cart, realAmount, isPaymentStatusApproved } = this.props;
      let promised_show = 0;
      let lastRelations;
      let promised_show_old = 0;
      const isTheFirstApply = customContents.length === 0;

      if ((!isTheFirstApply && customContentFilterByDisplay.length > 0 && !addNewDisplay) || isPaymentStatusApproved) {
        if (displayData.totalContents > 1) {
          lastRelations = customContents.find((c) => c.displayId === displayData.display.id && c.promised_shows > 1);
          if (lastRelations) {
            promised_show_old = Math.trunc(lastRelations.promised_shows / 2);
            promised_show = promised_show_old;
          }
        }

        if (displayData.totalContents === 1 || !lastRelations) {
          lastRelations = customContents.find(
            (c) => c.displayId !== displayData.display.id && c.promised_shows > 1
          );
          if (lastRelations) {
            promised_show_old = lastRelations.promised_shows / 2;
            const smart_campaign_cpm_for_display = cart.find( (display) => display.id === lastRelations.displayId).smart_campaign_cpm;
            const amount_impressions = promised_show_old * smart_campaign_cpm_for_display;
            promised_show = Math.trunc(amount_impressions / displayData.display.smart_campaign_cpm);
          }
        }
      }

      if(addNewDisplay && customContentFilterByDisplay.length > 0) {
        promised_show = customContentFilterByContent.promised_shows;
        const amount_aux = realAmount + (promised_show * displayData.display.smart_campaign_cpm)
        this.checkUpdateByAmountBudget(amount_aux)
      }

      if(isTheFirstApply || (addNewDisplay && customContentFilterByDisplay.length === 0)) {
        promised_show = this.calculatePromisedShow( displayData.display, displayData.totalContents);
        const amount_aux = realAmount + (promised_show * displayData.display.smart_campaign_cpm)
        this.props.updateRealAmount(realAmount + amount_aux)
      }

      let indice = listResolution.contents.length > 1 ? index : null;
       if (promised_show > 99999999) {
        promised_show = 99999998;
       }
      this.addRelationDisplayContent(
        displayData.totalContents,
        displayData.display,
        foundContent.id,
        promised_show,
        indice
      );

      if (promised_show_old > 0 && promised_show > 0 && !isTheFirstApply) {
        this.props.updateImpressionsDisplay(
          lastRelations.displayId,
          lastRelations.relationId,
          promised_show_old
        );
      }
  };


  // This function creates the relation between the display and the content.
  // return: contents_displays
  applyRelationOnScreens = (groupedDisplays=null, updatedListResolution=null) => {
    const { customContents, listResolutionWithContent } = this.props;
    const displayDataMap = {};
    const listResolutionWithContentAux = updatedListResolution ? updatedListResolution : listResolutionWithContent;

    // Create a map with the display data for optimization
    listResolutionWithContentAux.forEach(lr => {
      lr.displays.forEach(display => {
        const displayId = display.id;
        if (!displayDataMap[displayId]) {
          displayDataMap[displayId] = {
            display: display,
            totalContents: 0,
          };
        }
        displayDataMap[displayId].totalContents += lr.contentsDisplays.reduce((count, item) => {
          return item.displayId === displayId ? count + 1 : count;
        }, 0);
      });

    });

    // Check if add new display
    let addNewDisplay = false;
    if (!groupedDisplays) {
      addNewDisplay = Object.values(displayDataMap).some(displayData =>
        !customContents.some(c => c.displayId === displayData.display.id)
      );
    }

    // Iterate over listResolutionWithContent to add new relations
    listResolutionWithContentAux.forEach(listResolution => {
      if (listResolution && listResolution.contentsDisplays) {
        listResolution.contentsDisplays.forEach((contentDisplay, index) => {
          const displayData = displayDataMap[contentDisplay.displayId];
          if (groupedDisplays && !groupedDisplays.find(display => display.id === displayData.display.id)) {
            return;
          }

          const foundContent = listResolution.contents.find(content => content.id === contentDisplay.contentId);
          if(!foundContent) return;
          const customContentFilterByDisplay = customContents.filter(c => (c.displayId === contentDisplay.displayId));
          const customContentFilterByContent = customContentFilterByDisplay.filter(c => c.content.id === foundContent.id);

          // If relation exist, update the content name
          if (customContentFilterByContent.length > 0) {
            if(customContentFilterByContent[0].content.name != foundContent.name) {
              customContentFilterByContent.forEach(c => {
                this.props.updateContentName(c.relationId, foundContent );
              })
            }
            return
          }
          // Update the budget when add new relations
          this.calculateAndApplyRelation(customContentFilterByContent, customContentFilterByDisplay, displayData, listResolution, index, foundContent, addNewDisplay);
        })}
    });
  };

  updateCreditAvailable = (creditAvailable) => {
    const { creditAvailableOnTransferredImpressions } = this.state;
    const indiceExistente = creditAvailableOnTransferredImpressions.findIndex(item => item.company_id === creditAvailable.company_id);

    if (indiceExistente !== -1) {
      creditAvailableOnTransferredImpressions[indiceExistente] = creditAvailable;
    } else {
      creditAvailableOnTransferredImpressions.push(creditAvailable);
    }
    this.setState({ creditAvailableOnTransferredImpressions });
  };

  nextSteps = () => {
    if(!this.isSubmitOk()){ return; }

    // Loader customContents
    if(this.state.stepsCurrent == 0){
      this.applyRelationOnScreens()
    }

    //Create campaing
    if (this.state.stepsCurrent == 1 && !this.props.editCampaignActive) {
      this.createCampaign()
    }

    const stepsCurrent = this.state.stepsCurrent + 1;
    this.setState({ stepsCurrent });
  }

  prevSteps = () => {
    const { campaign } = this.props;
    if (this.state.stepsCurrent === 0) {
      this.applyRelationOnScreens() // is necesary for save new changes in customContents
      this.props.history.push("/map");
    }

    if (this.state.stepsCurrent === 2) {
      this.props.activateEditCampaign(campaign);
    }
    const stepsCurrent = this.state.stepsCurrent - 1;
    this.setState({ stepsCurrent });
  }

  hasAccessIsTestcampaign = () => {
    const { user, isSuperUser} = this.props;
      return (
        user && isSuperUser &&
        hasAccessRole(user.roles, [
          UserRoleEnum.planner,
          UserRoleEnum.companyMemberCommercial,
          UserRoleEnum.companyMemberAdmin
        ])
      );
  }

  hasAccessIsPauseCampaign = (allDisplaysAreOwn) => {
    const { user, editCampaignActive, currentCampaign } = this.props;
    return editCampaignActive && currentCampaign.payment_status !== 'pending'
      && this.props.currentCampaign.payment_status !== 'cancelled' && user
      && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)
  }

  // Build the client and brand tag
  getBrandClientInfoCampaign = () => {
    const { user, client, brand , t} = this.props;
    const hasAccess = user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]);

    if (hasAccess) {
      return (
        <div className="contentBrandTag">
          {client && (
            <div className="clientBrandTag">
              <span className="clientBrandTagNameText">{t('Client')}</span>
                <span className="clientBrandTagName">
                  {client.name}
                </span>
            </div>
          )}
          {brand && (
            <div className="clientBrandTag" style={{ marginBottom: 30 }}>
              <span className="clientBrandTagNameText">{t('Mark')}</span>
                <span className="clientBrandTagName">{brand.name}</span>
            </div>
          )}
        </div>
      );
    }
    return null;
  }

  isSubmitOk() {
    const { listResolutionWithContent } = this.props;
    const { stepsCurrent } = this.state;
    const errors = {};

    if (stepsCurrent === 0) {
      if (!this.props.campaignName) {
        errors["campaignName"] = this.props.t('Can not be blank');
      }
      let messageErrorMatch = this.props.t('All screens must have at least some content');
      listResolutionWithContent.forEach( listResolution => {
        if (listResolution.contentsDisplays.length === 0 ||
            listResolution.contentsDisplays.contents === 0 ||
            listResolution.contentsDisplays.some(content => content.contentId === null)) {
          errors["contentsDisplays"] = messageErrorMatch;
        }
      });
      if(listResolutionWithContent.length === 0){
        errors["contentsDisplays"] = messageErrorMatch;
      }
    }

    if (stepsCurrent === 1) {
      // Check that rules isn't null for day
      this.checkNullRules = false
      this.props.customContents.map(content => {
        if(content.rules){
          content.rules.map(rule =>{
            if(rule.conditions.find(condition => condition.type === 'day').value.length < 1){
              errors["rules"] = "Problem with rules"
              return;
            }
          })
        }
      })
    }
    this.setState({errors});
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  }

  createCampaign = () => {
      const {
          campaignName,
          isCampaignTest,
          date_from,
          date_to,
          client,
          brand,
          campaignDescription,
          categoriesId,
          currency,
          invoiceIssuingCountry,
          listBundle,
          dsp,
          customContents,
          programmatic,
          defaultRules,
          toleranceResolution
      } = this.props;

      if (!this.isSubmitOk()) return; // Check if the form is correct

      const mainContent = customContents.length > 0 ? customContents[0].content : null;
      const contentsDisplays = customContents.map(content => ({
          display_id: content.displayId,
          content_id: content.content.id,
          subsidized_price: content.subsidized_price || false,
          promised_shows_subsidized: content.promised_shows_subsidized || 0,
          fill_screen: content.fill_screen || false,
          promised_shows: content.promised_shows && programmatic === "programmatic" ? content.promised_shows : null,
          rules: content.rules || defaultRules,
          content_name: content.content.name,
          content_version_name: content.content.content_version_name
      }));

      const campaignData = {
          name: campaignName,
          is_test: isCampaignTest,
          active: 1,
          start_date: date_from,
          end_date: date_to,
          client: client.id,
          brand: brand.id,
          description: campaignDescription,
          tolerance_resolution: toleranceResolution,
          content_category_id: categoriesId,
          currency: currency,
          invoice_issuing_country: invoiceIssuingCountry,
          bundles: listBundle,
          external_dsp_id: dsp ? dsp.id : null,
          main_content_id: mainContent.id,
          smart_campaign: programmatic === "programmatic",
          contents_displays: contentsDisplays,
          new_ssp: true
      };

      return this.props.createCampaign(campaignData);
  };

  editCampaign = () => {
    if (!this.isSubmitOk()) {return;}
    return this.props.editCampaign (
      this.props.currentCampaign.id,
      {
        name: this.props.campaignName,
        is_test: this.props.isCampaignTest,
        start_date: this.props.date_from,
        end_date: this.props.date_to,
        client: this.props.client.id,
        brand: this.props.brand.id,
        description: this.props.campaignDescription,
        tolerance_resolution: this.props.toleranceResolution,
        content_category_id: this.props.categoriesId,
        currency: this.props.currentCampaign.currency,
        // main_content_id: this.props.defaultContent.id,
        bundles: this.props.listBundle,
        main_content_id: this.props.customContents[0].content.id,
        smart_campaign: this.props.programmatic === "programmatic" ? true : false,
        contents_displays: this.props.customContents.map(
          (content) => ({
            display_id: content.displayId,
            active: content.active,
            content_version_name: content.content.content_version_name,
            relation_id: content.relationId.toString().search(content.displayId + "_") === 0
              ? null
              : getRelationId(content.relationId),
            content_id: content.content.id,
            fill_screen: content && content.fill_screen
              ? content.fill_screen
              : false,
            promised_shows: content.promised_shows && this.props.programmatic === "programmatic"
              ? content.promised_shows
              : null,
            rules: content && content.rules
              ? content.rules
              : this.props.defaultRules,
            subsidized_price: content.subsidized_price
              ? content.subsidized_price
              : false,
            promised_shows_subsidized: content.promised_shows_subsidized
              ? content.promised_shows_subsidized
              : 0,
            content_name:  content.content.name,
          })
        ),
      }
    );
  };

  handleNameChange(e) {
    this.props.setCampaignName(e.target.value);

    this.setState({
      errors: {},
      inputCharLimit: {
        ...validateCharLimit(e.target.value.length, 40, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });

    validateCharLimit()
  }

  handleDescriptionChange(e) {
    this.props.setCampaignDescription(e.target.value);

    this.setState({
      areaCharLimit: {
        ...validateCharLimit(e.target.value.length, 200, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });

    validateCharLimit();
  }

  handleIsCampaignsTestToggle = (isCampaignTest) => {
    this.props.setIsCampaignTest(isCampaignTest);
  }

  handleIsCampaignsPausedToggle = (isCampaignPaused) => {
    this.props.setIsCampaignPaused(isCampaignPaused);
  }

  getIsCampaignsPaused = () => {
    const arrPausedContent = this.props.customContents.filter(content => content.active === 0)
    if (arrPausedContent.length === this.props.customContents.length) {
      return true
    }
    return false;
  }

  showClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: true,
    });
  }

  hideClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: false,
    });
  };

  checkUpdateByAmountBudget (amount) {
    let amountAux = 0
    if(amount > 0 && amount !== Infinity) {
      amountAux = amount;
    }

    if (amountAux === Infinity || amountAux > 99999999) {
      amountAux = 99999999;
    }

    this.props.updateRealAmount(amountAux)
  }

  updateByAmountBudget = () => {
    const displayIds = this.props.cart.map(d => d.id);
    let totalBudget = parseInt(this.props.amountBudget);
    let totalAssignedBudget = 0;
    let lastRelationData = { displayId: null, relationId: null, totalShows: 0 };
    let remainingBudget = 0;
    for (var i = 0; i < displayIds.length; i++) {
      const customContentsFiltered = this.props.customContents.filter(
        c => c.displayId === displayIds[i]
      );

      for (var j = 0; j < customContentsFiltered.length; j++) {
        if (customContentsFiltered[j].displayId && customContentsFiltered[j].relationId) {
          const amountForDisplays = totalBudget / this.props.cart.length;
          const amountForRelation = amountForDisplays / customContentsFiltered.length;

          const smart_campaign_cpm_for_display = this.props.cart.find(
            display => display.id === customContentsFiltered[j].displayId
          ).smart_campaign_cpm / 1000;
          const originalTotalShows = smart_campaign_cpm_for_display > 0 ? amountForRelation / smart_campaign_cpm_for_display : 0
          let totalShows = Math.trunc(originalTotalShows);

          // You can accumulate the remainder or add it to an accumulator
          totalAssignedBudget += (totalShows * smart_campaign_cpm_for_display);

          // Save the last relation data
          lastRelationData = {
            displayId: customContentsFiltered[j].displayId,
            relationId: customContentsFiltered[j].relationId,
            totalShows
          };
          totalShows = totalShows ? totalShows : 0;
          // Update impressions
          this.props.updateImpressionsDisplay(
            customContentsFiltered[j].displayId,
            customContentsFiltered[j].relationId,
            totalShows
          );
        }
      }
    }

    // Calculate the remaining budget and adjust impressions in the last relation for optimze the budget
    if(lastRelationData.displayId) {
      remainingBudget = totalBudget - totalAssignedBudget + remainingBudget;
      if (remainingBudget > 0) {
        const additionalShows = Math.trunc(remainingBudget /
          (this.props.cart.find(display => display.id === lastRelationData.displayId).smart_campaign_cpm / 1000));
        lastRelationData.totalShows += additionalShows;
        const promised_show_aux = lastRelationData.totalShows ? lastRelationData.totalShows : 0;
        this.props.updateImpressionsDisplay(
          lastRelationData.displayId,
          lastRelationData.relationId,
          promised_show_aux
        );
      }
    }
  };

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  loadContentInCampaign = () => {
    const seenIds = new Set();
    const listContent = this.props.customContents.filter(content => {
      const id = content.content.id;
      if (seenIds.has(id)) return false;
      seenIds.add(id);
      return true;
    }).map(content => content.content);

    this.props.setListOfContentsInCampaign(listContent);
  };

  componentDidMount() {
    const {
      user,
      cart,
      history,
      setShouldPromptLogin,
      reportSingleData,
      currentCampaign,
      editCampaignTotalPromisedShows,
      customContents,
      editCampaignActive,
      payments } = this.props

    if (editCampaignActive && currentCampaign.payment_status === 'pending' && ( !payments || ( payments && payments.length > 0))) {
        steps[2] = { title: 'Campaign', content: 'payment'};
    } else {
        steps[2] = { title: 'Payment', content: 'payment'};
    }

    if (!user) {
      history.push("/");
      setShouldPromptLogin(true, "/");
    } else if (cart.length === 0) {
      history.push("/map");
    }

    let totalShows = 0;
    if (reportSingleData && reportSingleData.report) {
      totalShows = reportSingleData.report.reduce(
        (acc, display) => {
          return acc + display.shows;
        },
        0
      );
      this.setState({ totalShows: totalShows });
    }

    let totalPromisedShows = 0;
    let subsidizeTotalPromisedShows = 0;
    if(currentCampaign && currentCampaign.contents_displays) {
      currentCampaign && currentCampaign.contents_displays.forEach(function(content_display) {
        totalPromisedShows = totalPromisedShows + content_display.promised_shows
        subsidizeTotalPromisedShows = subsidizeTotalPromisedShows + content_display.promised_shows_subsidized
      });
    }

    this.setState({ totalPromisedShows: totalPromisedShows });
    this.setState({ subsidizeTotalPromisedShows: subsidizeTotalPromisedShows });
    editCampaignTotalPromisedShows(totalPromisedShows);

    const { from } = this.props.location.state || {};

    // Setting the relation when update campaign
    if(this.props.editCampaign && from !== 'map') {
        this.loadContentInCampaign();
        const allSubsidized = customContents.every(item => item.subsidized_price === true);
        this.setState({
          subsidized_all: allSubsidized
        });
    }

    if(this.props.editCampaignActive) {
      this.props.getCampaignPayments(currentCampaign.id);
    }
  }

  createCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully created'),
      description: this.props.t('You can see it in the My campaigns section.'),
      duration: 8,
      placement
    });
  };

  editCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully edited'),
      description: this.props.t('You can review it from this section before generating a payment.'),
      duration: 8,
      placement
    });
  };

  // Define is necesary call getAudience
  changedDataOrRules = (nextProps) => {
    let { date_from, date_to, customContents, audience, displays } = this.props;
    if ( audience.displays_with_audience && audience.displays_with_audience.length === 0) return false;
    //upload file
    if (nextProps.customContents === customContents) return false;

    //newContent
    if ((customContents && nextProps.customContents) && (customContents.length !== nextProps.customContents.length)) {
      let lastContent = nextProps.customContents[nextProps.customContents.length - 1];
      if (!lastContent) return false
      if (!displays.some(display => display.id === (lastContent.displayId))) return false
      return true;
    }

    let arrContentChanged = [];
    arrContentChanged = customContents.filter((content, key) => {
      const lengthRulesCurrent = content && content.rules && content.rules.length
      const lengthRulesNext = nextProps.customContents[key] && nextProps.customContents[key].rules && nextProps.customContents[key].rules.length
      //compare changes in promised_show/impacts
      if (nextProps.customContents[key] && (content.promised_shows !== nextProps.customContents[key].promised_shows)) return true;
      //new rules
      if (lengthRulesCurrent === 0 && lengthRulesNext === 1) return true
      //compare changes in rules
      if (lengthRulesCurrent > 0 && (nextProps.customContents[key] && nextProps.customContents[key].rules) !== content.rules) {
        //change on this screen?
        if (!displays.some(display => display.id === content.displayId)) return false
        //have audience?
        if (audience.displays_with_no_audience.some(display => display.id === content.displayId)) return false
        return true
      };
      return false;
    });

    // Save relation in state that modify for then check audience
    if (arrContentChanged.length > 0) {
      this.setState({ arrContentChanged });
      return true;
    }

    if (nextProps.date_from !== date_from) return true;
    if (nextProps.date_to !== date_to) return true;
    return false;
  }

  getAudience(props) {
    let { date_from, date_to, customContents, programmatic, dsp} = props;
    let customContents_aux = customContents
    if(this.props.programmatic === 'traditional'){
      customContents_aux = customContents.map(content => ({ ...content, promised_shows: null}));
    }
    const getAudienceData = {
      "_method": "GET",
      "start_date": date_from,
      "end_date": date_to,
      "content_displays": customContents_aux,
      "type": programmatic,
      "external_dsp_id": dsp && programmatic == "programmatic" ? props.dsp.id : null,
    }

    this.debouncedGetAudience(getAudienceData);
  }

  componentDidUpdate(prevProps, prevState) {
      const nextProps = this.props;
      const { currentCampaign } = nextProps;

      // Update audience when customContents is updated
      if (this.changedDataOrRules(prevProps) && !nextProps.loadingAudience && !prevProps.loadingAudience) {
        this.getAudience(nextProps);
      }

      if( !prevProps.editCampaign && this.props.editCampaign ) this.loadContentInCampaign();

      // This case is when the user is in the second step, dependent of the state of the campaign the user can go to the third step
      if (( prevProps.editingCampaign !== nextProps.editingCampaign ) && !nextProps.editingCampaign ) {
        if (nextProps.errors.length === 0) {
          if(this.state.stepsCurrent === 1) {
            if (currentCampaign.payment_status === 'pending' && (!nextProps.payments || (nextProps.payments && nextProps.payments.length === 0))){
              this.nextSteps();
              return;
            } else {
              this.editCampaignSuccess('topRight');
              this.props.editCampaignInactive();
              nextProps.history.push(`/campaign/${nextProps.campaign.id}`);
              return;
            }
          }
        }

        if (nextProps.errors && nextProps.errors[0].contents_displays && nextProps.errors[0].contents_displays[0] === 'The contents displays field is required.') {
          message.error(this.props.t('You have not selected screens, you can go back to the map and do it from there.'));
        } else if (nextProps.errors && nextProps.errors[0].message) {
          message.error(nextProps.errors[0].message);
        } else {
          message.error(this.props.t('An error occurred, please try again'));
        }
      }

      if ( nextProps.listResolutionWithContent !== prevProps.listResolutionWithContent) {
         if (prevState.errors && prevState.errors.contentsDisplays) {
          this.isSubmitOk();
         }
      }

      if (nextProps.errors && nextProps.errors.length > 0 && nextProps.errors[0] === 'Token has expired') {
        message.error(this.props.t('Token expired, please login again'), 6);
        this.props.history.push("/");
        this.props.logout();
      }

      if (nextProps.customContents !== prevProps.customContents && this.props.programmatic === 'programmatic') {
        if (nextProps.customContents && nextProps.customContents.length > 0) {
          let calculatedBudget = nextProps.customContents.reduce((acc, display) => {
            const displayAux = this.props.cart.find(d => d.id === display.displayId);
            const display_cpm = displayAux ? displayAux.smart_campaign_cpm : 0;
            return acc + (display.promised_shows * display_cpm / 1000);
          }, 0);
          this.checkUpdateByAmountBudget(calculatedBudget);
        }
      }
  }

  createCanvaDesign = () => {
    this.handleCanvaClose();
    this.props.canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: this.state.width ? this.state.width : 300,
          height: this.state.height ? this.state.height : 300,
          units: 'px',
        },
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              uploadingFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 4000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('hacer algo al cerrar');
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleRulesChange = (value) => {
    const { rulesModalVisible }= this.state;
    this.setState({
      rulesModalVisible: !rulesModalVisible
    });
  };

  handleCanvaClose = e => {
    this.setState({
      canvaModalVisible: false
    });
  };

  showCanvaModal = () => {
    this.setState({
      canvaModalVisible: true
    });
  };

  changeSubsidizedPrice = (checked) =>{
    this.setState({
      subsidized_all: checked
    });
    this.props.updateSubsidizedPrice(
      null,
      checked,
      'multiple'
    )
  }
  changeFilter = (value) => {
    this.setState({
      settingFilter: value
    });
  }

  render() {
    const { errors, inputCharLimit, stepsCurrent, areaCharLimit } = this.state;
    const { t, user , currentCampaign, customContents, editCampaignActive, editingCampaign, programmatic, isPaymentStatusApproved } = this.props;

    const allDisplaysAreOwn = this.props.cart && this.props.cart.every(display => {
      const isOwnDisplay = (display.company_id ? display.company_id : display.company.id) == user.company_id;
      const displayHoldingId = display.holding_id ? display.holding_id : display.company.holding_id;
      const isHoldingDisplay = user.roles.includes('holding-member') && user.holding && user.holding.id === displayHoldingId;
      return isOwnDisplay || isHoldingDisplay;
    });
    const displaysByAspectRatio = [];
    this.state.excessTraditionalVideoLength = false;
    this.state.excessProgrammaticVideoLength = false;

    this.props.cart.forEach(display => {
      const current = displaysByAspectRatio.find(
        d => d.typeSize === getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t)
      );
      if ( programmatic === 'traditional' && this.props.defaultContent.type === 'video' && Math.floor(this.props.defaultContent.length / 1000) * 1000 > display.slot_length) {
        this.state.excessTraditionalVideoLength = true;
      }
      if ( programmatic === 'programmatic' && this.props.defaultContent.type === 'video' && this.props.defaultContent.length > 11000) {
        this.state.excessProgrammaticVideoLength = true
      }
      if (!current) {
        displaysByAspectRatio.push({
          aspectRatio: getAspectRatio(display.resolution_width, display.resolution_height),
          typeSize: getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t),
          displays: [display]
        });
      } else {
        current.displays.push(display);
      }
    });

    const { TextArea } = Input;
    return (
      <CampaignManagerHolder>
        <Row type="flex" justify="center" align="middle">
          <Col
            className="videoUploadContainer"
            xs={24}
            sm={24}
            md={22}
            lg={22}
            xl={18}
          >
            <Steps current={stepsCurrent} className="stepsContent">
              {steps.map(item => (
                <Step key={item.title} title={t(item.title)} />
              ))}
            </Steps>
            {/* Float component */}
            { stepsCurrent === 1 && (
              editCampaignActive ? (
                <Button
                  className={`continueButtons ${editingCampaign ? 'continueButtonsEditCreate' : ''}`}
                  onClick={this.editCampaign}
                  loading={editingCampaign}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Edit")}{" "}
                  </span>
                  { !editingCampaign && ( <Icon type="arrow-right" />)}
                </Button>
              ) : (
                <Button
                  className= "continueButtons createButton"
                  onClick={this.nextSteps}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Create campaign")}
                  </span>
                  <Icon className="createIcon" type="arrow-right" />
                </Button>
              )
            )}
            { stepsCurrent === 0 && (
              <Button className="continueButtons" onClick={this.nextSteps}>
                <span style={{ textTransform: "capitalize" }}>
                  {t("continue")}{" "}
                </span>
                <Icon type="arrow-right" />
              </Button>)
            }
            {stepsCurrent === 0 ? (
              <>
                {(!editCampaignActive ||  ( editCampaignActive && currentCampaign.payment_status !== "approved")) && (
                  <Button className="goBackButtons" onClick={this.prevSteps}>
                    {(!editCampaignActive ||
                      ["pending", "cancelled"].includes(currentCampaign.payment_status)) && (
                      <>
                        <Icon type="arrow-left" />
                        <span style={{ textTransform: "capitalize" }}></span>
                        {currentCampaign && ["pending", "cancelled"].includes(currentCampaign.payment_status) && editCampaignActive 
                          ? t("Edit")
                          : t("Map")}
                      </>
                    )}
                  </Button>
                )}
              </>
            ) : (
              <Button
                className="goBackButtons"
                disabled={editingCampaign}
                onClick={this.prevSteps}
              >
                <Icon type="arrow-left" />
                <span style={{ textTransform: "capitalize" }}>{t("Go back")}{" "}</span>
              </Button>
            )}
            { /* ****** Floating ******/}
            { (stepsCurrent != 2 && programmatic === "programmatic" ) && (
              <>
                <CartAudienceContainer view="cartAudienceVideoUpload" />
                <CartBudgetContainer
                  stepsCurrent={stepsCurrent}
                  updateByAmountBudget={this.updateByAmountBudget}
                  cart={this.props.cart}
                  customContents={this.props.customContents}
                  updateRealAmount={this.props.updateRealAmount}
                />
              </>
            )}
            { stepsCurrent !== 2 && (
              <CardGeneralContainer/>
            )}
            { /* ****** Define content ******/}
              {stepsCurrent === 0 && (
                <Collapse
                  defaultActiveKey={['1','3']} // Important to show 3 by default, by creating a campaign
                  style={customPanelStyleContent}>
                  <Panel header={t("Your campaign")} key="1" style={customPanelStyle} extra={this.getBrandClientInfoCampaign()}>
                    <Row gutter={[16, 16]}>
                      <Col className="contentMobileBrandTag" xs={24} sm={24} md={24} lg={11} xl={11}>
                          {this.getBrandClientInfoCampaign()}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                        <p>
                          {t('Give your campaign a name so you can identify it')}
                        </p>
                        <Form.Item
                          validateStatus={!!errors["campaignName"] ? "error" : (inputCharLimit.validateStatus ? "warning" : null )}
                          help={errors["campaignName"] || inputCharLimit.errorMsg}
                        >
                          <Input
                            autoFocus
                            placeholder={t('Campaign name')}
                            value={this.props.campaignName}
                            onChange={this.handleNameChange.bind(this)}
                            maxLength={40}
                            autoComplete="off"
                          />
                        </Form.Item>
                        <div className="contentSettingCampaign">
                          { this.hasAccessIsTestcampaign() && (
                            <Form.Item style={{display: 'flex', flexDirection: 'column'}}>
                              <span>
                                {t('Is it a test campaign?')}
                                &emsp;
                                <Switch
                                    onChange={this.handleIsCampaignsTestToggle.bind(this)}
                                    checked={this.props.isCampaignTest}
                                    size="small"
                                    disabled={(currentCampaign && !currentCampaign.smart_campaign) && this.props.editCampaignActive }
                                />
                              </span>
                            </Form.Item>
                          )}
                          { this.hasAccessIsPauseCampaign(allDisplaysAreOwn) && (
                            <Form.Item>
                              <span>
                                {t('Pause campaign')}
                                &emsp;
                                <Switch
                                  onChange={this.handleIsCampaignsPausedToggle}
                                  checked={this.getIsCampaignsPaused()}
                                  size="small"
                                />
                              </span>
                            </Form.Item>
                          )}
                        </div>
                        {(!this.props.editCampaignActive) &&
                          <>
                            {(user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) &&
                              <Form.Item className="contentSettingCampaign">
                                {t('Subsidize price of campaign')}
                                &emsp;
                                <Tooltip placement="bottomLeft" title={t('First load content for subsidize the campaign')}>
                                  <Switch
                                    checked={this.state.subsidized_all}
                                    onClick={(checked) =>this.changeSubsidizedPrice(checked)}
                                    size="small"
                                  />
                                </Tooltip>
                                <Tooltip placement="bottomLeft" title={t('By enabling this option, the campaign is subsidize, your price will be zero')}>
                                  <Icon
                                    style={{ fontSize: "17px", marginLeft: "10px", color: "#f7d455"}}
                                    type="warning"
                                  />
                                </Tooltip>
                              </Form.Item>
                            }
                          </>
                        }
                        {this.state.subsidized_all ?
                          <span className="ant-tag ant-tag-orange"
                            style={{marginLeft: "7px"}}>
                            {t('Campaign subsidized')}
                          </span>
                        :null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <p>
                          {t('Add more information if you need it')}
                        </p>
                        <Form.Item
                          validateStatus={areaCharLimit.validateStatus && "warning"}
                          help={errors["campaignDescription"] || areaCharLimit.errorMsg}
                        >
                          <TextArea
                            placeholder={t("Campaign extra information")}
                            onChange={this.handleDescriptionChange.bind(this)}
                            value={this.props.campaignDescription}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            maxLength={200}
                            className="textArea"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={t("Contents library")} key="2" style={customPanelStyle}>
                    <ContentsLibraryContainer />
                  </Panel>
                  <Panel
                    header={
                      <div>
                        {t("Contents for the campaign")}
                        { errors["contentsDisplays"] &&
                          <Tooltip placement="bottomLeft" title={ errors["contentsDisplays"] }>
                            <Icon style={{ marginLeft:"5px", fontSize: '16px', color: "#f5222d"}} type="warning" />
                          </Tooltip>
                        }
                      </div>
                    }
                    key="3"
                    style={customPanelStyle}>
                    <ContentsOfCampaignContainer
                      isPaymentStatusApproved={isPaymentStatusApproved}
                      errors={ errors }/>
                  </Panel>
                </Collapse>)
              }

              { /* ****** Define display******/}
              { stepsCurrent === 1 && (
                <>
                  <Row type="flex" className="campaingInfoCard customPanelStyleDisplays">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{display: "flex", justifyContent: "space-between"}}>
                      <div className="ant-collapse-header" role="button" aria-expanded="true">
                        {t('Required resolutions')}
                      </div>
                      <div style={{ textAlign: "left", marginTop: "10px", marginBottom: "20px"}}>
                        <Tooltip placement="bottomLeft" title={t('Set up rules in campaign')}>
                          <Button onClick={() => this.handleRulesChange(true)} >
                            {t("Create rules")}
                            <Icon type="control" />
                          </Button>
                        </Tooltip>
                      </div>
                      <Modal
                        title={t("Create rules")}
                        visible={this.state.rulesModalVisible}
                        footer={[
                          <Button key="ok" type="primary" onClick={() => this.handleRulesChange(false)}>
                            {t("Close")}
                          </Button>,
                        ]}
                        onCancel={() => this.handleRulesChange(false)}
                        width={800}
                      >
                        <RulesContainer
                          typeModal="campaign"
                          listContentDisplay={customContents}
                        />
                      </Modal>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="contentFilter">
                      <ScreensGroupFilterContainer />
                    </Col>
                  </Row>
                  <ScreensCollectionsContainer
                    applyRelationOnScreens={this.applyRelationOnScreens}
                    allDisplaysAreOwn={allDisplaysAreOwn}
                    creditAvailableOnTransferredImpressions={this.state.creditAvailableOnTransferredImpressions}
                    updateCreditAvailable={this.updateCreditAvailable} />
                </>
              )}
              { stepsCurrent === 2 && (
                <PaymentCampaignContainer />
              )}
          </Col>
        </Row>
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal} message={t("Company is not verified and has more than $5000 USD in campaigns this month")}></VerifiedAgencyInfoModal>
      </CampaignManagerHolder>
    );
  }
}

const Extended = withTranslation()(CampaignManagerComponent);
Extended.static = CampaignManagerComponent.static;

export default withRouter(
  Form.create({ name: "CampaignManagerComponent" })(Extended)
);
CampaignManagerComponent.contextTypes = {
  router: PropTypes.object
};
