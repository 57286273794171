import { connect } from 'react-redux'
import HideWhenCampaignActiveComponent from '../components/HideWhenCampaignActiveComponent'
import FiltersComponent from '../components/FiltersComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display }) => ({
  currentContentsDisplays : display.currentContentsDisplays,
  editCampaignActive: display.editCampaignActive,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
})
export default connect(mapStateToProps)(HideWhenCampaignActiveComponent)