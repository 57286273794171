import UrlEnum from "../constants/UrlEnum";
import { getDomain } from "../services/utils";
var domain = getDomain(window.location.hostname)

var Colors = {
  background: "#fafafa",
  primary: "#3996f3",
  blueDark: "#163B78",
  lightPrimary: "#3996f380",
  extraLightPrimary: "#e9f4fd",
  secondary: "#1565C0",
  backgroundPrimary: "#1e88e5",
  backgroundSecondary: "#42a5f5",
  green: "#3bbfa4",
  red: "#f44336",
  blue: "#295fd2",
  gray: "#f1f2f2",
  grayMedium: "#b1b1b1",
  black: "#000",
  snow: "#FFF",
  snowSecondary: "#FFF",
  coal: "#424242",
  transparent: "#00000000",
  atention: "#fa8c16",
}

if (domain === 'grupoVia') {
  Colors = {
    background: "#fafafa",
    primary: "#f4793b",
    blueDark: "#ff7a32",
    lightPrimary: "#ffb992",
    extraLightPrimary: "#ffb99230",
    secondary: "#b93f02",
    backgroundPrimary: "#f8884c",
    backgroundSecondary: "#fdba95",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#FFF",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#f4793b",
    lightPrimaryPins: "#ffb992",
  }
}

if (domain === 'smartFit') {
  Colors = {
    background: "#fff",
    primary: "#ffb612",
    blueDark: "#ff7a32",
    lightPrimary: "#0000004d",
    extraLightPrimary: "#000000a8",
    secondary: "#0000",
    backgroundPrimary: "#ffb8127a;",
    backgroundSecondary: "#00000099",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#FFF",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#ffb612",
    lightPrimaryPins: "#ffb612ed",
  }
}

if (domain === 'duoPrint') {
  Colors = {
    background: "#fff",
    primary: "#c11911",
    blueDark: "#ff7a32",
    lightPrimary: "#0000004d",
    extraLightPrimary: "#16171645",
    secondary: "#0000",
    backgroundPrimary: "#f8884c",
    backgroundSecondary: "#fdba95",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#FFF",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#c11911",
    lightPrimaryPins: "#c11a11a5",
  }
}

if (domain === 'mediaShake') {
  Colors = {
    background: "#fafafa",
    primary: "#ff7200",
    blueDark: "#460160",
    lightPrimary: "#ffb992",
    extraLightPrimary: "#ffb99230",
    secondary: "#ff031c",
    backgroundPrimary: "#f8884c",
    backgroundSecondary: "#fdba95",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#FFF",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#ff7200",
    lightPrimaryPins: "#ff7200b9",
  }
}

if (domain === 'territorioDigital') {
  Colors = {
    background: "#fafafa",
    primary: "#F4A703",
    blueDark: "#163B78",
    lightPrimary: "#F4A70380",
    extraLightPrimary: "#f4a8032a",
    secondary: "#3D6D8C",
    backgroundPrimary: "#F4A703",
    backgroundSecondary: "#f4a8031b",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#3D6D8C",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#F4A703",
    lightPrimaryPins: "#f4a703cd",
  }
}

if (domain === 'movImagen') {
  Colors = {
    background: "#fafafa",
    primary: "#FF6900",
    blueDark: "#163B78",
    lightPrimary: "#FF690080",
    extraLightPrimary: "#FF69002a",
    secondary: "#707070",
    backgroundPrimary: "#FF6900",
    backgroundSecondary: "#FF69001b",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000",
    snow: "#FFF",
    snowSecondary: "#FFF",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#FF6900",
    lightPrimaryPins: "#ff6900d3",
  }
}

if (domain === 'yoda') {
  Colors = {
    background: "#fafafa",
    primary: "#ffcc00",
    blueDark: "#163B78",
    lightPrimary: "#ffcc00cc",
    extraLightPrimary: "#fff4cc",
    secondary: "#6d6e6f",
    backgroundPrimary: "#1e88e5",
    backgroundSecondary: "#42a5f5",
    green: "#3bbfa4",
    red: "#f44336",
    blue: "#295fd2",
    gray: "#f1f2f2",
    grayMedium: "#b1b1b1",
    black: "#000000",
    snow: "#ffffff",
    snowSecondary: "#ffffff",
    coal: "#424242",
    transparent: "#00000000",
    atention: "#fa8c16",
    primaryPins: "#cca300",
    lightPrimaryPins: "#ffcc00",
  }
}
export default Colors;
