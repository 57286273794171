import styled from "styled-components";
import Colors from "../../themes/Colors";

const CampaingCardHolder = styled.div`
  .campaignCard {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin: 0 0 20px;
  }
  .campaignCard .ant-card-body {
    padding: 0;
  }
  .campaignCardImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    opacity: 0.7;
  }
  .campaignCardImage:hover {
    opacity: 1;
  }
  .campaignCardInfoNew {
    padding: 5px 15px 5px 10px ;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  }
  .campaignCardFooter {
    padding: 10px 15px 1px 10px ;
    width: 100%;
    color: #909090;
    box-shadow: rgb(0 0 0 / 9%) 0px -2px 1px, rgb(0 0 0 / 0%) 0px -4px 2px, rgb(0 0 0 / 10%) 0px -8px 4px, rgb(0 0 0 / 10%) 0px -16px 8px, rgb(0 0 0 / 5%) 0px -32px 16px;
  }
  .campaignCardInfo {
    padding: 10px 10px 5px;
    position: absolute;
    bottom: 48px;
    background-color: rgb(42 42 42 / 75%);
    width: 100%;
    padding: 10px 15px 10px;
  }
  .badge {
    z-index: 1;
    position: absolute;
    bottom: 40px;
    right: 5px;
    height: 20px;
    border-radius: 10px;
    min-width: 20px;
    background: ${Colors.red};
    color: #fff;
    line-height: 20px;
    text-align: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
  }

  .cardButton {
    height: 28px;
    margin: 10px 4px 10px 4px;
    background-color: #fff;
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: ${Colors.primary};
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }

  .cardButton:hover {
    background-color: ${Colors.primary};
    color: white;
  }


  .reportButton {
    height: 28px;
    margin: 10px 10px 10px 10px;
    background-color: #fff;
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: ${Colors.primary};
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .reportButton:hover {
    background-color: ${Colors.primary};
    color: white;
  }
  .editButton {
    height: 28px;
    margin: 10px 10px 10px 20px;
    background-color: #fff;
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: ${Colors.primary};
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .editButton:hover {
    background-color: ${Colors.primary};
    color: white;
  }
  .categoryTag {
    color: ${Colors.primary};
  }
  .contentNewVersion {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default CampaingCardHolder;
