import styled from "styled-components";
import Colors from "../../themes/Colors";

const PaymentCampaignHolder = styled.div`
  .ant-collapse-header {
    color: ${ Colors.primary } !important;
    font-size: 24px !important;
    padding: 20px !important;
    padding-left: 8px !important;
    padding-right: 15px !important;
  }
  .ant-collapse-sub-header {
    color: ${ Colors.primary } !important;
    margin-bottom: 10px;
    font-size: 20px;
    padding-top: 0px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .fieldForm {
    display: flex;
    margin-right: 16px;
    margin-bottom: 0;
    justify-content: space-between;
  }
  .ant-form-item {
    width: 100%;
  }
  .title-form {
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .ant-form {
    padding-left : 10px;
  }
  .content-row {
    margin-top: 20px;
    margin-left: 10px
  }
  .content-card {
    border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #F2F2F2;
  }
  .divider-payment {
    display: block;
    min-width: 63% !important;
    height: 2px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  .container-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 80px;
  }
  .checkoutButton{
    width: 100%;
    min-width: 200px;
    height: 45px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 10px;
  }
  .finishedButtons {
    position: fixed;
    width: 11%;
    z-index: 996;
    top: 159px;
    right: 10px;
    height: 45px;
    line-height: 30px;
    background-color: #23CBF2;
    font-size: 18px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    @media only screen and (max-width: 1200px) {
      width: 30%;
      top: 140px;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
      top: 140px;
    }
  }
  .proposalButton {
    background-color: #FFFFFF;
    margin-right: 0px !important;
    color: ${ Colors.primary } !important;
    z-index: auto;
    position: fixed;
    top: 235px;

    @media only screen and (max-width: 1200px) {
      position: relative;
      margin-left: 10px;
      bottom: 0px;
      top: 0px;
      left: 0px;
    }
  }
`;

export default PaymentCampaignHolder;

