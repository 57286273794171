import React, { Component } from "react";
import FiltersHolder from "./Styles/filtersStyle";
import { DateRangePicker } from "react-dates";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION
} from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  Icon,
  Collapse,
  Row,
  Col,
  Checkbox,
  Popover,
  Slider,
  Affix,
  Select
} from "antd";

import NumberFormat from "./NumberFormat";
import { getDisplayingLocation, getDisplayingSizes } from "../services/display";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
import { withTranslation } from 'react-i18next';
import { getMinimunByCurrency } from "../services/utils";
import Colors from "../themes/Colors";

const { Option } = Select;

const Panel = Collapse.Panel;

class FiltersComponent extends Component {
  constructor(props) {
    super(props);
    // this.handleVisibleClient = this.handleVisibleClient.bind(this);
    this.state = {
      visibleCompanyFilter: false,
      visibleDatesFilter: false,
      visiblePriceFilter: false,
      visibleExitFilter: false,
      visibleTypeFilter: false,
      visibleSizeFilter: false,
      outdoor: this.props.location_type.includes("outdoor"),
      indoor: this.props.location_type.includes("indoor"),
      buses: this.props.location_type.includes("buses"),
      pos: this.props.location_type.includes("pos"),
      small: this.props.size_type.includes("small"),
      medium: this.props.size_type.includes("medium"),
      big: this.props.size_type.includes("big"),
      giant: this.props.size_type.includes("giant")
    };
  }

  // handleVisibleClient(client) {
  //   this.state.client = client;
  // }

  handleVisibleCompanyFilterChange = visibleCompanyFilter => {
    this.setState({ visibleCompanyFilter });
  };
  handleVisibleDatesFilterChange = visibleDatesFilter => {
    this.setState({ visibleDatesFilter });
  };
  handleVisiblePriceFilterChange = visiblePriceFilter => {
    this.setState({ visiblePriceFilter });
  };
  handleVisibleExitFilterChange = visibleExitFilter => {
    this.setState({ visibleExitFilter });
  };
  handleVisibleTypeFilterChange = visibleTypeFilter => {
    this.setState({ visibleTypeFilter });
  };
  handleVisibleSizeFilterChange = visibleSizeFilter => {
    this.setState({ visibleSizeFilter });
  };

  async handlePriceChange(price) {
    await this.setState({ price });
    this.props.setPrice(price);
  }

  async handleShowsPerDayChange(showsPerDay) {
    await this.setState({ showsPerDay });
    this.props.setShowsPerDayMin(showsPerDay);
  }

  async handleTypeChange(name) {
    await this.setState({ [name]: !this.state[name] });

    if (this.state[name]) {
      this.props.setLocationType([...this.props.location_type, name]);
    } else {
      this.props.setLocationType(
        this.props.location_type.filter(t => t !== name)
      );
    }
  }

  async handleSizeChange(name) {
    await this.setState({ [name]: !this.state[name] });

    if (this.state[name]) {
      this.props.setSizeType([...this.props.size_type, name]);
    } else {
      this.props.setSizeType(this.props.size_type.filter(t => t !== name));
    }
  }

  renderIdentificationClients = () => {
    if (this.props.clients && this.props.clients.length > 0) {
      return this.props.clients.map(client => {
        return (
          <Select.Option key={client.id} value={client.id}>
            {client.name}
          </Select.Option>
        );
      });
    }
  };

  renderIdentificationBrands = () => {
    if (this.props.brands && this.props.brands.length > 0) {
      return this.props.brands.map(brand => {
        return (
          <Select.Option key={brand.id} value={brand.id}>
            {brand.name}
          </Select.Option>
        );
      });
    }
  };

  changeClient = async (clientSelect) => {
    const client = this.props.clients.find(client => client.id === clientSelect.key);
    this.props.setClient(client);
    this.props.setBrands(client.brands);
    // this.props.getBrands(clientSelect.key);
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.props.refreshDisplays();
    this.props.cleanAudience();
    this.props.removeDisplaysCart();
  }
  changeBrand = (brandSelect) => {
    this.props.setBrand(this.props.brands.find(brand => brand.id === brandSelect.key))
  }

  render() {
    const { t, i18n, user } = this.props;

    let isAgency = false;

    if ( user && ( hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]))) {
      isAgency = true;
    }

    return (
      <FiltersHolder>
        <Affix offsetTop={85}>
          <Collapse
            bordered={false}
            defaultActiveKey={
              window.matchMedia("(max-width: 1100px)").matches ? null : ["1"]
            }
          >
            <Panel header={t('Show and edit filters')} key="1" style={{borderBottom: "none"}}>
              <Row type="flex" gutter={8}>
                { isAgency ?
                  <Col xs={24} sm={8} md={12} lg={4} xl={4} className="option">
                    <Popover
                      content={
                        <div className="popover">
                          {t('Client')}
                          <Select
                            className="companySelect"
                            labelInValue onChange={this.changeClient.bind(this)}
                            loading={this.props.loadingClients}
                            value={{ key: this.props.client.id ? parseInt(this.props.client.id) : t('Select') }}
                          >
                            {this.renderIdentificationClients()}
                          </Select>
                          {t('Mark')}
                          <Select
                            className="brandSelect"
                            labelInValue onChange={this.changeBrand.bind(this)}
                            loading={this.props.loadingBrands} disabled={this.props.loadingBrands}
                            value={{ key: this.props.brand.id ? parseInt(this.props.brand.id) : t('Select') }}
                          >
                            {this.renderIdentificationBrands()}
                          </Select>
                        </div>
                      }
                      trigger="click"
                      placement="bottomLeft"
                      visible={this.state.visibleCompanyFilter}
                      onVisibleChange={this.handleVisibleCompanyFilterChange}
                    >
                      <div className="filterButton">
                        <div>
                          {t('Client and brand')}
                          { isAgency && this.props.client && this.props.clients !== 0 && this.props.brand && this.props.brands.length !== 0 && this.props.clients.find(client => client.id === parseInt(this.props.client.id)) && this.props.brands.find(brand => brand.id === parseInt(this.props.brand.id)) ?
                          <p>{this.props.clients.find(client => client.id === parseInt(this.props.client.id)).name} - {this.props.brands.find(brand => brand.id === parseInt(this.props.brand.id)).name}</p>
                          : <p>{t('Select')}</p>}
                        </div>
                        <Icon
                          style={{
                            fontSize: "13px",
                            color: Colors.secondary,
                            marginLeft: "10px"
                          }}
                          type="caret-down"
                        />
                      </div>
                    </Popover>
                  </Col>
                : null}
                <Col xs={24} sm={isAgency ? 16 : 24} md={isAgency ? 12 : 24} lg={isAgency ? 8 : 10} xl={isAgency ? 7 : 8} className="date">
                  <Popover
                    content={
                      <div className="popover">
                        {t('Change campaign dates')}
                        <DateRangePicker
                          startDatePlaceholderText={t('Start date')}
                          endDatePlaceholderText={t('Finish date')}
                          startDate={moment(this.props.date_from)}
                          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                          endDate={moment(this.props.date_to)}
                          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                          minimumNights={0}
                          onDatesChange={({ startDate, endDate }) => {
                            this.props.setDates(
                              startDate && startDate.format("YYYY-MM-DD"),
                              endDate && endDate.format("YYYY-MM-DD")
                            );
                          }} // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={focusedInput =>
                            this.setState({ focusedInput })
                          } // PropTypes.func.isRequired,
                          hideKeyboardShortcutsPanel={true}
                          customArrowIcon={<div />}
                          withPortal={
                            window.matchMedia("(max-width: 400px)").matches
                          }
                          orientation={
                            window.matchMedia("(max-width: 400px)").matches
                              ? VERTICAL_ORIENTATION
                              : HORIZONTAL_ORIENTATION
                          }
                        />
                      </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={this.state.visibleDatesFilter}
                    onVisibleChange={this.handleVisibleDatesFilterChange}
                  >
                    <div className="filterButton">
                      <div className="filterButtonDates">
                        <div className="filterButtonDate">
                          {t('Start date')}
                          {i18n.language === 'en' ? (
                            <p>
                              {moment(this.props.date_from).format(
                                "MMMM DD[,] YYYY"
                              )}
                            </p>
                          ) : (
                            <p>
                              {moment(this.props.date_from).format(
                                "DD [de] MMMM [de] YYYY"
                              )}
                            </p>
                          )}
                        </div>
                        <div>
                          {t('Finish date')}
                          {i18n.language === 'en' ? (
                            <p>
                              {moment(this.props.date_to).format(
                                "MMMM DD[,] YYYY"
                              )}
                            </p>
                          ) : (
                            <p>
                              {moment(this.props.date_to).format(
                                "DD [de] MMMM [de] YYYY"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <Icon
                        style={{
                          fontSize: "13px",
                          color: Colors.secondary,
                          marginLeft: "10px"
                        }}
                        type="caret-down"
                      />
                    </div>
                  </Popover>
                </Col>
                {/* <Col xs={24} sm={12} md={isAgency ? 6 : 6} lg={isAgency ? 3 : 4} xl={4} className="option">
                  <Popover
                    content={
                      <div className="popover">
                        {t('Price per day per screen')}
                        <Slider
                          range
                          min={0}
                          max={getMinimunByCurrency(this.props.currency)*10}
                          defaultValue={[
                            this.props.price_min,
                            this.props.price_max
                          ]}
                          onAfterChange={value => this.handlePriceChange(value)}
                        />
                        <div className="range">
                          <span>$0</span>
                          <span>{getMinimunByCurrency(this.props.currency)*10}</span>
                        </div>
                      </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={this.state.visiblePriceFilter}
                    onVisibleChange={this.handleVisiblePriceFilterChange}
                  >
                    <div className="filterButton">
                      <div>
                      {t('Price')}
                        <p className="filterCurrency">
                          setting currency for filter depend of lenguage
                          <NumberFormat value={this.props.price_min}
                                        currency={this.props.currency}/>
                                        -
                          <NumberFormat value={getMinimunByCurrency(this.props.currency)*10}
                                        currency={this.props.currency}/>
                        </p>
                      </div>
                      <Icon
                        style={{
                          fontSize: "13px",
                          color: Colors.secondary,
                          marginLeft: "10px"
                        }}
                        type="caret-down"
                      />
                    </div>
                  </Popover>
                </Col> */}
                <Col xs={24} sm={12} md={6} lg={isAgency ? 3 : 3} xl={isAgency ? 3 : 4} className="option">
                  <Popover
                    content={
                      <div className="popover">
                        {t('Impressions per day per screen')}
                        <Slider
                          min={1}
                          max={500}
                          defaultValue={this.props.shows_per_day_min}
                          onAfterChange={value =>
                            this.handleShowsPerDayChange(value)
                          }
                        />
                        <div className="range">
                          <span>1</span>
                          <span>500</span>
                        </div>
                      </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={this.state.visibleExitFilter}
                    onVisibleChange={this.handleVisibleExitFilterChange}
                  >
                    <div className="filterButton">
                      <div>
                        {t('Impressions')}
                        <p>+{this.props.shows_per_day_min}</p>
                      </div>
                      <Icon
                        style={{
                          fontSize: "13px",
                          color: Colors.secondary,
                          marginLeft: "10px"
                        }}
                        type="caret-down"
                      />
                    </div>
                  </Popover>
                </Col>
                <Col xs={24} sm={12} md={6} lg={3} xl={3} className="option">
                  <Popover
                    content={
                      <div className="popover">
                        {t('Type')}
                        <Checkbox
                          checked={this.state.outdoor}
                          onChange={() => this.handleTypeChange("outdoor")}
                        >
                          Outdoor
                        </Checkbox>
                        <Checkbox
                          checked={this.state.indoor}
                          onChange={() => this.handleTypeChange("indoor")}
                        >
                          Indoor
                        </Checkbox>
                        <Checkbox
                          checked={this.state.buses}
                          onChange={() => this.handleTypeChange("buses")}
                        >
                          {t('Mobile')}
                        </Checkbox>
                        <Checkbox
                          checked={this.state.pos}
                          onChange={() => this.handleTypeChange("pos")}
                        >
                          {t('Point of sale')}
                        </Checkbox>
                      </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={this.state.visibleTypeFilter}
                    onVisibleChange={this.handleVisibleTypeFilterChange}
                  >
                    <div className="filterButton">
                      <div>
                        {t('Type')}
                        <p>{getDisplayingLocation(this.props.location_type, this.props.t)}</p>
                      </div>
                      <Icon
                        style={{
                          fontSize: "13px",
                          color: Colors.secondary,
                          marginLeft: "10px"
                        }}
                        type="caret-down"
                      />
                    </div>
                  </Popover>
                </Col>
                <Col xs={24} sm={12} md={6} lg={3} xl={3} className="option">
                  <Popover
                    content={
                      <div className="popover">
                        {t('Size')}
                        <Checkbox
                          checked={this.state.small}
                          onChange={() => this.handleSizeChange("small")}
                        >
                          {t('Small')}
                        </Checkbox>
                        <Checkbox
                          checked={this.state.medium}
                          onChange={() => this.handleSizeChange("medium")}
                        >
                          {t('Medium')}
                        </Checkbox>
                        <Checkbox
                          checked={this.state.big}
                          onChange={() => this.handleSizeChange("big")}
                        >
                          {t('Big')}
                        </Checkbox>
                        <Checkbox
                          checked={this.state.giant}
                          onChange={() => this.handleSizeChange("giant")}
                        >
                          {t('Giant')}
                        </Checkbox>
                      </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={this.state.visibleSizeFilter}
                    onVisibleChange={this.handleVisibleSizeFilterChange}
                  >
                    <div className="filterButton">
                      <div>
                        {t('Size')}
                        <p>{getDisplayingSizes(this.props.size_type, this.props.t)}</p>
                      </div>
                      <Icon
                        style={{
                          fontSize: "13px",
                          color: Colors.secondary,
                          marginLeft: "10px"
                        }}
                        type="caret-down"
                      />
                    </div>
                  </Popover>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Affix>
      </FiltersHolder>
    );
  }
}
export default withTranslation()(FiltersComponent);
