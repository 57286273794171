import styled from "styled-components";
import Colors from "../../themes/Colors";

const ModalContentHolder = styled.div`
  padding: 28px 10px;
  min-height: 300px;
  .contentCard {
    position: relative;
    width: 90%;
    background-color: ${Colors.snow};
    border-radius: 16px;
    margin: 0 0 15px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s;
    .slick-arrow {
      display: none !important;
    }
  }

  .general-skeleton {
    padding: 10px;
    text-align: center;
  }

  .contentCard:hover {
    transform: translate3d(0, -5px, 0);
    width: 90%;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    .slick-arrow {
      display: block !important;
    }
  }

  .contentCardContent {
    text-align: center;
    padding: 10px;
    .cardTitle {
      font-size: 16px;
      font-weight: bold;
    }
    .cardSubTitle {
      padding: 0px 5px 0px 5px;
      font-size: 14px;
      color: rgb(0 0 0 / 65%);
    }
    .cardInfo {
      padding: 0px 5px 0px 5px;
      font-size: 14px;
      font-weight: lighter;
    }
  }
  .ant-card-body {
    padding: 0px;
  }
  .contentCardImage {
    border-radius: 16px;
    height: 163px;
    padding: 10px;
    object-fit: cover;
  }
  .contentCardInfo {
    color: #fff;
    font-size: 12px;
  }
  .titleCol {
    color: #aaa;
    padding-bottom: 10px;
  }

  .titleCol b {
    font-size: larger;
  }

  .infoCol {
    color: #342f2f91;
    font-size: 14px;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .primary {
    color: ${Colors.primary};
  }

  @media (max-width: 1200px) {
    .content-info-bunlde {
      border-left: 0px !important;
    }
    .content-map-bunlde {
      margin-bottom: 30px;
    }
  }
  .content-info-bunlde {
    paddingLeft: "15px";
    border-left: 1px solid #aaa;
    padding-left: 15px;
  }
`;

export default ModalContentHolder;