import React, { Component, Fragment } from "react";
import SearchBoxHolder from "./Styles/searchBoxStyle";
import { Menu, Dropdown, Button, Col, Icon, Input, Row, Select, message, Radio, Form ,Tooltip} from "antd";
import { withRouter } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION
} from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  convertBounds,
  updateParams,
  getDatesRange
} from "../services/display";
import queryString from "query-string";
import OnboardingContainer from "../containers/OnboardingContainer";
import { withTranslation } from 'react-i18next';
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole } from "../services/utils"
import { CSSTransition } from 'react-transition-group';
import CSSEffect from './Styles/CSSEffect'
import { getMinimunByCurrency } from "../services/utils";
import VerifiedAgencyInfoModal from './VerifiedAgencyInfoModal';


const { Option } = Select;

class SearchBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      redirect: false,
      addressResult: "",
      isVerifiedAgencyModalVisible: false,
      verifiedAgencyError: null
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.roles.find(element => element === 'agency-member') === 'agency-member' || this.props.user && this.props.user.roles.find(element => element === 'company-member') === 'company-member') {
      this.props.clearClients();
      this.props.clearBrands();
      this.props.clearDsp();
      this.props.getClients(this.props.user.id);
      if (this.props.user.company && this.props.user.company.enable_external_dsp_selector){
        this.props.getExternalDsps();
      }
    } else {
      this.props.history.replace("/");
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
       // this.setState({position});
      if (position && position.coords.longitude && position.coords.latitude) {
        // this.props.setCoords(position.coords)
        this.setState({
          lat_ne: position.coords.latitude + 0.5,
          lat_sw: position.coords.latitude - 0.5,
          lng_ne: position.coords.longitude + 0.5,
          lng_sw: position.coords.longitude - 0.5,
        });
      }
    });
    this.props.setProgrammatic('traditional');
    this.props.getConversionRates();
  }
  componentDidUpdate(nextProps) {
    const {user, dsps, conversionRates, getConversionRates} = this.props;
    if( user != nextProps.user && (!dsps || (dsps && dsps.length === 0))){
      this.props.getExternalDsps();
    }
    // Clear car when change dsp
    if (nextProps.dsp != this.props.dsp) {
      this.props.removeDisplaysCart();
    }
    if(!this.validateDates({startDate: this.state.startDate, endDate: this.state.endDate})) {
      this.setState({startDate: null, endDate: null})
    };
    if (!conversionRates) {
      getConversionRates();
    }
  }

  renderIdentificationClients = () => {
    return this.props.clients.map(client => {
      return (
        <Select.Option key={client.id} value={client.id}>
          {client.name}
        </Select.Option>
      );
    });
  };

  renderIdentificationBrands = () => {
    return this.props.brands.map(brand => {
      return (
        <Select.Option key={brand.id} value={brand.id}>
          {brand.name}
        </Select.Option>
      );
    });
  };

  renderIdentificationDsp = () => {
    let listDsp =  [...this.props.dsps];
    listDsp.unshift({ id: null, name: 'Latinad', exchange: 'Latinad' });

    return listDsp.map(dsps => {
      return (
        <Select.Option default={dsps.id == null} key={dsps.id} value={dsps.id}>
          {dsps.name} <span style= {{color:"rgb(135 135 135)"}}>{dsps.exchange}</span>
        </Select.Option>
      );
    });
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
    return geocodeByAddress(address)
      .then(results => {
        this.setState({ bounds: convertBounds(results[0].geometry.viewport) });
        const coords = convertBounds(results[0].geometry.viewport)
        this.props.setCoords(coords)
        if (results) {
          if (results[0].types[0] === 'continent') {
            this.props.setMapZoom(2)
          } else if (results[0].types[0] === 'country') {
            this.props.setMapZoom(5)
          } else if (results[0].types[0] === 'administrative_area_level_1') {
            this.props.setMapZoom(7)
          } else if (results[0].types[0] === 'administrative_area_level_2') {
            this.props.setMapZoom(9)
          } else if (results[0].types[0] === 'colloquial_area') {
            this.props.setMapZoom(11)
          } else if (results[0].types[0] === 'locality') {
            this.props.setMapZoom(12)
          } else if (results[0].types[0] === 'political' || (results[0].lenght > 1 && results[0].types[1] === 'sublocality')) {
            this.props.setMapZoom(14)
          } else if (results[0].types[0] === 'intersection' || results[0].types[0] === 'street_address' || results[0].types[0] === 'street_number' || results[0].types[0] === 'route') {
            this.props.setMapZoom(18)
          } else if (results[0].types[0] === 'establishment' || results[0].types[0] === 'point_of_interest' || results[0].types[0] === 'shopping_mall') {
            this.props.setMapZoom(16)
          }
        }
        this.setState({addressResult: results[0].address_components})

        if (!this.props.user) return;

        const getAudienceData = {
          "_method": "GET",
          "start_date": this.state.startDate ? moment(this.state.startDate._d).format("YYYY-MM-DD") : moment().subtract(30, 'days').format("YYYY-MM-DD"),
          "end_date": this.state.endDate ?  moment(this.state.startDate._d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          "address_components" : results[0].address_components
        }
        this.props.getAudience(getAudienceData);
      })
      .catch(error => console.error("Error", error));
  };

  changeClient = (clientSelect) => {
    const client = this.props.clients.find(client => client.id === clientSelect.key);
    this.props.setClient(client)
    this.props.setBrands(client.brands);
    // this.props.getBrands(clientSelect.key);
  }

  changeBrand = (brandSelect) => {
    this.props.setBrand(this.props.brands.find(brand => brand.id === brandSelect.key))
  }

  changeDsp = (dspSelect) => {
    this.props.setDsp(this.props.dsps.find(dsp => dsp.id === dspSelect.key))
  }

  changeProgrammaticMode = e => {
    const { isSuperUser } = this.props;

    this.props.setProgrammatic(e.target.value)
    this.props.handleBudgetBanner();

    // Only dsp can set external dsp for traditional
    if(e.target.value === 'traditional' && !isSuperUser){
        this.props.setDsp(null);
    }
  };

  // Limit programmatic campaign to 5000USD budget for non verified agencies
  validateBudget = (budget) => {
    const {user, programmatic, currency, conversionRates, t} = this.props;
    const isUnverifiedAgency = user && user.company && user.company.type === 'agency' && !user.company.is_verified;
    const conversionRate = conversionRates.find(rate => rate.currency === currency);
    const maxBudget = 5000 * conversionRate.rate;
    const overBudget = budget > maxBudget;
    const isSuperUser = user && user.roles && (user.roles.includes(UserRoleEnum.superAdmin) || user.roles.includes(UserRoleEnum.superModeratorAgency));
    if (isUnverifiedAgency && programmatic === 'programmatic' && overBudget && !isSuperUser) {
        this.setState({ verifiedAgencyError: t('The maximum budget for non verified agencies is 5000 USD a month') });
        this.showVerifiedAgencyModal();
        return Math.floor(maxBudget);
    }
    return budget;
  }

  handleAmountChange(e) {
    const budget = this.validateBudget(e.target.value);
    this.props.setBudget(budget);
  }

  // Limit traditional campaigns to a week for non verified agencies
  validateDates = (dates) => {
    const {user, programmatic, t} = this.props;
    const isUnverifiedAgency = user && user.company && user.company.type === 'agency' && !user.company.is_verified;
    const datesAreValid = dates.startDate && dates.endDate;
    const isSuperUser = user && user.roles && (user.roles.includes(UserRoleEnum.superAdmin) || user.roles.includes(UserRoleEnum.superModeratorAgency));
    if (isUnverifiedAgency && datesAreValid && programmatic === 'traditional' && !isSuperUser) {
      const diff = dates.endDate.diff(dates.startDate, 'days');
      if (diff > 7) {
        this.setState({ verifiedAgencyError: t("The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.") });
        this.showVerifiedAgencyModal();
        return false;
      }
    }
    return true;
  }

  setDates = (dates) => {
    if (!this.validateDates(dates)) return;

    this.setState({ startDate: dates.startDate, endDate: dates.endDate })

    if (!this.props.user) return;

    if (dates.startDate && dates.endDate) {
      const getAudienceData = {
        "_method": "GET",
        "start_date": dates.startDate._d ? moment(dates.startDate._d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        "end_date": dates.endDate._d ? moment(dates.endDate._d).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        "address_components" : this.state.addressResult
      }
      this.props.getAudience(getAudienceData);
    }
  }

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  render() {
    const { t, i18n, showBudget, user, dsp, isSuperUser} = this.props;
    moment.locale(i18n.language);

    let isAgency = false;
    if (this.props.user && ( hasAccessRole(this.props.user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]))) {
      isAgency = true;
    }
    return (
      <SearchBoxHolder>
        <Row type="flex" justify="center" align="middle" >
          <Col xs={23} sm={20} md={18} lg={16} xl={14} className="searchBox">
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="zonePicker">
              <div className="zoneTitle">
                {t('Campaign location')}
              </div>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{componentRestrictions: {
                  // Google allows to put only 5 countries in this array
                  // country: ['ar', 'mx', 'es', 'gt', 'hn', 'ni', 'sv', 'bz', 'pa', 'cr', 'do']
                }}}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div>
                    <Dropdown
                      visible={this.state.address !== ""}
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          {loading && <Menu.Item key={"loading"}>{t('Loading')}...</Menu.Item>}
                          {suggestions.map(suggestion => {
                            return (
                              <Menu.Item
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion, {
                                  className: suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      }
                    >
                      <Input
                        addonBefore={
                          <div>
                            <Icon type="environment" />
                          </div>
                        }
                        size="large"
                        placeholder="large size"
                        {...getInputProps({
                          placeholder: t('Add Country or City?'),
                          className: "location-search-input"
                        })}
                      />
                    </Dropdown>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="datePicker">
              <div className="calendarIcon">
                <Icon type="calendar" />
              </div>
              <div className="dateStartTitle">
                {t('Campaign start')}
              </div>
              <div className="dateEndTitle">
                {t('End of campaign')}
              </div>
              <DateRangePicker
                startDatePlaceholderText={t('Add dates')}
                endDatePlaceholderText={t('Add dates')}
                orientation='vertical'
                minimumNights={0}
                startDate={
                  this.state.startDate ? moment(this.state.startDate._d) : null
                } // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={
                  this.state.endDate ? moment(this.state.endDate._d) : null
                } // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.setDates({ startDate, endDate })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                hideKeyboardShortcutsPanel={true}
                customArrowIcon={<div />}
                orientation={
                  window.matchMedia("(max-width: 600px)").matches
                    ? VERTICAL_ORIENTATION
                    : HORIZONTAL_ORIENTATION
                }
                numberOfMonths={
                  window.matchMedia("(max-width: 600px)").matches ? 1 : 2
                }
              />
              <Button
                className="searchButton"
                type="primary"
                icon="search"
                onClick={() => {
                  if ((isAgency && this.props.client && this.props.brand.id) || !isAgency) {
                    this.props.resetFilters();
                    return this.props.history.push(
                      "/map?" +
                      queryString.stringify(
                        updateParams(
                          {},
                          {
                            ...getDatesRange(
                              this.state.startDate,
                              this.state.endDate
                            ),
                            lat_sw: this.state.bounds
                            ? this.state.bounds.lat_sw
                            : this.state.lat_sw,
                            lng_sw: this.state.bounds
                            ? this.state.bounds.lng_sw
                            : this.state.lng_sw,
                            lat_ne: this.state.bounds
                            ? this.state.bounds.lat_ne
                            : this.state.lat_ne,
                            lng_ne: this.state.bounds
                            ? this.state.bounds.lng_ne
                            : this.state.lng_ne,
                          },
                          { arrayFormat: "bracket" }
                        )
                      )
                    );
                  } else {
                    message.error(t('Select a customer and brand to continue'), 10);
                  }
                }}
              >
              </Button>
            </Col>
            <Row>
              { isAgency ?
                <Fragment>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Select
                      className="companySelect"
                      labelInValue
                      value={{
                        key: this.props.client && this.props.client.id ?
                        parseInt(this.props.client.id) : t('Client')
                      }}
                      loading={this.props.loadingClients}
                      onChange={this.changeClient.bind(this)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderIdentificationClients()}
                    </Select>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Select
                      className="brandSelect"
                      labelInValue
                      value={{
                        key: this.props.brand && this.props.brand.id ?
                        parseInt(this.props.brand.id) :
                        t('Mark') }} loading={this.props.loadingBrands
                      }
                      disabled={this.props.loadingBrands}
                      onChange={this.changeBrand.bind(this)}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderIdentificationBrands()}
                    </Select>
                  </Col>
                </Fragment>
              : null }
              <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{zIndex: "0"}}>
                <Radio.Group defaultValue={this.props.programmatic} onChange={this.changeProgrammaticMode} className="typePurchaseRadioButton">
                  <Radio.Button checked={this.props.programmatic === 'traditional'} size="large" className="typePurchaseRadioButtonTraditional" value="traditional">{t('Traditional')}</Radio.Button>
                  <Radio.Button size="large" className="typePurchaseRadioButtonProgrammatic" value="programmatic">{t('Programmatic')}</Radio.Button>
                </Radio.Group>
              </Col>
              { user && user.company && user.company.enable_external_dsp_selector ?
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="order-dsp" style={{zIndex: "0"}}>
                    <span hidden={ this.props.programmatic === 'traditional' && !isSuperUser }>
                      <CSSEffect>
                        <CSSTransition
                          in={showBudget}
                          timeout={1000}
                          classNames="my-node">
                          <Form.Item>
                            <div disabled={this.props.editCampaignActive} className="custom-container">
                              <div className="addon-before-content">
                              <Tooltip
                                placement="right"
                                title={
                                <span>{t("info_dsp")}</span>
                                }>
                                DSP
                                <Icon
                                  type="info-circle"
                                  style={{marginLeft: "3px", color: !dsp || (dsp && dsp.id == null) ? "#aaaaaad1": "#faad14" }}
                                  theme="filled"
                                />
                              </Tooltip>
                              </div>
                              <Select
                                labelInValue
                                style={{ flex: 1, width: '100%'}}
                                value={{
                                  key: dsp && dsp.id ?
                                    parseInt(dsp.id) : null
                                }}
                                loading={this.props.loadingClients}
                                onChange={this.changeDsp.bind(this)}
                                showSearch
                                filterOption={(input, option) =>
                                  option.props.children && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownStyle={{ width: "300px" }}
                                dropdownMatchSelectWidth= {true}
                              >
                                {this.renderIdentificationDsp()}
                              </Select>
                            </div>
                          </Form.Item>
                        </CSSTransition>
                      </CSSEffect>
                    </span>
                </Col>: null
              }
              <div>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}
                className={this.props.user !== null ? "order-budget" : "order-budget-no-user"}
                style={{zIndex: "0"}}>
                  <span hidden={this.props.programmatic === 'traditional'}>
                  <CSSEffect>
                  <CSSTransition
                    in={showBudget}
                    timeout={1000}
                    classNames="my-node">
                    <Form.Item
                    >
                      <Input
                        addonBefore={
                          <div>{this.props.currency} $</div>
                        }
                        type="number"
                        min={getMinimunByCurrency(this.props.currency)}
                        autoComplete="budgetInput"
                        placeholder={t('Budget')}
                        className="budgetInput"
                        value={this.props.amountBudget
                          ? this.props.amountBudget : ""}
                        onChange={this.handleAmountChange.bind(this)}
                      />
                    </Form.Item>
                    </CSSTransition>
                    </CSSEffect>
                  </span>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className="order-data" style={{zIndex: "0"}}>
                  {this.props.audience && this.props.audience.displays_with_audience && this.props.audience.displays_with_audience.length > 0 ? (
                    <Col xs={18} sm={18} md={12} lg={12} xl={24} className="order-data-col" >
                      { this.state.endDate && this.state.startDate ? (
                      <OnboardingContainer/>
                      ) : null }
                    </Col>
                  ) : null }
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal} message={this.state.verifiedAgencyError}></VerifiedAgencyInfoModal>
      </SearchBoxHolder>
    );
  }
}

const Extended = withTranslation()(SearchBoxContainer);
Extended.static = SearchBoxContainer.static;

export default withRouter(Extended);
