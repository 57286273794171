import React, { useState } from "react";
import { Icon } from "antd";
import "./styles.css";

const getUniqueId = (() => {
  const now = Date.now();
  let seed = 0;
  return () => `checkbox-button-${now}-${seed++}`;
})();

const CheckboxButton = ({ value, filter, icon, setFilter, label }) => {
  const uid = getUniqueId();

  const [checked, setChecked] = useState(false);

  

  function handleChange(e) {
    setChecked(e.target.checked);
    setFilter(filter);
  }

  const cls = `checkbox-button checkbox-label ${checked ? "checkbox-button-checked checkbox-label" : ""}`;

  return (
    <div className="checkbox-button-wrapper">
      <input
        type="checkbox"
        value={value}
        id={uid}
        className="checkbox-button-hidden"
        onChange={handleChange}
      />

      <label htmlFor={uid} className={cls}>
        <Icon type={icon} className="checkbox-icon"/>
        {label}
      </label>
    </div>
  );
};

export default CheckboxButton;
