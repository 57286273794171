import React, { useEffect, useState } from 'react';
import RulesComponentHolder from "./Styles/rulesComponentStyle";
import { useTranslation } from 'react-i18next';
import {Icon} from 'antd';

const ListRulesComponent = (props) => {

    const {t}  = useTranslation();
    const {listContentDisplay, styleRules, defaultText} = props;
    const [rulesAppliedInContentDisplay, setRulesAppliedInContentDisplay] = useState([]);

    const getRulesAppliedInContentDisplay = () => {
        const repeatedRules = [];

        if (listContentDisplay.length === 0) {
            return repeatedRules;
        }

        // Get rules of the first contentDisplay
        const firstContentDisplay = listContentDisplay[0];
        const rulesOfFirstContentDisplay = firstContentDisplay.rules || [];
        if (rulesOfFirstContentDisplay.length === 0) {
            return repeatedRules;
        }
        // Verify if the rules of the first contentDisplay are repeated in the other contentDisplays
        rulesOfFirstContentDisplay.forEach(rule => {
            let isRepeated = true;
            if(repeatedRules.find(r => r.configRules.id === rule.configRules.id)) {
                isRepeated = false;
            } else {
                for (let i = 1; i < listContentDisplay.length; i++) {
                    const contentDisplay = listContentDisplay[i];
                    if (!(contentDisplay.rules && contentDisplay.rules.some(r => r.configRules.id === rule.configRules.id))) {
                        isRepeated = false;
                        break;
                    }
                }
            }
            if (isRepeated) {
                repeatedRules.push(rule);
            }
        });

        return repeatedRules;
    };

    useEffect(() => {
        let listRules = getRulesAppliedInContentDisplay();
        setRulesAppliedInContentDisplay(listRules);
      }, [listContentDisplay]);


    return (
        <RulesComponentHolder>
            <div className="titleRuleList"> {t('Rules')}</div>
            {rulesAppliedInContentDisplay.length !== 0 ? (
            <>
            <div className="rulesContainer" style={{ justifyContent: styleRules ? styleRules : 'flex-start' }}>
                { rulesAppliedInContentDisplay.map(rule => (
                    <div key={rule.configRules.id} className="nameRules">
                        <span>{rule.configRules.name}</span>
                    </div>
                ))}
            </div>
            </>
            ): <p className='textRule'>{defaultText}</p>}
        </RulesComponentHolder>
    );
};

export default ListRulesComponent;