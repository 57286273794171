import React, { Component } from "react";
import PaymentHolder from "./Styles/paymentComponentStyle";
import CheckoutDetails from "./CheckoutDetails";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { compose } from "recompose";
import marker from "../images/marker-latinad.png";
import { marker as markerWhiteLabel } from "../images/customIcons/makersIcons/marker";
import placeholder from "../images/bg.jpg";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { Link } from "react-router-dom";
import MapHolder from "./Styles/mapStyle";
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  message,
  Radio,
  Modal,
  Button,
  Skeleton,
  Typography,
  Alert
} from "antd";
import storage from "redux-persist/lib/storage";
import PaymentTopbar from "./PaymentTopbar";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import MaskedInput from "react-text-mask";
import { withTranslation } from 'react-i18next';
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
import countryList from 'react-select-country-list'
import { getDomain } from "../services/utils";

const { Paragraph } = Typography;

const { Option } = Select;

const DisplayMap = withTranslation()(compose(withGoogleMap)(
  class DisplayMapComponent extends Component {

    render() {
      const { t } = this.props;
      const { displays = [] } = this.props;
      let defaultCenter = { lat: -34.397, lng: 150.644 };

      if (displays.length > 0) {
        defaultCenter = {
          lat: parseFloat(displays[0].display_latitude, 10),
          lng: parseFloat(displays[0].display_longitude, 10)
        };
      }

      return (
        <GoogleMap defaultZoom={10} center={defaultCenter}>
          {displays.map(display => {
            const position = {
              lat: parseFloat(display.display_latitude, 10),
              lng: parseFloat(display.display_longitude, 10)
            };

            return (
              <Marker
                key={display.id}
                icon={{
                  url: this.state.domain === "latinad" ? marker : markerWhiteLabel()
                }}
                onClick={() => this.props.openDisplay(display)}
                position={{
                  lat: parseFloat(display.display_latitude),
                  lng: parseFloat(display.display_longitude)
                }}
              >
                {this.props.currentDisplay === display && (
                  <InfoBox
                    onCloseClick={() => this.props.closeDisplay()}
                    options={{
                      closeBoxURL: ``,
                      enableEventPropagation: true
                    }}
                  >
                    <div className="screenCardMap">
                      <div className="screenCardMapContent">
                        <img
                          src={
                            display.display_picture
                              ? display.display_picture
                              : placeholder
                          }
                          className="screenCardMapImage"
                          alt=""
                        />
                        <div className="screenCardContentInImage">
                          <div className="screenCardMapPrice">
                            ${display.paid_amount_with_profit}
                          </div>
                          <div>
                            <Paragraph ellipsis className="screenCardMapTitle">
                              {display.display_name}
                             </Paragraph>
                          </div>
                        </div>
                        <div className="screenCardMapDownInfo">
                          <div className="screenCardMapInfo">
                            {t('Screen resolution')}:{" "}
                            <b>
                              {display.display_resolution_width +
                                "x" +
                                display.display_resolution_height}
                            </b>
                          </div>
                          <div className="screenCardMapInfo">
                            {t('Announcement resolution')}:{" "}
                            <b>
                              {display.content_width +
                                "x" +
                                display.content_height}
                            </b>
                          </div>
                          <div className="screenCardMapInfo">
                           {t('Promised impressions')}:
                            <b>
                              {" "}
                              {display.promised_shows}
                            </b>
                          </div>
                          <div className="screenCardMapDisplayDetailButton">
                            <Link to={"/screen/" + display.display_id}>
                              <Button
                                className="screenCardMapDisplayDetail"
                                style={{ width: "100%"}}
                              >
                                {t('Screen details')}
                              </Button>
                            </Link>
                          </div>
                        </div>
                        <Button
                          className="screenCardMapCloseButton"
                          onClick={() => this.props.closeDisplay()}
                          shape="circle"
                          icon="close"
                        />
                      </div>
                    </div>
                  </InfoBox>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      );
    }
  }
));

function CreditCardInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      showMask
      guide={false}
    />
  );
}

function ExpiryInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, "/", /\d/, /\d/]}
      showMask
      guide={false}
    />
  );
}

class PaymentComponent extends Component {

  constructor(props) {
    super(props);
    props.getMercadoPagoPaymentMethods();
  }

  INITIAL_CREDIT_CARD_DETAILS = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focused: "number",
    paymentMethodId: "visa",
    installments: 1,
    docType: "DNI"
  };


  state = {
    creditCardDetails: this.INITIAL_CREDIT_CARD_DETAILS,
    identificationTypes: [],
    creatingToken: false,
    paymentMethodSelected:"other",
    paymentMethodSelected:
      ((this.props.campaign && this.props.campaign.id &&
      (this.props.campaign.currency !== 'ARS' || this.props.currency !== "ARS") ||
      (this.props.campaign && !this.props.campaign.id && this.props.currency !== "ARS"))
      ? "other" : 'card'),
    payerCosts: [],
    couponData: {},
    couponErrors: "",
    deleteCouponFlag: false,
    errors: {},
    billingInformation: {
      doc_number:  (this.props.lastBillingInfo.billing_information_doc_number ? this.props.lastBillingInfo.billing_information_doc_number : ''),
      address:  (this.props.lastBillingInfo.billing_information_address_street ? this.props.lastBillingInfo.billing_information_address_street : ''),
      name:  (this.props.lastBillingInfo.billing_information_first_name ? this.props.lastBillingInfo.billing_information_first_name : '')
    },
    creatingCoupon: false,
    deletingCoupon: false,
    displayMapModal: false,
    displays: [],
    currentDisplay: null,
    phone_general: "",
    createdPaymentAuthomatic: false,
    domain: getDomain(window.location.hostname)
  };

  optionsCountry = countryList().getData()

  componentDidMount() {
    this.props.getCampaign(this.props.match.params.id);
    const {domain} = this.state;
    const mercadoPagoPublicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;

    window.Mercadopago.setPublishableKey(mercadoPagoPublicKey);
    window.Mercadopago.getIdentificationTypes((status, data) => {
      this.setState({
        identificationTypes: data,
        creditCardDetails: {
          ...this.state.creditCardDetails,
          documentType: data[0].id
        }
      });
    });

    if (domain == "latinad" && this.props.campaign.currency !== 'ARS' || (!this.props.campaign.currency && this.props.currency !== "ARS")) {
        this.handleSelectPaymentMethod("transfer")
    }
    if(domain != "latinad") {
      this.handleSelectPaymentMethod("cash")
    }
    // Billing_information_address_country is data of facturation when the pay was are create
    // and invoice_issuing have date of campaing that will create
    if (this.props.user && this.props.user.company && this.props.user.company.country) {
      setTimeout(() => {
        this.setState({
          billingInformation: {
            ...this.state.billingInformation,
            address_country: this.props.campaign.billing_information_address_country ?
                              this.props.campaign.billing_information_address_country :
                              this.props.campaign.invoice_issuing.alpha_2_code
          }
        })
      }, 3000);
    }

    if (this.props.user && this.props.user.phone) {
      setTimeout(() => {
        this.setState({
          phone_general: this.props.user.phone
        })
      }, 1000);
    }

    return storage
      .getItem("authHeaders")
      .then(headers => (!headers ? (this.props.setShouldPromptLogin(true, window.location.pathname) && this.props.history.push("/")) : null));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user , isSuperUser} = this.props;

    if (this.props.creatingCoupon && !nextProps.creatingCoupon) {

      if (nextProps.couponErrors.length > 0) {
        message.error(this.props.t('Error applying coupon'));
        if (nextProps.couponErrors[0] === "The coupon has already been added.") {
          this.setState((state) => {
            return {
              couponErrors: this.props.t('The coupon has already been applied')
            };
          });
        } else if (nextProps.couponErrors[0] === "No results for this model.") {
          this.setState((state) => {
            return {
              couponErrors: this.props.t('Coupon does not exist')
            };
          });
        } else if (nextProps.couponErrors[0] === "A coupon has already been added.") {
          this.setState((state) => {
            return {
              couponErrors: this.props.t('A coupon has already been applied in this campaign')
            };
          });
        } else {
          this.setState((state) => {
            return {
              couponErrors: this.props.t('An error occurred while applying coupon')
            };
          });
        }
      } else {
        this.setState((state) => {
          return {
            couponData: nextProps.couponData
          };
        });
        this.state.couponData = nextProps.couponData;
        message.success(this.props.t('Coupon applied successfully'));
        this.handleCreditCardNumberChange(this.state.creditCardDetails.number)
        return;
      }
    }

    if (this.props.deletingCoupon && !nextProps.deletingCoupon) {
      if (nextProps.couponErrors.length > 0) {
        message.error(this.props.t('Failed to delete coupon'));
      } else {
        this.setState((state) => {
          return {
            couponData: {},
            deleteCouponFlag: true
          };
        });
        this.state.couponData = {};
        message.success(this.props.t('Coupon removed successfully'));
        this.handleCreditCardNumberChange(this.state.creditCardDetails.number)
        return;
      }
    }

    if (this.props.fetchingCampaign && !nextProps.fetchingCampaign) {
      this.props.getLastBillingInfoRequest(this.props.user.id);

      if (!nextProps.campaign) {
        if (this.props.user) {
          this.props.history.push("/404");
        } else {
          this.props.setShouldPromptLogin(true, window.location.pathname);
          this.props.history.push("/");
        }
        return;
      }

      if (nextProps.campaign.payment_status === "approved") {
        message.success(this.props.t('The campaign has already been paid.'));
        this.props.history.push(`/payment/${nextProps.paymentData.id}`);
        return;
      }

    }

    // If the campaign is with an external DSP and it is not a super, an automatic payment is created.
    if((nextProps.campaign && this.props.campaign) && (this.props.campaign.id == nextProps.campaign.id)
        && !this.state.createdPaymentAuthomatic){

      if(nextProps.campaign.external_dsp_id != null && !!nextProps.campaign.smart_campaign
        && !isSuperUser
        && nextProps.campaign.payment_status == "pending") {
        this.makePayment("transfer");
      }

    }

    if (this.props.creatingPayment && !nextProps.creatingPayment) {
      if (nextProps.errors && nextProps.errors.message) {
        message.error(nextProps.errors.message);
        return;
      }

      if (
        nextProps.paymentData.external_resource_url ||
        nextProps.paymentData.init_point
      ) {
        window.open(
          nextProps.paymentData.external_resource_url ||
            nextProps.paymentData.init_point
        );
        this.props.history.push(`/campaign/${nextProps.campaign.id}`);
      }

      switch (nextProps.paymentData.status_detail) {
        case "pending":
          if(!this.state.createdPaymentAuthomatic){
            message.success(
              this.props.t('The payment is pending accreditation.')
            );
            this.props.history.push(`/payment/${nextProps.paymentData.id}`);
          }
          break;
        case "accredited":
          message.success(this.props.t('Congratulations! Your payment has been credited.'));
          this.props.history.push(`/payment/${nextProps.paymentData.id}`);
          break;
        case "pending_contingency":
        case "pending_review_manual":
          message.warning(
            this.props.t('We are processing your payment. In less than two business days we will notify you of the result.')
          );
          this.props.history.push(`/payment/${nextProps.paymentData.id}`);
          break;
        case "cc_rejected_bad_filled_card_number":
          message.error(this.props.t('Check the card number.'));
          break;
        case "cc_rejected_bad_filled_date":
          message.error(this.props.t('Check the card number.'));
          break;
        case "cc_rejected_bad_filled_other":
          message.error(this.props.t('Please review the data and try again.'));
          break;
        case "cc_rejected_bad_filled_security_code":
          message.error(this.props.t('Check the security code.'));
          break;
        case "cc_rejected_blacklist":
          message.error(
            this.props.t('We were unable to process your payment. Please try another payment method.')
          );
          break;
        case "cc_rejected_call_for_authorize":
          message.error(
            this.props.t('Please call your card to authorize the payment and try again.')
          );
          break;
        case "cc_rejected_card_disabled":
          message.error(
            this.props.t('Your card is not active. Please call the phone number on the back of your card.')
          );
          break;
        case "cc_rejected_card_error":
          message.error(
            this.props.t('We were unable to process your payment. Please try another payment method.')
          );
          break;
        case "cc_rejected_duplicated_payment":
          message.error(
            this.props.t("You already made a payment for that value. If you need to pay again, use another card or another means of payment.")
          );
          break;
        case "cc_rejected_high_risk":
          message.error(
            this.props.t('Your payment was declined. Choose another of the means of payment, we recommend using cash.')
          );
          break;
        case "cc_rejected_insufficient_amount":
          message.error(
            this.props.t('Your card does not have sufficient funds to carry out the operation.')
          );
          break;
        case "cc_rejected_invalid_installments":
          message.error(this.props.t('Your card cannot process installment payments.'));
          break;
        case "cc_rejected_max_attempts":
          message.error(
            this.props.t('You have reached the limit of allowed attempts. Choose another card or other means of payment.')
          );
          break;
        case "cc_rejected_other_reason":
          message.error(
            this.props.t('We were unable to process your payment. Please try another payment method.')
          );
          break;
      }
    }
    if (nextProps.errors && nextProps.errors.length > 0 && nextProps.errors[0] === 'Token has expired') {
      message.error(this.props.t('Token expired, please login again'), 6);
      this.props.history.push("/");
      this.props.logout();
    }
  }

  handleCreditCardNumberChange = value => {
    this.setState({
      creditCardDetails: {
        ...this.state.creditCardDetails,
        number: value
      }
    });

    let bin = value.replace(/[ .-]/g, "").slice(0, 6);
    if (bin.length >= 6) {
      window.Mercadopago.getPaymentMethod(
        {
          bin: bin
        },
        (status, response) => {
          if (this.state.couponData.coupons) {
            if (status === 200) {
              window.Mercadopago.getInstallments(
                {
                  bin: bin,
                  amount: this.state.couponData.total_price
                },
                (status, installmentInfo) => {
                  this.setState((state, props) => {
                    return {
                      creditCardDetails: {
                        ...state.creditCardDetails,
                        paymentMethodId: response[0].id
                      },
                      payerCosts: installmentInfo[0]
                      ? installmentInfo[0].payer_costs
                      : []
                    };
                  });
                }
              );
            }
          } else {
            if (status === 200) {
              window.Mercadopago.getInstallments(
                {
                  bin: bin,
                  amount: this.state.deleteCouponFlag
                  ? this.props.campaign.original_price_with_profit
                  : (parseFloat(this.props.campaign.total_price_with_profit) + (parseFloat(this.props.campaign.total_price_with_profit) * parseFloat(this.props.campaign.tax) / 100))
                },
                (status, installmentInfo) => {
                  this.setState((state, props) => {
                    return {
                      creditCardDetails: {
                        ...state.creditCardDetails,
                        paymentMethodId: response[0].id
                      },
                      payerCosts: installmentInfo[0]
                      ? installmentInfo[0].payer_costs
                      : []
                    };
                  });
                }
              );
            }
          }
        }
      );
    } else {
      this.setState((state, props) => {
        return {
          payerCosts: []
        };
      });
    }
  };


  renderCountries = () => {
    return this.optionsCountry.map(e => {
      return (
        <Select.Option key={e.value} value={e.value}>
          {e.label}
        </Select.Option>
      );
    });
  };

  renderIdentificationTypes = () => {
    return this.state.identificationTypes.map(e => {
      return (
        <Select.Option key={e.id} value={e.id}>
          {e.name}
        </Select.Option>
      );
    });
  };

  renderPaymentMethods = (type) => {
    const totalPrice = this.props.campaign.total_price_with_profit;
    const {domain} = this.state;
    let paymentMethods = this.props.paymentMethods.filter(pm => {
      return (
        pm.payment_type_id !== "credit_card" &&
        pm.payment_type_id !== "debit_card"
      );
    });

    paymentMethods = paymentMethods.filter(pm => {
      return (
        pm.type === type
      );
    });

    paymentMethods = paymentMethods.filter(pm => {
      return (
        pm.min_allowed_amount <= totalPrice &&
        pm.max_allowed_amount >= totalPrice
      );
    });
    if (this.props.user && !this.props.user.roles.find(role => (role === "agency-member" || role === "company-member")))
       {
          paymentMethods = paymentMethods.filter(pm => {
            return (
              pm.id !== "credit_account"
            )
          });
        }
    //Delete option transfer when is not latinad dsp
    if(domain != "latinad" && domain != "duoPrint"){
      const updatedPaymentMethods = [...paymentMethods];
      const transferIndex = updatedPaymentMethods.findIndex(pm => pm.id === 'transfer');
      if (transferIndex !== -1) {
        updatedPaymentMethods.splice(transferIndex, 1);
      }
      return updatedPaymentMethods.map(pm => {
        return (
          <Select.Option key={pm.id} value={pm.id}>
            {
            pm.id === 'cash' ? this.props.t('Cash') :
            pm.id === 'cheque' ? this.props.t('Check') :
            pm.id === 'credit_account' && domain === 'duoPrint' ? this.props.t('Direct Credit') :
            pm.id === 'credit_account' ? this.props.t('Current account') : pm.name
            }
          </Select.Option>
        );
      });
    }
    return paymentMethods.map(pm => {
      return (
        <Select.Option key={pm.id} value={pm.id}>
          {
           pm.id === 'transfer' ? this.props.t('Transfer') :
           pm.id === 'cash' ? this.props.t('Cash') :
           pm.id === 'cheque' ? this.props.t('Check') :
           pm.id === 'credit_account' && domain === 'duoPrint' ? this.props.t('Direct Credit') :
           pm.id === 'credit_account' ? this.props.t('Current account') : pm.name
          }
        </Select.Option>
      );
    });
  };

  handleSelectPaymentMethod = paymentMethodId => {
    // const paymentMethod = this.props.paymentMethods.find(
    //   pm => pm.id == paymentMethodId
    // );

    if (paymentMethodId !== 'credit_account') {
      this.setState({
        creditCardDetails: {
          ...this.state.creditCardDetails,
          paymentMethodId: paymentMethodId
        }
      });
    } else {
      this.setState({
        creditCardDetails: {
          ...this.state.creditCardDetails,
          paymentMethodId: 'credit_account'
        }
      });
    }
  };

  handleSelectCountry = country => {
    this.setState({
      billingInformation: {
        ...this.state.billingInformation,
        address_country: country
      }
    })
  };

  createCoupon = (code) => {
    const errors = {};

    this.setState({
      errors
    });

    this.props.createCoupon(
      this.props.campaign.id,
      code,
    )

    if (Object.keys(errors).length > 0) {
      this.setState({
        creatingCoupon: false,
        errors
      });

      return;
    }

  }

  deleteCoupon = (campaignId, couponId) => {

    this.props.deleteCoupon(
      campaignId,
      couponId
    )

  }

  makePayment = (paymentMethod) => {
    window.Mercadopago.clearSession();

    const {
      paymentMethodSelected,
      billingInformation,
      creditCardDetails,
      domain
    } = this.state;
    const { campaign, user } = this.props;

    if (paymentMethod === "transfer") {
      this.setState({
        createdPaymentAuthomatic: true
      });
    }

    const payWithCard = paymentMethodSelected === "card";
    const errors = {};

    // not verify when is external dsp
    // the method of payment is transfer for campaign is with external dsp
    let hidden_for_external_dsp = (campaign.external_dsp_id != null && !!campaign.smart_campaign);

    if (paymentMethodSelected === "other" && !hidden_for_external_dsp) {
      if (!this.state.phone_general) {
        errors["phone_general"] = this.props.t('Can not be blank');
      }
    }

    if (payWithCard) {
      if (!creditCardDetails["number"]) {
        errors["creditCardNumber"] = this.props.t('Can not be blank');
      }

      if (!creditCardDetails["name"]) {
        errors["name"] = this.props.t('Can not be blank');
      }

      if (!creditCardDetails["expiry"]) {
        errors["expiry"] = this.props.t('The month / year')+' '+this.props.t('Can not be blank');
      }

      if (!creditCardDetails["cvc"]) {
        errors["cvc"] = this.props.t('Can not be blank');
      }

      if (!creditCardDetails["documentType"]) {
        errors["documentType"] = this.props.t('Can not be blank');
      }

      if (!creditCardDetails["doc_number"]) {
        errors["doc_number"] = this.props.t('Can not be blank');
      }
    }

    if (!billingInformation["doc_number"] && !hidden_for_external_dsp ) {
      errors["doc_number"] = this.props.t('Can not be blank');
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        creatingToken: false,
        errors
      });

      return;
    }
    // If campaigns have external dsp the id has to be transfer
    let paymentByDsp = paymentMethod === "transfer" ? paymentMethod : creditCardDetails.paymentMethodId

    const publinetPaymentMethods = ["cash", "cheque", "transfer", "credit_account"];
    const payWithPublinet =
      publinetPaymentMethods.indexOf(paymentByDsp) >= 0;

    if (!payWithCard) {
      if (paymentMethodSelected === "other") {
        this.props.createPayment(this.props.campaign.id, {
          payment_method_id: paymentByDsp,
          gateway: payWithPublinet ? "publinet" : "mercadopago_custom",
          payer_phone: this.state.phone_general,
          billing_information: billingInformation
        });
      }

      if (paymentMethodSelected === "mp") {
        this.props.getMpInitPointRequest(this.props.campaign.id, {
          billing_information: {
            ...billingInformation
          }
        });
      }

      return;
    }

    const month = creditCardDetails.expiry.split("/")[0];
    const year = `20${creditCardDetails.expiry.split("/")[1]}`;

    this.setState({
      creatingToken: true
    });

    window.Mercadopago.createToken(
      {
        cardNumber: creditCardDetails.number.replace(/\s/gi, ""),
        securityCode: creditCardDetails.cvc,
        cardExpirationMonth: month,
        cardExpirationYear: year,
        cardholderName: creditCardDetails.name,
        docType: creditCardDetails.documentType,
        docNumber: creditCardDetails.doc_number,
        installments: 1
      },
      (status, response) => {
        if (status === 200) {
          this.props.createPayment(this.props.campaign.id, {
            payment_method_id: creditCardDetails.paymentMethodId,
            token: response.id,
            installments: creditCardDetails.installments,
            gateway: "mercadopago_custom",
            billing_information: billingInformation
          });

          this.setState({
            creatingToken: false,
            errors: {}
          });

          return;
        }

        if (response.cause.length > 0) {
          if (response.cause.some(e => e.code === "205")) {
            errors["creditCardNumber"] = this.props.t('Can not be blank');
          }

          if (response.cause.some(e => e.code === "208")) {
            errors["expiry"] = this.props.t('The month / year')+' '+this.props.t('Can not be blank');
          }

          if (response.cause.some(e => e.code === "214")) {
            errors["doc_number"] = this.props.t('Can not be blank');
          }

          if (response.cause.some(e => e.code === "221")) {
            errors["name"] = this.props.t('Can not be blank');
          }

          if (response.cause.some(e => e.code === "325")) {
            errors["expiry"] = this.props.t('The month / year')+' '+this.props.t('Its not valid');
          }

          if (response.cause.some(e => e.code === "326")) {
            errors["expiry"] = this.props.t('The month / year')+' '+this.props.t('Its not valid');
          }

          if (response.cause.some(e => e.code === "E301")) {
            errors["creditCardNumber"] = this.props.t('Its not valid');
          }

          if (response.cause.some(e => e.code === "E302")) {
            errors["cvc"] = this.props.t('Its not valid');
          }

          if (response.cause.some(e => e.code === "212")) {
            errors["documentType"] = this.props.t('Its not valid');
          }

          if (response.cause.some(e => e.code === "324")) {
            errors["doc_number"] = this.props.t('Its not valid');
          }
        }

        this.setState({
          creatingToken: false,
          errors
        });
      }
    );
  };
  getProposal = () => {
    this.props.getProposalRequest({
        campaign_id: this.props.campaign.id
    })
  }
  displayMapModal = () => {
    this.setState({ displayMapModal: true });
  };

  openDisplay(display) {
    this.setState({ currentDisplay: display });
  }

  closeDisplay(display) {
    this.setState({ currentDisplay: null });
  }


  render() {
    const { t, user, campaign, isSuperUser} = this.props;
    const {domain} = this.state;

    const paymentMethodId = this.state.creditCardDetails.paymentMethodId;

    const paymentMethod = this.props.paymentMethods.find(
      pm => pm.id === paymentMethodId
    );

    let bankData = []
    // To DO: Move this to a service and get the bank data from the backend
    if (domain == "duoPrint") {
      bankData = "\n Banco Pichincha \n Cuenta Corriente: 3470813004 \n email: cobranzas@duoprint.com.ec \n\n\n  Banco Internacional \n Cuenta Corriente: 1400612058 \n email: cobranzas@duoprint.com.ec".split(/\n/);
    } else {
      if (campaign &&
          campaign.invoice_issuing &&
          campaign.invoice_issuing.business &&
          campaign.invoice_issuing.business.bank_details) {
      bankData = campaign.invoice_issuing.business.bank_details.split(/\n/)
      }
    }

    const { errors } = this.state;
    if (!campaign) {
      return (
        <PaymentHolder>
          <PaymentTopbar
            displayBackButton={true}
            onClickMapButton={this.displayMapModal}
            getProposalRequest={this.props.getProposalRequest}
            loadingProposal={this.props.loadingProposal}
            proposalToken={this.props.proposalToken}
          />
          <Row type="flex" justify="center">
            <Col
              className="checkoutContainer"
              xs={24}
              sm={24}
              md={20}
              lg={20}
              xl={18}
            >
              <Row type="flex" justify="center" gutter={40}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <h1>{t('Campaign payment')}</h1>
                  <h3>{t('Payment method')}</h3>

                  <Skeleton title paragraph active paragraph={{ rows: 4 }} />
                  <Skeleton title paragraph active paragraph={{ rows: 4 }} />
                </Col>
                <Col xs={23} sm={23} md={10} lg={10} xl={10}>
                  <Skeleton title paragraph active paragraph={{ rows: 4 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </PaymentHolder>
      );
    }

    const paymentMethodsAllowedBasedOnTotalPrice = this.props.paymentMethods
      .filter(pm => {
        return (
          pm.payment_type_id !== "credit_card" &&
          pm.payment_type_id !== "debit_card"
        );
      })
      .filter(pm => {
        return (
          pm.min_allowed_amount <= campaign.total_price_with_profit &&
          pm.max_allowed_amount >= campaign.total_price_with_profit
        );
      });

    const payWithCard = this.state.paymentMethodSelected === "card";
    const cardPlaceholders = {
      name: this.props.t('NAME LAST NAME')
    };
    const cardLocale = {
      valid: ""
    };

    return (
      <PaymentHolder>
        <PaymentTopbar
          campaign={campaign}
          displayBackButton={true}
          onClickMapButton={this.displayMapModal}
          loadingProposal={this.props.loadingProposal}
          getProposalRequest={this.props.getProposalRequest}
          proposalToken={this.props.proposalToken}
        />
        <Row type="flex" justify="center">
          <Col
            className="checkoutContainer"
            xs={24}
            sm={24}
            md={20}
            lg={20}
            xl={18}
          >
            <Row type="flex" justify="center" gutter={40}>
              {( campaign.external_dsp_id == null || (!campaign.smart_campaign && !isSuperUser)) || isSuperUser ?
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="leftContainer">
                  <div>
                    <h1>{t('Campaign payment')}</h1>
                    <h3>{t('Payment method')}</h3>

                    <Form layout="inline">
                      <Row type="flex" gutter={6}>
                        <Radio.Group
                          style={{ marginBottom: 20 }}
                          onChange={e => {
                            let paymentMethod;
                            if (e.target.value === "card") {
                              paymentMethod = this.props.paymentMethods.find(
                                pm => pm.id === "visa"
                              );
                            } else if (e.target.value === "other" && (user && hasAccessRole(user.roles,[UserRoleEnum.agencyMember, UserRoleEnum.companyMember]))) {
                              paymentMethod = this.props.paymentMethods.find(
                                pm => pm.id === "credit_account"
                              );
                            } else {
                              paymentMethod = this.props.paymentMethods.find(
                                pm => pm.id === "transfer"
                              );
                            }
                          this.setState({
                              paymentMethodSelected: e.target.value,
                              creditCardDetails: {
                                ...this.INITIAL_CREDIT_CARD_DETAILS,
                                paymentMethodId: paymentMethod.id
                              },
                              payerCosts: []
                            });
                          }}
                          value={this.state.paymentMethodSelected}
                        >
                          <Radio value={"card"} disabled={(campaign.currency !== 'ARS') || (!campaign.currency && this.props.currency !== "ARS")}>
                            {t('Pay by credit / debit card')}
                          </Radio>
                          {paymentMethodsAllowedBasedOnTotalPrice.length > 0 && (
                            <Radio value={"other"}>
                              {t('Pay with other means of payment')}
                            </Radio>
                          )}
                          <Radio value={"mp"} disabled={(campaign.currency !== 'ARS' || (!campaign.currency && this.props.currency !== "ARS")) || campaign.total_price_with_profit > 1000000} >
                            {t('Pay with Mercado Pago')}
                          </Radio>
                        </Radio.Group>

                        {this.state.paymentMethodSelected === "other" && (
                          <Col xs={24}>
                            <Select
                              value={paymentMethodId == "visa" ? this.props.t('transfer') && this.handleSelectPaymentMethod("transfer") : paymentMethodId}
                              onChange={value =>
                                this.handleSelectPaymentMethod(value)
                              }
                            >
                              {this.renderPaymentMethods("other")}
                            </Select>
                          </Col>
                        )}
                      </Row>
                    </Form>

                    {paymentMethod &&
                      (paymentMethod.id === "cash" ||
                        paymentMethod.id === "cheque" || paymentMethod.id === "transfer") && this.state.paymentMethodSelected === "other" && (
                        <Row type="flex" align="top" justify="start">
                          <Col
                            className="card"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            type="flex"
                            align="top"
                            justify="start"
                          >
                            <h3>
                              {domain == "duoPrint" ?
                                t('Information for direct cash payments with Duoprint') :
                                t('Information for direct cash payments with Publinet')
                              }
                            </h3>
                            <p>
                              { domain == "duoPrint" ?
                                t('Please complete the form with your billing information and your contact telephone number, a Duoprint collector will contact you to coordinate the collection.') :
                                t('Please complete the form with your billing information and your contact telephone number, a Publinet collector will contact you to coordinate the collection.')
                              }
                            </p>
                          </Col>{" "}
                          <Col xs={24}>
                            <Form.Item
                              validateStatus={!!errors["phone_general"] && "error"}
                              help={errors["phone_general"]}
                            >
                              <Input
                                placeholder={t('Telephone contact')}
                                autoComplete="tel"
                                onChange={e =>
                                  this.setState({
                                    phone_general: e.target.value
                                  })
                                }
                                value={this.state.phone_general}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                    {paymentMethod && paymentMethod.id === "transfer" && this.state.paymentMethodSelected === "other" && (
                      <Row type="flex" align="top" justify="start">
                        <Col
                          className="card"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          type="flex"
                          align="start"
                          justify="start"
                        >
                          <h3>{t('Bank data')}</h3>
                          <p>
                            {t('Please make a transfer to the following account, and put the number as a reference')}:{" "}
                            <b>{campaign.id}</b>.<br /><br />
                            <div>
                              {domain == "duoPrint" ? (
                                "DUOPRINT-G S.A. - R.U.C.: 0992670835001" ) :
                                (campaign.invoice_issuing.business.name +  " - CUIT: " + campaign.invoice_issuing.business.tax_id)
                              }
                            </div>
                            <div>
                            <br/>
                              {t('Bank data')}:
                              <div style={{marginLeft:"10px"}}>
                                {bankData.map(data => {
                                  return (
                                    <span>{data}<br /></span>
                                  )
                                })}
                              </div>
                            </div>
                          </p>
                        </Col>
                      </Row>
                    )}

                    {paymentMethod && paymentMethod.id === "credit_account" && this.state.paymentMethodSelected !== "mp" && (
                      <Row type="flex" align="top" justify="start">
                        <Col
                          className="card"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          type="flex"
                          align="start"
                          justify="start"
                        >
                          {domain == "duoPrint" ?
                            <h3>{t('Information for direct current account payments with DuoPrint')} </h3> :
                            <h3>{t('Information for direct current account payments with Publinet')}</h3>
                          }
                          <p>
                            {t('Once the payment has been made using this method, your media manager will be able to approve the campaign.')}
                          </p>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            validateStatus={!!errors["phone_general"] && "error"}
                            help={errors["phone_general"]}
                          >
                            <Input
                              placeholder={t('Telephone contact')}
                              autoComplete="tel"
                              onChange={e =>
                                this.setState({
                                  phone_general: e.target.value
                                })
                              }
                              value={this.state.phone_general}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}

                    {payWithCard && (
                      <Row type="flex" align="top" justify="start">
                        <Col
                          className="card"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          type="flex"
                          align="start"
                          justify="start"
                        >
                          <Cards
                            number={this.state.creditCardDetails.number}
                            name={this.state.creditCardDetails.name}
                            expiry={this.state.creditCardDetails.expiry}
                            cvc={this.state.creditCardDetails.cvc}
                            focused={this.state.creditCardDetails.focused}
                            placeholders={cardPlaceholders}
                            locale={cardLocale}
                          />
                        </Col>
                      </Row>
                    )}

                    {payWithCard && (
                      <Form layout="inline">
                        <Row
                          type="flex"
                          gutter={6}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <Col xs={24}>
                            <Form.Item
                              validateStatus={
                                !!errors["creditCardNumber"] && "error"
                              }
                              help={errors["creditCardNumber"]}
                            >
                              <CreditCardInput
                                id="number"
                                className="ant-input"
                                placeholder={t('Card number')}
                                autoComplete="cc-number"
                                value={this.state.creditCardDetails.number}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "number"
                                    }
                                  })
                                }
                                onChange={e =>
                                  this.handleCreditCardNumberChange(
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              validateStatus={!!errors["name"] && "error"}
                              help={errors["name"]}
                            >
                              <Input
                                placeholder={t('Name')}
                                autoComplete="cc-name"
                                value={this.state.creditCardDetails.name}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "name"
                                    }
                                  })
                                }
                                onChange={e =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      name: e.target.value
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              validateStatus={!!errors["expiry"] && "error"}
                              help={errors["expiry"]}
                            >
                              <ExpiryInput
                                id="expiry"
                                className="ant-input"
                                placeholder="MM/AA"
                                autoComplete="cc-exp"
                                value={this.state.creditCardDetails.expiry}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "expiry"
                                    }
                                  })
                                }
                                onChange={e =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      expiry: e.target.value
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              validateStatus={!!errors["cvc"] && "error"}
                              help={errors["cvc"]}
                            >
                              <Input
                                id="cvc"
                                placeholder={t('Security code')}
                                autoComplete="cc-csc"
                                value={this.state.creditCardDetails.cvc}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "cvc"
                                    }
                                  })
                                }
                                onChange={e =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      cvc: e.target.value
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              validateStatus={!!errors["documentType"] && "error"}
                              help={errors["documentType"]}
                            >
                              <Select
                                placeholder={t('Document type')}
                                value={this.state.creditCardDetails.documentType}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "number"
                                    }
                                  })
                                }
                                onChange={value =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      documentType: value
                                    }
                                  })
                                }
                              >
                                {this.renderIdentificationTypes()}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              validateStatus={!!errors["doc_number"] && "error"}
                              help={errors["doc_number"]}
                            >
                              <Input
                                id="identification_number"
                                placeholder={t('Document number')}
                                onFocus={() =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      focused: "number"
                                    }
                                  })
                                }
                                onChange={e =>
                                  this.setState({
                                    creditCardDetails: {
                                      ...this.state.creditCardDetails,
                                      doc_number: e.target.value
                                    }
                                  })
                                }
                                value={this.state.creditCardDetails.doc_number}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                      <Form layout="inline">
                        <Row type="flex" gutter={6} style={{ marginTop: 10 }}>
                            <div>
                              <h3>{t('Fill in the following information')}</h3>
                              {this.props.user && this.props.user.company && (
                                <Radio.Group
                                  style={{ marginBottom: 20 }}
                                  onChange={e => {
                                    let tax_id;
                                    let address;
                                    let state;
                                    let name;
                                    if (e.target.value === "client") {
                                      tax_id = this.props.campaign.client.billing_information_tax_id;
                                      address = this.props.campaign.client.billing_information_address;
                                      name = this.props.campaign.client.billing_information_name;
                                    } else if (e.target.value === "company") {
                                      tax_id = this.props.user.company.billing_information_tax_id;
                                      address = this.props.user.company.address;
                                      state = this.props.user.company.state;
                                      name = this.props.user.company.billing_information_name;
                                    } else if (e.target.value === "lastBilling") {
                                      tax_id = this.props.lastBillingInfo.billing_information_doc_number;
                                      address = this.props.lastBillingInfo.billing_information_address_street;
                                      name = this.props.lastBillingInfo.billing_information_first_name;
                                    }
                                  this.setState({
                                        billingInformation: {
                                      ...this.state.billingInformation,
                                      doc_number: tax_id,
                                      address_street: address,
                                      address_state: state,
                                      first_name: name
                                    }
                                    });
                                  }}
                                >
                                  <Radio value={"client"}>
                                    {t('Billing to client')}
                                  </Radio>
                                  <Radio value={"company"}>
                                    {t('Billing to agency')}
                                  </Radio>
                                  <Radio value={"lastBilling"}>
                                    {t('Last billing')}
                                  </Radio>
                                </Radio.Group>
                              )}
                              <Col xs={24}>
                                <Form.Item
                                  validateStatus={!!errors["doc_number"] && "error"}
                                  help={errors["doc_number"]}
                                >
                                  <Input
                                    placeholder="DNI / CUIT / RUT / NIT"
                                    value={this.state.billingInformation.doc_number}
                                    defaultValue={this.state.billingInformation && this.state.billingInformation.doc_number && this.state.billingInformation.doc_number !== '' ?
                                                  this.state.billingInformation.doc_number :
                                                  (this.props.lastBillingInfo.billing_information_doc_number ?
                                                  this.props.lastBillingInfo.billing_information_doc_number :
                                                  '')}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          doc_number: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24}>
                                <Form.Item>
                                  <Input
                                    placeholder={t('Social name')}
                                    autoComplete="address-line1"
                                    value={this.state.billingInformation.first_name}
                                    defaultValue={this.props.lastBillingInfo.billing_information_first_name ? this.props.lastBillingInfo.billing_information_first_name : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          first_name: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col xs={12}>
                                <Form.Item>
                                  <Input
                                    placeholder={t('Number')}
                                    autoComplete="address-line2"
                                    defaultValue={this.props.lastBillingInfo.billing_information_address_number ? this.props.lastBillingInfo.billing_information_address_number : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          address_number: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                >
                                  <Input
                                    placeholder={t('Postal code')}
                                    autoComplete="postal-code"
                                    defaultValue={this.props.lastBillingInfo.billing_information_address_zip_code ? this.props.lastBillingInfo.billing_information_address_zip_code : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          address_zip_code: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col> */}
                              <Col xs={24}>
                                <Form.Item>
                                  <Select
                                    placeholder={t('Billing Country')}
                                    defaultValue={campaign.invoice_issuing ? campaign.invoice_issuing.alpha_2_code : ''}
                                    onChange={value =>
                                      this.handleSelectCountry(value)
                                    }
                                    style={{width: '100%'}}
                                  >
                                    <Select.Option disabled key={""} value={""}>
                                      <span style={{color: "#9fa2a3"}}>{t('Billing Country')}</span>
                                    </Select.Option>
                                    {this.renderCountries()}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24}>
                                <Form.Item>
                                  <Input
                                    placeholder={t('Province')}
                                    autoComplete="address-level1"
                                    value={this.state.billingInformation.address_state}
                                    defaultValue={this.props.lastBillingInfo.billing_information_address_state ? this.props.lastBillingInfo.billing_information_address_state : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          address_state: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col xs={24}>
                                <Form.Item>
                                  <Input
                                    placeholder={t('Location')}
                                    autoComplete="address-level2"
                                    defaultValue={this.props.lastBillingInfo.billing_information_address_city ? this.props.lastBillingInfo.billing_information_address_city : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          address_city: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col> */}
                              <Col xs={24}>
                                <Form.Item>
                                  <Input
                                    placeholder={t('Address')}
                                    autoComplete="address-line1"
                                    value={this.state.billingInformation.address_street}
                                    defaultValue={this.props.lastBillingInfo.billing_information_address_street ? this.props.lastBillingInfo.billing_information_address_street : ''}
                                    onChange={e =>
                                      this.setState({
                                        billingInformation: {
                                          ...this.state.billingInformation,
                                          address_street: e.target.value
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </div>
                        </Row>
                      </Form>
                  </div>
                </Col>
              :<Col xs={24} sm={24} md={12} lg={12} xl={12} className="leftContainer">
                <h1>{t('Campaign payment')}</h1>
                <p>{t('info_payment_first')} (<strong>{campaign.external_dsp.name}</strong>) {t('info_payment_second')}</p> <br/>
                <Alert description={t('info_payment_third')} type="info" showIcon />
              </Col>}
              <Col xs={23} sm={23} md={12} lg={12} xl={12} style={{marginTop: "20px"}}>
                <CheckoutDetails
                  campaign={campaign}
                  campaignRequest={this.props.campaign}
                  payerCosts={this.state.payerCosts}
                  installments={this.state.creditCardDetails.installments}
                  paymentMethod={this.state.paymentMethodSelected}
                  onPaymentButtonClick={this.makePayment}
                  createCouponRequest={this.createCoupon}
                  deleteCouponRequest={this.deleteCoupon}
                  couponData={this.state.couponData}
                  couponErrors={this.state.couponErrors}
                  deleteCouponFlag={this.state.deleteCouponFlag}
                  user={this.props.user}
                  onInstallmentsChange={value =>
                    this.setState({
                      creditCardDetails: {
                        ...this.state.creditCardDetails,
                        installments: value
                      }
                    })
                  }
                  creatingToken={
                    this.state.creatingToken || this.props.creatingPayment
                  }
                  creatingCoupon={
                    this.props.creatingCoupon
                  }
                  deletingCoupon={
                    this.props.deletingCoupon
                  }
                  getProposalRequest={this.props.getProposalRequest}
                  loadingProposal={this.props.loadingProposal}
                  proposalToken={this.props.proposalToken}
                  currency={this.props.currency}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          footer={null}
          onCancel={() => this.setState({ displayMapModal: false })}
          visible={this.state.displayMapModal}
        >
          <MapHolder>
            <DisplayMap
              displays={campaign.contents_displays}
              currentDisplay={this.state.currentDisplay}
              openDisplay={this.openDisplay.bind(this)}
              closeDisplay={this.closeDisplay.bind(this)}
              loadingElement={<div style={{ width: "100%", height: `100%` }} />}
              containerElement={
                <div
                  className="mapHeight"
                  style={{
                    width: "100%",
                    height: `500px`,
                    borderRadius: "10px"
                  }}
                />
              }
              mapElement={
                <div
                  style={{
                    width: "100%",
                    height: `100%`,
                    borderRadius: "10px"
                  }}
                />
              }
            />
          </MapHolder>
        </Modal>
      </PaymentHolder>
    );
  }
}
export default withTranslation()(PaymentComponent);
