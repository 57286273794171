import { connect } from 'react-redux'
import PaymentComponent from '../components/PaymentComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ display, userAccount }) => {
  return {
    user: userAccount.user,
    token: userAccount.token,
    campaign: display.currentCampaign,
    fetching: display.fetching,
    paymentData: display.paymentData,
    creatingPayment: display.creatingPayment,
    errors: display.errors,
    couponErrors: display.couponErrors,
    paymentMethods: display.paymentMethods,
    fetchingCampaign: display.fetchingCampaign,
    creatingCoupon: display.creatingCoupon,
    deletingCoupon: display.deletingCoupon,
    couponData: display.couponData,
    lastBillingInfo: display.lastBillingInfo,
    gettingBiilingInfo: display.gettingBiilingInfo,
    proposalToken: display.proposalToken,
    loadingProposal: display.loadingProposal,
    currency: userAccount.currency,
    countries: userAccount.countries,
    invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
    external_dsp_id: display.external_dsp_id,
    isSuperUser: userAccount.isSuperUser,
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCampaign: (id) => dispatch(DisplayActions.getCampaignRequest(id)),
  getProposalRequest: (proposalData) => dispatch(DisplayActions.getProposalRequest(proposalData)),
  createCoupon: (campaignId, code) => dispatch(DisplayActions.createCouponRequest(campaignId, code)),
  deleteCoupon: (campaignId, couponId) => dispatch(DisplayActions.deleteCouponRequest(campaignId, couponId)),
  createPayment: (campaignId, paymentData) => dispatch(DisplayActions.createPaymentRequest(campaignId, paymentData)),
  getMercadoPagoPaymentMethods: () => dispatch(DisplayActions.getMercadoPagoPaymentMethods()),
  getMpInitPointRequest: (campaignId, paymentData) => dispatch(DisplayActions.getMpInitPointRequest(campaignId, paymentData)),
  getLastBillingInfoRequest: (userId) => dispatch(DisplayActions.getLastBillingInfoRequest(userId)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
})

export default connect(mapStateToProps, mapStateToDispatch)(PaymentComponent)
