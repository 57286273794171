import React, { Component } from "react";
import "./Styles/App.css";
import AppHolder from "./Styles/appComponentStyle";
import HomeContainer from "../containers/HomeContainer";
import MapContainer from "../containers/MapContainer";
import ScreenContainer from "../containers/ScreenContainer";
import VideoUploadContainer from "../containers/VideoUploadContainer";
import CampaignManagerContainer from "../containers/CampaignManagerContainer";
import PaymentContainer from "../containers/PaymentContainer";
import PaymentStatusContainer from "../containers/PaymentStatusContainer";
import CampaignsContainer from "../containers/CampaignsContainer";
import CampaignContainer from "../containers/CampaignContainer";
import QuotesContainer from "../containers/QuotesContainer";
import UserSettingsContainer from "../containers/UserSettingsContainer";
import Topbar from "../containers/TopbarContainer";
import NotFoundContainer from "../containers/NotFoundContainer";
import PropTypes from "prop-types";
import UrlEnum from "../constants/UrlEnum";
import { Route, Switch } from 'react-router-dom';
import { getDomain } from "../services/utils";

import moment from "moment";
import "moment/locale/es";

moment.locale("es");

document.documentElement.className = getDomain(window.location.hostname)
export default class AppComponent extends Component {
  render() {
    return (
      <AppHolder>
        <Topbar />
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/reload/:url/:msg?" component={HomeContainer} />
          <Route exact path="/login/:token?" component={HomeContainer} />
          <Route exact path="/userSettings" component={UserSettingsContainer} />
          <Route exact path="/map" component={MapContainer} />
          <Route exact path="/screen/:id" component={ScreenContainer} />
          <Route exact path="/videoUpload" component={VideoUploadContainer} />
          <Route exact path="/campaignManager" component={CampaignManagerContainer} />
          <Route exact path="/campaigns" component={CampaignsContainer} />
          <Route exact path="/campaign/:id" component={CampaignContainer} />
          <Route exact path="/quotes" component={QuotesContainer} />
          <Route exact path="/campaign/:id/payment" component={PaymentContainer} />
          <Route exact path="/payment/:id" component={PaymentStatusContainer} />
          <Route exact path="*" component={NotFoundContainer} />
        </Switch>
      </AppHolder>
    );
  }
}
