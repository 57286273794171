import styled from "styled-components";
import Colors from "../../themes/Colors";

const PaymentStatusHolder = styled.div`
  
  padding: 120px 0px 0px 0px;
  
  .checkoutContainer {
    padding: 25px 15px 10px;
    @media only screen and (min-width: 768px) {
      padding: 25px 0 10px;
    }
  }

  .statusCard {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .statusCard .ant-card-body {
    padding: 0px;
  }

  .statusCard .cardContent {
    padding: 24px;
  }

  .cardListOdc{
    display: flex;
    flex-direction: column;
    max-height: 30rem;
    overflow: overlay
  }

  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }

  h3 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    color: ${Colors.coal};
    font-weight: 600;
  }

  .cardHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cardHeader h2 {
    margin: 0;
    color: ${Colors.snow};
    font-size: 22px;
    font-weight: 600;
  }

  .details {
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    @media (max-width: 1203px) {
      flex-direction: column;
    }
  }

  .goBackButton {
    min-height: 45px;
    height: 100%;
    padding: 0 25px;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    white-space: normal;
  }

  .finishCard {
    background-color: ${Colors.secondary};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: ${Colors.snow};
  }

  .checkoutButton {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
  }
`;

export default PaymentStatusHolder;
