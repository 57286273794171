const DaysEnum = [
  { value: 1, label: 'M' },
  { value: 2, label: 'T' },
  { value: 3, label: 'W' },
  { value: 4, label: 'TH' },
  { value: 5, label: 'F' },
  { value: 6, label: 'SA' },
  { value: 0, label: 'SU' },
];

export default DaysEnum;