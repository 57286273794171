import { connect } from 'react-redux'
import RulesComponent from "../components/RulesComponent";
import DisplayActions from '../redux/DisplayRedux';

const mapStateToProps = ({ display, userAccount }) => ({
    date_from: display.date_from,
    date_to: display.date_to,
    libraryRules: display.libraryRules,
    customContents: display.customContents,
    editCampaignActive: display.editCampaignActive,
})


const mapStateToDispatch = (dispatch) => ({
    setLibraryRules: (libraryRules) => dispatch(DisplayActions.setLibraryRules(libraryRules)),
    cleanLibraryRules: () => dispatch(DisplayActions.cleanLibraryRules()),
    updateRulesRelationScreen: (displayId, relationId, newRules) => dispatch(DisplayActions.updateRulesRelationScreen(displayId, relationId, newRules)),
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(RulesComponent);