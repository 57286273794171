import styled from "styled-components";
import Colors from "../../themes/Colors";

const CartBudgetStyleHolder = styled.div`
  .cartBudget {
    position: fixed;
    z-index: 996;
    top: 500px;
    right: 12px;
    height: auto;
    background-color: ${Colors.primary};
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 15px 5px 15px 15px;
    width: 11%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;

    @media only screen and (min-width: 1000px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 100%;
      .impacts-container {
        line-height: 20px;
      }
    }

    @media only screen and (max-width: 1200px) {
      position: inherit;
      width: 100%;
    }
  }
  .text-title {
    font-size: 20px;
  }
  span {
    font-size: 16px;
    color: inherit;
  }

  h3 {
    color: inherit;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }

  svg {
    font-size: 20px;
  }

  .ant-row-flex {
    align-items: baseline;
    flex-wrap: nowrap;
    gap: 8px;
  }
}`;

export default CartBudgetStyleHolder;