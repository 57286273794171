import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import NumberFormat from "./NumberFormat";
import { Icon, Popover} from "antd";

class TagsDiscount extends Component {
    constructor(props) {
    super(props);
    }
    getVolumePrice(rank_discounts, base_price_per_day, currency){
        const {t} = this.props
        let priceVolumeList = []
        rank_discounts.forEach((rank_discount, key) => {
            priceVolumeList.push(
                <div key={key}>
                    <Icon type="tags" theme="outlined"></Icon>
                    {t(rank_discount.range)} {rank_discount.discount} % dto.
                    <p className="textDiscount">
                        <NumberFormat
                            tax={false}
                            value={base_price_per_day - ((base_price_per_day * rank_discount.discount)/100)}
                            currency= {currency}
                        />/{t('Day')}
                    </p>
                </div>
            )
        })
        return priceVolumeList
    }

  render() {
    const {t, display, currency, color} = this.props
    return (
        <Popover
            content= {
                this.getVolumePrice(
                    display.rank_discounts,
                    display.base_price_per_day ? display.base_price_per_day : display.price_per_day,
                    currency
                )}
            title={t('Volume discount')}>
            <span className="primary">
                <Icon  style={{color: color}} type="tags" theme="outlined"></Icon>
            </span>
        </Popover>
    );
  }
}

export default withTranslation()(TagsDiscount);
