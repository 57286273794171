import React, { useEffect, useState } from "react";
import { Modal, Card, Row, Col } from "antd";
import ModalContentHolder from "./Styles/ModalContentStyle";
import { useTranslation } from "react-i18next";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";

const ModalContentComponent = ({ visible = false, onCancel, content }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) return;

    setLoading(true);
  }, [visible]);
  return (
    <>
      {visible &&
        <Modal
          visible={visible}
          onCancel={onCancel}
          footer={null}
          width={800}
        >
          <ModalContentHolder>
            {content &&
              <Row
                type="flex"
                justify="center"
                align="middle"
              >
                <Col xs={14} sm={14}>
                  <Card className="contentCard">
                    <div className="contentCardContent margin-card-image" hidden={loading}>
                      {content.file.endsWith(".jpg") || content.file.endsWith(".png") || content.file.endsWith(".gif") ? (
                        <img
                          className="appear-animation-without-movement"
                          src={content.file}
                          alt=""
                          width={350}
                          onLoad={() => setLoading(false)}
                        />
                      ) : (
                        <video
                          className="appear-animation-without-movement"
                          src={content.file}
                          width={350}
                          controls
                          autoPlay
                          onLoadedData={() => setLoading(false)}
                          preload="metadata"
                        />
                      )}
                    </div>
                    {loading &&
                      <GenerateSkeletonComponent
                        count={1}
                        height={202}
                        width="100%"
                      />
                    }
                  </Card>
                </Col>
                <Col xs={10} sm={10}>
                  <div className="content-info-bunlde">
                    <div className="title-displays titleCol">
                      <b>{t("Content data")}</b>
                    </div>
                    <div className="infoCol">
                      <b>{t("Name")}: </b>
                      <span className="primary">{content.name}</span>
                    </div>
                    <div className="infoCol">
                      <b>{t("Resolution")}: </b>
                      <span className="primary"> {content.width} x {content.height}</span>
                    </div>
                    <div className="infoCol">
                      <b>{t("Type")}: </b>
                      <span className="primary"> {content.type.toUpperCase()}</span>
                    </div>
                    <div className="infoCol">
                      <b>{t("Duration")}: </b>
                      <span className="primary"> {Math.floor(content.length / 1000)} {t("secs")}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </ModalContentHolder>
        </Modal>
      }
    </>
  );
};

export default ModalContentComponent;