import styled from "styled-components";
import Colors from "../../themes/Colors";

const FooterHolder = styled.div`
  padding: 25px 0 15px;

  .footerRow {
    margin: 30px 40px 30px 40px;

    @media only screen and (max-width: 576px) {
      margin: 30px 20px 30px 20px;
    }
    @media only screen and (min-width: 1000px) {
      margin: 30px 100px 30px 140px;
    }
  }
  .logo {
    height: 25px;
    margin: 0 15px 0 0;
  }
  .disclaimer {
    padding: 15px 0 0;
    font-size: 14px;
    font-weight: 600;
    color: ${Colors.coal};
    border-top: 1px solid #DDDDDD !important;
    margin-right: 40px;
    margin-left: 40px;
  }
  a {
    color: #868686
  }
  a:hover {
    color: #2196f3 !important;
  }
`;

export default FooterHolder;
