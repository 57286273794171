import React, { Component } from "react";
import BlogHolder from "./Styles/blogStyle";
import { Modal, Col, Card, Icon } from "antd";
import YouTube from "react-youtube";

import blog1 from "../images/blog/1.png";
import blog2 from "../images/blog/2.png";
import blog3 from "../images/blog/3.png";

import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";
const { Meta } = Card;

class Expansion extends Component {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  showModal = () => {
    this.setState({
      visible: true
    });
    if (this.video.current) this.video.current.internalPlayer.playVideo();
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
    this.video.current.internalPlayer.pauseVideo();
  };

  render() {
    const { t } = this.props;
    return (
      <BlogHolder>
        <div className="titleBlog">
          <h1 style={{ color: Colors.blueDark}}>{t('Blog')}</h1><br />
        </div>
        <div className="lastBlog">
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="cardBlog">
            <a href="https://help.latinad.com/crea-campanas-en-latinad-para-implementarlas-en-150-dsps/" target="_blank">
              <Card
                hoverable
                cover={<img alt="example" src={blog1} />}
              >
                <Meta title={t('Text Blog 1')} description={t('Description Blog 1')} />
              </Card>
            </a>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="cardBlog">
            <a href="https://help.latinad.com/article/implementar-un-deal-de-latinad-en-display-video-360-de-google/" target="_blank">
              <Card
                hoverable
                cover={<img alt="example" src={blog2} />}
              >
                <Meta title={t('Text Blog 2')} description={t('Description Blog 2')} />
              </Card>
            </a>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="cardBlog">
            <a href="https://help.latinad.com/" target="_blank">
              <Card
                hoverable
                cover={<img alt="example" src={blog3} />}
              >
                <Meta title={t('Text Blog 3')} description={t('Description Blog 3')} />
              </Card>
            </a>
          </Col>
        </div>
      </BlogHolder>
    );
  }
}
export default withTranslation()(Expansion)
