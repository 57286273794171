import styled from "styled-components";

const ReplicateCampaignHolder = styled.div`
    margin-top: 25px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px;

  .regionButton{
    border-color: "transparent";
  }
  .content-input {
    padding-bottom: 20px;
  }

  .content-input-info {
    margin-top: 20px;
    color: #342f2f91;
  }
`;

export default ReplicateCampaignHolder;