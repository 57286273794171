import React, { Component } from "react";
import MapFiltersHolder from "./Styles/mapFiltersStyle";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  List,
  Input,
  Collapse,
  Divider,
  Button,
  Card,
  Checkbox,
  Spin
} from "antd";
import CheckboxButton from "./customComponents/CheckboxButton/CheckboxButton";
import { poiCategories } from "../services/utils";
import { withTranslation } from "react-i18next";
import Colors from "../themes/Colors";

const Search = Input.Search;
const Panel = Collapse.Panel;
const CheckSearch = Card;
class MapFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedFilters: [],
      typedPlace:"",
    };
  }

  componentDidMount = () => {
    this.props.resetPoiFilters();
  }


  handleFilters = (filter) => {
    const { refreshDisplays, setPoiFilters} = this.props;
    const { appliedFilters } = this.state;
    let filterIncludes = appliedFilters.includes(filter);
    let newArr = null;
    if (filterIncludes) {
      newArr = appliedFilters.filter((item) => item !== filter);
    } else {
      newArr = [...appliedFilters, filter];
    }
    setPoiFilters(newArr);
    refreshDisplays(newArr);
    this.setState({appliedFilters: newArr});
  }

  handleChangePlace = (place) => {
    this.setState({typedPlace: place});
    if (place === "")
      this.props.handleSelectPlace(place);
  }

  removePins = () => {
    this.setState({ typedPlace: "" });
    this.props.handleSelectPlace("");
  }

  render() {
    const {
      t,
      address,
      handleChangeAddress,
      handleSelectAddress,
      handleSelectPlace,
      checkSearchMap,
      setCheckSearchMap,
      fetching
    } = this.props;
    const { typedPlace } = this.state;
    const customPanelStyle = {
      backdropFilter: "blur(2px)",
      border: 0,
      overflow: "hidden",
      background: "rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      textAlign: "-webkit-center"
    };
    return (
      <MapFiltersHolder>
        <Row
          type="flex"
          width="100%"
          height="200px"
          className="mapFiltersContainer"
          style={{ pointerEvents: "none" }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={17}
            xl={11}
            style={{ pointerEvents: "all" }}
            className="customCol"
          >
            <Collapse
              bordered={false}
              style={{ background: "transparent", width: "95%" }}
            >
              <Panel header={t("Map filters")} key="1" style={customPanelStyle}>
                <List
                  grid={{ gutter: 3, column: 3, xxl: 3 }}
                  dataSource={poiCategories}
                  renderItem={(item) => (
                    <List.Item style={{ height: "32px", fontSize: "12px" }}>
                      <CheckboxButton
                        filter={item.slug}
                        label={t(`${item.name}`)}
                        icon={item.icon}
                        setFilter={this.handleFilters}
                      />
                    </List.Item>
                  )}
                />
                <Divider style={{ margin: "15px 0px 10px 0px" }} />
                <Row type="flex" justify="space-between">
                  <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                    <Search
                      placeholder={t("Search by place")}
                      onChange={(e) => this.handleChangePlace(e.target.value)}
                      onSearch={() => handleSelectPlace(typedPlace)}
                      value={typedPlace}
                      enterButton
                      allowClear
                      onPressEnter={() => handleSelectPlace(typedPlace)}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={this.removePins} style={{ borderRadius: "8px"}} disabled={typedPlace === ""}>
                      <Icon type="delete" />
                      Pins
                    </Button>
                  </Col>
                  {/* 
                  // TO DO: It will be used tom hide/show screens and pois.
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Radio.Group defaultValue="a" size="small">
                      <Radio.Button value="a">{t("Show all")}</Radio.Button>
                      <Radio.Button value="b">{t("POI")}</Radio.Button>
                      <Radio.Button value="c">{t("Screens")}</Radio.Button>
                    </Radio.Group>
                  </Col> 
                  // It will be used tom hide/show screens and pois. */}
                </Row>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={9} sm={9} md={8} lg={8} xl={4} justify="right">
              <CheckSearch style={{ pointerEvents: "all", width: "98%" }} className="customCheckStyle">
                <Spin spinning={fetching}>
                  <Checkbox
                    onChange={setCheckSearchMap}
                    defaultChecked={checkSearchMap}
                    value={checkSearchMap}>
                        <span style={{fontSize:"smaller"}}>
                          {t("Search on move")}
                        </span>
                  </Checkbox>
                </Spin>
              </CheckSearch>
          </Col>
          <Col xs={17} sm={17} md={17} lg={18} xl={9} justify="right" align="right" className="mobileOrderComponent">
            <div style={{ pointerEvents: "all", width: "98%"}} className="zonePicker">
              {/* SearchBox inside the Map */}
              <PlacesAutocomplete
                value={address}
                onChange={(address) => handleChangeAddress(address)}
                onSelect={(address) => handleSelectAddress(address)}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Dropdown
                      visible={address !== ""}
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          {loading && <Menu.Item>{t("Loading")}...</Menu.Item>}
                          {suggestions.map((suggestion) => {
                            return (
                              <Menu.Item
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion, {
                                  className: suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item",
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      }
                    >
                      <Input
                        style={{ borderColor: Colors.primary , width: "95%"}}
                        addonBefore={
                          <div style={{fontSize: "small"}}>
                            <Icon type="search" />
                            {t("Campaign location")}
                          </div>
                        }
                        size="large"
                        placeholder="large size"
                        {...getInputProps({
                          placeholder: t("Location, street or city"),
                          className: "location-search-input",
                        })}
                      />
                    </Dropdown>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </Col>
        </Row>
      </MapFiltersHolder>
    );
  }
}
export default withTranslation()(MapFiltersComponent);
