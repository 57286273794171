import styled from "styled-components";
import Colors from "../../themes/Colors";

const CampaignHolder = styled.div`
  
  padding: 130px 0;

  .campaignContainer {
    padding: 25px 0 0;
  }
  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }
  h3 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }
  h2 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }
  .campaignTitle {
    color: ${Colors.primary};
    font-weight: 600;
    text-align: center;
  }
  .CampaignInfo {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .SpotsTitle {
    color: ${Colors.primary};
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 5px;
    font-size: 22px;
  }
  .separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Colors.primary};
    border-radius: 10px;
    height: 50px;
    padding: 0 25px;
    margin: 25px 0 10px;
    color: ${Colors.snow};
    font-size: 20px;
    font-weight: 600;
  }
  .cardsInfoCampaign {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-between;
  }

  .circle {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    font-size: 20px;
    font-weight: 600;
  }

  .tableCard {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 0 15px;
  }
  .infoTableCard {
    flex: auto;
    border-radius: 8px;
    .ant-card-body {
      padding: 18px;
    }
  }
  .tableCard .ant-card-body {
  }
  .checkoutButton {
    height: 28px;
    margin: 5px 5px 5px 5px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .screenCardMapDisplayDetailButton {
    display: flex;
  }
  .screenCardMapDisplayDetail {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    color: ${Colors.snow};
  }
  .reportButton {
    height: 28px;
    margin: 5px 5px 5px 5px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .reportButton:hover {
    background-color: white;
    color: ${Colors.primary};
  }
  .editButton {
    height: 28px;
    margin: 10px 10px 10px 20px;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .editButton:hover {
    background-color: white;
    color: ${Colors.primary};
  }
  .slick-next:before {
    color: #c8c8c8;
  }
  .slick-prev:before {
    color: #c8c8c8;
  }
  .ant-table-content {
    overflow-x: auto!important;
  }
  .backButtonReplicator {
    border-color: #e7e3e3;
  }
  .backButtonReplicator {
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: #FFF;
    color: #3996f3;
    font-size: 12px;
    border-color: #e7e3e3;
    font-weight: 600;
    line-height: 2.5 !important;
  }
  .contentNameButton {
      display: flex;
      justifyContent: space-between;
  }
  @media only screen and (max-width: 900px) {
     .contentNameButton {
      flex-direction: column;
    }
`;

export default CampaignHolder;
