import React, { useState, useEffect} from 'react';
import { Icon, Row, Col, Tooltip} from "antd";
import CardInfoTotalPriceHolder from "./Styles/cardInfoTotalPriceStyle";
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import NumberFormat from "./NumberFormat";

const CardInfoTotalPriceComponent = (props) => {
  const {t, i18n}  = useTranslation();
  const { campaign } = props;
  const total = parseFloat(campaign.total_price_with_profit) + (parseFloat(campaign.total_price_with_profit) * parseFloat(campaign.tax) / 100);
  const [taxInfoText, setTaxInfoText] = useState('');

  useEffect(() => {
      let tax_info_object = campaign && campaign.tax_info ? Object.values(campaign.tax_info): [];
      let tax_info_text_prev = "";

      for (let index = 0; index < tax_info_object.length; index++) {
        if (tax_info_object[index].tax_name !== 'WITHHOLDING TAX') {
          tax_info_text_prev = tax_info_text_prev + tax_info_object[index].tax_name + ' (' + parseFloat(tax_info_object[index].tax_value) + '%). ';
        }
      }
      setTaxInfoText(tax_info_text_prev);
  }, [campaign]);

  return (
      <CardInfoTotalPriceHolder>
        { campaign.id && (
          <div className="CardInfoTotalPrice">
            <Row justify="center">
              { campaign.invoice_issuing.alpha_2_code != 'F' &&
                <>
                  <Col className={"textTitle"}>{t("Subtotal")}<br /></Col>
                  <Col>
                    <div className='infoDate'>
                      <NumberFormat value ={campaign.total_price_with_profit} currency={campaign.currency}/>
                    </div>
                  </Col>
                </>
              }
              <Col className={"textTotalTitle"}>{t("Total")}<br /></Col>
              <Col style={{marginBottom: "10px"}}>
                <div className='infoDate'>
                  <NumberFormat value ={total} currency={campaign.currency}/>
                  {campaign.invoice_issuing.alpha_2_code != 'F' &&
                    <Tooltip placement="topRight" title={t('Tax details') + ': ' + taxInfoText}>
                      <Icon className="taxTooltip" type="info-circle" />
                    </Tooltip>
                  }
                </div>
              </Col>
            </Row>
          </div>
        )}
      </CardInfoTotalPriceHolder>
  );
};

export default CardInfoTotalPriceComponent;