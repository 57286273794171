import styled from "styled-components";
import Colors from "../../themes/Colors";

const BundleCardHolder = styled.div`
  .titleBundle {
    margin-bottom: 10px;
  }
  .titleBundle h2 {
    color: ${Colors.primary} !important;
  }
  .imagenTypeScreen {
    max-width: auto;
    max-height: 17px
  }
  .listDisplays span {
    display: inline-block;
    margin-right: 5px;
  }
  .bundleIcon {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  @media (max-width: 768px) {
    .bundleIcon {
      display: contents;
    }
  }
  .bundleIcon img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .bundleIcon h2 {
    margin: 0;
  }
  .title-col {
    padding-bottom: 5px;
  }
  .title-col b{
    margin-right: 5px;
  }
  .vertical-line {
    border-left: 2px solid black;
    height: 100px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    .content-info-bunlde {
      border-left: 0px !important;
    }
    .content-map-bunlde {
      margin-bottom: 30px;
    }
  }
  .contenedor-list-displays {
    max-height: 20px;
    overflow-y: vertical;
  }
  .title-displays{
    padding-top: 3px;
  }
  .content-info-bunlde {
    paddingLeft: "15px";
    paddingTop: "20px";
    border-left: 1px solid #aaa;
    padding-left: 15px;
  }
`;

export default BundleCardHolder;
