import React, { useEffect, useState } from "react";
import { Modal, Button} from "antd";
import api from '../services/apiInstance';
import { makeApiCall } from '../services/apiErrorHandlers';
import { useTranslation } from 'react-i18next';

const CheckCompanyForUser = (props) => {
  const [userData, setUserData] = useState(null);
  const [visible, setVisible] = useState(false);
  const { user, history, userUpdate } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if(!user) return;
    const getUserInfo = async () => {
      try {
        const response = await makeApiCall(api.getUserInfo);
        setUserData(response.data);

        if (response.data.company.id !== user.company.id) {
          setVisible(true);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    getUserInfo();
  }, []);

  const handleLogout = () => {
    userUpdate(userData)
    history.push("/");
  }

  const handleOk = () => {
    setVisible(false);
    handleLogout();
  };

  return (
    <>
        {   visible ? (
            <Modal
                bodyStyle={{ padding: 20 }}
                title={t("Different companies")}
                visible={visible}
                okText={t("OK")}
                onOk={handleOk}
                maskClosable={false}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                        {t("OK")}
                    </Button>,
                ]}>
                <p>
                  {t("The company logged into the system (n_company) does not match the one you have in the DSP (n_company_check). You will be redirected to avoid errors.").replace('n_company', userData.company.name).replace('n_company_check', user.company.name)}
                </p>
            </Modal>): null
        }
    </>
  );
};

export default CheckCompanyForUser;