import styled from "styled-components";
import Colors from "../../themes/Colors";

const CartHolder = styled.div`
  .cartButton {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 30px;
    height: auto;
    padding: 8px 15px;
    width: 210px;
    border-radius: 8px;
    background-color: ${Colors.primary};
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;

    font-size: 16px;
    font-weight: 600;

    @media only screen and (max-width: 576px) {
      margin-top: 10px;
      position: revert;
      margin-left: 10px;
      margin-bottom: 5px;
    }

    @media only screen and (min-width: 576px) {
      top: calc(5px + 127px + 70px);
    }

    @media only screen and (min-width: 1000px) {
      top: calc(120px + 77px + 12px);
    }
  }
  .cartNextButton {
    display: block;
    position: fixed;
    z-index: 996;
    bottom: 15px;
    right: 15px;
    height: 65px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
  }
  .cartNextButtonHidden {
    display: none;
  }
  .cartButtonHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};

    font-size: 16px;
    font-weight: 600;

    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 70px);
    }

    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 12px);
    }
  }

  .ant-badge-count {
    background-color: ${Colors.secondary};
    box-shadow: none;
    right: unset;
    left: -5px;
    top: unset;
    bottom: -8px;
  }

  .disabledCart {
    pointer-events: none;
    opacity: 0.4;
  }
  .cartDrawer {
    padding: 10px !important;
    box-shadow: none !important;
  }

  .currencyExchangeButton {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
  }
`;

export default CartHolder;
