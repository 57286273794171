import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AudienceGraphHolder from "./Styles/audienceGraphStyle";
import { Row, Col } from "antd";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ChartTooltip, Legend);

class AudienceGraphResumenComponent extends Component {
    constructor(props) {
        super(props);
    }

    getDaysOfWeek = (t) => [
        { label: t('Sunday'), value: 0 },
        { label: t('Monday'), value: 1 },
        { label: t('Tuesday'), value: 2 },
        { label: t('Wednesday'), value: 3 },
        { label: t('Thursday'), value: 4 },
        { label: t('Friday'), value: 5 },
        { label: t('Saturday'), value: 6 },
    ];

    generateChartData = (labels, dataPoints, label, color) => ({
        labels,
        datasets: [{
            label,
            data: dataPoints,
            borderColor: color,
            borderWidth: 3,
        }],
    });

    chartColors = {
        daily: 'rgb(169, 137, 197)',
        hourly: 'rgb(136, 191, 77)',
        displays: ["#57c7d4", "#926dde", "#3aa99e", "#Fb8db4", "#bba7E4", "#9daaf3", "#a2caee", "#9ae1e9", "#79d1c9", "#f978a6"]
    };

    baseChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                beginAtZero: true,
            }
        }
    };

    chartOptions = (title, showLegend) => ({
        ...this.baseChartOptions,
        plugins: {
            legend: { display: showLegend },
        },
        scales: {
            ...this.baseChartOptions.scales,
            y: {
                ...this.baseChartOptions.scales.y,
                title: {
                    display: true,
                    text: title,
                }
            }
        }
    });

    renderImpactsByDay() {
        const { t, proposalAudience } = this.props;
        const data = proposalAudience.daily_audience.reduce((acc, day) => {
            acc.dates.push(day.date);
            acc.audiences.push(day.audience);
            return acc;
        }, { dates: [], audiences: [] });

        return (
            <div className="audience-graph-container">
                <Line
                    data={this.generateChartData(data.dates, data.audiences, t('Sum of Impacts per day'), this.chartColors.daily)}
                    options={this.chartOptions(t('Audience Average'), false)}
                />
            </div>
        );
    }

    renderImpactsByHour() {
        const { t, proposalAudience } = this.props;
        const hours = proposalAudience.hourly_audience_avg.map(function (entry) { return entry.hour; });
        const audienceCounts = proposalAudience.hourly_audience_avg.map(day => day.audience);

        return (
            <div className="audience-graph-container">
                <Line
                    data={this.generateChartData(hours, audienceCounts, t('Sum of Impacts per day'), 'rgb(136, 191, 77)')}
                    options={this.chartOptions(t('Audience'), false)}
                />
            </div>
        );
    }

    renderComparisonByDisplays() {
        const { t, proposalAudience } = this.props;

        const displaysData = proposalAudience.daily_audience_by_display.reduce((acc, entry) => {
            if (!acc[entry.display.id]) {
                acc[entry.display.id] = {
                    display: entry.display,
                    dates: [],
                    audiences: []
                };
            }
            acc[entry.display.id].dates.push(entry.date);
            acc[entry.display.id].audiences.push(entry.audience);
            return acc;
        }, {});

        const datasets = Object.entries(displaysData).map(([_, data], index) => ({
            label: data.display.name,
            data: data.audiences,
            borderColor: this.chartColors.displays[index % this.chartColors.displays.length],
            borderWidth: 3
        }));

        return (
            <div className="audience-graph-container">
                <Line
                    data={{
                        labels: displaysData[Object.keys(displaysData)[0]].dates,
                        datasets
                    }}
                    options={this.chartOptions(t('Audience Average'), true)}
                />
            </div>
        );
    }

    render() {
        const { t, proposalAudience } = this.props;

        return (
            <AudienceGraphHolder>
                <div className="audience-graph">
                    <h3>{t('Average audience by date')}</h3>
                    <Row type="flex" justify="center" align="middle" gutter={16}>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ height: '100%' }}>
                            <div className='card-container' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className='data-title'>
                                    {(() => {
                                        const maxDay = proposalAudience.max_audience_day
                                            && proposalAudience.max_audience_day.day;
                                        const dayObj = maxDay !== undefined ?
                                            this.getDaysOfWeek(t).find(day => day.value === maxDay) :
                                            null;
                                        return dayObj ? dayObj.label : '';
                                    })()}
                                </div>
                                {t('Day of greatest impact')}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ height: '100%' }}>
                            <div className='card-container' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className='data-title'>
                                    {proposalAudience.max_audience_day.audience}
                                </div>
                                {t('Biggest Impact/Day')}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ height: '100%' }}>
                            <div className='card-container' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className='data-title'>
                                    {proposalAudience.max_audience_hour.hour}:00
                                </div>
                                {t('Hour with the greatest impact')}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ height: '100%' }}>
                            <div className='card-container' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className='data-title'>
                                    {proposalAudience.max_audience_hour.audience}
                                </div>
                                {t('Biggest Impact/Hour')}
                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={16} style={{ marginTop: "10px" }}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className='card-container'>
                                <h4>{t('Sum of Impacts per day (Dates)')}</h4>
                                {this.renderImpactsByDay()}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className='card-container'>
                                <h4>{t('Average Impacts per Hour (Dates)')}</h4>
                                {this.renderImpactsByHour()}
                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={16} style={{ marginTop: "10px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className='card-container'>
                                <h4>{t('Comparison by Screen')}</h4>
                                {this.renderComparisonByDisplays()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </AudienceGraphHolder>
        );
    }
}

export default withTranslation()(AudienceGraphResumenComponent);
