import React, { Component } from "react";
import InfoHolder from "./Styles/infoMediaStyle";
import { Col, Card, Button } from "antd";
import audience from "../images/medicion-audiencia.png";
import imageLatinadAudience from "../images/logo-latinad-audience.png";
import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";

const { Meta } = Card;

class Info extends Component {
  state = {
    videoLatinadVisible: false,
    videoPublinetVisible: false
  };

  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  render() {
    const { t , i18n } = this.props;
    return (
      <InfoHolder>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            marginTop: "140px",
            marginBottom: "130px",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            justify="right"
            align="right"
            style={{
              paddingRight: "10%",
            }}
          >
            <img src={audience} alt="" style={{width: "440px"}}/>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              padding: "30px",
              paddingRight: "10%",
            }}
          >
            <h1 style={{ marginBottom: "20px", color: Colors.blueDark }}>{t('Audience measurement with artificial intelligence')}</h1>
            <h2 style={{ marginBottom: "40px" }}>
              {t('Our campaigns are based on traffic and people metrics. Find out how we do it.')}
            </h2>
            <a className="link" target="_blank" href="https://latinad.com/medios/audience.html">
              <Button type="primary" shape="round">
                {t('Know the audience details')}
              </Button>
            </a>
            <div>
              <img
                style={{ marginTop: "30px", width: "120px" }}
                src={imageLatinadAudience}
                alt=""
              />
            </div>
          </Col>
        </Col>
      </InfoHolder>
    );
  }
}
export default withTranslation()(Info);
