import React, { Component } from "react";
import ScreenPreviewHolder from "./Styles/screenPreviewStyle";
import Rectangle from "react-rectangle";
import { Alert, Icon, Switch, Upload, message, Spin, Tag, Tooltip, Col, Row, InputNumber, DatePicker, TimePicker, Checkbox, Button, Card, Input, Modal, Progress, Popover } from "antd";
import { get, random, remove, clone, cloneDeep } from "lodash";
import Slider from "react-slick";
import moment from "moment";
import canvaLogoEdit from "../images/canva-icon-edit.png";
import canvaLogoAdd from "../images/canva-icon-add.png";
import { withTranslation } from 'react-i18next';
import ScreenContainer from "../containers/ScreenContainer";
import { getWorkingHoursList } from "../services/display";
import { hasAccessRole, calculateValueByCpm } from "../services/utils";
import UserRoleEnum from "../constants/UserRoleEnum";
import { CSSTransition } from 'react-transition-group';
import CSSEffect from "./Styles/CSSEffect";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import ImpressionManagementComponent  from "./ImpressionManagementComponent";
import NumberFormat from "./NumberFormat";
import Colors from "../themes/Colors";
import { AimSvg } from "../images/customIcons/AimSvg";
import { getCPM , calculateImpressions} from "../services/display";
import { createNameNewContent } from "../services/contents";

const AimIcon = (props) => <Icon component={AimSvg} {...props} />;

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const { TextArea }  =  Input;

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "-10px", top: "17px"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "-10px", top: "17px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

class ScreenPreview extends Component {

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      // realAmount: 0,
      // ruleActive: false,
      previuStatusCpm: null,
      viewAlert: true,
      rules: [],
      arrayTime: {
        "type":"hour",
        "from": "00:00",
        "to": "23:59",
      },
      arrayDays: {
        "type":"day",
        "value":[]
      },
      relation: {},
      uploadingCanvaFile: false,
      uploadingFile: false,
      canvaModalVisible: false,
      width: 300,
      height: 300,
      totalSubsidizePromisedShowsAllRelations: 0,
      displaysAudience: [],
      totalPromisedShowsAllRelations: 0,
      transferModalVisiblePreview: null,
      hasAgencyAccess: hasAccessRole(props.user.roles, [UserRoleEnum.agencyMember]) && props.user.is_in_media_holding
    };
  }

  componentDidMount() {
    if (!this.props.customContents.find(c => c.displayId === this.props.display.id)) {
      this.props.addRelationDisplay(this.props.display.id, this.props.display.id + '_' + (this.props.customContents.length + random(99999999)), Math.trunc((parseFloat(this.props.amountBudget) / this.props.cart.length ) / (this.props.display.smart_campaign_cpm/1000)))
    }
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      this.setState({
        totalPromisedShowsAllRelations: this.props.currentCampaign.contents_displays.filter(customContent => customContent.display_id === this.props.display.id)
                                                                                    .reduce((sum, customContent) => {return sum + (customContent.promised_shows - customContent.promised_shows_subsidized)}, 0)
      });
      this.setState({
        totalSubsidizePromisedShowsAllRelations : this.props.currentCampaign.contents_displays.filter(customContent => customContent.display_id === this.props.display.id)
                                                                                    .reduce((sum, customContent) => {return ( sum + (customContent.promised_shows_subsidized === undefined ? 0:
                                                                                                                     customContent.promised_shows_subsidized ))}, 0)
      });
    } else {
      this.setState({
        totalPromisedShowsAllRelations: this.props.customContents.filter(customContent => customContent.displayId === this.props.display.id)
                                                                 .reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      });
      this.setState({
        totalSubsidizePromisedShowsAllRelations: this.props.customContents.filter(customContent => customContent.displayId === this.props.display.id)
                                                                          .reduce((sum, customContent) => {return (sum + ((customContent.promised_shows_subsidized == undefined) ? 0 :
                                                                                                          customContent.promised_shows_subsidized))}, 0)
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!nextProps.fetching)
      this.setState({ uploadingFile: false });
    if (this.props.loadingUploadFile && !nextProps.loadingUploadFile && this.state.uploadingCanvaFile) {
      setTimeout(() => {
        const info = {
          file: {
            response:this.props.fileNew.resp.data,
            status: this.props.fileNew.resp.status
          },
        }
        this.setState({
          uploadingCanvaFile: false,
        });
        this.onChangeContentState(info, this.state.relation, 'single');
      }, 1000);
    }
    let sumTotal = 0
    let sumTotalSubsidized = 0
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      // admin and superModeratorAgency can change impressions subsidized, so render with custom Content
      nextProps.customContents.forEach(function(customContent) {
        if(customContent.displayId === nextProps.display.id){
          sumTotalSubsidized = sumTotalSubsidized + (customContent.promised_shows_subsidized ? customContent.promised_shows_subsidized : 0)
        }}
      );
    } else {
      nextProps.customContents.forEach(function(customContent) {
        if(customContent.displayId === nextProps.display.id){
          sumTotal = sumTotal + customContent.promised_shows
          sumTotalSubsidized = sumTotalSubsidized + (customContent.promised_shows_subsidized ? customContent.promised_shows_subsidized : 0)
        }}
      );
    }
    this.setState({totalSubsidizePromisedShowsAllRelations: sumTotalSubsidized})
  }

  updateImpressionsDisplay(displayId, relation, e) {
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      let min = this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ? this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows : 1
      let max = this.state.totalPromisedShowsAllRelations - this.props.customContents.filter(customContent => customContent.displayId === this.props.display.id).filter(customContentRelation => customContentRelation.relationId !== relation.relationId).reduce((sum, customContentRelation) => {return sum + customContentRelation.promised_shows}, 0)

      if ( min < e < max) {
        this.props.updateImpressionsDisplay(displayId, relation.relationId, e);
      } else {
        return;
      }
    } else {
      this.props.updateImpressionsDisplay(displayId, relation.relationId, e);
    }
    const customContents = this.props.customContents.filter(c => c.displayId === displayId)
    var totalPromise = 0
    let prevStatus = false //is for initialization of alert messenger
    e = (e == "0") ? 0 : e
    for (var i = 0; i < customContents.length; i++) {
      var addPromise = (relation.relationId == customContents[i].relationId) ? e : customContents[i].promised_shows
      totalPromise = totalPromise + addPromise
    }
    if(this.state.previuStatusCpm == null){
      var prevTotalPromise = 0
      for (var i = 0; i < customContents.length; i++) {
        prevTotalPromise = prevTotalPromise + customContents[i].promised_shows
      }
      if((prevTotalPromise < totalPromise ) & calculateValueByCpm(this.props.display.smart_campaign_cpm, prevTotalPromise, "difference_cpm_price") > 0){
        prevStatus = true
      }
    }
    this.showAlertCmpMin(calculateValueByCpm(this.props.display.smart_campaign_cpm, totalPromise, "difference_cpm_price"), prevStatus)
  }

  updateImpressionsSubsidizeDisplay(relationId, e) {
      if (!(0 < e < 99999999)) {
        e = 99999999
      }
      this.props.updatePromisedShowsSubsidized(relationId, e, 'single')
  }
  addRule = (relation) => {
    let newRules = clone(relation.rules);
    if (!newRules) {
      newRules = [];
    }
    newRules.push({"conditions":[]});

    this.state.arrayTime = {
      "type":"hour",
      "from": this.props.display.working_hours[0] ? this.props.display.working_hours[0].start_time.slice(0, 5) : "00:00",
      "to": this.props.display.working_hours[0] ? this.props.display.working_hours[0].end_time.slice(0, 5) : "23:59",
    }
    this.state.arrayDays = {
      "type":"day",
      "value": [1,2,3,4,5,6,0]
    }
    newRules[relation.rules.length].conditions.push(this.state.arrayTime)
    newRules[relation.rules.length].conditions.push(this.state.arrayDays)
    this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
  };

  // Change status for view alert of min cpm
  showAlertCmpMin = (difference, prevDiference) => {
    if(difference > 0){
      this.setState({ previuStatusCpm: true });
      this.setState({ viewAlert: true });
    } else if((this.state.previuStatusCpm && difference <= 0) || prevDiference){
      this.setState({ previuStatusCpm: false});
      this.setState({ viewAlert: true });
      setTimeout(() => {
        if(!this.state.previuStatusCpm){
          this.setState({ viewAlert: false });
          //this.setState({ previuStatusCpm: true});
        }
      }, 2000);
    }
  }

  showTransferModalPreview = (relationId) => {
    // this.props.showTransferModal();
    // Is necessary new variable because transferModalVisible is use in updateComponent
    this.setState({ transferModalVisiblePreview: relationId });
  }

  componentDidUpdate(prevProps, prevState) {
     if(prevProps.customContents !== this.props.customContents &&
      (prevProps.transferModalVisible !== this.props.transferModalVisible) &&
      !this.props.transferModalVisible &&
      this.state.totalPromisedShowsAllRelations === prevState.totalPromisedShowsAllRelations){
        var { display } = this.props
        let totalPromisedShows =this.props.calculatePromisedShows (display.id)
        this.setState({totalPromisedShowsAllRelations: totalPromisedShows})
    }
  }
  removeAllRules = (relation) => {
    const rulesEmpty = [];
    this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, rulesEmpty)
  };

  cloneAllRules = (relation) => {
    this.props.cloneRulesRelationScreen(relation.rules)
  };

  removeRule = (rule, indexToRemove, relation) => {
    let newRules = clone(relation.rules);
    remove(newRules, function(rule, index) {
      return index === indexToRemove;
    });
    this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
  };

  changeDate = (date, dateString, index, relation) => {
    const newRules = cloneDeep(relation.rules);
    if (dateString[0]==="" && dateString[1]===""){
      dateString[0] = this.props.date_from
      dateString[1] = this.props.date_to
    }else{
      dateString[0] = moment(dateString[0], "DD/MM/YYYY").format("YYYY-MM-DD")
      dateString[1] = moment(dateString[1], "DD/MM/YYYY").format("YYYY-MM-DD")
    }
    const arrayDate = {"type":"date","from_date":dateString[0],"to_date":dateString[1]}
    if (newRules[index].conditions.find(condition => condition.type === 'date')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'date';
      });
      newRules[index].conditions.push(arrayDate)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    } else {
      newRules[index].conditions.push(arrayDate)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    }
  };

  changeDay = (e, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'day')) {
      this.state.arrayDays = {
        "type":"day",
        "value":[]
      }
      var daysRules = newRules[index].conditions.find(condition => condition.type === 'day')
      for (var i = 0; i < daysRules.value.length; i++) {
        this.state.arrayDays.value.push(daysRules.value[i])
      }
    } else {
      this.state.arrayDays = {
        "type":"day",
        "value":[]
      }
    }
    if (e.target.checked) {
      if (parseInt(e.target.value) === 1) {
        this.state.arrayDays.value.push(1)
      } else if (parseInt(e.target.value) === 2) {
        this.state.arrayDays.value.push(2)
      } else if (parseInt(e.target.value) === 3) {
        this.state.arrayDays.value.push(3)
      } else if (parseInt(e.target.value) === 4) {
        this.state.arrayDays.value.push(4)
      } else if (parseInt(e.target.value) === 5) {
        this.state.arrayDays.value.push(5)
      } else if (parseInt(e.target.value) === 6) {
        this.state.arrayDays.value.push(6)
      } else if (parseInt(e.target.value) === 0) {
        this.state.arrayDays.value.push(0)
      }
    } else {
      if (parseInt(e.target.value) === 1) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 1;
        });
      } else if (parseInt(e.target.value) === 2) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 2;
        });
      } else if (parseInt(e.target.value) === 3) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 3;
        });
      } else if (parseInt(e.target.value) === 4) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 4;
        });
      } else if (parseInt(e.target.value) === 5) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 5;
        });
      } else if (parseInt(e.target.value) === 6) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 6;
        });
      } else if (parseInt(e.target.value) === 0) {
        remove(this.state.arrayDays.value, function(day) {
          return day === 0;
        });
      }
    }
    if (newRules[index].conditions.find(condition => condition.type === 'day')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'day';
      });
      newRules[index].conditions.push(this.state.arrayDays)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    } else {
      newRules[index].conditions.push(this.state.arrayDays)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    }
  };

  changeStartTime = (time, timeString, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      this.state.arrayTime = {}
      this.state.arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
    } else {
      this.state.arrayTime = {
        "type":"hour",
        "from": this.props.display.working_hours[0] ? this.props.display.working_hours[0].start_time : "00:00",
        "to": this.props.display.working_hours[0] ? this.props.display.working_hours[0].end_time : "23:59",
      }
    }
    this.state.arrayTime.from = timeString;
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'hour';
      });
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    } else {
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    }
  };

  changeEndTime = (time, timeString, index, relation) => {
    let newRules = cloneDeep(relation.rules);
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      this.state.arrayTime = {}
      this.state.arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
    } else {
      this.state.arrayTime = {
        "type":"hour",
        "from": this.props.display.working_hours[0] ? this.props.display.working_hours[0].start_time : "00:00",
        "to": this.props.display.working_hours[0] ? this.props.display.working_hours[0].end_time : "23:59",
      }
    }
    this.state.arrayTime.to = timeString;
    if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
      remove(newRules[index].conditions, function(condition) {
        return condition.type === 'hour';
      });
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    } else {
      newRules[index].conditions.push(this.state.arrayTime)
      this.props.updateRulesRelationScreen(this.props.display.id, relation.relationId, newRules)
    }
  };

  updateImpressions = (customContents, addNewRelation, impressionsForDeletedRelation) => {
    var displayRealShows = this.props.reportSingleData.report.filter(relation => relation.display === this.props.display.id)
    var totalDisplayRealShows = displayRealShows.reduce((sum, relation) => {return sum + relation.shows}, 0)
    let subsidized_price = 'no_subsidized_price'
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      var totalDisplayPromisedShows = customContents.filter(customContent => customContent.displayId === this.props.display.id).reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      var totalPromisedShows = this.props.totalPromisedShowsForCampaignApproved(this.props.display.id)
      subsidized_price = customContents.find(customContent => customContent.subsidized_price === true) ? 'subsidized_price' : 'no_subsidized_price'
    } else {
      var totalDisplayPromisedShows = customContents.reduce((sum, customContent) => {return sum + customContent.promised_shows}, 0)
      var totalPromisedShows = this.props.currentCampaign.contents_displays.reduce(
        (acc, content_display) => {
          return acc + content_display.promised_shows;
        },
        0
      );
    }

    if ((totalDisplayPromisedShows - totalDisplayRealShows) < 2 && !impressionsForDeletedRelation) {
      message.error(this.props.t('Unable to distribute due to lack of remaining prints'));
    } else {
      let new_promise_show
      if (addNewRelation) {
        const numDisplays = customContents.length + 1;
        new_promise_show = Math.trunc((totalDisplayPromisedShows - totalDisplayRealShows) / numDisplays)
        const rest = (totalDisplayPromisedShows - totalDisplayRealShows) % numDisplays;
        let newContentId = this.props.display.id + '_' + (customContents.length + random(99999999))

        this.props.addRelationDisplay(this.props.display.id, newContentId , new_promise_show + rest , subsidized_price)
      }
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          let new_promise_show_relation = 0
          if(new_promise_show) {
            let promise_real_shows_relation = displayRealShows.filter(relation => relation.content === customContents[i].content.id).reduce((sum, relation) => {return sum + relation.shows}, 0)
            new_promise_show_relation = new_promise_show + promise_real_shows_relation
          } else if (impressionsForDeletedRelation) {
            new_promise_show_relation = impressionsForDeletedRelation - totalDisplayRealShows
          } else {
            new_promise_show_relation = customContents[i].promised_shows + totalPromisedShows - totalDisplayPromisedShows
          }
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, new_promise_show_relation);
        }
      }
    }
  }

  addRelationDisplay = (customContents) => {
    this.setState({ previuStatusCpm: null }); // Initialize state for alert message of cpm
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      this.updateImpressions(customContents, true);
    } else {
      let  impressions = 0
      customContents.forEach(customContent => {
        impressions = customContent.promised_shows + impressions
      });
      const numDisplays = customContents.length + 1;
      const impressionsPerDisplay = Math.trunc(impressions / numDisplays)
      const rest = impressions % numDisplays;

      this.props.addRelationDisplay(this.props.display.id, this.props.display.id + '_' + (customContents.length + random(99999999)), impressionsPerDisplay + rest )
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, impressionsPerDisplay );
        }
      }
    }
    SliderNextArrow(this.props)
    this.slider.slickGoTo(customContents.length + 1)
  };

  removeRelationFromDisplay = (displayId, relationId, customContents) => {
    this.props.removeRelationFromDisplay(displayId, relationId)
    var impressions = 0
    customContents.forEach(customContent => {
      impressions = customContent.promised_shows + impressions
    });
    impressions = Math.trunc(impressions / (customContents.length - 1))
    if (this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved') {
      this.updateImpressions(customContents, false);
    } else {
      for (var i = 0; i < customContents.length; i++) {
        if (customContents[i].displayId && customContents[i].relationId) {
          this.props.updateImpressionsDisplay(customContents[i].displayId, customContents[i].relationId, impressions);
        }
      }
    }
  };

  onChangeContentState = (info, relation, typePreview) => {
    this.setState({ uploadingFile: true });
    this.props.onChangeContent(info, relation, typePreview)
  }

  createCanvaDesign = (relation) => {
    this.handleCanvaClose();
    this.props.canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: this.state.width ? this.state.width : 300,
          height: this.state.height ? this.state.height : 300,
          units: 'px',
        },
      },
      editor: {
        publishLabel: 'Guardar',
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              relation: relation
            });
            this.setState({
              uploadingCanvaFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 2000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('Cerró canva');
      },
    });
  }

  editCanvaDesign = (relation) => {
    this.props.canva.editDesign({
      design: {
        type: 'Poster',
        id: relation.content.canva_id
      },
      editor: {
        publishLabel: 'Guardar',
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              relation: relation
            });
            this.setState({
              uploadingCanvaFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 2000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('Cerró canva');
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleCanvaClose = e => {
    this.setState({
      canvaModalVisible: false
    });
  };

  showCanvaModal = (relation) => {
    if (this.props.display.resolution_height && this.props.display.resolution_width) {
      this.setState({
        width: this.props.display.resolution_width,
        height: this.props.display.resolution_height,
      });
    }
    this.setState({
      canvaModalVisible: true,
      relation: relation
    });
  };

  screenDetail = (id, currency, invoice_issuing_country, external_dsp_id) => {
    // Update currenDisplay for audience data
    this.props.getDisplayInfoRequest(id, null, currency, invoice_issuing_country, external_dsp_id);
    this.setState({
      screenDetail: true,
    });
  }
  okScreenDetail = e => {
    this.setState({
      screenDetail: false,
    });
  };
  cancelScreenDetail = e => {
    this.setState({
      screenDetail: false,
    });
  };

  showCamTrafficModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamTrafficView: true,
    });
  }

  okCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  cancelCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  showCamScreenModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamScreenView: true,
    });
  }

  okCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  cancelCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  showAudienceModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleAudienceView: true,
    });
  }

  okAudienceModal = e => {
    this.setState({
      visibleAudienceView: false,
    });
  };

  cancelAudienceModal = e => {
    this.setState({
      visibleAudienceView: false,
    });
  };

  setCamlyticsTokens() {
    // Set live view cam and traffic
    var baseLiveViewUrl = 'https://cloud.camlytics.com/widget/live_view/';
    // var baseReportUrl = 'https://cloud.camlytics.com/widget/report/';
    if (this.props.display && this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
      if (camlyticsTokens && camlyticsTokens.cam_traffic) {
        this.setState({cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic});
      }
      if (camlyticsTokens && camlyticsTokens.cam_display) {
        this.setState({cam_display: baseLiveViewUrl + camlyticsTokens.cam_display});
      }
    }
  }

  showWarningNoAutomateModal = () => {
    this.setState({visibleWarningNoAutomateView: true});
  }

  okWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  cancelWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  handleContent = (value, content) => {
    const defaultContentId = this.props.defaultContent.id;
    let newContent = {...content, name: value};
    if((!content || (content && content.id === defaultContentId )) && !this.props.editCampaignActive) {
      this.props.updateDefaultContent(newContent);
    }
    else {
      const customContents = this.props.customContents;
      customContents.map(c => {
        if (c.content && (c.content.id === content.id)){
          this.props.updateContentName(c.relationId, content, value, 'single');
        }
        return
      })
    }
  }

  handleIsCampaignsPausedToggle = (isCampaignPaused, relation) => {
    const active = isCampaignPaused ? 0 : 1;
    this.props.setActive(relation.relationId, active, 'single')
  }

  getTooltipAudience = (camera) => {
    const { t } = this.props;
    return camera ? t('Potential reach in real time of the screen based on the measurement data with cameras, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.') :
                    t('Audience data provided by the media. They are impression based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.')
  }

  getTooltipImpacts = () => {
    const { t } = this.props;
    return t("Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.")
  }

  getAmountAudience = (relation, isImpacts) => {
    const { audience, display } = this.props;
    if(!audience || !audience.displays_with_audience)
      return null
    const amountAudience = audience.displays_with_audience.find(element => element.relation_id === relation.relationId) || audience.displays_with_audience.find(element => element.id === display.id)
    if (isImpacts)
      return (amountAudience && amountAudience.impacts) || null
    return  (amountAudience && amountAudience.audience) || null
  }

  render() {

    const {
      t,
      user,
      currentCampaign,
      date_from,
      date_to,
      editCampaignActive,
      arrContentChanged,
      allDisplaysAreOwn,
      permissionsTransferSpots,
      calculateMax,
      client } = this.props;

    const { RangePicker } = DatePicker;

    if (this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
    }
    var workingHoursList = []
    if (this.props.display.working_hours) {
      getWorkingHoursList(t, this.props.display.working_hours, workingHoursList)
     }
    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };
    const campaign = currentCampaign;

    const campaignPending = campaign ? (campaign.payment_status === "pending" ||
      campaign.payment_status === "cancelled" ||
      campaign.payment_status === "rejected" ||
      campaign.payment_status === "cancelledin_mediation" ||
      campaign.payment_status === "charged_back" ||
      campaign.payment_status === "in_process") : false;

    const defaultContent = this.props.defaultContent;
    const displayId = this.props.display.id;

    const customContents = this.props.customContents.filter(c => c.displayId === displayId);

    const previewData = [];

    // Calcule primise for all stot in a screen
    var totalPromise = 0
    // indice_relation define if is Smart List
    for (var i = 0; i < customContents.length; i++) {
      previewData.push(
        {
          active: customContents[i].active,
          indice_relation: customContents[i].indice_relation,
          relationId: customContents[i].relationId,
          fill_screen: customContents[i].fill_screen,
          isContentMixed: false,
          promised_shows: customContents[i].promised_shows,
          content: get(customContents, '[' + i + '].content') || defaultContent,
          content_name: get(customContents, '[' + i + '].content_name') || defaultContent.name,
          promised_shows_subsidized: customContents[i].promised_shows_subsidized ? customContents[i].promised_shows_subsidized : 0,
          subsidized_price: customContents[i].subsidized_price == undefined ? false: customContents[i].subsidized_price,
          rules: customContents[i].rules && customContents[i].rules.length > 0 ? customContents[i].rules : []
        }
      )
      totalPromise = totalPromise + customContents[i].promised_shows
    }

    // Variables to rangepicker
    const dateFormat = 'DD/MM/YYYY';
    const dates = [
      moment(this.props.date_from, 'YYYY-MM-DD'),
      moment(this.props.date_to, 'YYYY-MM-DD'),
    ]
    const disabledDate = current => {
      if (!dates || dates.length === 0) {
        return false;
      }
      return current && current < dates[0] || current && current > dates[1];
    };

    if (previewData.rules && previewData.rules.length > 0) {
      this.state.ruleActive = true;
      for (var i = 0; i < previewData.rules.length; i++) {
        if (previewData.rules[i].conditions && !this.state.rules[i]) {
          this.state.rules.push({"conditions":[]})
        }

        var dateRule = previewData.rules[i].conditions.find(rule => rule.type === 'date');
        if (dateRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'date')) {
          remove(this.state.rules[i].conditions, function(condition) {
            return condition.type === 'date';
          });
          this.state.rules[i].conditions.push(dateRule)
        }

        var dayRule = previewData.rules[i].conditions.find(rule => rule.type === 'day');
        if(dayRule.value.length<1){
          dayRule.value = [1,2,3,4,5,6,0]
        }
        if (dayRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'day')) {
          var temporaryArrayDays = {
            "type":"day",
            "value": dayRule.value
          }
          this.state.rules[i].conditions.push(temporaryArrayDays)
          temporaryArrayDays = {};
        }

        var hourRule = previewData.rules[i].conditions.find(rule => rule.type === 'hour');
        if (hourRule && this.state.rules[i] && !this.state.rules[i].conditions.find(rule => rule.type === 'hour')) {
          this.state.arrayTime = clone(hourRule);
          remove(this.state.rules[i].conditions, function(condition) {
            return condition.type === 'hour';
          });
          this.state.rules[i].conditions.push(this.state.arrayTime)
        }
      }
    }

    const uploadProps = {
      name: "file",
      accept: "video/*,image/*,video/mp4,video/x-m4v",
      action: `${apiUrl}/contents`,
      multiple: false,
      showUploadList: false,
      data: file => ({
        name: createNameNewContent(client, customContents)
      }),
      beforeUpload(file, fileList) {
        const isLt2M = file.size / 1024 / 1024 < 50;
        if (!isLt2M) {
          message.error(this.props.t('The file cannot be larger than 50MB!'), 10);
        }
        return isLt2M;
      },
      headers: { authorization: `Bearer ${this.props.token}` },
    };

    return (
      <ScreenPreviewHolder>
        <div style={{ position: "absolute", width: "94%" }}>
          {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ?
            (
              <Upload
                {...uploadProps}
                disabled = {this.state.uploadingFile}
                onChange={(info) => this.onChangeContentState(info, null, 'single')}>
                <Tooltip title={t('Add a Spot for this screen. (In case they meet the same rules, it will print once each)')}>
                  <Button
                    shape="circle"
                    style={{position: "absolute", right: "30px", top: "0px", zIndex: 1 }}
                    onClick={() => this.addRelationDisplay(customContents)}

                    disabled = {this.state.uploadingFile}
                    >
                    <span>
                      <Icon style={{ fontSize: "14px" }} type="plus" />
                    </span>
                  </Button>
                </Tooltip>
              </Upload>
            ) : (
              <Tooltip title={t('Add a Spot for this screen. (In case they meet the same rules, it will print once each)')}>
                <Button
                  shape="circle"
                  style={{position: "absolute", right: "30px", top: "0px", zIndex: 1 }}
                  onClick={() => this.addRelationDisplay(customContents)}

                >
                  <span>
                    <Icon style={{ fontSize: "14px" }} type="plus" />
                  </span>
                </Button>
              </Tooltip>
            )
          }
        </div>
        <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
          {previewData.map((relation, index) => (
            <Row key={relation.relationId} className="singleScreen">
              <Col xs={24} sm={12} md={12} lg={12} xl={12} className="dataScreenColumn">
                <span>
                  <div style={{marginBottom: "10px"}}>
                    {previewData.length > 1 ? (
                      <Button shape="circle" style={{position: "absolute", left: "15px", top: "-40px", zIndex: 1 }}>
                        <span>
                          {index + 1}
                        </span>
                      </Button>
                    ) : null}
                    {this.props.programmatic === 'programmatic' && (this.props.display.slot_length + 1000) < relation.content.length ? (
                      <Tooltip
                        placement="left"
                        title={
                          <span>{t('In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time')}</span>
                        }>
                        <Tag color="#f74f48">
                          <span>
                            {t('Exceeded in')} {Math.floor((relation.content.length - this.props.display.slot_length) / 1000)} {t('secs')} <Icon type="question-circle"/>
                          </span>
                        </Tag>
                        <br />
                      </Tooltip>
                    ) : (
                      this.props.programmatic === 'traditional' && Math.floor(relation.content.length / 1000) * 1000 > (this.props.display.slot_length) ? (
                      <Tooltip
                        placement="left"
                        title={
                            <span>{t('If you do not change the ad for a shorter one, it will add approximately')} ${Math.round((this.props.display.price_per_day * (Math.floor(relation.content.length / 1000) * 1000) / this.props.display.slot_length - this.props.display.price_per_day) * 100) / 100} {t('per day for this screen.')}</span>
                        }>
                        <Tag color="#f74f48">
                            <span>
                              {t('Exceeded in')} {Math.floor((relation.content.length - this.props.display.slot_length) / 1000)} {t('secs')} <Icon type="question-circle"/>
                            </span>
                        </Tag>
                        <br />
                      </Tooltip>
                    ) : null )
                    }
                    <a>
                      <b><span className="titelScreen">{this.props.display.name} ({this.props.display.resolution_width}x{this.props.display.resolution_height})</span></b>
                      {" "}
                      {this.props.display.use_cms === 0 ? (
                        <Tooltip placement="bottomLeft" title={t('Non-automated screen. Click to see detail')}>
                          <Button className="screenWarningNoAutomateView" shape="circle" size='small' onClick={() => this.showWarningNoAutomateModal()}>
                            <Icon type="warning" theme="outlined" style={{color: "#ffc107"}}/>
                          </Button>
                        </Tooltip>
                      ) : null}
                      <Modal
                        title={t('Non-automated screen')}
                        visible={this.state.visibleWarningNoAutomateView}
                        onOk={this.okWarningNoAutomateModal}
                        onCancel={this.cancelWarningNoAutomateModal}
                      >
                        <p style={{margin: "25px"}}>{t('This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.')}</p>
                      </Modal>
                    </a>
                  </div>
                  <span style={{marginRight: "5px"}}>
                    <Tooltip title={!relation.fill_screen ? t('Expand ad') : t('Return original resolution')}>
                      <Button
                        shape="circle"
                        onClick={() =>
                          this.props.toggleFillScreen(
                            this.props.display.id,
                            relation.relationId,
                            !relation.fill_screen,
                            'single'
                          )
                        }
                      >
                        {!relation.fill_screen ? (
                          <Icon
                            type="fullscreen"
                          />
                        ) : (
                          <Icon
                            type="fullscreen-exit"
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{marginRight: "5px", display: "inline-block"}}>
                    <Upload
                      {...uploadProps}
                      onChange={(info) => this.onChangeContentState(info, relation, 'single')}>
                      <Tooltip placement="top" title={t('Change material')}>
                        <Button
                          shape="circle"
                        >
                          {this.state.uploadingFile ? <Icon type="loading"/> : <Icon type="interaction"/>}
                        </Button>
                      </Tooltip>
                    </Upload>
                  </span>
                  {relation.content.canva_id ? (
                    <span style={{marginRight: "5px"}}>
                      <Tooltip placement="top" title={t('Ad created in canva, you can edit and change it for this screen or relationship.')}>
                        <Button
                          shape="circle"
                          onClick={() => this.editCanvaDesign(relation)}
                        >
                          <img
                            src={canvaLogoEdit}
                            style={{height: "20px", marginTop: "-3px", display: "inherit"}}
                            alt=""
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  ) : null}
                  <span style={{marginRight: "5px"}}>
                    <Tooltip placement="top" title={t('Create new ad in canva for this relationship')}>
                      <Button
                        shape="circle"
                        onClick={() => this.showCanvaModal(relation)}
                      >
                        <img
                          src={canvaLogoAdd}
                          style={{height: "20px", marginTop: "-3px", display: "inherit"}}
                          alt=""
                        />
                      </Button>
                    </Tooltip>
                  </span>
                  <span style={{marginRight: "5px"}}>
                    <Tooltip placement="top" title={t('Screen detail')}>
                      <Button
                        shape="circle"
                        onClick={() => this.screenDetail(this.props.display.id,
                                                         this.props.display.price_currency,
                                                         this.props.campaignInvoiceIssuingCountry,
                                                         this.props.display.external_dsp_id
                                                         )}
                      >
                        <Icon
                          type="info-circle"
                        />
                      </Button>
                    </Tooltip>
                  </span>
                  <Modal
                    title={t('Screen detail')}
                    visible={this.state.screenDetail}
                    onCancel={this.cancelScreenDetail}
                    style={{top: "10px"}}
                    footer={[
                      <Button key="back" onClick={this.okScreenDetail}>
                        OK
                      </Button>,
                    ]}
                    width={1200}
                  >
                  <ScreenContainer
                    display={this.props.display}
                    displays={this.props.displays}
                    popup={true}
                    campaignInvoiceIssuingCountry = {this.props.campaignInvoiceIssuingCountry}
                    editCart={false}
                  />
                  </Modal>
                </span>
                {customContents.length > 1 ? (
                  <span style={{marginRight: "5px"}}>
                    {!this.props.editCampaignActive || 
                      (this.props.editCampaignActive && relation.relationId.toString().search('_') > 0) ||
                      campaignPending ? (
                      <span style={{marginRight: "5px"}}>
                        <Tooltip placement="top" title={t('Remove spot')}>
                          <Button
                            shape="circle"
                            onClick={() =>
                              this.props.removeRelationFromDisplayView(this.props.display.id, relation.relationId, customContents)
                            }
                          >
                            <Icon
                              type="close-square"
                            />
                          </Button>
                        </Tooltip>
                      </span>
                    ) : null }
                  </span>
                ) : null}
                {this.props.editCampaignActive && this.props.currentCampaign.status === 'ready' ?
                  null
                : <span style={{marginRight: "5px"}}>
                  <Tooltip placement="top" title={this.props.cart.length < 2 ? t('To delete need at least two screens in the campaign') : t('Remove screen')}>
                    <Button
                      shape="circle"
                      disabled={this.props.cart.length < 2}
                      onClick={() =>
                        this.props.removeRelationFromCustomContents(this.props.display.id) &&
                        this.props.removeDisplayFromCart(this.props.display.id)
                      }
                    >
                      <Icon
                        type="delete"
                      />
                    </Button>
                  </Tooltip>
                  </span>
                }
                { permissionsTransferSpots ?
                  <>
                      <span style={{marginRight: "5px", display: "inline-block"}}>
                            <Tooltip placement="top" title={`${t("Transfer of spots")}`}>
                              <Button
                                shape="circle"
                                onClick={() => this.showTransferModalPreview(relation.relationId)}
                              >
                                <Icon type="transaction"/>
                              </Button>
                            </Tooltip>
                      </span>
                      <Modal
                        title={t("Transfer of spots")}
                        visible={this.state.transferModalVisiblePreview === relation.relationId}
                        onCancel={this.showTransferModalPreview}
                        footer={null}
                        width={1200}
                        style={{ top: "10px" }}
                      >
                        <ImpressionManagementComponent
                          currentCampaign = {this.props.currentCampaign}
                          customContents = {this.props.customContents}
                          companySelected = {this.props.display.company ? this.props.display.company : null}
                          reportSingleData = {this.props.reportSingleData}
                          transferModalVisible = {this.state.transferModalVisiblePreview}
                          cart= {this.props.cart}
                          showTransferModal = {this.showTransferModalPreview}
                          updateImpressionsDisplay = {this.props.updateImpressionsDisplay}
                          creditAvailableOnTransferredImpressions = {this.props.creditAvailableOnTransferredImpressions}
                          updateCreditAvailable= {this.props.updateCreditAvailable}
                        />
                      </Modal>
                  </>
                :null}
                <div>
                  {this.props.programmatic === 'programmatic' ? (
                    <div style={{marginTop: 10, marginBottom: 10 }}>
                      <Popover
                        placement="left"
                        content={
                          <div>
                            {t('Consumed')}{" "}
                            <span style={{color: Colors.primary}}>
                              {(this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                              this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                              0)}
                            </span><br />
                            {t('To consumed')}{" "}
                            <span style={{color: Colors.primary}}>
                              {calculateImpressions("to_consumed" , relation, this.props.reportSingleData, this.props.display)}
                            </span><br />
                            {t('Total material')}{" "}
                            <span style={{color: Colors.primary}}>
                              {(this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                              this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                              0) + (relation.promised_shows - (this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                              this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                              0))}
                            </span><br />
                            {this.state.totalSubsidizePromisedShowsAllRelations > 0 ? (
                                <>
                                  {t('Total material subsidized')}{" "}
                                  <span style={{color: Colors.primary}}>
                                    {relation.promised_shows_subsidized}
                                  </span><br />
                                  {t('Total subsidize')}{" "}
                                  <span style={{color: Colors.primary}}>
                                    {this.state.totalSubsidizePromisedShowsAllRelations}
                                  </span><br />
                                </>
                              ): null}
                            {t('Total display')}{" "}
                            <span style={{color: Colors.primary}}>
                              {this.props.calculatePromisedShows(this.props.display.id)}
                            </span>
                          </div>
                        }
                        title={t('Impressions details')}
                      >
                        <Progress
                          percent={Math.round(((relation.promised_shows + relation.promised_shows_subsidized)* 100 / (this.state.totalPromisedShowsAllRelations + this.state.totalSubsidizePromisedShowsAllRelations) * 100) / 100)}
                          successPercent={Math.round(((this.props.reportSingleData.report && this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                          this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                          0) * 100 / (this.state.totalPromisedShowsAllRelations + this.state.totalSubsidizePromisedShowsAllRelations) * 100) / 100)}
                        />
                      </Popover>
                      {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ?
                        null : (
                          <Tag color="blue">
                            <Icon
                              style={{ marginRight: "5px" }}
                              type="images"
                            />{"$ " + Math.round(this.props.display.smart_campaign_cpm / 1000 * 1000) / 1000 + t('per print')}
                          </Tag>
                        )
                      }
                      <Tag color="blue" style={{ marginBottom: "7px" }}>
                        <Icon
                          style={{ marginRight: "5px", marginBottom: "5px" }}
                          type="desktop"
                        />
                          <span>
                            {this.props.GetImpressionsToShowPerDay(this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules) === 0 ? 0 : Math.round(relation.promised_shows / this.props.GetImpressionsToShowPerDay (this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules))} {t('Prints')}/{t('day')}
                          </span>
                      </Tag>
                      {(relation.promised_shows && this.getAmountAudience(relation,'impacts')) &&
                        <>
                          <Tooltip
                            placement="left"
                            title= {t('(CPM) cost per thousand impression')}>
                            <Tag color="blue">
                              CPM
                              { arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0 && this.props.loadingAudience ? <Icon type="loading" /> :
                                <span>
                                  &nbsp;$ {getCPM(calculateValueByCpm(
                                    this.props.display.smart_campaign_cpm,
                                    relation.promised_shows, "calculate_price"),
                                    this.getAmountAudience(relation, 'impacts'))
                                  }
                                </span>
                              }
                            </Tag>
                          </Tooltip>
                        </>
                      }
                      {relation.promised_shows_subsidized ?
                        <Tag color="orange">
                          <Icon
                            style={{ marginRight: "5px"}}
                            type="desktop"
                          />
                            <span>
                              {Math.round(relation.promised_shows_subsidized / this.props.GetImpressionsToShowPerDay (this.props.date_from, this.props.date_to, relation.promised_shows_subsidized, relation.rules))} imp {t('subsidized')}/{t('day')}
                            </span>
                        </Tag>
                        :null
                      }
                      <br />
                      <Tooltip
                        placement="left"
                        title={
                          <span>{this.props.GetImpressionsToShowPerDay(this.props.date_from, this.props.date_to, relation.promised_shows, relation.rules)} {t('day')}s</span>
                        }>
                        {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ? (
                          <span style={{ color: Colors.primary, fontSize: "12px" }}>{t('Remaining to consume')}:<br /> </span>
                        ) : null }
                        <InputNumber
                          type="number"
                          style={{width: 100, marginRight: 3, marginTop: 10, color: "#000000b8" }}
                          min={0}
                          disabled={this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved'}
                          max={calculateMax(this.props.display.id, relation)}
                          value={
                            relation.promised_shows - (this.props.reportSingleData && this.props.reportSingleData.report ? (
                              this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                              this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                            0) : 0 )
                          }
                          onChange={e => {
                            this.updateImpressionsDisplay(
                              this.props.display.id,
                              relation,
                              e + (this.props.reportSingleData && this.props.reportSingleData.report ?
                                    (this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id) ?
                                    this.props.reportSingleData.report.find(element => element.display === this.props.display.id && element.content === relation.content.id).shows :
                                    0) : 0 )
                            )
                          }}
                        />
                        {this.props.editCampaignActive && this.props.currentCampaign.payment_status === 'approved' ? null : <Icon type="arrow-right" theme="outlined" style={{color: '#c8c8c8'}}/>}
                      </Tooltip>
                      {(!this.props.editCampaignActive || this.props.currentCampaign.payment_status != 'approved') && (
                        <>
                          <b className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                            <NumberFormat
                              tax_include={false}
                              value={(Math.round((this.props.display.smart_campaign_cpm / 1000) * relation.promised_shows * 100) / 100)}
                              currency={this.props.editCampaignActive ? this.props.currentCampaign.currency: this.props.currency}
                            />
                          </b>
                          <Tooltip
                          placement="bottomLeft"
                          title={t('Setting this value in all screen and spot')}>
                            <Button
                                className="button cloneAllSpotButton"
                                style={{marginLeft: "10px"}}
                                onClick={() => this.props.cloneAllSpots(relation)}
                              >
                                {t('Clone spot')}
                              </Button>
                          </Tooltip>
                          </>
                      )}
                    </div>
                  ) : null }
                {this.props.programmatic === 'programmatic' &&
                <>
                  {user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)?
                      <div style={{marginBottom: 10, marginTop: 10}}>
                        <InputNumber
                            type="number"
                            style={{width: 100, marginRight: 3}}
                            min={0}
                            max={999999999}
                            value={relation.promised_shows_subsidized}
                            onChange={ e => {
                            this.updateImpressionsSubsidizeDisplay(
                              relation.relationId,
                              e
                            )}}
                        />
                        <Tooltip placement="bottomLeft" title={t('These impressions are reflected as subsidize in the campaign')}>
                          <Icon
                          style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "middle"}}
                          type="warning"
                          />
                        </Tooltip>
                        &emsp;
                        {t('Subsidize impression')}
                      </div> : null
                  }
                  <CSSEffect>
                    <CSSTransition in={this.state.viewAlert} unmountOnExit timeout={100} classNames="my-node">
                      <>
                        {(calculateValueByCpm(this.props.display.smart_campaign_cpm, totalPromise, "difference_cpm_price") > 0) ?
                            <Alert
                              className="alertCPM"
                              type="warning"
                              description= {t("Avoid getting your ad disapproved, add %deference_cpm impressions to cover the CPM!").replace(
                                          "%deference_cpm", calculateValueByCpm(this.props.display.smart_campaign_cpm, totalPromise, "compare_cpm_impresion"))}
                            />:
                            <>
                              {this.state.previuStatusCpm == false ?
                                <Alert
                                  className="alertCPM"
                                  type="success"
                                  description= {calculateValueByCpm(this.props.display.smart_campaign_cpm, totalPromise, "compare_cpm_impresion") == 0 ?
                                                t("Congratulations! You reached the minimum CPM."): t("Congratulations! You exceeded the minimum CPM.")}
                                />:null
                              }
                            </>
                        }
                      </>
                    </CSSTransition>
                  </CSSEffect>
                </>}
                 {relation.indice_relation === null &&
                  ( this.props.editCampaignActive
                    && this.props.currentCampaign.payment_status !== 'pending'
                    && this.props.currentCampaign.payment_status !== 'cancelled'
                    && user
                    && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn))?
                    (
                        <div style={{ marginBottom: 10, marginTop: 10 }}>
                          <span>
                            {t('Pause content')}
                            &emsp;
                            <Switch
                              onChange={(value) => this.handleIsCampaignsPausedToggle(value, relation)}
                              checked={relation.active === 0 ? true : false}
                              size="small"
                            />
                          </span>
                        </div>
                  ): null}
                {!this.props.editCampaignActive?
                  <>
                      {(user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) ? (
                          <div style={{marginBottom: 10, marginTop: 10}}>
                              {t('Subsidize price of this screen')}
                              &emsp;
                              <Switch
                                checked = {relation.subsidized_price}
                                disabled = {this.props.programmatic === 'traditional' && previewData.length > 1}
                                onClick={() =>
                                  this.props.updateSubsidizedPrice(
                                    relation.relationId,
                                    !relation.subsidized_price,
                                    'single'
                                  )
                                }
                                size="small"
                              />
                            <Tooltip placement="bottomLeft" title={t('By enabling this option, the screen is subsidize, affecting the price of the campaign')}>
                              <Icon
                                style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                type="warning"
                              />
                            </Tooltip>
                          </div>
                      ) : ( null ) }
                  </>:<>
                      {relation.subsidized_price ?
                        (relation.promised_shows != 0 ?
                          <span className="ant-tag ant-tag-orange" style={{marginLeft: "5px", fontSize: "10px", marginTop: "5px"}}>
                            {t('Subsidized')}
                          </span>
                          : // When the user changes the value to zero.
                            <span className="ant-tag ant-tag-blue" style={{marginLeft: "5px", fontSize: "10px"}}>
                          {t('Suspended')}
                        </span>)
                      :null}
                  </>
                  }

                  {this.props.programmatic === 'programmatic' ||
                  (this.props.programmatic === 'traditional' &&
                    ((user && hasAccessRole(user.roles,[UserRoleEnum.companyMember])) || this.state.hasAgencyAccess ))? (
                    <div style={{marginBottom: 10}}>
                      <Button className="button addRuleButton" onClick={() => this.addRule(relation)}>
                        {relation.rules.length > 0 ? (
                            t('Add another rule')
                          ) : t('Add rule') }
                      </Button>
                      {relation.rules.length > 0  ? (
                        <span>
                          <Button className="button removeAllRulesButton" onClick={() => this.removeAllRules(relation)} style={{fontSize: "11px" }}>
                            {t('Remove rules')}
                          </Button>
                          <Button className="button cloneAllRulesButton" onClick={() => this.cloneAllRules(relation)}>
                            {t('Clone rules')}
                          </Button>
                        </span>
                      ) : null }
                      <br />
                    </div>
                  ) : null }
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{paddingLeft: 20, paddingRight: 20}}>
                <div className="screenContainer">
                  <Rectangle
                    aspectRatio={[
                      this.props.display.resolution_width,
                      this.props.display.resolution_height
                    ]}
                  >
                    <div className="screen">
                      {relation.content.type === 'image' ? (
                        <img
                          src={
                            relation.content && relation.content.ready
                              ? relation.content.file_thumb.replace(".mp4", "-00001.jpg")
                              : null
                          }
                          className={
                            relation.fill_screen
                              ? "screenPreviewFilled"
                              : "screenPreview"
                          }
                          alt=""
                        />
                      ) : null}
                      {(this.props.defaultContent.ready &&
                        (relation.content && relation.content.ready && relation.content.type === 'video')) ? (
                        <video
                          controls
                          className={
                            relation.fill_screen
                              ? "screenPreviewFilled"
                              : "screenPreview"
                          }
                        >
                          <source src={ relation.content.file } type="video/mp4" />
                        </video>
                      ) : null}
                      {(relation.content && relation.content.id && !relation.content.ready) || !this.props.defaultContent.ready ? (
                        <div className="pulseLoaderContainer">
                          <Spin size="large" spinning />
                        </div>
                      ) : null}
                      {
                        this.props.programmatic === 'programmatic' ? (
                          <Tooltip
                            placement="top"
                            title={
                              <span>{t('Standard time of the programmatic space, an extra could be charged in the case of a longer ad.')}</span>
                            }>
                            <div className="time">
                              {t('Space time')}:  {this.props.display.slot_length / 1000} {t('secs')}
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="top"
                            title={
                              <span>{t('Standard time of the space of this screen, an extra could be charged in the case of being a longer ad.')}</span>
                            }>
                            <div className="time">
                             {t('Space time')}: {this.props.display.slot_length / 1000} {t('secs')}
                            </div>
                          </Tooltip>
                        )
                      }
                    </div>
                  </Rectangle>
                  <div className="cardsContainer">
                    {camlyticsTokens && camlyticsTokens.cam_traffic ? (
                      <Tooltip placement="bottomLeft" title={t('Camera to traffic')}>
                        <Button className="screenCardCamTrafficView" shape="circle" size='small' onClick={() => this.showCamTrafficModal()}>
                          <Icon type="car" theme="outlined"/>
                        </Button>
                      </Tooltip>
                    ): null}
                    <Modal
                      title={t('Traffic camera for audience control and measurement')}
                      visible={this.state.visibleCamTrafficView}
                      onOk={this.okCamTrafficModal}
                      onCancel={this.cancelCamTrafficModal}
                    >
                      <iframe id="live-view-traffic" src={this.state.cam_traffic} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                    </Modal>
                    {camlyticsTokens && camlyticsTokens.cam_display ? (
                      <Tooltip placement="bottomLeft" title={t('Camera for certification')}>
                        <Button className="screenCardCamCertification" shape="circle" size='small' onClick={() => this.showCamScreenModal()}>
                          <Icon type="video-camera" theme="outlined"/>
                        </Button>
                      </Tooltip>
                    ): null}
                    <Modal
                      title={t('Camera looking at screen for certification')}
                      visible={this.state.visibleCamScreenView}
                      onOk={this.okCamScreenModal}
                      onCancel={this.cancelCamScreenModal}
                    >
                      <iframe id="live-view-traffic" src={this.state.cam_display} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                    </Modal>
                    {this.getAmountAudience(relation) &&
                      <>
                        <Tooltip placement="bottomLeft" title={this.getTooltipAudience(camlyticsTokens && camlyticsTokens.cam_traffic)}>
                            <Button className="screenCardAudit" size='small' onClick={() => this.showAudienceModal()}>
                              {arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0 && this.props.loadingAudience ? <Icon type="loading" /> :
                              <>
                                <Icon type="usergroup-add" theme="outlined"/>
                                <span>
                                  {this.getAmountAudience(relation)}
                                </span>
                              </>
                              }
                            </Button>
                        </Tooltip>
                      </>
                    }
                    {this.getAmountAudience(relation,'impacts') &&
                      <Tooltip placement="bottomLeft" title={this.getTooltipImpacts()}>
                        <Button className="screenCardAudit" size='small' onClick={() => this.showAudienceModal()}>
                          { arrContentChanged.filter( item => item.relationId === relation.relationId ).length > 0  && this.props.loadingAudience ? <Icon type="loading" /> :
                          <>
                            <AimIcon className="aimIcon"/>
                            <span>
                              {this.getAmountAudience(relation,'impacts')}
                            </span>
                          </>
                          }
                        </Button>
                      </Tooltip>
                    }
                    <TextArea
                    placeholder={t('Identify your ad...')}
                    maxLength={20}
                    className="displayTextArea"
                    autoSize
                    value={relation.content.name}
                    onChange={e => this.handleContent(e.target.value, relation.content)}
                    />
                    <Modal
                      title={t('Audience')}
                      visible={this.state.visibleAudienceView}
                      onOk={this.okAudienceModal}
                      onCancel={this.cancelAudienceModal}
                      width={1000}
                    >
                      <AudienceSummaryComponentGeneral
                        t={t}
                        display= {this.props.display}
                        user={user}
                        campaign={ editCampaignActive ? currentCampaign : null}
                        audienceHoursOfDay = {this.props.audienceHoursOfDay}
                        audienceDaysOfWeek = {this.props.audienceDaysOfWeek}
                        getAudienceHoursOfDayRequest={this.props.getAudienceHoursOfDayRequest}
                        getAudienceDaysOfWeekRequest={this.props.getAudienceDaysOfWeekRequest}
                        />
                    </Modal>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{paddingLeft: 0, paddingRight: 0}}>
                <div>
                  {relation.rules.map((rule, index) => (
                    <Card key={index} style={{borderRadius: 10, marginBottom: 10}}>
                      <Tooltip
                        placement="top"
                        title={
                          <span>{t('Remove rule')}</span>
                        }>
                        <Icon type="close" className="closeRuleButton" onClick={() => this.removeRule(rule, index, relation)}/>
                      </Tooltip>
                      <div style={{marginBottom: 15}}>
                        <RangePicker
                          onChange={(date, dateString) => this.changeDate(date, dateString, index, relation)}
                          disabledDate={disabledDate}
                          defaultValue={[
                            rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').from_date ?
                            moment(rule.conditions.find(condition => condition.type === 'date').from_date, 'YYYY-MM-DD') : moment(this.props.date_from, 'YYYY-MM-DD'),
                            rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').to_date ?
                            moment(rule.conditions.find(condition => condition.type === 'date').to_date, 'YYYY-MM-DD') : moment(this.props.date_to, 'YYYY-MM-DD')
                          ]}
                          value={[
                            rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').from_date ?
                            moment(rule.conditions.find(condition => condition.type === 'date').from_date, 'YYYY-MM-DD') : moment(this.props.date_from, 'YYYY-MM-DD'),
                            rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').to_date ?
                            moment(rule.conditions.find(condition => condition.type === 'date').to_date, 'YYYY-MM-DD') : moment(this.props.date_to, 'YYYY-MM-DD')
                          ]}
                          format={['DD/MM/YYYY', 'DD/MM/YY']}/>
                      </div>
                      <div style={{marginBottom: 15}}>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="1" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 1) ? true : false}>{t('M')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="2" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 2) ? true : false}>{t('T')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="3" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 3) ? true : false}>{t('W')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="4" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 4) ? true : false}>{t('TH')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="5" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 5) ? true : false}>{t('F')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="6" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 6) ? true : false}>{t('SA')}</Checkbox>
                        <Checkbox onChange={(e) => this.changeDay(e, index, relation)} value="0" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 0) !== undefined ? true : false}>{t('SU')}</Checkbox>
                        {rule.conditions.find(condition => condition.type === 'day') && (rule.conditions.find(condition => condition.type === 'day').value.length < 1)?
                          <p className="alertMsg">{t('You must select at least one day')}</p>
                        :null}
                      </div>
                      <div>
                        <TimePicker
                          onChange={(time, timeString) => this.changeStartTime(time, timeString, index, relation)}
                          defaultValue={
                            rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').from ?
                            moment(rule.conditions.find(condition => condition.type === 'hour').from, 'HH:mm') : (this.props.display.working_hours[0] ? moment(this.props.display.working_hours[0].start_time, 'HH:mm') : moment('00:00','HH:mm'))
                          }
                          value={
                            rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').from ?
                            moment(rule.conditions.find(condition => condition.type === 'hour').from, 'HH:mm') : (this.props.display.working_hours[0] ? moment(this.props.display.working_hours[0].start_time, 'HH:mm') : moment('00:00','HH:mm'))
                          }
                          format='HH:mm'
                          style={{marginRight: 15}}
                        />
                        <TimePicker style={{ marginRight: "10px"}}
                          onChange={(time, timeString) => this.changeEndTime(time, timeString, index, relation)}
                          defaultValue={rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').to ?
                          moment(rule.conditions.find(condition => condition.type === 'hour').to, 'HH:mm') : (this.props.display.working_hours[0] ? moment(this.props.display.working_hours[0].end_time, 'HH:mm') : moment('23:59','HH:mm'))
                        }
                          value={
                            rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').to ?
                            moment(rule.conditions.find(condition => condition.type === 'hour').to, 'HH:mm') : (this.props.display.working_hours[0] ? moment(this.props.display.working_hours[0].end_time, 'HH:mm') : moment('23:59','HH:mm'))
                          }
                          format='HH:mm'
                        />
                        <p className="msgHoursOperation">
                          {t('Hours of Operation')}
                          <Tooltip
                            placement="right"
                            title={<span>{this.props.display.working_hours && this.props.display.working_hours.length !== 0? workingHoursList:t('Lit all day every day')}</span>}>
                            <Icon style={{ fontSize: "16px", marginLeft: "12px" }}
                              type="schedule" />
                          </Tooltip>
                        </p>
                      </div>
                    </Card>
                  ))}
                </div>
              </Col>
              {this.props.programmatic === 'traditional' &&
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{paddingLeft: 0, paddingRight: 0, textAlign: "center"}}>
                  {this.props.getNextVolumeDiscount(t, this.props.display, date_from, date_to)}
                </Col>
              }
            </Row>
          ))}
        </Slider>
        <Modal
          visible={this.state.canvaModalVisible}
          onCancel={this.handleCanvaClose}
          footer={null}
        >
          <div className="modalCanva">
            <h2>{t('What resolution will your main Spot have?')}</h2>
          </div>
          <div className="modalContent">
            <Input
              autoFocus
              name="width"
              className="modalInput"
              placeholder="Ancho (px)"
              defaultValue={this.state.width}
              onChange={this.handleInputChange}
              prefix={<Icon type="column-width" className="inputIcon" />}
              type="number"
              suffix="px"
              style={{marginBottom: "10px"}}
              autoComplete="off"
            />
            <Input
              name="height"
              className="modalInput"
              placeholder="Alto (px)"
              defaultValue={this.state.height}
              onChange={this.handleInputChange}
              prefix={<Icon type="column-height" className="inputIcon" />}
              onPressEnter={() => this.createCanvaDesign(this.state.relation)}
              type="number"
              suffix="px"
              autoComplete="off"
            />
            <Button
              className="modalButton"
              onClick={() => this.createCanvaDesign(this.state.relation)}
            >
              {t('Start')}
            </Button>
          </div>
        </Modal>
      </ScreenPreviewHolder>
    );
  }
}

export default withTranslation()(ScreenPreview);
