import React, { useState, useEffect, useRef, useMemo, act } from 'react';
import Slider from "react-slick";
import { Row, Col, Select, Card, Button, Icon, Input, InputNumber, Typography, Tooltip } from "antd";
import OptionModal from "./OptionModal";
import ContentsOfCampaignHolder from "./Styles/contentsOfCampaignStyle"
import { useTranslation } from 'react-i18next';
import RequiredResolutionsCard from "./RequiredResolutionsCardComponent";
import { convertImage } from '../services/contents';
import ModalContentComponent from "./ModalContentComponent";
import HideWhenCampaignActive from "../containers/HideWhenCampaignActiveContainer";
import VersionEnum from "../constants/VersionEnum";

const { Text } = Typography;

function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "-15px", top: "108px" }}
            onClick={onClick}
        />
    );
}

function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "-30px", top: "108px", zIndex: "1" }}
            onClick={onClick}
        />
    );
}

const ContentsOfCampaignComponent = (props, { location }) => {
    const { t } = useTranslation();
    const {
        cart,
        listOfContentsInCampaign, // this are contents added to the campaign
        cleanListOfContentsInCampaign,
        deleteContentFromCampaign,
        listResolutionWithContent,
        setListResolutionWithContent,
        updateListOfContentsInCampaign,
        customContents,
        removeRelationFromDisplay,
        clearCustomContents,
        errors,
        groupedDisplays, // When modal open from the group display
        groupName,
        selectedContent,
        toleranceResolutionInit,
        toleranceResolution,
        setToleranceResolution,
        isSuperUser,
        editCampaignActive,
        applyRelationOnScreens,
        removeRelationsOnCampaign,
        filterByContent,
        updateCustomContents,
        remplaceContentForUniqueVersion,
        isPaymentStatusApproved,
        setListOfContentsInCampaign,
        updateImpressionsDisplay,
    } = props;

    let sliderRef = useRef(null);

    const [visibleModalDeleteContentRelation, setVisibleModalDeleteContentRelation] = useState(false);
    const [contentModal, setContentModal] = useState(null); // Save the content to show in all modal (Delete and info View)
    const [deletingContentFromLibrary, setDeletingContentFromLibrary] = useState(false); // Is when delete content from library IN MODAL VIEW
    const [visibleModalCleanContent, setVisibleModalCleanContent] = useState(false);
    const [currentStick, setCurrentStick] = useState(0);
    const [loadingListOfResolutions, setLoadingListOfResolutions] = useState(true); // Waiting for the list of resolutions
    const [slidesToScroll, setSlidesToScroll] = useState(3);
    const [visibleModalContent, setVisibleModalContent] = useState(false);
    const [listResolution, setListResolution] = useState();
    const [hasDifferentResolution, setHasDifferentResolution] = useState(false);
    const [isCreatedEditCampaign, setIsCreatedEditCampaign] = useState(false);
    const [resolutionFilterIndex, setResolutionFilterIndex] = useState(null);
    const [versionFilter, setVersionFilter] = useState(null);
    
    const [isLoaderView, setIsLoaderView] = useState(false);
    const prevListOfContents = useRef(props.listOfContentsInCampaign);

    const resolutionSubtitle = useMemo(() => getResolucionSubtitle(), [groupedDisplays]);
    const resolutionTitle = useMemo(() => getResolucionTitle(), [groupedDisplays, listResolution]);
    const listContentResolutions = useMemo(() => generateResolutions(), [listOfContentsInCampaign]);
    const listOfContentsInCampaignFiltered = useMemo(() => filterListOfContentsInCampaign() || listOfContentsInCampaign, [listOfContentsInCampaign, resolutionFilterIndex, versionFilter]);

    const withCardInRelation = 130; // Define the width of the card in the relation
    const { Option } = Select;

    const settingsSlider = {
        infinite: false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 3,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        variableWidth: true,
        adaptiveHeight: false,
        swipeToSlide: true,
        responsive: [{
            breakpoint: 600,
            settings: { slidesToShow: 2, slidesToScroll: 2 }
        }, {
            breakpoint: 480,
            settings: { slidesToShow: 1, slidesToScroll: 1 }
        }]
    };

    function aspectRatiosMatch(contentWidth, contentHeight, screenWidth, screenHeight) {
        // Calculate the aspect ratios of the content and the screen
        const contentAspectRatio = contentWidth / contentHeight;
        const screenAspectRatio = screenWidth / screenHeight;

        let blackBarsPercentage = 0;

        // Adjust and calculate the black bars percentage
        if (contentAspectRatio > screenAspectRatio) {
            // The content is wider in proportion; adjust height
            const adjustedHeight = screenWidth / contentAspectRatio;
            const blackBarsHeight = screenHeight - adjustedHeight;
            blackBarsPercentage = (blackBarsHeight / screenHeight) * 100; // Percentage of black bars in height
        } else if (contentAspectRatio < screenAspectRatio) {
            // The content is taller in proportion; adjust width
            const adjustedWidth = screenHeight * contentAspectRatio;
            const blackBarsWidth = screenWidth - adjustedWidth;
            blackBarsPercentage = (blackBarsWidth / screenWidth) * 100; // Percentage of black bars in width
        } else {
            // Aspect ratios are equal; no black bars
            blackBarsPercentage = 0;
        }

        // Determine if the black bars percentage is within the allowed tolerance
        const isMatch = blackBarsPercentage <= toleranceResolution;

        // Return the result and the absolute difference between aspect ratios
        return [isMatch, Math.abs(contentAspectRatio - screenAspectRatio)];
    }

    function filteredDisplays() {
        const groupedDisplayIds = groupedDisplays.map(display => display.id);
        const customCart = cart.filter((display) => groupedDisplayIds.includes(display.id))
        return getListOfResolutions(deletingContentFromLibrary ?  cart : customCart);
    }

    function getClosestAspectRatio (contentAddedInRelation, listResolution, contentResolution) {
        if(contentAddedInRelation.width === listResolution.resolution_width && contentAddedInRelation.height === listResolution.resolution_height) {
            return contentAddedInRelation;
        }
        if(contentResolution.width === listResolution.resolution_width && contentResolution.height === listResolution.resolution_height) {
            return contentResolution;
        }

        const contentAddedInRelationAspectRatio = contentAddedInRelation.width / contentAddedInRelation.height;
        const listResolutionAspectRatio = listResolution.resolution_width / listResolution.resolution_height;
        const contentResolutionAspectRatio = contentResolution.width / contentResolution.height;

        const differenceWithContentAdded = Math.abs(contentAddedInRelationAspectRatio - listResolutionAspectRatio);
        const differenceWithContentResolution = Math.abs(contentResolutionAspectRatio - listResolutionAspectRatio);

        return differenceWithContentAdded < differenceWithContentResolution ? contentAddedInRelation : contentResolution;
    }

    function getResolucionSubtitle() {
        const isOnlyone = groupedDisplays && groupedDisplays.length === 1;

        const partOne = isOnlyone ? t('for the screen') : t('for screens grouped as');
        const partTwo = isOnlyone ? groupedDisplays[0].name : groupName;

        return `${partOne} ${partTwo}`;
    }

    function getResolucionTitle() {
        const isOnlyone = groupedDisplays && groupedDisplays.length === 1;
        const hasDifferentVersion = listResolution && listResolution[0] && listResolution[0].contents.some(content => content.content_version_name !== "V1");

        const partOne = isOnlyone ? t('Required resolution') : t('Required resolutions');
        const partTwo = hasDifferentVersion && isOnlyone && !filterByContent ? "para estas versiones" : "";


        return `${partOne} ${partTwo}`;
    }

    function filterListOfContentsInCampaign() {
        const resolutionFilter = listContentResolutions[resolutionFilterIndex];

        return listOfContentsInCampaign.filter(content => {
            const matchesResolution = resolutionFilter
                ? content.width === resolutionFilter.width && content.height === resolutionFilter.height
                : true;
            const matchesVersion = versionFilter
                ? content.content_version_name === versionFilter
                : true;
            return matchesResolution && matchesVersion;
        });
    }

    function generateResolutions() {
        const uniqueResolutions = listOfContentsInCampaign.reduce((acc, item) => {
            const exists = acc.some(
                (res) => res.width === item.width && res.height === item.height
            );
            if (!exists) {
                acc.push({ width: item.width, height: item.height });
            }
            return acc;
        }, []);

        setResolutionFilterIndex(null);
        const sortedResolutions = [...uniqueResolutions].sort((a, b) => a.width - b.width);
        return sortedResolutions;
    }

    function pushFormattedContent (contentResolution, listResolution, contentMatch) {
        // Check if aspect ratios match within tolerance
        const [ismatch, difference] = aspectRatiosMatch(contentResolution.width , contentResolution.height, listResolution.resolution_width, listResolution.resolution_height) 

        if (selectedContent && selectedContent.id !== contentResolution.id) return;
        if (!ismatch) return;

        // Do not load all the content because it overloads the browser.
        const content = {
            ...contentResolution,
            id: contentResolution.id,
            file_thumb: contentResolution.file_thumb,
            name: contentResolution.name,
            content_version_name: contentResolution.content_version_name,
            differentResolution: difference ? ismatch : false,
        }
        contentMatch.push(content);
    }

    function matchResolutionContent(contentsInCampaign, listResolution, contentAction ) {
        // Iterate over each content resolution
        let contentMatch = [];
        const listDisplay = listResolution.displays.map(display => display.id);
        contentsInCampaign.forEach(contentResolution => {

            if( contentAction &&
                contentResolution.id != contentAction.id &&
                !listResolution.contents.some(content => content && content.id === contentResolution.id)) {
                return
            }

            const contentAddedInRelation = contentMatch.find(relation => relation.content_version_name === contentResolution.content_version_name);
            if (contentAddedInRelation && contentAddedInRelation.id !== contentResolution.id) {
                if(!(contentAddedInRelation.height === listResolution.resolution_height && contentAddedInRelation.width === listResolution.resolution_width)) {
                    const contentClosest = getClosestAspectRatio(contentAddedInRelation, listResolution, contentResolution)
                    if(contentClosest.id != contentAddedInRelation.id) {
                        remplaceContentForUniqueVersion(contentAddedInRelation.id, listDisplay, contentResolution);
                        contentMatch = contentMatch.filter(contentM => contentM.id !== contentAddedInRelation.id);
                        pushFormattedContent(contentClosest, listResolution, contentMatch)
                    }
                }
            }

            if(!contentAddedInRelation) {
                pushFormattedContent(contentResolution, listResolution, contentMatch)
            }
        });

        // Order by content version
        contentMatch = contentMatch.sort((a, b) => {
            if (a.content_version_name && b.content_version_name) {
                return parseInt(a.content_version_name.slice(1)) - parseInt(b.content_version_name.slice(1));
            }
            return [];
        });
        return contentMatch;
    }

    function searchContentWhenUpdateCampaign(relation) {
        const displayIds = relation.displays.map(display => display.id);
        let listContents = [];

        customContents.forEach(customContent => {
            if (displayIds.includes(customContent.displayId)) {
                if( listContents.find(c => c.id === customContent.content.id)) return;
                listContents.push(customContent.content);
            }
        });
        return listContents;
    }

    // Create the relation depending of customContents
    function createRelation(uniqueResolutionsArray, actionWithRelation, newDisplays) {
        const { displays, contents } = uniqueResolutionsArray;
        let relations = [];

        // This case is when initial campaign
        if (!customContents ||
            customContents.length === 0 ||
            actionWithRelation === "cleanRelations" 
        ) {
            displays.forEach(display => {
                contents.forEach(content => {
                    relations.push({
                        displayId: display.id,
                        contentId: content.id
                    });
                });
            });
        } else{
            displays.forEach(display => {
                if (groupedDisplays) {
                    const groupedDisplayIds = groupedDisplays.map(display => display.id);
                    if (!groupedDisplayIds.includes(display.id)) return;
                }
                let filteredContents = customContents.filter(content => content.displayId === display.id);
                filteredContents.forEach(customContent => {
                    relations.push({
                        displayId: customContent.displayId,
                        contentId: customContent.content.id
                    });
                });

                // When update content in library
                if(actionWithRelation === "updateContent") {
                    const newContents = listOfContentsInCampaign
                    .filter(content => !customContents
                    .some(customContent => customContent.content.id === content.id));
                    if (groupedDisplays && newContents[0]) { //Search all relationships to avoid adding content from previous ones
                        if (filterByContent) {
                            const customContentAux = customContents.map(customContent => {
                                if (customContent.displayId === display.id && customContent.content.id === filterByContent.id) {
                                    return { ...customContent, content: newContents[0]}
                                }
                                return customContent;
                            });
                            updateCustomContents(customContentAux)
                            relations = relations.map(relation => {
                                if (relation.displayId === display.id && relation.contentId === filterByContent.id) {
                                    return { displayId: display.id, contentId: newContents[0].id}
                                }
                                return relation;
                            });
                        }
                    }
                }

                // When add new displays from cart
                if(actionWithRelation === "addDisplays" && newDisplays.find(item => item.id === display.id)) {
                    contents.forEach(content => {
                        relations.push({
                            displayId: display.id,
                            contentId: content.id
                        });
                    });
                }

                if(actionWithRelation === "checkContent") {
                    let newContents;
                    const listDisplay = uniqueResolutionsArray.displays.map(display => display.id);
                    newContents = contents
                    .filter(content => !uniqueResolutionsArray.contentsDisplays
                    .some(contentDisplay => contentDisplay.contentId === content.id && listDisplay.includes(contentDisplay.displayId)));
                    newContents.forEach(content => {
                        relations.push({
                            displayId: display.id,
                            contentId: content.id
                        });
                    });
                }

                if (actionWithRelation === "addDeletedContent" || actionWithRelation === "deletingContentFromLibrary") {
                    let newContents;
                    if (groupedDisplays) { //Search all relationships to avoid adding content from previous ones
                        newContents = contents
                            .filter(content => !customContents
                            .some(customContent => customContent.content.id === content.id));
                    } else {
                        newContents = contents
                            .filter(content => !customContents
                            .some(customContent => customContent.content.id === content.id));
                    }
                    newContents.forEach(content => {
                        relations.push({
                            displayId: display.id,
                            contentId: content.id
                        });
                    });
                }
            });
        }
        return relations;
    };
    const isModifiedListContent = function() {
        const prevContents = prevListOfContents.current;
        prevListOfContents.current = listOfContentsInCampaign;
        const currentIds = listOfContentsInCampaign.map(content => content.id);
        const prevIds = prevContents.map(content => content.id);

        let contentAction = listOfContentsInCampaign.filter(content => !prevIds.includes(content.id));
        contentAction = contentAction.length > 0 ? contentAction : prevContents.filter(content => !currentIds.includes(content.id));
        return contentAction.length > 0 ? contentAction[0] : null;
    };

    function loadViewWithContentsDisplays(actionWithRelation, isAddFromModal) {
        // Search the content the first time when edit campaign
        const isLoaderUpdateView = editCampaignActive && !isCreatedEditCampaign && actionWithRelation !== "cleanRelations"
        // No apply whe is modal [isAddFromModal]
        if ((isLoaderUpdateView || isLoaderView) && !isAddFromModal) return true;
        return false;
    }

    // Add relation and contents that match the resolution
    function addRelations(uniqueResolutionsArray, actionWithRelation, newDisplays=null) {
        let actionForCustomContents = "";
        const uniqueResolutions = uniqueResolutionsArray.map((relation) => {
            let newRelation = { ...relation, contents: [...relation.contents] };
            let hasDisplay = true;
            let contentAction = null;

            if (actionWithRelation !== "cleanRelations") contentAction = isModifiedListContent();

            // Diferent action when add content from modal or when load the view for modal
            const isAddFromModal = groupedDisplays && actionWithRelation === "addDeletedContentFromModal";

            // Search the content the first time when edit campaign
            if ((editCampaignActive &&
                !isCreatedEditCampaign &&
                actionWithRelation !== "cleanRelations") ||
                (groupedDisplays && !isAddFromModal) ) {

                newRelation.contents = searchContentWhenUpdateCampaign(relation);
            } else {
                newRelation.contents = matchResolutionContent(listOfContentsInCampaign, relation, contentAction);
            }

            if (isAddFromModal) actionWithRelation = "addDeletedContent";

            if (newRelation.contents && newRelation.contents.length === relation.contents.length) {
                actionForCustomContents = "updatedCustomContent";
            }

            if (groupedDisplays) {
                const groupedDisplayIds = groupedDisplays.map(display => display.id);
                hasDisplay = relation.displays.some(display => groupedDisplayIds.includes(display.id))
            }

            if (newRelation.contents.length > 0 && hasDisplay) {
                const actionForCreateRelation = loadViewWithContentsDisplays(actionWithRelation, isAddFromModal) && !newDisplays ? "isLoaderUpdateView" : actionWithRelation;
                newRelation.contentsDisplays = createRelation(newRelation, actionForCreateRelation, newDisplays);
            }
            return newRelation;
        });
        return [uniqueResolutions, actionForCustomContents]
    }

    // Search set of resolutions in the added screens
    function getListOfResolutions(customCart, newDisplays=null ) {
        const uniqueResolutions = {};
        customCart.forEach(item => {
            const resolutionKey = `${item.resolution_height}-${item.resolution_width}`;
            // Do not load all the display because it overloads the browser,
            // only the things that use in this view.
            const displayItem = {
                id: item.id,
                fill_screen: item.fill_screen,
                smart_campaign_cpm: item.smart_campaign_cpm,
            }
            if (!uniqueResolutions[resolutionKey]) {
                uniqueResolutions[resolutionKey] = {
                    resolution_height: item.resolution_height,
                    resolution_width: item.resolution_width,
                    countScreen: 1,
                    contents: [],
                    displays: [displayItem],
                    contentsDisplays: [],
                };
            } else {
                uniqueResolutions[resolutionKey].displays.push(displayItem);
                uniqueResolutions[resolutionKey].countScreen++;
            }
        });
        let uniqueResolutionsArray = Object.values(uniqueResolutions); // Convert object to array
        const typeAux = newDisplays ? "addDisplays" : "addDeletedContent";
        uniqueResolutionsArray = addRelations(uniqueResolutionsArray, typeAux, newDisplays)[0];
        return uniqueResolutionsArray;
    }

    const handleContent = (value, content) => {
        let updatedContent = { ...content, name: value };
        updateListOfContentsInCampaign(updatedContent)
        if (editCampaignActive) {
            const updatedlist = listResolutionWithContent.map(relation => {
                const updatedContents = relation.contents.map(item => {
                    if (item.id === content.id) {
                        return { ...item, name: value };
                    }
                    return item;
                });
                return { ...relation, contents: updatedContents };
            });
            setListResolutionWithContent(updatedlist);
        }
    }

    const cleanProgress = () => {
        // Clean the list of contents in the campaign
        cleanListOfContentsInCampaign();
        clearCustomContents();
        showModalCleanContent();
    };

    const showModalDeleteContentRelation = (content) => {
        setContentModal(content)
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalCleanContent = () => {
        setVisibleModalCleanContent(!visibleModalCleanContent);
    };

    const deleteContentOfListRelation = () => {
        setDeletingContentFromLibrary(true);
        deleteContentFromCampaign(contentModal.id);
        setVisibleModalDeleteContentRelation(!visibleModalDeleteContentRelation);
    };

    const showModalContent = (content) => {
        if (!content) return;
        setContentModal(content);
        setVisibleModalContent(true);
    };
    const cancelModalContent = () => {
        setContentModal(null);
        setVisibleModalContent(false);
    };

    const calculateSlidesToShow = () => {
        if (toleranceResolution >= 25) return setSlidesToScroll(1);
        if (toleranceResolution >= 15) return setSlidesToScroll(2);
        return setSlidesToScroll(3)
    }

    const setTolerance = (toleranceResolution) => {
        toleranceResolution = parseInt(toleranceResolution);
        const maxSuperUser = 50;
        const maxUser = 25;
        if (toleranceResolution > maxUser && !isSuperUser) return setToleranceResolution(maxUser);
        if (toleranceResolution > maxSuperUser && isSuperUser) return setToleranceResolution(maxSuperUser);
        if (toleranceResolution < 0 || !toleranceResolution ) return setToleranceResolution(0);
        setToleranceResolution(toleranceResolution);
    }

    const initSlider = () => {
        if (!sliderRef) return
        sliderRef.slickGoTo(0);
    }

    const updateListResolutions = (type) => {
        if (loadingListOfResolutions || listResolutionWithContent.length === 0) return;

        let updatedListResolution = listResolutionWithContent;
        let removedRelations = []
        if(groupedDisplays){
            const displaysId = groupedDisplays.map(display => display.id);
            updatedListResolution = updatedListResolution.filter(relation => relation.displays.some(display => displaysId.includes(display.id)));
            removedRelations = listResolutionWithContent.filter(relation => !relation.displays.some(display => displaysId.includes(display.id))
    );
        }

        updatedListResolution = addRelations(updatedListResolution, type);

        // When not is modal of group displays update redux
        if (!groupedDisplays)
            setListResolutionWithContent(updatedListResolution[0]);

        if(groupedDisplays) {
            const hasDifferentResolutionAux = updatedListResolution[0].some(item => item.contents.some(content => content.differentResolution === true));
            setHasDifferentResolution(hasDifferentResolutionAux);
            const newUpdatedListResolution = [...updatedListResolution[0], ...removedRelations];
            setListResolutionWithContent(newUpdatedListResolution);
            setListResolution(updatedListResolution[0]);
        }

        if( groupedDisplays &&
            (type === "deletingContentFromLibrary" || updatedListResolution[1] != "updatedCustomContent")
        ) {
            applyRelationOnScreens(cart, updatedListResolution[0]);
        }
    };

    const isTheLastOfTheRelationship = (relation, content) => {
        const listDisplaysWithContent = relation.contentsDisplays.filter(item => item.contentId === content.id);
        for (const display of listDisplaysWithContent) {
            if (relation.contentsDisplays.filter(item => item.displayId === display.displayId).length === 1) {
                return true;
            }
        }

        return false;
    };

    const defineListResolution = (listResolutionWithContent) => {
       // Filter the resolutions that have display is necessary by modal of group displays
        const listResolutionAux = groupedDisplays ? filteredDisplays() : listResolutionWithContent;
        const hasDifferentResolutionAux = listResolutionAux.some(item => item.contents.some(content => content.differentResolution === true));

        setListResolution(listResolutionAux);
        setHasDifferentResolution(hasDifferentResolutionAux);
    }

    const listDisplay = groupedDisplays ? groupedDisplays.map(display => display.id) : null;
    const haveContentInRelation = (content) => {
        if ( customContents.find(customContent => customContent.content.id === content.id
             && listDisplay.includes(customContent.displayId ))) {
            return true;
        }
        return false;
    }

    const checkAddInRelation = (content) => {
        let listResolutionWithContentAux = listResolutionWithContent
        const uniqueResolutions = listResolution.map((relation) => {
            let newRelation = { ...relation, contents: [...relation.contents] };
            newRelation.contents = matchResolutionContent(listOfContentsInCampaign, relation, content);
            let hasDisplay = true;

            const groupedDisplayIds = groupedDisplays.map(display => display.id);
            hasDisplay = relation.displays.some(display => groupedDisplayIds.includes(display.id))

            if (newRelation.contents.length > 0 && hasDisplay) {
                newRelation.contentsDisplays = createRelation(newRelation, "checkContent");
            }

            listResolutionWithContentAux = listResolutionWithContentAux.map(item => {
                if (item.resolution_height === newRelation.resolution_height && item.resolution_width === newRelation.resolution_width) {
                    return newRelation;
                }
                return item;
            });

            return newRelation;
        });

        setListResolution(uniqueResolutions)
        setListResolutionWithContent(listResolutionWithContentAux);
        applyRelationOnScreens(groupedDisplays, listResolutionWithContentAux);
    }

    // *** useEffect *** //
    useEffect(() => {
        if (!groupedDisplays) {
            let newDisplays = null;
            if (listResolutionWithContent.length > 0) {
                // Check if add new displays
                newDisplays = cart.filter(display =>
                    !listResolutionWithContent.some(
                        relation => relation.displays.some(item => item.id === display.id)
                    )
                );
            }
            setListResolutionWithContent(getListOfResolutions(cart, newDisplays));
        }
        setLoadingListOfResolutions(false);
        setListResolution([])
    }, []);

    // Define if the view is loaded for the first time
    useEffect(() => {
        const navEntries = performance.getEntriesByType("navigation")[0];

        if (navEntries && navEntries.type === "reload") {
            setIsLoaderView(true);
        }

    }, []);

    useEffect(() => {
        initSlider();
        calculateSlidesToShow();
        updateListResolutions("cleanRelations");
    }, [toleranceResolution])

    useEffect(() => {
        if(!deletingContentFromLibrary){
            if( groupedDisplays && filterByContent) {
                updateListResolutions("updateContent");
            } else {
                let type = "addDeletedContent";
                // When add content from modal
                if(groupedDisplays) {
                    type = "addDeletedContentFromModal"
                }
                updateListResolutions(type);
            }
        }

        //prevListOfContents.current = listOfContentsInCampaign;

    }, [listOfContentsInCampaign]);

    useEffect(() => {
        if(deletingContentFromLibrary)
            updateListResolutions("deletingContentFromLibrary");
            defineListResolution(listResolutionWithContent);
            setDeletingContentFromLibrary(false);
    }, [deletingContentFromLibrary]);

    useEffect(() => {
        if( editCampaignActive && listResolutionWithContent.length === 0 ) return;
        if (!deletingContentFromLibrary) {
            if (groupedDisplays && listResolution ) {
                listResolution.forEach(relation => {
                    listResolutionWithContent.forEach(relationWithContent => {
                        if (
                            relation.resolution_height === relationWithContent.resolution_height &&
                            relation.resolution_width === relationWithContent.resolution_width &&
                            relation.contents.length != relationWithContent.contents.length
                        ) {
                            defineListResolution(listResolutionWithContent);
                        }
                    });
                });
            } else {
                defineListResolution(listResolutionWithContent);
            }
        }

        // When the view is loaded for the first time
        if(editCampaignActive && !isCreatedEditCampaign) setIsCreatedEditCampaign(true);
        if (isLoaderView) setIsLoaderView(false);

    }, [listResolutionWithContent]);

    // TO DO: change content for relation
    // const filteredContents = (relation) => {
    //     return (filterByContent
    //         ? relation.contents.filter((content) => filterByContent.id === content.id)
    //         : relation.contents);
    // }

    return (
        <ContentsOfCampaignHolder withCardInRelation={withCardInRelation} >
            <Row>
                {!groupedDisplays &&
                    <Row type="flex" justify="end" style={{ marginBottom: 20 }}>
                        <Row type="flex" justify="space-between" style={{ gap:20, marginRight: 20 }} >
                            <Col>
                                <Row type="flex" align="middle" style={{ gap:12 }} >
                                    <span className='selectTag'>
                                        {t("Version")}:
                                    </span>
                                    <Select
                                        style={{ width: 150 }}
                                        className='select'
                                        defaultValue={null}
                                        onChange={(value) => setVersionFilter(value)}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value={null}>{t("All")}</Option>
                                        {VersionEnum.map(item => (
                                            <Option key={item.key} value={item.key}>{t(item.name)}</Option>
                                        ))}
                                    </Select>
                                </Row>
                            </Col>
                            <Col>
                                <Row type="flex" align="middle" style={{ gap:12 }} >
                                    <span className='selectTag'>
                                        {t("Resolution")}:
                                    </span>
                                    <Select
                                        style={{ width: 150 }}
                                        className='select'
                                        defaultValue={null}
                                        onChange={(value) => setResolutionFilterIndex(value)}
                                        value={resolutionFilterIndex}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value={null}>{t("All")}</Option>
                                        {listContentResolutions && listContentResolutions.map(( item, index) => (
                                            <Option key={index} value={index}>{`${item.width} x ${item.height}`}</Option>
                                        ))}
                                    </Select>
                                </Row>
                            </Col>
                        </Row>
                        <Col>
                            <Tooltip title={t('Clear all contents added to the campaign, clearing its progress')}>
                                <Button className="secondary buttonContent standard" onClick={() => showModalCleanContent()}>
                                    <Icon type="delete" />
                                    {t('Clear progress')}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                }
                <Col xs={24} sm={24} md={24} lg={24} >
                    <div className="slider-container">
                        {listOfContentsInCampaignFiltered.length === 0 ?
                            <div id="1" className='contentMessage'>
                                <p>{t("You must select a content")}</p>
                            </div>
                            :
                            <Slider {...settingsSlider}>
                                {listOfContentsInCampaignFiltered.length > 0 && listOfContentsInCampaignFiltered.map((content, index) => (
                                    <div key={index} style={{ padding: "0 8px" }}>
                                        <Card className='cardContent'>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    size="small"
                                                    className='inputContentName'
                                                    placeholder={t('Identify your ad...')}
                                                    value={content.name}
                                                    onChange={e => handleContent(e.target.value, content)}
                                                />
                                                <span className={"primaryCircle"} >
                                                    {content.content_version_name || "V1"}
                                                </span>
                                            </div>
                                            <div className='centerCard'>
                                                {<img
                                                    src={convertImage(content.file_thumb)}
                                                    onClick={() => showModalContent(content)}
                                                    className="contentCardImage"
                                                    alt=""
                                                />}
                                            </div>
                                            <div className='contentCardContent'>
                                                <p className="textCard centerCard" > {content.width} x {content.height}</p>
                                                <HideWhenCampaignActive contentId={content.id}>
                                                    {!groupedDisplays && (
                                                        <Tooltip title={t('Remove contents from the campaign')}>
                                                            <Button
                                                                shape="circle"
                                                                className="icon-card cardRed"
                                                                onClick={() => showModalDeleteContentRelation(content)}
                                                            >
                                                                <Icon type="delete" />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                    { (groupedDisplays && !haveContentInRelation(content)) && (
                                                        <Tooltip title={t('Apply ad to available resolutions')}>
                                                            <Button
                                                                shape="circle"
                                                                className="icon-card cardGreen"
                                                                onClick={() => checkAddInRelation(content)}
                                                            >
                                                               <Icon type="plus" />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </HideWhenCampaignActive>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        }
                    </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20}>
                    <div className="ant-collapse-header collapse-resolution" style={{ marginTop: "10px", paddingBottom: "10px !important" }} role="button" tabIndex="0" aria-expanded="true">
                        <Row type="flex" align="middle" style={{ gap: 8 }}>
                            <Col xs={24}>
                                {resolutionTitle}
                            </Col>
                            {groupedDisplays && (
                                <Col xs={24}>
                                    <Row className="ant-collapse-subtitle" type="flex" align="middle" style={{ gap: 8 }}>
                                        {filterByContent ? (
                                            <span>
                                             {t("To replace this content")}
                                            </span>
                                        ) : (
                                            <>
                                                <Icon type="desktop" />
                                                <span>
                                                    {resolutionSubtitle}
                                                </span>
                                            </>
                                        )}
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        {errors && errors["contentsDisplays"] &&
                            <p className="error-message error-message-contents-displays">
                                {t(errors["contentsDisplays"])}
                            </p>
                        }
                    </div>
                </Col>
                {!groupedDisplays && (
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <div className="contentTolerance" style={{ marginTop: '30px' }} role="button" tabIndex="0" aria-expanded="true">
                            <p style={{ marginRight: "5px" }}>{t("Tolerance")}:</p>
                            <InputNumber
                                style={{ width: 70 }}
                                value={toleranceResolution}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace('%', '')}
                                onChange={setTolerance}
                                min={( editCampaignActive && isPaymentStatusApproved ) ? toleranceResolutionInit : 0 }
                                max={isSuperUser ? 50 : 25}
                            />
                            <Tooltip
                                placement="right"
                                title={t('Info tolerance')}
                            >
                                <Icon className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                            </Tooltip>
                        </div>
                    </Col>
                )}
                {hasDifferentResolution && (
                    <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: "20px" }} className="appear-animation">
                        <Row type="flex" style={{ gap: "10px", alignItems: "center" }}>
                            <Icon type="warning" theme="outlined" style={{ color: "#FAAD14" }} />
                            <Text style={{ color: "#888888", fontSize: "17px", fontStyle: "italic" }}>
                                {t("There are ads that do not adapt correctly to the resolutions. You can continue or select ads from closer resolutions.")}
                            </Text>
                        </Row>
                    </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="slider-container required-resolution">
                        <Slider
                            ref={slider => { sliderRef = slider }}
                            {...settingsSlider}
                            slidesToScroll={slidesToScroll}
                            afterChange={(current) => setCurrentStick(current)}
                        >
                            {listResolution && listResolution.map((relation, index) => (
                                <div key={index} style={{ padding: "0 8px", position: "relative" }}>
                                    {(relation.contentsDisplays.length > 0 && relation.contents.length) ? (
                                        <div className="contentRelation" style={{ width: `${withCardInRelation + (relation.contents.length * 40)}px` }}>
                                            <Card>
                                                {/* { filteredContents(relation).map((content, i) => ( */}
                                                { relation.contents.map((content, i) => (
                                                    <RequiredResolutionsCard
                                                        key={i}
                                                        customContents={customContents}
                                                        removeRelationFromDisplay={removeRelationFromDisplay}
                                                        setListResolutionWithContent={setListResolutionWithContent}
                                                        listResolutionWithContent={listResolutionWithContent}
                                                        loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                                        className="multi"
                                                        style={{ left: `${i * 40}px`, zIndex: (relation.contents.length - i) }}
                                                        relation={relation}
                                                        content={content}
                                                        showModalDelete={showModalDeleteContentRelation}
                                                        isWarning={content.differentResolution}
                                                        removeRelationsOnCampaign={removeRelationsOnCampaign}
                                                        onClickImage={() => showModalContent(content)}
                                                        isPaymentStatusApproved={isPaymentStatusApproved}
                                                        diseabledDeleteButton={ groupedDisplays ? isTheLastOfTheRelationship(relation, content) : false}
                                                        setListOfContentsInCampaign={setListOfContentsInCampaign}
                                                        listOfContentsInCampaign={listOfContentsInCampaign}
                                                        updateImpressionsDisplay= {updateImpressionsDisplay}
                                                        groupedDisplays={groupedDisplays}
                                                    />
                                                ))}
                                            </Card>
                                        </div>
                                    ) : (
                                        <RequiredResolutionsCard
                                            loading={index < (currentStick - 8) || index > (currentStick + 16)}
                                            relation={relation}
                                            showModalDelete={showModalDeleteContentRelation}
                                            removeRelationsOnCampaign={removeRelationsOnCampaign}
                                            onClickImage={() => showModalContent(relation)}
                                            isPaymentStatusApproved={isPaymentStatusApproved}
                                        />
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </Col>
            </Row>
            <ModalContentComponent
                visible={visibleModalContent}
                onCancel={cancelModalContent}
                content={contentModal}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to remove this content from the campaign')}
                visible={visibleModalDeleteContentRelation}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => deleteContentOfListRelation()}
                onCancel={() => showModalDeleteContentRelation()}
                okButtonProps={{ loading: true }}
                width={600}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to delete all progress so far? It will remove the loaded ads and created relationships.')}
                visible={visibleModalCleanContent}
                okText={t('OK')}
                cancelText={t('Cancel')}
                onOk={() => cleanProgress()}
                onCancel={() => showModalCleanContent()}
                okButtonProps={{ loading: true }}
            />
        </ContentsOfCampaignHolder>
    )
}

export default ContentsOfCampaignComponent;