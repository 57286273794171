import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Card, Tooltip, Button, Icon, Pagination, Upload, message, Input } from "antd";
import OptionModal from "./OptionModal";
import ContentsLibraryHolder from "./Styles/contentsLibraryStyle"
import { useTranslation } from 'react-i18next';
import api from '../services/apiInstance';
import { incrementVersion, truncateText } from '../services/utils';
import ModalContentComponent from './ModalContentComponent';
import { uploadContent, convertImage } from '../services/contents';
import { makeApiCall } from '../services/apiErrorHandlers';
import GenerateSkeletonComponent from './GenerateSkeletonComponent';
import useBreakpoint from "../hooks/useBreackpoint";
import VersionEnum from '../constants/VersionEnum';
import canvaLogo from "../images/canva-icon.png";
import CanvaContainer from "../containers/CanvaContainer";
import SearchInput from "./SearchInput";

const defaultTag = { id: null, key: null, label: "Todos", name: "Todos" }
const { Option } = Select;

const ContentsLibraryComponent = (props) => {
    const { t } = useTranslation();
    const { user,
        getContentsByCompany,
        setLibraryContents,
        libraryContents,
        loadingContentsbyCompany,
        token,
        setListOfContentsInCampaign,
        listOfContentsInCampaign,
        updateDefaultContentRequest,
        defaultContent,
        brand,
        customContents,
        groupedDisplays, // When use the modal to update the relation
        contentSelected, // When change content in relation
        remplaceContentFromCampaign,
        fileCanvaNew,
    } = props;

    const [pageContents, setPageCampaigns] = useState(1);
    const [totalContent, setTotalContent] = useState(0);
    const [lengthPage, setLengthPage] = useState(7);
    const [tagsContents, setTagasContents] = useState([]); // List of folders
    const [selectTagContent, setSelectTagContent] = useState(null); //Save the selected folder
    const [selectVersion, setSelectVersion] = useState("V1"); //Save the selected version

    const [visibleModalContent, setVisibleModalContent] = useState(false);
    const [contentModal, setContentModal] = useState(null); // Save the content to show in all modal (Delete and info View)
    const [visibleModalDeleteContent, setVisibleModalDeleteContent] = useState(false);
    const [statusNewContent, setStatusNewContent] = useState(null);
    const [loading, setLoading] = useState(false); // Loading for upload content
    const breakpoint = useBreakpoint();
    const [contentToReplace, setContentToReplace] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateVersionOpen, setIsModalCreateVersionOpen] = useState(false);
    const [visibleCanvaModal, setVisibleCanvaModal] = useState(false);
    const [search, setSearch] = useState("");

    // Upload content
    const uploadProps = uploadContent(t, message, token, setStatusNewContent, setLoading, selectTagContent);
    const uploadButton = (
        <>
            <div className='cardUploadContent'>
                <Button shape="circle" className="icon-card cardUpload">
                    <Icon type={loading ? 'loading' : 'cloud-upload'} />
                </Button>
            </div>
            <p className="textCard centerCard" >{t("Upload")}</p>
        </>
    );

    const checkReadyContent = (id) => {
        updateDefaultContentRequest(id);
    };

    const changePageContents = (pageContents) => {
        setPageCampaigns(pageContents);
        getLibraryContents(pageContents, selectTagContent);
    }

    const onChangeFolder = (value) => {
        value.key = value.key === "null" ? null : value.key;
        setSelectTagContent(value)
    }

    const onChangeVersion = (value) => {
        setSelectVersion(value)
    }

    const onSearch = (e) => {
        const value = e.target.value;

        setPageCampaigns(1);
        setSearch(value)
    }

    const getSearchParams = () => {
        let width = "";
        let height = "";

        const regex = /(\d+)\s*x\s*(\d+)/;
        const match = search.match(regex);

        if (match) {
            width = match[1];
            height = match[2];
        }

        return {
            search: {
                value: !match ? search : null,
                regex: false
            },
            columns: [
                {
                    data: !match ? "name" : null,
                    searchable: true,
                    orderable: false,
                    search: {
                        value: "",
                        regex: false
                    }
                },
                {
                    data: "width",
                    searchable: true,
                    orderable: false,
                    search: {
                        value: match ? width : "",
                        regex: false
                    }
                },
                {
                    data: "height",
                    searchable: true,
                    orderable: false,
                    search: {
                        value: match ? height : "",
                        regex: false
                    }
                }
            ]
        };
    }

    // Get the contents of the company
    const getLibraryContents = (page, tagFolder = null) => {
        if (tagsContents.length == 0) return;

        let tag = tagFolder ? tagFolder.key : selectTagContent.key;
        let startPage = (page - 1) * lengthPage;
        let params = {
            start: startPage,
            length: lengthPage,
            page: page,
            draw: 1,
            datatable: true,
            origin: "dsp",
        }

        if (tag) params.tag = tag;
        if (search) params = {
            ...params,
            ...getSearchParams()
        };

        getContentsByCompany(user.company.id, params);
    }

    // Get the tags of the company (Folders)
    const fetchTagsContent = async () => {
        try {
            const response = await makeApiCall(api.getTagsContents, user.company.id);
            setTagasContents(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error(error.message);
        }
    };

    const updateContent = async () => {
        try {
            let params = { archived: true }
            const response = await makeApiCall(api.updateContent, contentModal.id, params)
            setVisibleModalDeleteContent(false);
            getLibraryContents(pageContents, selectTagContent);
        } catch (error) {
            console.error('Error updateContent:', error.message);
            message.error(error.message);
            setVisibleModalDeleteContent(false);
        }
    };

    // Show modal with the content information
    const showModalContent = (content) => {
        if (!content) return;
        setContentModal(content);
        setVisibleModalContent(true);
    };
    const cancelModalContent = () => {
        setContentModal(null);
        setVisibleModalContent(false);
    };

    const showModalDeleteContent = (value = false, content) => {
        setContentModal(content);
        setVisibleModalDeleteContent(value);
    };

    const existContentOtherScreens = (existingContent) => {
        if (!groupedDisplays) return false;

        const existingDisplayIds = customContents
            .filter(customContent => customContent.content.id === existingContent.id)
            .map(customContent => customContent.displayId);

        const allDisplaysMatch = existingDisplayIds.every(displayId => 
            groupedDisplays.some(groupedDisplay => groupedDisplay.id === displayId)
        );

        if (allDisplaysMatch) return false;

        return true;
    };

    const addContents = (content) => {
        const contentCopy = { ...content, content_version_name: selectVersion };

        if(contentSelected) {
            setContentToReplace(contentCopy);
            const isExistingContentOtherScreens = existContentOtherScreens(contentSelected);
            if (isExistingContentOtherScreens) return setIsModalCreateVersionOpen(true);
        }

        const isExistingContent = listOfContentsInCampaign.find(
            item => item.width === contentCopy.width &&
                item.height === contentCopy.height &&
                item.content_version_name === contentCopy.content_version_name
        );

        if (isExistingContent) {
            setContentToReplace(contentCopy);
            const isExistingContentOtherScreens = existContentOtherScreens(isExistingContent);
            if (isExistingContentOtherScreens) return setIsModalCreateVersionOpen(true);
            return setIsModalOpen(true);
        }

        setListOfContentsInCampaign([...listOfContentsInCampaign, contentCopy]);
    };

    // Remplace version when exist this version for the same resolution
    const replaceVersion = () => {
        let replacedContent = null;
        const listContent = listOfContentsInCampaign.map((content) => {
            if (contentSelected && content.id === contentSelected.id) {
                replacedContent = content;
                return contentToReplace;
            }

            if (
                ( content.width === contentToReplace.width &&
                  content.height === contentToReplace.height &&
                  content.content_version_name === contentToReplace.content_version_name)
            ) {
                replacedContent = content;
                return contentToReplace;
            }
            return content;
        });

        remplaceContentFromCampaign(replacedContent.id, contentToReplace);
        setListOfContentsInCampaign(listContent);
        setIsModalOpen(false);
    };

    const createVersion = () => {
        const listContent = [
            ...listOfContentsInCampaign,
            {
                ...contentToReplace,
                content_version_name: incrementVersion(listOfContentsInCampaign, contentToReplace),
            }
        ];
        setListOfContentsInCampaign(listContent);
        setIsModalCreateVersionOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setContentToReplace(null);
    };

    const handleModalCreateVersionCancel = () => {
        setIsModalCreateVersionOpen(false);
        setContentToReplace(null);
    };

    const checkContentsAdded = (id) => {
        return listOfContentsInCampaign.some(content => content.id === id);
    };

    const listFolder = () => {
        const folders = [...tagsContents];
        folders.unshift({ id: null, key: null, name: "Todos" });
        return folders.map(folder => (
            <Select.Option key={folder.id}>
                {folder.name}
            </Select.Option>
        ));
    };

    const calculateLengthPage = () => {
        if (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md") return setLengthPage(2);
        if (breakpoint === "lg") return setLengthPage(3);
        if (breakpoint === "xl") return setLengthPage(5);

        return setLengthPage(7);
    };

    const setReadyContent = () => {
        const copyStatusNewContent = {
            ...statusNewContent,
            fileList: [...statusNewContent.fileList.slice(1)],
        };
        if (statusNewContent.fileList.length === 1) {
            const copyLibraryContents = {
                ...libraryContents,
                data: libraryContents.data ? [...libraryContents.data] : []
            };

            copyLibraryContents.data[0] = defaultContent;
            setLibraryContents(copyLibraryContents);
        }

        setStatusNewContent(copyStatusNewContent);
        setLoading(false);
    };

    const isOwnerDefaultContent = (selectTagContent) => {
        const companyOwnerOfDefaultContent = 757; // Agency LatinAd
        if ( selectTagContent &&
             selectTagContent.key === "1" &&
             user.company.id != companyOwnerOfDefaultContent ) return false

        return true;
    }
    // *** useEffect *** //
    useEffect(() => {
        setLibraryContents([]);
        fetchTagsContent();
    }, []);

    useEffect(() => {
        let brandTags = defaultTag;

        if (tagsContents && tagsContents.length > 0 && brand) {
            brandTags = tagsContents.find(tag => tag.brand_id === brand.id) || defaultTag;
        }

        setSelectTagContent({ key: brandTags.id, label: brandTags.name });
    }, [tagsContents, brand]);

    useEffect(() => {
        listFolder();
        getLibraryContents(1);
    }, [selectTagContent]);

    useEffect(() => {
        getLibraryContents(pageContents, selectTagContent);
    }, [search]);

    useEffect(() => {
        if (!lengthPage) return;

        getLibraryContents(pageContents, selectTagContent);
    }, [lengthPage]);

    useEffect(() => {
        if (!libraryContents || !libraryContents.data) return;
        if (search) return setTotalContent(libraryContents.recordsFiltered);

         setTotalContent(libraryContents.recordsTotal);
    }, [libraryContents]);

    // When the content is uploaded, the list of contents is updated
    useEffect(() => {
        // TO DO: Setting tag by default, is same brand
        if (!statusNewContent || !statusNewContent.fileList || !statusNewContent.fileList.length) return;

        getLibraryContents(1, selectTagContent);
        checkReadyContent(statusNewContent.fileList[0].response.id);
    }, [statusNewContent]);

    useEffect(() => {
        if (!fileCanvaNew || !fileCanvaNew.resp || !fileCanvaNew.resp.data) return;

        getLibraryContents(1, selectTagContent);
        checkReadyContent(fileCanvaNew.resp.data.id);
    }, [fileCanvaNew]);

    useEffect(() => {
        if (!statusNewContent || !statusNewContent.fileList || !statusNewContent.fileList.length) return;
        if (defaultContent.type !== "video") return;

        const id = statusNewContent.fileList[0].response.id;
        if (defaultContent.id === id && defaultContent.file_thumb !== "pending") return setReadyContent();

        checkReadyContent(id);
        setLoading(true);
    }, [defaultContent]);

    useEffect(() => {
        if (!breakpoint) return;

        calculateLengthPage();
    }, [breakpoint]);

    return (
        <ContentsLibraryHolder lengthCard={!loadingContentsbyCompany && libraryContents.data && libraryContents.data.length === 0 ? 300 : 125}>
            <Row type="flex" className="contents-library" gutter={40}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Row type="flex" style={{ gap: 20, flexWrap: "nowrap" }} justify="space-between">
                        <Row type="flex" style={{ columnGap: 20 }} align="middle">
                            <Col>
                                <span className='selectTag'>
                                    {t("View contents from the folder")}:
                                </span>
                                <Select
                                    style={{ width: 200 }}
                                    className='select'
                                    placeholder={t("Select a folder")}
                                    optionFilterProp="children"
                                    onChange={onChangeFolder}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={!selectTagContent}
                                    value={selectTagContent || defaultTag}
                                    labelInValue
                                >
                                    {listFolder()}
                                </Select>
                            </Col>
                            <Col>
                                <span className='selectTag'>
                                    {t("Version")}:
                                </span>
                                <Select
                                    style={{ width: 150 }}
                                    className='select'
                                    showSearch
                                    defaultValue="V1"
                                    onChange={onChangeVersion}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {VersionEnum.map(item => (
                                        <Option key={item.key} value={item.key}>{t(item.name)}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row type="flex" style={{ columnGap: 20 }} align="middle">
                            <Col lg={12}>
                                <SearchInput
                                    type="async"
                                    onChange={onSearch}
                                />
                            </Col>
                            <Button
                                className="canvaButton"
                                disabled={!selectTagContent || !selectTagContent.key}
                                onClick={() => setVisibleCanvaModal(true)}
                            >
                                <img
                                    src={canvaLogo}
                                    style={{ height: "25px", marginRight: "6px" }}
                                    alt=""
                                />{" "}
                                {t('Design spot')}
                            </Button>
                        </Row>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='libCard'>
                    <Row
                        className="contentCard"
                        type="flex"
                        justify="center"
                    >
                        {!libraryContents.data || !selectTagContent ? <GenerateSkeletonComponent count={lengthPage} height={218.3} width={141.3} /> :
                            libraryContents && libraryContents.data.map((content, index) => (
                                <Card className='cardContent' key={index}>
                                    <span className="textCard">
                                        {content.name ? (
                                            <Tooltip placement="top" title={content.name}>
                                                {truncateText(content.name, 13)}
                                            </Tooltip>
                                        ) : <>&nbsp;</>}      
                                    </span>
                                    <div className='centerCard'>
                                        {content.file_thumb !== "pending" ? (
                                            <img
                                                src={convertImage(content.file_thumb)}
                                                onClick={() => showModalContent(content)}
                                                className="contentCardImage"
                                                alt=""
                                            />
                                        ) : (
                                            <GenerateSkeletonComponent
                                                count={1}
                                                height={70}
                                                width={115}
                                            />
                                        )}
                                    </div>
                                    <p className="textCard centerCard" > {content.width} x {content.height}</p>
                                    <div className='contentCardContent'>
                                        {!checkContentsAdded(content.id) ?
                                            <Tooltip title={t('Add content to the campaign')}>
                                                <Button
                                                    shape="circle"
                                                    disabled={content.file_thumb === "pending"}
                                                    className="icon-card cardGreen"
                                                    onClick={() => addContents(content)}
                                                >
                                                <Icon type="plus" />
                                                </Button>
                                            </Tooltip> :
                                            <Tooltip title={t("Content added in campaign list")}>
                                                <div className="circle-div cardGreen">
                                                    <Icon type="check" style={{ marginLeft: "8px", marginTop: "8px" }} />
                                                </div>
                                            </Tooltip>
                                        }
                                        <Tooltip title={t('Content information')}>
                                            <Button
                                                shape="circle"
                                                style={{ marginLeft: "6px", marginRight: "6px" }}
                                                className="icon-card cardBlue"
                                                onClick={() => showModalContent(content)}>
                                                {content.type === "image" && <Icon type="file-image" />}
                                                {content.type === "video" && <Icon type="video-camera" />}
                                                {(content.type != "video" && content.type != "image") && <Icon type="eye" />}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={t('Remove content from the library')}>
                                            <Button
                                            shape="circle"
                                            className="icon-card cardRed"
                                            onClick={() => showModalDeleteContent(true, content)}
                                            >
                                            <Icon type="delete" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </Card>
                            ))
                        }
                        { isOwnerDefaultContent(selectTagContent) &&
                            <div
                                id="1"
                                className={`contentVideoUpload
                                ${  !selectTagContent || !selectTagContent.key ?
                                    'disabledVideoUpload' : ''}`}>
                                <Upload
                                    {...uploadProps}
                                    disabled={loading}
                                >
                                    {uploadButton}
                                    {(!selectTagContent || !selectTagContent.key) &&
                                        <p className="msgAlertLib">{t("Select a folde")}</p>
                                    }
                                </Upload>
                            </div>
                        }
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="pagination">
                    <Pagination
                        current={pageContents}
                        pageSize={lengthPage}
                        total={totalContent}
                        onChange={pageContents => {
                            changePageContents(pageContents);
                        }}
                    />
                </Col>
            </Row>
            {/* Modal to show information of the content */}
            <ModalContentComponent
                visible={visibleModalContent}
                onCancel={cancelModalContent}
                content={contentModal}
            />
            <CanvaContainer
                visible={visibleCanvaModal}
                tag={selectTagContent}
                onClose={() => setVisibleCanvaModal(false)}
            />
            <OptionModal
                title={t('Atention!')}
                body={t('Are you sure you want to delete this content?')}
                visible={visibleModalDeleteContent}
                okText={t('Delete')}
                cancelText={t('Cancel')}
                onOk={updateContent}
                onCancel={() => showModalDeleteContent(false)}
                okButtonProps={{ loading: true }}
            />
            <OptionModal
                title={t("Remplace version")}
                body={t('A version with that resolution already exists, you want to replace the content')}
                visible={isModalOpen}
                okText={t('Remplace')}
                cancelText={t('Cancel')}
                onOk={replaceVersion}
                onCancel={handleCancel}
            />
            <OptionModal
                title={t("Create another version")}
                body={t('A version with that resolution already exists on another screen, a new version will be created to avoid replacing the one currently in use.')}
                visible={isModalCreateVersionOpen}
                okText={t('Create')}
                cancelText={t('Cancel')}
                onOk={createVersion}
                onCancel={handleModalCreateVersionCancel}
            />
        </ContentsLibraryHolder>
    )
}

export default ContentsLibraryComponent;