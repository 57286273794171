import styled from "styled-components";
import Colors from "../../themes/Colors";

const FiltersHolder = styled.div`
  background-color: ${Colors.snow};
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
  position: relative;
  z-index: 100;
  height: 44px;
  @media only screen and (min-width: 1100px) {
    height: 77px;
  }

  .ant-affix {
    z-index: 99;
  }

  .ant-collapse-header {
    @media only screen and (min-width: 1100px) {
      display: none;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: ${Colors.primary};
    color: ${Colors.snow};
    font-weight: 600;
    font-size: 18px;
  }
  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    line-height: 0;
  }

  .companySelect {
    width: 200px !important;
    margin: 0 0 15px;
  }
  .brandSelect {
    width: 200px !important;
    margin: 0 0 15px;
  }
  .ant-select-selection--single {
    height: 39px;
    border-radius: 10px;
  }
  .ant-select-selection__rendered {
    line-height: 35px;
  }
  .ant-select-selection:hover {
    border-color: ${Colors.primary};
  }

  .date {
    cursor: pointer;
    margin: 0 0 10px;
    color: ${Colors.secondary};
    @media only screen and (min-width: 992px) {
      margin: 0;
    }
  }
  .date.final-date {
    cursor: default;
  }
  .date p {
    color: ${Colors.primary};
  }
  .option {
    margin: 0 0 10px;
    color: ${Colors.coal};
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      margin: 0;
    }
  }
  .option p {
    color: ${Colors.coal};
  }

  p {
    margin: 0;
  }
  .filterButton {
    margin: 0 5px;
    padding: 2px 5px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 900px) {
      justify-content: center;
    }
  }
  .filterButton:hover {
    background-color: #f2f2f2;
  }
  .filterButton.ant-popover-open {
    background-color: #f2f2f2;
  }
  .filterButtonDates {
    display: flex;
    align-items: center;
  }
  .filterButtonDate {
    margin-right: 15px;
  }
  .filterCurrency span{
    font-size: 11px !important;
  }
`;

export default FiltersHolder;
