import { connect } from 'react-redux'
import ScreenCardComponent from '../components/ScreenCardComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ display, userAccount}) => ({
  user: userAccount.user,
  cart: display.cart,
  clients: display.clients,
  client: display.client,
  programmatic: display.programmatic,
  date_from: display.date_from,
  date_to: display.date_to,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  currentCampaign: display.currentCampaign,
  listBundle: display.listBundle,
  audienceHoursOfDay: display.audienceHoursOfDay,
  audienceDaysOfWeek: display.audienceDaysOfWeek,
})

const mapStateToDispatch = (dispatch) => ({
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  removeBundlesFromCart: (displayId, listBundle) => dispatch(DisplayActions.removeBundlesFromCart(displayId, listBundle)),
  getAudienceHoursOfDayRequest: (params) => dispatch(DisplayActions.getAudienceHoursOfDayRequest(params)),
  getAudienceDaysOfWeekRequest: (params) => dispatch(DisplayActions.getAudienceDaysOfWeekRequest(params)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreenCardComponent)
