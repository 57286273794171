import styled from 'styled-components'
import Colors from '../../themes/Colors'

const AppHolder = styled.div`
  background-color: ${Colors.background};  

  .ant-btn-primary{
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }
`;

export default AppHolder;