const chartsEnum = {
  audienceMap: process.env.REACT_APP_AUDIENCE_MAP,
  audienceWithRules: process.env.REACT_APP_AUDIENCE_WITH_RULES,
  audienceQuantitative: process.env.REACT_APP_AUDIENCE_QUANTITATIVE,
  audienceGreneral: process.env.REACT_APP_AUDIENCE_GENERAL,
  audienceGreneralPerday: process.env.REACT_APP_AUDIENCE_GENERAL_PER_DAY,

  audienceMapRefined: process.env.REACT_APP_AUDIENCE_MAP_REFINED,
  audienceWithRulesRefined: process.env.REACT_APP_AUDIENCE_WITH_RULES_REFINED,
  audienceQuantitativeRefined: process.env.REACT_APP_AUDIENCE_QUANTITATIVE_REFINED,
  audienceGreneralRefined: process.env.REACT_APP_AUDIENCE_GENERAL_REFINED,
  audienceGreneralPerdayRefined: process.env.REACT_APP_AUDIENCE_GENERAL_PER_DAY_REFINED
}
export default chartsEnum;
