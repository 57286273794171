import { connect } from 'react-redux'
import CardGeneralComponent from '../components/CardGeneralComponent'
import DisplayActions from '../redux/DisplayRedux'
import { Card } from 'antd'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  currentCampaign: display.currentCampaign,
  editCampaignActive: display.editCampaignActive,
  date_from: display.date_from,
  date_to: display.date_to,
  endDate: display.endDate,
  cart: display.cart,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  currency: display.currency,
  invoiceIssuingCountry: display.invoiceIssuingCountry,
})

const mapStateToDispatch = (dispatch) => ({
    setDates: (from, to) => dispatch(DisplayActions.setDates(from, to)),
})

export default connect(mapStateToProps, mapStateToDispatch)(CardGeneralComponent)