const countriesEnum = [
  { "key": "AR", "name": "Argentina" },
  { "key": "CL", "name": "Chile" },
  { "key": "UY", "name": "Uruguay" },
  { "key": "PY", "name": "Paraguay" },
  { "key": "PE", "name": "Perú" },
  { "key": "EC", "name": "Ecuador" },
  { "key": "BR", "name": "Brasil" },
  { "key": "CO", "name": "Colombia" },
  { "key": "PA", "name": "Panamá" },
  { "key": "CR",  "name": "Costa Rica" },
  { "key": "SV", "name": "El Salvador" },
  { "key": "HN", "name": "Honduras" },
  { "key": "GT", "name": "Guatemala" },
  { "key": "DO", "name": "República Dominicana" },
  { "key": "US", "name": "Estados Unidos" },
  { "key": "MX", "name": "México" },
  { "key": "NI", "name": "Nicaragua" }
]

export default countriesEnum;
