import React, { Component } from "react";
import ScreenCardHolder from "./Styles/screenCardStyle";
import { Button, Icon, Tooltip, Modal, Row, Col, Divider, Badge} from "antd";
import Slider from "react-slick";
import Dotdotdot from "react-dotdotdot";
import { withTranslation } from "react-i18next";
import NumberFormat from "./NumberFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
import ScreenContainer from "../containers/ScreenContainer";
import { Provider } from "react-redux";
import { store } from "../redux";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import defaultImage from "../images/bg.jpg";
import { BundleSvg } from "../images/customIcons/BundleSvg";
import TagsDiscount from "./TagsDiscount"
import { getRefinedAudience , getIconType, belongsToBundle, checkIfBundle} from "../services/display";
import RequestSpecialCpmContainer from "../containers/RequestSpecialCpmContainer";
import RequestSpecialPriceContainer from "../containers/RequestSpecialPriceContainer";
import DeleteBundleModalComponent from "./DeleteBundleModalComponent";
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import VerifiedAgencyInfoModal from "./VerifiedAgencyInfoModal";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "8%", top: "35%" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "8%",
        top: "35%",
        zIndex: "1",
      }}
      onClick={onClick}
    />
  );
}

class ScreenCardComponent extends Component {
  getSizeType(type) {
    if (type === "small") {
      return this.props.t("Small");
    }
    if (type === "medium") {
      return this.props.t("Medium");
    }
    if (type === "big") {
      return this.props.t("Big");
    }
    if (type === "giant") {
      return this.props.t("Giant");
    }
  }

  state = {
    visibleCamTrafficView: false,
    visibleCamScreenView: false,
    visibleAudienceView: false,
    visibleWarningNoAutomateView: false,
    showScreenDetail: false,
    cam_traffic: null,
    cam_display: null,
    displaysAudience: [],
    isVerifiedAgencyModalVisible: false,
    deleteModalVisible: false,
  };

  setCamlyticsTokens() {
    // Set live view cam and traffic
    var baseLiveViewUrl = "https://cloud.camlytics.com/widget/live_view/";
    //var baseReportUrl = "https://cloud.camlytics.com/widget/report/";
    if (this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
      if (camlyticsTokens.cam_traffic) {
        this.setState({
          cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic,
        });
      }
      if (camlyticsTokens.cam_display) {
        this.setState({
          cam_display: baseLiveViewUrl + camlyticsTokens.cam_display,
        });
      }
    }
  }

  showCamTrafficModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({
        visibleCamTrafficView: true,
      });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okCamTrafficModal = (e) => {
    this.setState({ visibleCamTrafficView: false });
  };

  cancelCamTrafficModal = (e) => {
    this.setState({ visibleCamTrafficView: false });
  };
  handleDeleteModal = () => {
    this.setState({
      deleteModalVisible: !this.state.deleteModalVisible,
    });
  };
  showCamScreenModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({
        visibleCamScreenView: true,
      });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okCamScreenModal = (e) => {
    this.setState({ visibleCamScreenView: false });
  };

  cancelCamScreenModal = (e) => {
    this.setState({ visibleCamScreenView: false });
  };

  showAudienceModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({ visibleAudienceView: true });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okAudienceModal = (e) => {
    this.setState({ visibleAudienceView: false });
  };

  cancelAudienceModal = (e) => {
    this.setState({ visibleAudienceView: false });
  };

  showWarningNoAutomateModal = () => {
    this.setState({ visibleWarningNoAutomateView: true });
  };

  okWarningNoAutomateModal = (e) => {
    this.setState({ visibleWarningNoAutomateView: false });
  };

  cancelWarningNoAutomateModal = (e) => {
    this.setState({ visibleWarningNoAutomateView: false });
  };

  handleShowScreenDetail = (defaultTabScreenDetail) => {
    this.setState((prevState) => ({
      showScreenDetail: !prevState.showScreenDetail,
    }))
    if (defaultTabScreenDetail != "close"){
      this.setState(() => ({
        defaultTabScreenDetail: defaultTabScreenDetail,
      }));
    }
  };

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  screenOwnerProviderId = 2;
  getAudienceProviderName = (provider) => {
    return provider.id === this.screenOwnerProviderId ? this.props.t('display owner') : provider.stylized_name;
  };

  render() {
    const { t, user, display, editCampaignActive, currentCampaign, cart , listBundle, removeBundlesFromCart, getAudienceHoursOfDayRequest, getAudienceDaysOfWeekRequest} = this.props;
    const { showScreenDetail , defaultTabScreenDetail, deleteModalVisible} = this.state;
    const isAgencyUser = user && user.company && user.company.type == "agency";
    const isVerifiedAgency =  isAgencyUser && user.company.is_verified;

    var matchBundle = belongsToBundle(display, listBundle);

    let countBundlesAdded = checkIfBundle (display.bundles, listBundle)

    if (this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
    }
    let onCart = this.props.cart.find((d) => d.id === this.props.display.id);

    // Modal width
    const modalWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth > 992 ? 900 : window.innerWidth;

    var settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <ScreenCardHolder>
        <div className="screenCard">
          <Row type="flex">
            <Col span={12}>
              <Slider {...settings}>
                {display.pictures.length === 0 ? (
                  <img
                    src={defaultImage}
                    alt=""
                    className="screenCardImage"
                  />
                ) : (
                  this.props.display.pictures &&
                  this.props.display.pictures.map((p, index) => (
                    <img
                      key="{index}"
                      src={
                        p.url
                          .split(".")
                          .slice(0, -1)
                          .join(".") + "-thumb.jpg"
                      }
                      alt=""
                      className="screenCardImage"
                    />
                  ))
                )}
              </Slider>
            </Col>
            <Col span={12}>
              <div className="screenCardContent">

                <div className="cardTitle" style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip
                    placement="top"
                    title={
                      user &&
                        hasAccessRole(user.roles, [
                          UserRoleEnum.superModeratorAgency,
                          UserRoleEnum.superModeratorMediaCompany,
                        ])
                        ? " (" +
                        this.props.display.name + ' - ' +
                        this.props.display.id +
                        ")"
                        : " (" + this.props.display.name + ")"
                    }
                  >
                    <span>
                      <Dotdotdot clamp={1}>{this.props.display.name}</Dotdotdot>
                    </span>
                  </Tooltip>
                  {this.props.display.audience_provider_id && user && user.company && user.company.see_refined_audience == 1 &&
                    <Tooltip
                      placement="top"
                      title={t("Audience data for this screen provided by") + " " + this.getAudienceProviderName(this.props.display.audience_provider)}
                    >
                      <Icon
                        style={{ color: "#faad14", paddingLeft: "5px", fontSize: "large" }}
                        type="star" >
                      </Icon>
                    </Tooltip>
                  }
                </div>

                <div className="screenCardPrice">
                  <Tooltip placement="top" title={t("Final prices may contain platform commissions and may vary depending on the selected client")} >
                    <NumberFormat
                      value={
                        this.props.programmatic === "programmatic"
                          ? (this.props.display.smart_campaign_cpm / 1000) *
                          100
                          / 100
                          : this.props.display.price_per_day
                      }
                      currency={this.props.display.price_currency}
                      include_currency={true}
                    />
                    {this.props.programmatic === "programmatic"
                      ?
                      <span>
                        {" / " + t("Print")}
                      </span>
                      : <> {" / " + t("Day")}
                        {(this.props.display.rank_discount_apply && this.props.display.rank_discount_apply.discount) ?
                          <Tooltip placement="right"
                            title={
                              <span>
                                {t('Discount applied') + ' ' + this.props.display.rank_discount_apply.discount}%
                              </span>
                            }>
                            <Icon
                              style={{ color: "#faad14", paddingLeft: "10px", fontSize: "large" }}
                              type="tags" theme="outlined">
                            </Icon>
                          </Tooltip> : null
                        }
                      </>
                    }
                  </Tooltip>
                  {/* Request special CPM */}
                  {isAgencyUser && isVerifiedAgency && this.props.programmatic === "programmatic" ?
                    <RequestSpecialCpmContainer displayId={this.props.display.id} originalBaseCpm={(this.props.display.base_smart_campaign_cpm)} agreedBaseCpm={(this.props.display.arranged_smart_campaign_cpm)}></RequestSpecialCpmContainer>
                    : null
                  }
                  {/* Request special traditional price */}
                  {isAgencyUser && isVerifiedAgency && this.props.programmatic !== "programmatic" ?
                    <RequestSpecialPriceContainer displayId={this.props.display.id} originalBasePrice={(this.props.display.original_base_price_per_day)} agreedBasePrice={(this.props.display.arranged_price_per_day)}></RequestSpecialPriceContainer>
                    : null
                  }
                  {/* If agency is not verified, show help information */}
                  {isAgencyUser && !isVerifiedAgency ?
                    <Tooltip placement="top" title={t("Request agreed price")} >
                      <Button type="dashed" shape="circle" onClick={this.showVerifiedAgencyModal} size="small" style={{ marginLeft: "5px" }}>
                        <FontAwesomeIcon icon={faHandshake} size="20px" style={{ color: "grey" }} className='icoinversenEdit' />
                      </Button>
                    </Tooltip>
                    : null
                  }
                </div>
                <Divider style={{ margin: "5px" }} />
                <div className="cardInfo">
                  <Dotdotdot clamp={1}>
                    {t("Resolution")} {this.props.display.resolution_width}
                    {"x"}
                    {this.props.display.resolution_height}
                  </Dotdotdot>
                </div>
                <Row type="flex">
                  <Col span={24}>
                    <span
                      style={{ textTransform: "capitalize" }}
                      className="cardInfo"
                    >
                      {t("Size")}{" "}
                      {this.getSizeType(this.props.display.size_type)}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span
                      style={{ textTransform: "capitalize", fontSize: "10px" }}
                      className="cardInfo"
                    >
                      {t("Slot duration")}{" "}
                      {this.props.display.slot_length / 1000} {t("secs")}
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="rowButton">
              <Row type="flex">
                <Col xs={9} sm={9} md={9} lg={24} xl={5} className="colButtonFirst">
                  {!display.location_type ||
                    <span>
                      <img
                        src={getIconType(display.location_type, display.display_type, false, "isNotPin")}
                        className="imagenTypeScreen"
                      />
                      <span className="iconTypeScreen">
                        {display.location_type}
                      </span>
                    </span>
                  }
                </Col>
                <Col xs={14} sm={14} md={14} lg={24} xl={18} style={{textAlign:"end", alignItems: "baseline"}}>
                  {this.props.display.display_type === "main" &&
                    this.props.display.secondary_displays ? (
                    <Tooltip
                      placement="top"
                      title={
                        this.props.display.secondary_displays.length +
                        " pantallas"
                      }
                    >
                        <Button className="circuit" shape="circle" size="small">
                          <Icon type="deployment-unit" theme="outlined" />
                          <span style={{fontSize: "small"}}>{t("circuit")}</span>
                        </Button>
                    </Tooltip>
                  ) : null}
                  {(((display.rank_discounts && display.rank_discounts.length > 0)
                    && this.props.programmatic === 'traditional')) &&
                    <div
                      className="borderTagDiscount"
                    >
                      <TagsDiscount
                        display={this.props.display}
                        currency={this.props.display.price_currency}
                      />
                    </div>
                  }
                  {camlyticsTokens && camlyticsTokens.cam_traffic ? (
                    <Tooltip
                      placement="bottomLeft"
                      title={t("Camera to traffic")}
                    >
                      <Button
                        className="screenCardCamTrafficView"
                        shape="circle"
                        size="small"
                        onClick={() => this.showCamTrafficModal()}
                      >
                        <Icon type="car" theme="outlined" />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {camlyticsTokens && camlyticsTokens.cam_display ? (
                    <Tooltip
                      placement="bottomLeft"
                      title={t("Camera for certification")}
                    >
                      <Button
                        className="screenCardCamCertification"
                        shape="circle"
                        size="small"
                        onClick={() => this.showCamScreenModal()}
                      >
                        <Icon type="video-camera" theme="outlined" />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {(display.has_external_audience_data ||
                    getRefinedAudience(user, editCampaignActive ? (currentCampaign ? currentCampaign.programmatic_provider_id : null) : null)) &&
                    <Tooltip placement="bottomLeft" title={t("Audience detail")}>
                      <Button
                        className="screenCardAudit"
                        shape="circle"
                        size="small"
                        onClick={() => this.showAudienceModal()}
                      >
                        <Icon type="audit" theme="outlined" />
                      </Button>
                    </Tooltip>
                  }
                  <Modal // AUDIENCE MODAL
                    title={t("Audience")}
                    visible={this.state.visibleAudienceView}
                    onOk={this.okAudienceModal}
                    onCancel={this.cancelAudienceModal}
                    width={1000}
                  >
                    <AudienceSummaryComponentGeneral
                      t={t}
                      display={display}
                      user={user}
                      campaign={ editCampaignActive ? currentCampaign : null}
                      audienceHoursOfDay = { this.props.audienceHoursOfDay }
                      audienceDaysOfWeek = { this.props.audienceDaysOfWeek }
                      getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
                      getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest}
                    />
                  </Modal>
                  {this.props.display.mirror_screens_count > 1 ? (
                    <Tooltip
                      placement="bottomLeft"
                      title={`${t("Multiple faces screen")} (${this.props.display.mirror_screens_count})`}
                    >
                      <Button
                        className="doubleScreen"
                        shape="circle"
                        size="small"
                        style={{marginLeft: "7px"}}
                      >
                        <FontAwesomeIcon
                          icon={faClone}
                          size="20x"
                          className="iconTranslate"
                        />
                      </Button>
                    </Tooltip>
                  ) : null}
                  <span>
                    <Tooltip placement="top" title={t("Screen detail")}>
                      <Button
                        className="screenCardInformation"
                        shape="circle"
                        size="small"
                        onClick={() => this.handleShowScreenDetail("info")}
                      >
                        <Icon type="info-circle" theme="outlined" />
                      </Button>
                    </Tooltip>
                  </span>
                  { display.bundles && display.bundles.length > 0 ?
                    <Tooltip placement="top" title={t("Sales bundle details")}>
                      <Button
                        style={{marginLeft: "7px", marginRight: "2px", top: "9px",}}
                        shape="circle"
                        onClick={() => this.handleShowScreenDetail("bundles")}
                      >
                      <Badge count={
                        <>
                          {display.bundles.length > 0 && (
                            countBundlesAdded !== 0 && countBundlesAdded < display.bundles.length ? (
                              <>
                                <Icon type="plus-circle" className="addIconBundle" theme="filled"/>
                                <Icon type="minus-circle" className="minusIconBundle" theme="filled"/>
                              </>
                            ) : (
                              countBundlesAdded === display.bundles.length ?
                                <Icon type="minus-circle" className="minusIconBundle" theme="filled"/>:
                                <Icon type="plus-circle" className="addIconBundle" theme="filled"/>
                            )
                          )}
                        </>
                      }>
                        <Icon component={BundleSvg} style={{width: '30px'}}/>
                      </Badge>
                      </Button>
                    </Tooltip>
                  :null}
                  <Modal
                    title={t("Screen detail")}
                    visible={showScreenDetail}
                    onCancel={() =>this.handleShowScreenDetail("close")}
                    footer={[
                      <Button key="back" onClick={() =>this.handleShowScreenDetail("close")}>
                        OK
                      </Button>,
                    ]}
                    width={modalWidth}
                    style={{ top: "10px" }}
                  >
                    <Provider store={store}>
                      <ScreenContainer
                        display={display}
                        screenDetail={showScreenDetail}
                        popup={true}
                        // Tab for bundles
                        defaultTab={defaultTabScreenDetail}
                        editCart={true}
                        closeModal={() => this.handleShowScreenDetail("close")}
                      />
                    </Provider>
                  </Modal>
                  <DeleteBundleModalComponent
                    deleteModalVisible={deleteModalVisible}
                    matchBundle={matchBundle}
                    handleDeleteModal={this.handleDeleteModal}
                    cart={cart}
                    listBundle={listBundle}
                    removeBundlesFromCart={removeBundlesFromCart}
                  />
                  { !display.bundle_only ?
                    <Button
                      disabled={
                        (this.props.editCampaign.payment_status === "approved" &&
                          this.props.editCampaignActive) ||
                        false
                      }
                      title={
                        this.props.editCampaign.payment_status === "approved" &&
                          this.props.editCampaignActive
                          ? t("Ads cannot be edited in approved campaigns")
                          : null
                      }
                      className={
                        onCart ? "screenCardRemoveButton" : "screenCardAddButton"
                      }
                      shape="circle"
                      icon={onCart ? "minus" : "plus"}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onCart) {
                          if (matchBundle){
                            this.handleDeleteModal()
                          } else {
                            this.props.removeDisplayFromCart(this.props.display.id);
                          }
                        } else {
                          this.props.addDisplayToCart(this.props.display);
                        }
                        onCart = !onCart;
                      }}
                    />
                  :null}
                </Col>
              </Row>
            </Col>
            <Modal // TRAFFIC MODAL
              title={t("Traffic camera for audience control and measurement")}
              visible={this.state.visibleCamTrafficView}
              onOk={this.okCamTrafficModal}
              onCancel={this.cancelCamTrafficModal}
            >
              <iframe
                id="live-view-traffic"
                src={this.state.cam_traffic}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="300px"
              ></iframe>
            </Modal>
            <Modal //NON-AUTOMATED MODAL
              title={t("Non-automated screen")}
              visible={this.state.visibleWarningNoAutomateView}
              onOk={this.okWarningNoAutomateModal}
              onCancel={this.cancelWarningNoAutomateModal}
            >
              <p style={{ margin: "25px" }}>
                {t(
                  "This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected."
                )}
              </p>
            </Modal>
            <Modal // CAMERA CERTIFICATION MODAL
              title={t("Camera looking at screen for certification")}
              visible={this.state.visibleCamScreenView}
              onOk={this.okCamScreenModal}
              onCancel={this.cancelCamScreenModal}
            >
              <iframe
                id="live-view-traffic"
                src={this.state.cam_display}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="300px"
              ></iframe>
            </Modal>
            <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal}></VerifiedAgencyInfoModal>
          </Row>
        </div>
      </ScreenCardHolder>
    );
  }
}
export default withTranslation()(ScreenCardComponent);
