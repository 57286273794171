import React, { Component } from "react";
import InformativeHeader from "./Styles/informativeHeaderStyle";
import { Row, Col, Icon, Button } from "antd";
import moment from "moment";
import "moment-duration-format";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import { SeparateNumberFormat } from "../services/utils";
import Colors from "../themes/Colors";

class CampaignTopbar extends Component {
  render() {
    const { t , i18n} = this.props;

    if (!this.props.campaign) {
      return (
        <InformativeHeader>
          <Row type="flex" gutter={8} align="middle">
          </Row>
        </InformativeHeader>
      );
    }

    const totalTime = this.props.campaign.contents_displays.reduce(
      (acc, display) => {
        return acc + (display.length * display.promised_shows) / 1000;
      },
      0
    );
    const promisedShows = this.props.campaign.contents_displays.reduce(
      (acc, display) => {
        return acc + display.promised_shows;
      },
      0
    );
    const remainingDaysCount = -moment().diff(this.props.campaign.start_date, "days");
    let remainingDays;
    if (remainingDaysCount >= 0) {
      if (remainingDaysCount === 0) {
        remainingDays = t("Today");
      }
      if (remainingDaysCount > 0) {
        remainingDays = `${remainingDaysCount} ${t('Day')}${remainingDaysCount === 1 ? "" : "s"}`;
      }
    } else {
      if (moment(this.props.campaign.end_date).isAfter(moment())) {
        remainingDays = moment(this.props.campaign.start_date).format("L");
      }
      else {
        remainingDays = t("Finished");
      }
    }

    const campaingDays = -moment(this.props.campaign.start_date).diff(this.props.campaign.end_date, "days")+1;

    function StatusCampaing (props) {
      const { campaign } = props;
      if (campaign.payment_status == 'pre_approved') return <span className="campaignTitle">{t('Pre approved')}</span>
      if (campaign.total_price_with_profit == 0) return <span className="campaignTitle">{t('Subsidized')}</span>

      if (campaign.status === 'ready' || (campaign.contents_displays_approved === campaign.contents_displays_total)) {
        if (moment().isBetween(campaign.start_date, campaign.end_date)) {
          return <span className="campaignTitle">{t('Active')}</span>
        } else if (moment(campaign.start_date).isAfter(moment())) {
          return <span className="campaignTitle">{t('Coming soon')}</span>
        }
        return <span className="campaignTitle">{t('Finalized')}</span>
      } else if (campaign.status === 'cancelled_by_user') {
        return <span className="campaignTitle">{t('Cancelled by user')}</span>
      } else if (campaign.status === 'cancelled') {
        return <span className="campaignTitle">{t('Cancelled')}</span>
      } else if(campaign.status == 'pending') {
        if (campaign.payment_status == 'pre_approved') return <span className="campaignTitle">{t('Approved')}</span>
        return <span className="campaignTitle">{t('Pending')}</span>
      }
    }

    return (
      <InformativeHeader>
        <Row type="flex"
          gutter={[2,3]}
          align="top"
          justify="space-between"
         >
          <Col
            xs={{span:12, order:1}}
            sm={{span:11, order:1}}
            md={{span:12, order:1}}
            lg={{span:12, order:1}}
            xl={{span:2, order:1}}>
          {this.props.displayBackButton && (
              <Button
                className="backButton"
                onClick={() => this.props.history.goBack()}
              >
                <Icon
                  style={{ fontSize: "18px", color: Colors.primary }}
                  type="left"
                />{" "}
                {t('Go back')}
              </Button>
          )}
          </Col>
          <Col className="campaignInfo"
            xs={{span:24, order:3}}
            sm={{span:11, order:3}}
            md={{span:7, order:3}}
            lg={{span:7, order:3}}
            xl={{span:3, order:2}}>
            <span className="campaignSubTitle">{t('Campaign status')}</span>
            <br />
            <b>
              <StatusCampaing campaign={this.props.campaign}/>
            </b>
          </Col>
          <Col className="campaignInfo"
            xs={{span:24, order:4}}
            sm={{span:11, order:4}}
            md={{span:10, order:4}}
            lg={{span:10, order:4}}
            xl={{span:4, order:3}}>
            <span className="campaignSubTitle">{t('Exhibition date')}</span>
            <br />
            <b className="campaignTitle">
              {i18n.language === 'en' ? (
                moment(this.props.campaign.start_date).format("MM/DD/YYYY") + " - "+
                moment(this.props.campaign.end_date).format("MM/DD/YYYY")
              ) : (
                moment(this.props.campaign.start_date).format("DD/MM/YYYY")  + " - " +
                moment(this.props.campaign.end_date).format("DD/MM/YYYY")
              )}
            </b>
          </Col>
          <Col className="campaignInfo"
            xs={{span:11, order:5}}
            sm={{span:11, order:5}}
            md={{span:5, order:5}}
            lg={{span:5, order:5}}
            xl={{span:2, order:4}}>
            <span className="campaignSubTitle">{t('Duration')}</span>
            <br />
            <b className="campaignTitle">
              {campaingDays > 0 ? campaingDays : 0} {t('Day')}
              {campaingDays === 1 ? "" : "s"}
            </b>
          </Col>
          <Col className="campaignInfo"
            xs={{span:12, order:6}}
            sm={{span:11, order:6}}
            md={{span:7, order:6}}
            lg={{span:7, order:6}}
            xl={{span:2, order:5}}>
            <span className="campaignSubTitle">{t('Starts')}</span>
            <br />
            <b className="campaignTitle">
              {remainingDays}
            </b>
          </Col>
          <Col className="campaignInfo"
            xs={{span:10, order:7}}
            sm={{span:11, order:7}}
            md={{span:7, order:7}}
            lg={{span:7, order:7}}
            xl={{span:2, order:6}}>
            <span className="campaignSubTitle">{t('Real Impressions')}</span>
            <br />
            {this.props.loadInitialReport ? (<Icon style={{ fontSize: "18px", color: "#fff" }} type="loading"/>) : (<b className="campaignTitle">{SeparateNumberFormat(this.props.totalShows)}</b>)}
          </Col>
          <Col className="campaignInfo"
            xs={{span:13, order:8}}
            sm={{span:11, order:8}}
            md={{span:7, order:8}}
            lg={{span:7, order:8}}
            xl={{span:4, order:7}}>
            <span className="campaignSubTitle">{this.props.campaign.smart_campaign ? t('Purchased impressions') : t('Promised impressions')}</span>
            <br />
            <b className="campaignTitle">{SeparateNumberFormat(promisedShows)}</b>
          </Col>
          <Col
            xs={{span:11, order:2}}
            sm={{span:11, order:2}}
            md={{span:11, order:2}}
            lg={{span:11, order:2}}
            xl={{span:2, order:8}}
            style={{textAlign:"-webkit-right"}}>
            <Button className="backButton" onClick={this.props.onClickMapButton}>
              <Icon
                style={{ fontSize: "18px", color: Colors.primary }}
                type="global"
              />{" "}
              {t('Show map')}
            </Button>
          </Col>
        </Row>
      </InformativeHeader>
    );
  }
}

CampaignTopbar.defaultProps = {
  displayBackButton: true
};

const Extended = withTranslation()(CampaignTopbar);
Extended.static = CampaignTopbar.static;

export default withRouter(Extended);
