import styled from "styled-components";
import Colors from "../../themes/Colors";

const BlogHolder = styled.div`
  .titleBlog {
    background-image: white;
    color: ${Colors.snow};
    padding: 30px 50px 0px 50px;

    h1 {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
      color: ${Colors.primary};
    }

    h2 {
      font-size: "20px";
      color: ${Colors.primary};
      padding-left: "20px";
    }

    p {
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 25px;
      line-height: 1.2;
    }
  }
  .lastBlog {
    padding: 0px 30px 0px 30px;
    height: 450px;
    margin-bottom: 50px;
  }
  .cardBlog {
    padding: 40px;
  }

`;

export default BlogHolder;
