import React from "react";
import "./Bundles.css";

export const BundleSvg = () => (

    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.96 213.96">
        <g>
            <polygon class="cls-2" points="62.62 80.36 106.31 55.76 150.75 80.36 150.75 132.5 106.68 158.21 62.62 130.67 62.62 80.36"/>
            <polyline class="cls-2" points="150.75 80.36 106.68 106.98 62.62 80.36"/>
            <line class="cls-2" x1="106.68" y1="158.21" x2="106.68" y2="106.98"/>
            <line class="cls-2" x1="84.65" y1="93.67" x2="128.34" y2="67.95"/>
            <line class="cls-3" x1="123.21" y1="132.6" x2="137.89" y2="124.06"/>
            </g>
        <g>
        <path class="cls-2" d="M93.01,16.18c-9.49,1.55-18.73,4.67-27.15,9.34"/>
        <path class="cls-2" d="M198.53,92.51c-4.5-39.2-39.5-71.2-77.12-76.42"/>
        <path class="cls-2" d="M120.44,197.33c39.6-4.02,72.6-38.02,78.39-77.47"/>
        <path class="cls-2" d="M16.78,120.73c4.26,37.58,36.26,69.58,74.4,76.05"/>
        <path class="cls-2" d="M29.91,58.14c-6.91,10.88-11.46,23.24-13.26,36.01"/>
            <circle class="cls-1" cx="106.98" cy="15.75" r="11.83"/>
            <circle class="cls-1" cx="198.22" cy="106.63" r="11.83"/>
            <circle class="cls-1" cx="106.5" cy="198.22" r="11.83"/>
            <circle class="cls-1" cx="15.75" cy="107.27" r="11.83"/>
        <path class="cls-4" d="M40.09,39.84c-.04-2.95,2.1-5.13,5.04-5.1,2.8,.02,4.97,2.15,5.02,4.94,.05,2.81-2.3,5.2-5.1,5.17-2.77-.03-4.92-2.19-4.96-5Z"/>
        </g>
    </svg>);