import React, { Component } from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { withTranslation } from "react-i18next";
import GenderChartComponent from "./GenderChartComponent";
import EconomicSocialChartComponent from "./EconomicSocialChartComponent";
import GenderByAgeChartComponent from "./GenderByAgeChartComponent";
import LogoDatsWhy from "../images/logo-dats-why.svg";
import { Card, Row, Col, Tooltip} from "antd";
import ScreenHolder from "./Styles/screenComponentStyle";

class QualitativeAudienceComponent extends Component {

  render() {
    const { t, age, gender, se, flow_data, loadingAudienceDatsWhy } = this.props;
    const gridStyle = {
        width: '33.33333%',
        textAlign: 'center',
    };
    return(
        <ScreenHolder>
            <Row
            className="screenContainer"
            type="flex"
            justify="center"
            align="bottom"
            >
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    {loadingAudienceDatsWhy ?
                        <Card style={{ padding: "20px" , display: "flex", flexWrap: "wrap", "justifyContent": "center"}} className="screenCard" >
                            <div className="squeletorCircle loading screenCardContent margin-card-image"></div>
                        </Card>
                    :
                        <Card style={{ padding: "20px"}} className="screenCard" >
                            {gender ? (
                                <div style={{paddingTop: "5px",paddingBottom: "5px"}}>
                                    {/* Is important the order of the data [male, female] */}
                                    <GenderChartComponent
                                        data={[gender.male, gender.female]}
                                    />
                                </div>
                            ) : null}
                        </Card>
                }
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Card style={{ padding: "20px" }} className="screenCard">
                    {loadingAudienceDatsWhy ?
                        <div className="squeletorRectangle loading screenCardContent margin-card-image"></div>
                    :
                        <>{age ? (
                        <GenderByAgeChartComponent
                            data={[
                            [
                                age.female["0-14"],
                                age.female["15-19"],
                                age.female["20-29"],
                                age.female["30-44"],
                                age.female["45-54"],
                                age.female["55+"],
                            ],
                            [
                                age.male["0-14"],
                                age.male["15-19"],
                                age.male["20-29"],
                                age.male["30-44"],
                                age.male["45-54"],
                                age.male["55+"],
                            ],
                            ]}
                        />
                        ) : null}</>
                    }
                    </Card>
                    <Tooltip
                    placement="bottomLeft"
                    title={t(
                        "Audience Hyper-Insights that help you plan, monitor, and analyze Out of Home sites and campaigns to maximize ROI."
                    )}
                    ></Tooltip>
                </Col>
            </Row>
            <Row
            className="screenContainer"
            type="flex"
            justify="center"
            align="bottom"
            >
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    {loadingAudienceDatsWhy ?
                        <Card style={{ padding: "25px" , display: "flex", flexWrap: "wrap", "justifyContent": "center"}} className="screenCard" >
                            <div className="squeletorCircle loading screenCardContent margin-card-image" style={{marginTop: "15px", marginBottom: "15px"}}></div>
                        </Card>
                    :
                        <Card style={{ padding: "20px"}} className="screenCard" >
                            {se ? (
                            <div style={{paddingTop: "5px",paddingBottom: "5px"}}>
                            <EconomicSocialChartComponent
                                data={[
                                    se["C+"],
                                    se["C"],
                                    se["AB"],
                                    se["E"],
                                    se["D"],
                                    se["NC"],
                                ]}
                            />
                            </div>
                            ) : null}
                        </Card>
                }
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Card
                        style={{ padding: "10px" , display: "flex", flexWrap: "wrap", "justifyContent": "center"}} className="screenCard"
                        title={t("Data per month in the radius of the screen location")}>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Total vehicle impacts')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{flow_data.count_monthly}</p>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Unique vehicle impacts')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{flow_data.count_uniques_monthly}</p>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Frequency of unique people')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{Math.round(flow_data.frequency_monthly)}</p>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Total people impacts')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{flow_data.count_people_monthly}</p>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Impacts on unique people')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{flow_data.count_people_uniques_monthly}</p>
                        </Card.Grid>
                        <Card.Grid style={gridStyle}>
                            <span style={{color: '#b3b3b3'}}>
                                {t('Average exposure in seconds')}
                            </span>
                            <p style={{fontSize: '19px', marginTop: '5px', marginBottom: '0'}}>{Math.round(flow_data.exhibithion_time)}</p>
                        </Card.Grid>
                    </Card>
                </Col>
            </Row>
            <h3
            style={{ padding: "20px", textAlign: "center" }}
            className="title-col title-inherit"
            >
            {t("Data obtained with the integration of")}
            &nbsp;&nbsp;
            <a href="https://datswhy.com/" target="_blank">
                <img alt="" src={LogoDatsWhy} className="logoDatswhy" />
            </a>
            </h3>
        </ScreenHolder>
    )
  }
}
export default withTranslation()(QualitativeAudienceComponent);