import React from 'react'
import {Translator, Translate} from 'react-auto-translate';
// Traslate text if to is null not translate
// ej {to} es or en
const api_google = process.env.REACT_APP_GOOGLE_KEY

export default function GoogleTranslator({to ,from , text}) {

    return (
        <>
        { to ? (
            <Translator
                // cacheProvider={cacheProvider}
                from={from}
                to={to}
                googleApiKey= {api_google}
            >
                <Translate>{text}</Translate>
            </Translator>
            ):(<> {text} </>)
        }
        </>
    )
}