import React, { Component } from "react";
import InformativeHeader from "./Styles/informativeHeaderStyle";
import { Row, Col, Icon, Button } from "antd";
import moment from "moment";
import "moment-duration-format";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";

class PaymentTopbar extends Component {

  render() {
    const { t, i18n } = this.props;
    moment.locale(i18n.language);
    if (!this.props.campaign) {
      return (
        <InformativeHeader>
          <Row type="flex" gutter={8} align="middle">
          </Row>
        </InformativeHeader>
      );
    }

    const totalTime = this.props.campaign.contents_displays.reduce(
      (acc, display) => {
        return acc + (display.length * display.promised_shows) / 1000;
      },
      0
    );
    const promisedShows = this.props.campaign.contents_displays.reduce(
      (acc, display) => {
        return acc + display.promised_shows;
      },
      0
    );
    const remainingDays = -moment().diff(this.props.campaign.start_date, "days");
    const campaingDays = -moment(this.props.campaign.start_date).diff(this.props.campaign.end_date, "days")+1;

    return (
      <InformativeHeader>
        <Row type="flex" gutter={[2,3]} align="top" justify="space-between">
          <Col
          xs={{span:12, order:1}}
          sm={{span:12, order:1}}
          md={{span:12, order:1}}
          lg={{span:12, order:1}}
          xl={{span:3, order:1}}
          >
            <Button
              className="backButton"
              onClick={() => this.props.history.push(`/map`)}
            >
              <Icon
                style={{ fontSize: "18px", color: Colors.primary }}
                type="left"
              />{" "}
              {t('Go to map')}
            </Button>
          </Col>
          <Col
          xs={{span:24, order:3}}
          sm={{span:11, order:3}}
          md={{span:11, order:3}}
          lg={{span:11, order:3}}
          xl={{span:3, order:2}}
          className="campaignInfo">
            <span className="campaignSubTitle">{t('Campaign status')}</span>
            <br />
            {this.props.campaign.status === 'pending' ? (
              <span className="campaignTitle">{t('Pending')}</span>
            ) : null}
            {this.props.campaign.status === 'approved' ? (
              <span className="campaignTitle">{t('Approved')}</span>
            ) : null}
            {this.props.campaign.status === 'ready' ? (
              <span className="campaignTitle">{t('Active')}</span>
            ) : null}
            {this.props.campaign.status === 'cancelled' ? (
              <span className="campaignTitle">{t('Cancelled')}</span>
            ) : null}
            {this.props.campaign.status === 'rejected' ? (
              <span className="campaignTitle">{t('Rejected')}</span>
            ) : null}
          </Col>
          <Col
          xs={{span:24, order:4}}
          sm={{span:11, order:4}}
          md={{span:11, order:4}}
          lg={{span:11, order:4}}
          xl={{span:4, order:3}}
          className="campaignInfo">
            <span className="campaignSubTitle">{t('Exhibition date')}</span>
            <br />
            <b className="campaignTitle">
              {i18n.language === 'en' ? (
                moment(this.props.campaign.start_date).format("MM/DD/YYYY")
              ) : (
                moment(this.props.campaign.start_date).format("DD/MM/YYYY")
              )} -{" "}
              {i18n.language === 'en' ? (
                moment(this.props.campaign.end_date).format("MM/DD/YYYY")
              ) : (
                moment(this.props.campaign.end_date).format("DD/MM/YYYY")
              )}
            </b>
          </Col>
          <Col
          xs={{span:11, order:5}}
          sm={{span:11, order:5}}
          md={{span:5, order:5}}
          lg={{span:5, order:5}}
          xl={{span:2, order:4}}
          className="campaignInfo">
            <span className="campaignSubTitle">{t('Duration')}</span>
            <br />
            <b className="campaignTitle">
              {campaingDays > 0 ? campaingDays : 0} {t('Day')}
              {campaingDays === 1 ? "" : "s"}
            </b>
          </Col>
          <Col
          xs={{span:11, order:7}}
          sm={{span:11, order:7}}
          md={{span:7, order:7}}
          lg={{span:7, order:7}}
          xl={{span:2, order:6}}
          className="campaignInfo">
            <span className="campaignSubTitle">{t('Starts at')}</span>
            <br />
            <b className="campaignTitle">
              {remainingDays > 0 ? remainingDays : 0} {t('Day')}
              {remainingDays === 1 ? "" : "s"}
            </b>
          </Col>
          <Col
          xs={{span:24, order:8}}
          sm={{span:11, order:8}}
          md={{span:7, order:8}}
          lg={{span:7, order:8}}
          xl={{span:4, order:7}}
          className="campaignInfo">
            <span className="campaignSubTitle">{t('Promised impressions')}</span>
            <br />
            <b className="campaignTitle">{promisedShows}</b>
          </Col>
          <Col
            xs={{span:10, order:2}}
            sm={{span:11, order:2}}
            md={{span:7, order:2}}
            lg={{span:7, order:2}}
            xl={{span:2, order:8}}
            className="mapButtonContainer"
          >
            <Button className="backButton" onClick={this.props.onClickMapButton}>
              <Icon
                style={{ fontSize: "18px", color: Colors.primary }}
                type="global"
              />{" "}
              {t('Show map')}
            </Button>
          </Col>
        </Row>
      </InformativeHeader>
    );
  }
}

PaymentTopbar.defaultProps = {
  displayBackButton: true
};

const Extended = withTranslation()(PaymentTopbar);
Extended.static = PaymentTopbar.static;


export default withRouter(Extended);
