import { connect } from 'react-redux'
import AppComponent from '../components/AppComponent'
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ userAccount }) => ({
  userAccount
})

const mapStateToDispatch = (dispatch) => ({
  loginVerify: (user) => dispatch(UserAccountActions.userLoginVerify(user)),
  logout: () => dispatch(UserAccountActions.userReset())
})

export default connect(mapStateToProps, mapStateToDispatch)(AppComponent)
