export const marker= () => {
  const lightPrimary = getComputedStyle(document.documentElement).getPropertyValue('--lightPrimaryPins').trim();
  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
          .cls-1 {
            fill: ${lightPrimary};
            stroke: #1d71b8;
            stroke-miterlimit: 10;
            stroke-width: .74px;
          }
        </style>
      </defs>
      <g id="Group_14">
        <g id="Group_13">
          <path id="Path_8" class="cls-1" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
        </g>
      </g>
    </svg>`;
  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};