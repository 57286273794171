import styled from "styled-components";
import Colors from "../../themes/Colors";

const InfoHolder = styled.div`
  .videoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.primary};
    color: ${Colors.snow};
    padding: 65px 0 35px;

    h1 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 5px;
      color: ${Colors.snow};
    }

    p {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 25px;
      line-height: 1.2;
    }
  }
  .videoImage {
    max-width: 90%;
    border-radius: 15px;
    object-fit: contain;
    background-color: white;
    cursor: pointer;
    margin-bottom: 45px;
    height: 100%;
  }
  .our {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 0 15px;
  }
  .ant-card-cover {
    // background-image: linear-gradient(100deg,#2E8EE5,#1ADCFC);
    border-radius: 10px 10px 0 0;
    height: 250px;
    border: solid;
    border-color: whitesmoke;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1px;
  }
  .ant-card-meta-title {
    text-align: left;
    font-size: 20px;
  }
  .our .ant-card-cover img {
    border-radius: 10px 10px 0 0;
  }
  .imageVideo {
    cursor: pointer;
    width: 60%;
    border-radius: 10px;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    @media (max-width: 576px) {
      width: 80%;
    }
  }
  .imageVideo:hover {
    transform: scale(1.05);
  }
`;

export default InfoHolder;
