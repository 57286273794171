import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardInfoTotalPriceStyleHolder = styled.div`

  .CardInfoTotalPrice {
    position: fixed;
    z-index: 996;
    top: 5px;
    right: 10px;
    height: auto;
    width: 210px;
    line-height: 25px;
    background-color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    box-shadow: 0px 4px 6px 2px #0000001A;

    @media only screen and (max-width: 575px) {
      position: relative;
      bottom: 0px;
      top: 0px;
      left: 0px;
    }
    @media only screen and (min-width: 1000px) {
      top: calc(120px + 170px + 12px);
      width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      width: 11%;
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      width: 100%;
    }
  }
  .textTitle {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #3996F3;
    margin-top: 15px;
    margin-left: 15px;
  }
  .textTotalTitle {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #3996F3;
    margin-top: 15px;
    margin-left: 15px;
  }
  .titleInfoDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
    margin-left: 15px;
  }

  .infoDate {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #4C4C4C;
    margin-left: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  ${'' /* .infoDateTwo {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #D7EAFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    padding-left: 20px;
  } */}
}`;

export default CardInfoTotalPriceStyleHolder;