import styled from "styled-components";

const CampaignGroupFilterHolder = styled.div`
  .groupFilter {
    margin-bottom: 20px;
    display: flex;
  }

  @media (max-width: 1660px) {
    .groupFilter {
      width: fit-content;
    }
  }

  .contentGroupFilter {
    text-align: left;
  }

  .groupFilter .ant-btn {
    border-radius: 8px;
  }

  .groupFilterButton {
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export default CampaignGroupFilterHolder;