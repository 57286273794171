import styled from "styled-components";

const SearchInputHolder = styled.div`
  height: 32px;

  .ant-progress {
    top: -12px;
    padding: 0 2px;
  }

  .ant-progress-bg {
    height: 2px !important;
  }
`;

export default SearchInputHolder;
