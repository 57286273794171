import styled from "styled-components";
import Colors from "../../themes/Colors";

const CampaignsHolder = styled.div`
  .tag-status {
    background-color: rgb(250 140 22 / 80%);
    border-radius: 10px;
    color: white;
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: smaller;
  }
  .icon-archived {
    margin-top: 3px;
    margin-left: 10px;
  }
  /* TO DO: Separate the archive button from the others. */
  /* setting the last div of the tabs to the right */
  /* 
  .ant-tabs-nav div {
    display: flex;
  }
  .ant-tabs-nav {
    width:100%;
  }
  .ant-tabs-nav div > div:last-child {
     margin-left: auto !important;
  } */
  .campaignContainer {
    padding: 0 0 0;
  }
  h1 {
    color: ${Colors.primary};
    font-weight: 600;
  }
  .row-search {
    padding-bottom: 10px;
    padding-top: 5px;
  }
  .paginationCampaigns {
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
  .paginationCampaigns ul {
    min-width: 200px;
  }

  .button-contanier {
    padding: 140px 45px 0px 10px;
  }

  .backButton {
    position: fixed;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;
    @media only screen and (min-width: 576px) {
      margin-bottom: 0px;
    }
  }

  .mobileAlign {
    @media only screen and (max-width: 1024px) {
      padding-left: 20px;
    }

    @media only screen and (max-width: 768px) {
      text-align: right;
      padding-right: 10px;
    }
  }
`;

export default CampaignsHolder;