import React, { Component } from "react";
import OnboardingHolder from "./Styles/onboardingStyle";
import { Row, Icon, Col, Card , Tooltip} from "antd";
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { SeparateNumberFormat } from "../services/utils";
import CSSEffect from "./Styles/CSSEffect";
import screenIcon from "../images/screen-icon-grey.svg";
import { AimSvg } from "../images/customIcons/AimSvg";
const AimIcon = (props) => <Icon component={AimSvg} {...props} />;

class OnboardingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audience: 0,
      cpi: 0,
      screens: 0,
      inProp: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      inProp: true,
    })

  }

  render() {
    const { inProp }= this.state;
    const { t, i18n } = this.props;
    const temperatureMarks = {
      0: '0°C',
      15: '15°C',
      32: '32°C',
      45: {
        style: {
          color: '#f50',
        },
        label: <strong>45°C</strong>,
      },
    };
    const ageMarks = {
      5: '5',
      20: '20',
      55: '55',
      100: '100'
    };
    const humidityMarks = {
      0: this.props.t('Lower'),
      50: this.props.t('Middle'),
      100: this.props.t('High'),
    };

    var settings = {
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 8000,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <CSSEffect>
        <CSSTransition in={inProp} timeout={500} classNames="my-node">
          <OnboardingHolder>
            <div className="sliderContainer">
              <div style={{textAlign: "center"}}>
                <Row>
                  <Card className="border-card">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: `20px`, paddingRight: `20px`}}>
                      <h3 style={{textAlign: "center"}}>{t('Estimated audience for this location')}</h3>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="containerIconAudience" style={{ paddingLeft: `20px`, paddingRight: `20px`}}>
                      <Tooltip
                          placement="bottom"
                          title={`${t('Estimated audience')}`}>
                          <Icon className="iconAudience" type="usergroup-add" theme="outlined"/>
                          <p style={{display: "contents"}}><span className="dataAudience">{SeparateNumberFormat(Math.round(this.props.audience.total_audience / 1000))}K {" "}{this.props.loadingAudience ? <Icon type="loading" /> : null}</span></p>
                      </Tooltip>
                      <Tooltip
                          placement="bottom"
                          title={`${t('impacts')}`}>
                          <AimIcon className="iconAudience" />
                          <p style={{display: "contents"}}><span className="dataAudience">{SeparateNumberFormat(this.props.audience.total_impacts)}</span></p>
                      </Tooltip>
                      <Tooltip
                          placement="bottom"
                          title={`${t('Screens with cameras measuring traffic and people')}`}>
                          <img src={screenIcon} className="iconAudience" style={{height: "18px", width: "18px"}} />
                          <p style={{display: "contents"}}><span className="dataAudience">{SeparateNumberFormat(Math.round(this.props.audience.displays_with_audience.length))}</span></p>
                      </Tooltip>
                    </Col>
                  </Card>
                </Row>
              </div>
            </div>
          </OnboardingHolder>
        </CSSTransition>
      </CSSEffect>

    );
  }
}

export default withTranslation()(OnboardingComponent);
