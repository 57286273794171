import React, { Component } from "react";
import CampaignsHolder from "./Styles/campaignsComponentStyle";
import CampaignCard from "./CampaignCard";
import { Row, Col, Icon, Input, Card, Button, message, Tabs , Pagination} from "antd";
import CampaignCardHolder from "./Styles/campaignCardStyle";
import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";
import { faInbox} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { TabPane } = Tabs;
const stateFilter = [ {"tab": 0, "value": "active"},
                      {"tab": 1, "value": "next"},
                      {"tab": 2, "value": "pending"},
                      {"tab": 3, "value": "payment_pending"},
                      {"tab": 4, "value": "previous"},
                      {"tab": 5, "value": "archived"}
                    ]
class CampaignsComponent extends Component {
  constructor(props) {
    super(props);
    this.searchCampaigns = this.searchCampaigns.bind(this);
    this.callback = this.callback.bind(this)
    this.changePage = this.changePage.bind(this)
    this.state = {
      search: "",
      state_filter : stateFilter[0].value,
      start: 0,
      length: 18,
      page: 1,
      draw: 1,
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.getCampaigns({...this.state});
    } else {
      this.props.setShouldPromptLogin(true, window.location.pathname);
      this.props.history.push("/");
    }
  }

  searchCampaigns(e){
    var value = e.target.value
    setTimeout(() => {
      this.setState({ search: value })
      this.props.getCampaigns({...this.state, search: value});
    }, 800)
  }

  changePage(pageCampaigns){
    this.props.setPageCampaigns(pageCampaigns);
    let startPage = (pageCampaigns - 1) * this.state.length
    this.props.getCampaigns({...this.state, start: startPage});
  }

  callback(key) {
    let state = stateFilter[parseInt(key)].value
    this.props.setPageCampaigns(1);
    this.setState({
      state_filter: state,
      start:0
    });
    this.props.getCampaigns({...this.state, state_filter: state, start: 0});
  }

  loaderCampaing(){
      return(
        <Row type="flex" gutter={16}>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Col key={i} xs={24} sm={24} md={12} lg={8} xl={8}>
              <CampaignCardHolder>
                <Card
                  className="campaignCard"
                  loading
                  style={{ padding: 20 }}
                />
              </CampaignCardHolder>
            </Col>
          ))}
        </Row>
      )
  }

  getMsgNotCampaigns(typeCampaign){
    return(
        <Card style={{marginBottom: "20px",}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#aaa",
              fontSize: "25px",
            }}
          >
          {" "}
          {this.props.t('No campaigns')}{" "}{typeCampaign.toLowerCase()}
          </div>
        </Card>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loadingDeleteCampaign && !nextProps.loadingDeleteCampaign) {
      if (nextProps.errors.length === 0) {
        message.success(this.props.t('Campaign successfully removed'));
        return;
      }
      if (nextProps.errors.message === 'You cannot delete a campaign with payments.') {
        message.error(this.props.t('You cannot delete campaigns with payments created'), 6);
      } else {
        message.error(this.props.t('An error occurred while deleting campaign'));
      }
    }
    if (nextProps.errors && nextProps.errors.length > 0 && nextProps.errors[0] === 'Token has expired') {
      message.error(this.props.t('Token expired, please login again'), 6);
      this.props.history.push("/");
      this.props.logout();
    }
  }

  render() {
    const { 
      t,
     } = this.props;
    const campaignStatus = {
      data: this.props.campaigns
    };

    return (
      <CampaignsHolder>
        <Row
         className="button-contanier"
         type="flex"
         justify="start">
          <Col xs={12} sm={12} md={4} lg={3} xl={3}>
                <Button
                  className="backButton"
                  onClick={() => this.props.history.goBack()}
                >
                  <Icon
                    style={{ fontSize: "18px", color: Colors.primary }}
                    type="left"
                  />{" "}
                  {t('Go back')}
                </Button>
          </Col>
        </Row>
        <Row
          className="campaignContainer"
          type="flex"
          justify="center"
        >
          <Col xs={23} sm={22} md={20} lg={18} xl={16}>
            <h1 className="mobileAlign">{t('My campaigns')}</h1>
            <Row
              className="campaignContainer row-search"
              type="flex"
              justify="start"
            >
              <Col xs={23} sm={22} md={12} lg={12} xl={12}>
                <Input
                  prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={t('Search')}
                  onChange={this.searchCampaigns}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <div  style={{ width:"100%"}}>
              <Tabs defaultActiveKey="0" onChange={this.callback}>

                <TabPane tab={t('Active')} key="0">
                { this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                  <>
                    {campaignStatus.data.length > 0 ? (
                      <div>
                        <Row type="flex" gutter={16}>
                          {campaignStatus.data.map(campaign => (
                            <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                              <CampaignCard 
                               user={this.props.user}
                               campaign={campaign}
                               deleteCampaign={this.props.deleteCampaign}
                               loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                               getReportRequest={this.props.getReportRequest}
                               reportToken={this.props.reportToken}
                               loadingReport={this.props.loadingReport}
                               getProposalRequest={this.props.getProposalRequest}
                               proposalToken={this.props.proposalToken}
                               loadingProposal={this.props.loadingProposal}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : this.getMsgNotCampaigns(t('Active'))}
                  </>
                }
                </TabPane>
                <TabPane tab={t('Next')} key="1">
                {  this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                  <>
                    {campaignStatus.data.length > 0 ? (
                      <div>
                        <Row type="flex" gutter={16}>
                          {campaignStatus.data.map(campaign => (
                            <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                              <CampaignCard 
                               user={this.props.user}
                               campaign={campaign}
                               deleteCampaign={this.props.deleteCampaign}
                               loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                               getReportRequest={this.props.getReportRequest}
                               reportToken={this.props.reportToken}
                               loadingReport={this.props.loadingReport}
                               getProposalRequest={this.props.getProposalRequest}
                               proposalToken={this.props.proposalToken}
                               loadingProposal={this.props.loadingProposal}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : this.getMsgNotCampaigns(t('Next'))}
                  </>
                }
                </TabPane>
                <TabPane tab={t('Pending approval')} key="2">
                {  this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                  <>
                  {campaignStatus.data.length > 0 ? (
                    <div>
                      <Row type="flex" gutter={16}>
                        {campaignStatus.data.map(campaign => (
                          <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}
                          >
                            <CampaignCard 
                             user={this.props.user}
                             campaign={campaign}
                             deleteCampaign={this.props.deleteCampaign}
                             loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                             getReportRequest={this.props.getReportRequest}
                             reportToken={this.props.reportToken}
                             loadingReport={this.props.loadingReport}
                             getProposalRequest={this.props.getProposalRequest}
                             proposalToken={this.props.proposalToken}
                             loadingProposal={this.props.loadingProposal}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : this.getMsgNotCampaigns(t('Pending approval'))}
                  </>
                }
                </TabPane>
                <TabPane tab={t('Still to be paid')} key="3">
                  {  this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                    <>
                      {campaignStatus.data.length > 0 ? (
                        <div>
                          <Row type="flex" gutter={16}>
                            {campaignStatus.data.map(campaign => (
                              <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                                <CampaignCard
                                  key={campaign.id}
                                  user={this.props.user}
                                  campaign={campaign}
                                  archiveCampaign={this.props.archiveCampaign}
                                  loadingArchiveCampaign={this.props.loadingArchiveCampaign}
                                  deleteCampaign={this.props.deleteCampaign}
                                  loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                                  getProposalRequest={this.props.getProposalRequest}
                                  proposalToken={this.props.proposalToken}
                                  loadingProposal={this.props.loadingProposal}
                                />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : this.getMsgNotCampaigns(t('Still to be paid'))}
                    </>
                  }
                </TabPane>
                <TabPane tab={t('Previous')} key="4">
                {  this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                  <>
                    {campaignStatus.data.length > 0 ? (
                      <div>
                        <Row type="flex" gutter={16}>
                          {campaignStatus.data.map(campaign => (
                            <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                              <CampaignCard
                                user={this.props.user}
                                campaign={campaign}
                                deleteCampaign={this.props.deleteCampaign}
                                loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                                archiveCampaign={this.props.archiveCampaign}
                                loadingArchiveCampaign={this.props.loadingArchiveCampaign}
                                getReportRequest={this.props.getReportRequest}
                                reportToken={this.props.reportToken}
                                loadingReport={this.props.loadingReport}
                                getProposalRequest={this.props.getProposalRequest}
                                proposalToken={this.props.proposalToken}
                                loadingProposal={this.props.loadingProposal}
                                status="previous"
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : this.getMsgNotCampaigns(t('Previous'))}
                  </>
                }
                </TabPane>
                <TabPane tab={<><FontAwesomeIcon icon={faInbox} className="icon-archived"/> {t('Archived')}</>} key="5">
                  {  this.props.fetching == true || this.props.fetching == undefined ? this.loaderCampaing():
                    <>
                      {campaignStatus.data.length > 0 ? (
                        <div>
                          <Row type="flex" gutter={16}>
                            {campaignStatus.data.map(campaign => (
                              <Col key={campaign.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                                <CampaignCard
                                  user={this.props.user}
                                  campaign={campaign}
                                  deleteCampaign={this.props.deleteCampaign}
                                  loadingDeleteCampaign={this.props.loadingDeleteCampaign}
                                  archiveCampaign={this.props.archiveCampaign}
                                  loadingArchiveCampaign={this.props.loadingArchiveCampaign}
                                  getReportRequest={this.props.getReportRequest}
                                  reportToken={this.props.reportToken}
                                  loadingReport={this.props.loadingReport}
                                  getProposalRequest={this.props.getProposalRequest}
                                  proposalToken={this.props.proposalToken}
                                  loadingProposal={this.props.loadingProposal}
                                  status="archived"
                                />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : this.getMsgNotCampaigns(t('Archived'))}
                    </>
                  }
                </TabPane>
              </Tabs>
            </div>
          </Col>
            <Col xs={23} sm={22} md={20} lg={18} xl={16} className="paginationCampaigns">
              { this.props.fetching ? null:
                  <Pagination
                    current={this.props.pageCampaigns}
                    pageSize={this.state.length}
                    total={this.props.totalCampaigns}
                    onChange={pageCampaigns => {
                    this.changePage(pageCampaigns);
                    }}
                  />
              }
            </Col>
        </Row>
      </CampaignsHolder>
    );
  }
}
export default withTranslation()(CampaignsComponent);
