import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router';

import AppContainer from '../containers/AppContainer'

export default ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/" component={AppContainer} />
    </Switch>
  </ConnectedRouter>
);
