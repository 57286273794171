import { connect } from "react-redux";
import CampaignManagerComponent from "../components/CampaignManagerComponent";
import DisplayActions from "../redux/DisplayRedux";
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  token: userAccount.token,
  campaignName: display.campaignName,
  campaignDescription: display.campaignDescription,
  cart: display.cart,
  defaultContent: display.defaultContent,
  creatingCampaign: display.creatingCampaign,
  editingCampaign: display.editingCampaign,
  errors: display.errors,
  campaign: display.campaign,
  client: display.client,
  brand: display.brand,
  amountBudget: display.amountBudget,
  realAmount: display.realAmount,
  defaultRules: display.defaultRules,
  programmatic: display.programmatic,
  editCampaignActive: display.editCampaignActive,
  currentCampaign: display.currentCampaign,
  customContents: display.customContents,
  loadingUploadFile: display.loadingUploadFile,
  fileNew: display.fileNew,
  canva: display.canva,
  isCampaignTest: display.isCampaignTest,
  reportSingleData: display.reportSingleData,
  currency: userAccount.currency,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  audience : display.audience,
  date_from: display.date_from,
  date_to: display.date_to,
  displays: display.displays,
  dsp: display.dsp,
  isSuperUser: userAccount.isSuperUser,
  listResolutionWithContent: display.listResolutionWithContent,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  amountBudget: display.amountBudget,
  isPendingCurrentCampaign: display.isPendingCurrentCampaign,
  payments: display.payments,
  toleranceResolution: display.toleranceResolution,
  listBundle: display.listBundle,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
});

const mapStateToDispatch = dispatch => ({
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  setCampaignName: campaignName => dispatch(DisplayActions.setCampaignName(campaignName)),
  setCampaignDescription: campaignDescription => dispatch(DisplayActions.setCampaignDescription(campaignDescription)),
  setIsCampaignTest: isCampaignTest => dispatch(DisplayActions.setIsCampaignTest(isCampaignTest)),
  setIsCampaignPaused: isCampaignPaused => dispatch(DisplayActions.updateFieldInCustomContents(null, isCampaignPaused ? 0 : 1, "active", "multiple")),
  updateContentName: (relationId, value)  => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "content", "single")),
  setDefaultContent: content => dispatch(DisplayActions.setDefaultContent(content)),
  updateDefaultContentRequest: id => dispatch(DisplayActions.updateDefaultContentRequest(id)),
  updateCustomContents: customContents => dispatch(DisplayActions.updateCustomContents(customContents)),
  updateSubsidizedPrice: (relationId, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "subsidized_price", typePreview)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  uploadFileRequest: (file) => dispatch(DisplayActions.uploadFileRequest(file)),
  setCanvaInit: (canva) => dispatch(DisplayActions.setCanvaInit(canva)),
  editCampaignInactive: (campaign) => dispatch(DisplayActions.editCampaignInactive(campaign)),
  editCampaignTotalPromisedShows: (totalPromisedShows) => dispatch(DisplayActions.editCampaignTotalPromisedShows(totalPromisedShows)),
  updateRealAmount: (realAmount) => dispatch(DisplayActions.updateRealAmount(realAmount)),
  updateImpressionsDisplay: (displayId, relationId, impressions) => dispatch(DisplayActions.updateImpressionsDisplay(displayId, relationId, impressions)),
  addCustomContentFromCampaign: (relationId, indice_relation, displayId, fill_screen, isContentMixed, promised_shows, length, content, rules, subsidized_price, promised_shows_subsidized, content_name ) => dispatch(DisplayActions.addCustomContentFromCampaign(relationId, indice_relation, displayId, fill_screen, isContentMixed, promised_shows, length, content, rules, subsidized_price, promised_shows_subsidized, content_name)),
  createCampaign: (params) => dispatch(DisplayActions.createCampaignRequest(params)),
  clearCustomContents: () => dispatch(DisplayActions.clearCustomContents()),
  editCampaign: (campaignId, params) => dispatch(DisplayActions.editCampaignRequest(campaignId, params)),
  getCampaignPayments: (id) => dispatch(DisplayActions.getCampaignPaymentsRequest(id)),
  activateEditCampaign: (campaign) => dispatch(DisplayActions.activateEditCampaign(campaign)),
  setListOfContentsInCampaign: (contents) => dispatch(DisplayActions.setListOfContentsInCampaign(contents)),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CampaignManagerComponent);
