import { connect } from "react-redux";
import RefinateFiltersForm from "../components/RefinateFiltersForm";
import DisplayActions from "../redux/DisplayRedux";

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  date_from: display.date_from,
  date_to: display.date_to,
  price_min: display.price_min,
  price_max: display.price_max,
  shows_per_day_min: display.shows_per_day_min,
  location_type: display.location_type,
  size_type: display.size_type,
  clients: display.clients,
  client: display.client,
  isCircuit: display.isCircuit,
  brands: display.brands,
  brand: display.brand,
  countries: display.countries,
  loadingClients: display.braloadingClientsnd,
  loadingBrands: display.loadingBrands,
  location_sub_type: display.location_sub_type
});

const mapStateToDispatch = (dispatch) => ({
  setPrice: (prices) => dispatch(DisplayActions.setPrice(prices)),
  setShowsPerDayMin: (shows_per_day_min) =>
    dispatch(DisplayActions.setShowsPerDayMin(shows_per_day_min)),
  setLocationType: (types) => dispatch(DisplayActions.setLocationType(types)),
  setLocationSubType: (subType) => dispatch(DisplayActions.setLocationSubType(subType)),
  setDates: (from, to) => dispatch(DisplayActions.setDates(from, to)),
  setSizeType: (sizes) => dispatch(DisplayActions.setSizeType(sizes)),
  clearBrands: (id) => dispatch(DisplayActions.clearBrands(id)),
  getClients: (userId) => dispatch(DisplayActions.getClientsRequest(userId)),
  setClient: (client) => dispatch(DisplayActions.setClient(client)),
  setCircuit: (isCircuit) => dispatch(DisplayActions.setCircuit(isCircuit)),
  getBrands: (clientId) => dispatch(DisplayActions.getBrandsRequest(clientId)),
  setBrands: (brands) => dispatch(DisplayActions.setBrands(brands)),
  setBrand: (brand) => dispatch(DisplayActions.setBrand(brand)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  cleanAudience: () => dispatch(DisplayActions.cleanAudience()),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(RefinateFiltersForm);
