import styled from "styled-components";
import Colors from "../../themes/Colors";

export const CollapseHolder = styled.div`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
    ${"" /* color: ${Colors.snow} */}
  }

  .ant-collapse-content > .ant-collapse-header {
    background: white;
    border-radius: 16px 16px 4px 4px;
  }
`;

export const SelectionFiltersHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  position: static;
  left: 10px;
  top: 10px;

  background-color: ${Colors.snow};
  border-radius: 16px;

  z-index: 100;

  .ant-form-explain{
    margin-left: 10px;
  }

  .buttonPrimary {
    border-radius: 8px;
  }

  .ant-affix {
    z-index: 99;
  }

  .ant-collapse-header {
    @media only screen and (min-width: 1100px) {
      display: none;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: ${Colors.primary};
    color: ${Colors.snow};
    font-weight: 600;
    font-size: 18px;
  }

  .inputSelect {
    width: 100%;
    padding: 0px 0px 0 10px;

    ${"" /* @media (max-width: 575px) {
      margin-bottom: 15px;
    } */}
  }

  .inputSelectBudget {
    width: 100%;
    padding: 5px 0px 0px 0px;

    ${"" /* @media (max-width: 575px) {
      margin-bottom: 15px;
    } */}
  }

  .inputBudget {
    width: 100%;
    padding: 0px 0px 0 10px;

    ${"" /* @media (max-width: 575px) {
      margin-bottom: 15px;
    } */}
  }


  .noWrap {
    flex-wrap: nowrap;
    padding-bottom: 20px;
  }

  .typePurchase {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }

  .longInput {
    padding: 0 10px 20px 10px;
    width: 100%;
  }
  .longInputTwo {
    padding: 0 10px 0px 10px;
    width: 100%;
  }
  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    line-height: 0;
  }

  .companySelect {
    width: 100%
    margin: 0 0 15px;
  }
  .brandSelect {
    width: 100%
    margin: 0 0 15px;
  }
  .ant-select-selection__rendered {
    line-height: 3
    5px;
  }


  .date {
    cursor: pointer;
    margin: 0 0 10px;
    color: ${Colors.secondary};
    @media only screen and (min-width: 992px) {
      margin: 0;
    }
  }
  .date.final-date {
    cursor: default;
  }
  .date p {
    color: ${Colors.primary};
  }
  .option {
    margin: 0 0 10px;
    color: ${Colors.coal};
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      margin: 0;
    }
  }
  .option p {
    color: ${Colors.coal};
  }

  p {
    margin: 0;
  }
  .filterButton {
    width: 100%;
    margin: 0 5px;
    padding: 2px 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 900px) {
      justify-content: center;
    }
  }
  .filterButton:hover {
    background-color: #f2f2f2;
  }
  .filterButton.ant-popover-open {
    background-color: #f2f2f2;
  }
  .filterButtonDates {
    width: 100%;
    display: flex;
    justify-content: space-between
    align-items: center;
  }
  .filterButtonDate {
    margin-right: 55px;
  }
  .filterCurrency span{
    font-size: 11px !important;
  }
  .inputWidth {
    width: 33%;
  }
  .purchaseTypeButton {
    display: flex;
    align-items: center;
    border: 0px;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .inputSelectOneLine{
    @media (max-width: 570px) {
      width: 28%;
    }
    @media (max-width: 980px) {
      width: 33%;
    }
    @media (max-width: 768px) {
      width: 35%;
    }
    @media (min-width: 1480px) {
      width: 13%;
    }
  }
  .purchaseTypeButtonSelected {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 0px;
    background-color: ${Colors.extraLightPrimary} !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-btn-background-ghost.ant-btn-primary {
    color: ${Colors.primary} !important;
  }
`;


