import React, { useState} from 'react';
import { Icon, Row, Col, DatePicker, Tooltip } from "antd";
import { getTotalPice } from "../services/display";
import NumberFormat from "./NumberFormat";
import moment from 'moment';
import CardGeneralHolder from "./Styles/cardGeneralStyle";
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';


const CardGeneralComponent = (props) => {
  const { t, i18n } = useTranslation();
  const { editCampaignActive, currentCampaign, date_from, date_to, setDates, cart, programmatic, amountBudget, currency, invoiceIssuingCountry } = props;
  const [endOpen, setEndOpen] = useState(false);

  const disabledDateEnd = (endValue) => {
    if (!endValue || !date_from) {
      return false;
    }
    return endValue.isBefore(date_from);
  };
  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const disabledDate = !(!editCampaignActive ||
    (currentCampaign.smart_campaign ||
      (!currentCampaign.smart_campaign &&
        currentCampaign.payment_status !== "approved")));

  return (
    <CardGeneralHolder>
      <div className="cardGeneral">
        <Row justify="center">
          <Col className={"text-title"}>{t("General")}<br /></Col>
          <Col>
            <div className="calendar-container">
              <div className='contentDate'>
                <div className='infoDate'>{t("Start date")}</div>
                <DatePicker
                  allowClear={false}
                  value={moment(date_from)}
                  format={i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY"}
                  suffixIcon={<Icon type="down" />}
                  onChange={(date) => {
                    setDates(
                      date.format("YYYY-MM-DD"),
                      date_from
                    );
                  }}
                  onOpenChange={handleStartOpenChange}
                  disabled={disabledDate}
                />
              </div>
              <div className='contentDate'>
                <div className='infoDate'>{t("Finish date")}</div>
                <DatePicker
                  format={i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY"}
                  style={{ backgroundColor: '#D7EAFD' }}
                  allowClear={false}
                  value={moment(date_to)}
                  suffixIcon={<Icon type="down" />}
                  disabledDate={disabledDateEnd}
                  onChange={(date) => {
                    setDates(
                      date_from,
                      date.format("YYYY-MM-DD")
                    );
                  }}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                  disabled={disabledDate}
                />
              </div>
               </div>
          </Col>
          <Col>
            <div className='infoDate'>{t("Number of screens")}</div>
            <div className="content-info">
              {cart.length}
            </div>
          </Col>
          <Col>
            <div className='infoDate'>
              { programmatic === "programmatic" ? (
                <span>
                  {t("Campaign budget")}{" "}
                  <Tooltip
                    placement="left"
                    title={t(
                      "You can configure the impressions per screen and see how the updated budget varies according to the impact you want."
                    )}
                  >
                    <Icon type="question-circle" theme="outlined" />
                  </Tooltip>
                </span>
              ) : (
                t("Total price")
              )}</div>
              <div className="content-info" style={{width: "95%", fontSize: "13px"}}>
                <NumberFormat
                  tax={invoiceIssuingCountry != 'F'}
                  value={ programmatic === 'programmatic' ?
                      Number(amountBudget).toFixed(2)
                      : getTotalPice(cart, date_from, date_to)
                  }
                  currency= { cart[0] && cart[0].id ? cart[0].price_currency : currency }
                />
            </div>
          </Col>
        </Row>
      </div>
    </CardGeneralHolder>
  );
};

export default CardGeneralComponent;