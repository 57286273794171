export const markerIndoorOnCart = () => {
  const primary = getComputedStyle(document.documentElement).getPropertyValue('--primaryPins').trim(); 
  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
          .cls-1 {
            stroke-width: 1.56px;
          }

          .cls-1, .cls-2 {
            stroke-miterlimit: 10;
          }

          .cls-1, .cls-3 {
            fill: #fff;
            stroke: #192a39;
          }

          .cls-2 {
            fill: ${primary};
            stroke: #1d71b8;
          }

          .cls-3 {
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.56px;
          }
        </style>
      </defs>
      <g id="Group_13">
        <path id="Path_8" class="cls-2" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
      </g>
      <line class="cls-3" x1="25.35" y1="48.95" x2="25.35" y2="29.14"/>
      <rect class="cls-1" x="10.72" y="18.92" width="29.32" height="17.52" rx="3.42" ry="3.42" transform="translate(53.06 2.3) rotate(90)"/>
    </svg>`;
  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};