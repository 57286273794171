import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tabs, Radio } from 'antd';
import ScreenHolder from "./Styles/screenComponentStyle";
import { Card, Button, Icon, Row, Col, Tooltip } from "antd";
import { withTranslation } from "react-i18next";
import { metabaseUrl } from "../services/utils";
import  chartsEnum from "../constants/AudienceChartEnum";
import {getRefinedAudience} from "../services/display";
import moment from "moment";
import  AudienceProviderInfo  from "./AudienceProviderInfo";
import AudienceGraphGeneralComponent from "./AudienceGraphGeneralComponent";
import AudienceGraphByDayComponent from "./AudienceGraphByDayComponent";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";

const { TabPane } = Tabs;

class AudienceSummaryComponentGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'general',
      audienceHoursOfDay: null,
      audienceDaysOfWeek: null,
    };
  }

  componentDidMount() {
    this.loadAudienceData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.display.id !== this.props.display.id) {
      this.loadAudienceData();
    }
    if (prevProps.audienceHoursOfDay !== this.props.audienceHoursOfDay) {
      this.setState({ audienceHoursOfDay: this.props.audienceHoursOfDay });
    }
    if (prevProps.audienceDaysOfWeek !== this.props.audienceDaysOfWeek) {
      this.setState({ audienceDaysOfWeek: this.props.audienceDaysOfWeek });
    }
  }

  loadAudienceData = () => {
    const { display } = this.props;

    if (display && display.id) {
      this.setState({ audienceHoursOfDay: null, audienceDaysOfWeek: null });

      this.getAudienceHoursOfDay(display.id);
      this.getAudienceDaysOfWeek(display.id);
    }
  };

  getAudienceHoursOfDay = (displayId) => {
    const params = { display_ids: displayId, days: [] };
    this.props.getAudienceHoursOfDayRequest(params)
  };

  getAudienceDaysOfWeek = (displayId) => {
    this.props.getAudienceDaysOfWeekRequest(displayId)
  };

  getTypeAudience = (noRefined, refined) => {
    const { user, campaign} = this.props;
    let audienceRefined = getRefinedAudience( user, campaign ? campaign.programmatic_provider_id: null)
    return audienceRefined ? refined : noRefined;
  }

  handleModeChange = e => {
    const mode = e.target.value;
    this.setState({ mode });
  };

  getAudienceGraph = (general) => {
    const { user, campaign, display, getAudienceHoursOfDayRequest } = this.props;
    const { audienceHoursOfDay, audienceDaysOfWeek } = this.state;
    let refined = getRefinedAudience( user, campaign ? campaign.programmatic_provider_id: null)
    if (refined && general) {
      return(
        <AudienceGraphGeneralComponent
          audiencehoursOfDay={ audienceHoursOfDay }
          audienceDaysOfWeek={ audienceDaysOfWeek }
          displayId={display.id} />);
    } else if(refined && !general) {
      return(
        <AudienceGraphByDayComponent
          audiencehoursOfDay={ audienceHoursOfDay }
          displayId={display.id}
          getAudienceHoursOfDayRequest={ getAudienceHoursOfDayRequest } />);
    } else if(!general) {
      return(
        <iframe
          id="audience-by-day-of-week"
          title="audience-by-day-of-week"
          src={
            `${metabaseUrl}${this.getTypeAudience(chartsEnum.audienceGreneralPerday, chartsEnum.audienceGreneralPerdayRefined)}?idtoken=${user.company.id_token}&displayid=${display.id}#hide_parameters=displayid,idtoken,archived`
          }
          frameBorder="0"
          scrolling="yes"
          width="100%"
          height="600px"
        ></iframe>)
    } else {
      return (
        <iframe
          id="audience-general"
          title="audience-general"
          src={`${metabaseUrl}${this.getTypeAudience(chartsEnum.audienceGreneral,chartsEnum.audienceGreneralRefined)}?idtoken=${user.company.id_token}&displayid=${display.id}#hide_parameters=displayid,idtoken,archived`}
          frameBorder="0"
          scrolling="yes"
          width="100%"
          height="600px"
        ></iframe>
      );
    }
  }

  render() {
    const { mode, audienceHoursOfDay, audienceDaysOfWeek } = this.state;
    const { t, display, user} = this.props;
    return (
      <Tabs defaultActiveKey="1">
          <TabPane tab={t("General")} key="1">
            <AudienceProviderInfo displays={[display]} user={user}/>
            {audienceHoursOfDay && audienceDaysOfWeek ? (
              this.getAudienceGraph(true)
            ) : (
              <GenerateSkeletonComponent
                count={1}
                height={400}
                width="100%"
                type="graph"
              />
            )}
          </TabPane>
          <TabPane tab={t("By day of week")} key="2">
            <AudienceProviderInfo displays={[display]} user={user}/>
            {audienceDaysOfWeek ? (
              this.getAudienceGraph(false)
            ) : (
              <GenerateSkeletonComponent
                count={1}
                height={400}
                width="100%"
                type="graph"
              />
            )}
          </TabPane>
      </Tabs>
    );
  }
}

AudienceSummaryComponentGeneral.contextTypes = {
  router: PropTypes.object,
};

const Extended = withTranslation()(AudienceSummaryComponentGeneral);
Extended.static = AudienceSummaryComponentGeneral.static;

export default AudienceSummaryComponentGeneral;
