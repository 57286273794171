import React from 'react'
import { Provider } from 'react-redux'
import { history, store, persistor } from './redux';
import NavigationRouter from './navigation/NavigationRouter'
import { PersistGate } from 'redux-persist/integration/react'

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NavigationRouter history={history} />
    </PersistGate>
  </Provider>
);

export default App
