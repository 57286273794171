import React, { useEffect, useState } from "react"
import { Button, Col, Icon, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";

export const CanvaModal = ({ visible, canva, setCanvaInit, uploadFileRequest, tag, onClose }) => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);

  const api_canva = process.env.REACT_APP_CANVA_KEY

  const createCanvaDesign = () => {
    onClose();
    canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: width,
          height: height,
          units: 'px',
        },
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        const tagId = tag ? tag.key : null;
        let blob = null;
        let xhr = new XMLHttpRequest();

        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = (e, a) => {
          console.log("🚀 ~ createCanvaDesign ~ e:", e, a);
          console.log("🚀 ~ createCanvaDesign ~ xhr:", xhr);
          console.log("🚀 ~ createCanvaDesign ~ xhr.response:", xhr.response);
          blob = xhr.response;//xhr.response is now a blob object
          blob.lastModifiedDate = new Date();
          blob.canva_id = designId;
          blob.file = blob;
          blob.tags = [tagId];
          uploadFileRequest(blob)
        }

        xhr.send();
      },
    });
  };

  const init = () => {
    if (!window.Canva || !window.Canva.DesignButton) return;

    window.Canva.DesignButton.initialize({
      apiKey: api_canva,
    }).then((canva) => {
      setCanvaInit(canva)
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="modalCanva">
        <h2>{t("What resolution will your main Spot have?")}</h2>
      </div>
      <Row className="modalContent" type="flex" style={{ gap: 20 }}>
        <Input
          autoFocus
          name="width"
          className="modalInput"
          placeholder={t("Width (px)")}
          defaultValue={width}
          onChange={(e) => setWidth(e.target.value)}
          prefix={<Icon type="column-width" className="inputIcon" />}
          type="number"
          suffix="px"
          autoComplete="off"
        />
        <Input
          name="height"
          className="modalInput"
          placeholder={t("Height (px)")}
          defaultValue={height}
          onChange={(e) => setHeight(e.target.value)}
          prefix={<Icon type="column-height" className="inputIcon" />}
          onPressEnter={createCanvaDesign}
          type="number"
          suffix="px"
          autoComplete="off"
        />
        {t('You will need to create an account and log in the first time you want to create a design.')}
        <Button
          className="modalButton"
          onClick={createCanvaDesign}
        >
          {t("Start")}
        </Button>
      </Row>
    </Modal>
  )
}
