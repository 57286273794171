import { connect } from 'react-redux'
import SearchBoxComponent from '../components/SearchBoxComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount  }) => ({
  user: userAccount.user,
  clients: display.clients,
  client: display.client,
  loadingClients: display.loadingClients,
  brands: display.brands,
  brand: display.brand,
  loadingBrands: display.loadingBrands,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  mapZoom: display.mapZoom,
  audience: display.audience,
  loadingAudience: display.loadingAudience,
  currency: userAccount.currency,
  dsps: display.dsps,
  dsp: display.dsp,
  editCampaignActive: display.editCampaignActive,
  isSuperUser: userAccount.isSuperUser,
  conversionRates: display.conversionRates,
})

const mapStateToDispatch = (dispatch) => ({
  resetFilters: (id) => dispatch(DisplayActions.resetFilters(id)),
  setCoords: coords => dispatch(DisplayActions.setCoords(coords)),
  setMapZoom: zoom => dispatch(DisplayActions.setMapZoom(zoom)),
  getClients: (userId) => dispatch(DisplayActions.getClientsRequest(userId)),
  setClient: (client) => dispatch(DisplayActions.setClient(client)),
  getExternalDsps: () => dispatch(DisplayActions.getExternalDspsRequest()),
  setDsp: (dsp) => dispatch(DisplayActions.setDsp(dsp)),
  clearDsp: () => dispatch(DisplayActions.clearDsp()),
  getBrands: (clientId) => dispatch(DisplayActions.getBrandsRequest(clientId)),
  setBrands: (brands) => dispatch(DisplayActions.setBrands(brands)),
  setBrand: (brand) => dispatch(DisplayActions.setBrand(brand)),
  clearBrands: (id) => dispatch(DisplayActions.clearBrands(id)),
  clearClients: (id) => dispatch(DisplayActions.clearClients(id)),
  setProgrammatic: (programmatic) => dispatch(DisplayActions.setProgrammatic(programmatic)),
  setBudget: (amountBudget) => dispatch(DisplayActions.setBudget(amountBudget)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  removeDisplaysCart: () => dispatch(DisplayActions.removeDisplaysCart()),
  getConversionRates: () => dispatch(DisplayActions.getConversionRatesRequest()),
})

export default connect(mapStateToProps, mapStateToDispatch)(SearchBoxComponent)
