// List of gtag events: https://developers.google.com/gtagjs/reference/event
const googleAnalyticsMiddleware = store => next => action => {
  if (window.gtag) {
    switch (action.type) {
      case '@@router/LOCATION_CHANGE':
        window.gtag('event', 'page_view', {
          'page_path': action.payload.location.pathname,
        });
        break;
      case 'USER_LOGOUT':
        window.gtag('event', 'logout', {
          'event_category': 'engagement',
        });
        break;
      case 'USER_REGISTER_REQUEST':
        window.gtag('event', 'register_attempt', {
          'event_category': 'engagement',
          'event_label': action.user.email,
        });
        break;
      case 'USER_REGISTER_FAILURE':
        window.gtag('event', 'register_failure', {
          'event_category': 'engagement',
          'event_label': action.errors[0],
        });
        break;
      case 'USER_LOGIN_REQUEST':
        window.gtag('event', 'login_attempt', {
          'event_category': 'engagement',
          'event_label': action.email,
        });
        break;
      case 'USER_LOGIN_SUCCESS':
        window.gtag('event', 'login', {
          'method': 'Password'
        });
        window.gtag('set', {
          'user_id': action.user.id,
        });
        break;
      case 'USER_LOGIN_FAILURE':
        window.gtag('event', 'login_failure', {
          'event_category': 'engagement',
          'event_label': action.errors[0],
        });
        break;
      case 'ADD_DISPLAY_TO_CART':
        window.gtag('event', 'add_to_cart', {
          'items': [{
            'id': action.display.id,
            'name': action.display.name,
            'price': action.display.price_per_day,
          }]
        });
        break;
      case 'REMOVE_DISPLAY_FROM_CART':
        window.gtag('event', 'remove_from_cart', {
          'items': [{
            'id': action.displayId,
            'name': '-',
          }]
        });
        break;
    }
  }
  return next(action);
};

const trackOutboundLink = function(url, e) {
  e.preventDefault();

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  // Send data
  if (window.gtag) {    
    window.gtag('event', 'click', {
      'event_category': 'external_links',
      'event_label': url,
      'transport_type': 'beacon',
    });
  }
  // Open url in new tab
  openInNewTab(url)
}

export { trackOutboundLink , googleAnalyticsMiddleware };

export default googleAnalyticsMiddleware;