import React from "react";
import { Button, Collapse, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import ContentsLibraryContainer from "../containers/ContentsLibraryContainer";
import ContentsOfCampaignContainer from "../containers/ContentsOfCampaignContainer";
import ModalCampaignManagerHolder from "./Styles/modalCampaignManager";

const { Panel } = Collapse;
const ModalEditCampaignManagerComponent = ({
  visible,
  groupName,
  onCancel,
  groupedDisplays,
  selectedContent,
  applyRelationOnScreens,
  //filterByContent = null,
  isPaymentStatusApproved
}) => {

  const { t } = useTranslation();
  return (
    <Modal
      title={(
        <Row type="flex" style={{ gap: 4 }}>
          <p>{t("Manage ads and screens")}</p>
          <strong className="first-capitalize">{groupName}</strong>
        </Row>
      )}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1366}
    >
      <ModalCampaignManagerHolder>
        <Collapse defaultActiveKey={["1", "2"]} >
          <Panel header={t("Ad library")} key="1">
            <ContentsLibraryContainer
              groupedDisplays={groupedDisplays}
              // filterByContent={filterByContent}
            />
          </Panel>
          <Panel header={t("Campaign Ads")} key="2">
            <ContentsOfCampaignContainer
              groupedDisplays={groupedDisplays}
              groupName={groupName}
              selectedContent={selectedContent}
              applyRelationOnScreens={applyRelationOnScreens}
              // filterByContent={filterByContent}
              isPaymentStatusApproved={isPaymentStatusApproved}
            />
          </Panel>
        </Collapse>
        <Row type="flex" justify="end">
          <Button type="primary" onClick={() => { onCancel() }}>
            {t("Close")}
          </Button>
        </Row>
      </ModalCampaignManagerHolder>
    </Modal>
  );
};

export default ModalEditCampaignManagerComponent;