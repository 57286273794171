import { connect } from 'react-redux'
import RequestSpecialCpmComponent from "../components/RequestSpecialCpmComponent";
import DisplayActions from '../redux/DisplayRedux';

const mapStateToProps = ({ display, userAccount }) => ({
  requestingSpecialCpm: display.requestingSpecialCpm,
  requestSpecialCpmSuccessful: display.requestSpecialCpmSuccessful,
  errors: display.errors,
  currency: userAccount.currency
})

const mapStateToDispatch = (dispatch) => ({
  requestSpecialCpm: (displayId, requestedPrice, currency) => dispatch(DisplayActions.requestSpecialCpmRequest(displayId, requestedPrice, currency)),
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(RequestSpecialCpmComponent);
