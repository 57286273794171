import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import AudienceGraphHolder from "./Styles/audienceGraphStyle";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";
import { Button, Select, Tooltip } from "antd";
import { getDaysOfWeek } from '../services/utils';

class AudienceGraphByDayComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audienceHoursData: null,
            selectedDays: [],
        };
    }

    componentDidMount() {
        this.prepareAudienceData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.audiencehoursOfDay !== this.props.audiencehoursOfDay) {
            this.prepareAudienceData();
        }
    }

    handleDayChange = (selectedDays) => {
        this.setState({ selectedDays });
    };

    prepareAudienceData() {
        const { t, audiencehoursOfDay } = this.props;

        if (!audiencehoursOfDay || !audiencehoursOfDay.data) {
            return;
        }

        const hoursData = {
            labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
            datasets: [
                {
                    label: t('Audience by time of day'),
                    data: audiencehoursOfDay.data.map(hour => hour.audience),
                    backgroundColor: `rgba(113, 114, 173, 1)`,
                    borderColor: `rgba(113, 114, 173, 1)`,
                    borderWidth: 1,
                    fill: true,
                }
            ]
        };

        this.setState({
            audienceHoursData: hoursData
        });
    }

    filterByDays() {
        const { selectedDays } = this.state;
        if (selectedDays.length > 0) {
            const params = {
                display_ids: this.props.displayId,
                days: selectedDays.join(','),
            };
            this.props.getAudienceHoursOfDayRequest(params);
        }
    }

    getChartOptions = () => {
        const { t } = this.props;
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: t('Audience Average'),
                    }
                },
                x: {
                    beginAtZero: true,
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
            }
        };
    };

    render() {
        const { t } = this.props;
        const { audienceHoursData, selectedDays } = this.state;
        const daysOfWeek = getDaysOfWeek(t);
        const chartOptions = this.getChartOptions();

        return (
            <AudienceGraphHolder>
                <div className="audience-graph">
                    <span className="inputSelect">
                        <Select
                            allowClear
                            placeholder={t("Select days of the week")}
                            style={{ width: "40%" }}
                            mode="multiple"
                            onChange={this.handleDayChange}
                            value={selectedDays}
                        >
                            {daysOfWeek.map((day) => (
                                <Select.Option key={day.value} value={day.value}>
                                    {day.label}
                                </Select.Option>
                            ))}
                        </Select>
                        <Tooltip placement="topLeft" title={t('Filter audience by day of the week')}>
                            <Button
                                size="default"
                                className="btn-secondary"
                                type="secondary"
                                target="_blank"
                                style={{ marginBottom: "10px", marginLeft: "10px" }}
                                onClick={() => this.filterByDays()}>
                                {t('Filter')}
                            </Button>
                        </Tooltip>
                    </span>
                    <h5>{t('Average audience per hour in the day')}</h5>
                    <div className="audience-graph-container">
                        {audienceHoursData ? (
                            <Bar data={audienceHoursData} options={chartOptions} />
                        ) : (
                            <GenerateSkeletonComponent
                                count={7}
                                height={300}
                                width="100%"
                            />
                        )}
                    </div>
                </div>
            </AudienceGraphHolder>
        );
    }
}

export default withTranslation()(AudienceGraphByDayComponent);
