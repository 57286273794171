import React, { Component } from "react";
import PropTypes from "prop-types";
import NotFoundComponent from "./Styles/NotFoundComponentStyle";
import Footer from "./Footer";

import { Row, Col, Button, Icon } from "antd";
import imageRobot from "../images/not-found-img2.png";
import logoWhite from "../images/logo-latinad-white.png";

import { withTranslation } from 'react-i18next';

class HomeComponent extends Component {

  render() {
    const { t } = this.props;
    return (
      <NotFoundComponent>
        <div className="NotFoundContainer">
          <Col xs={24} sm={10} md={10} lg={10} xl={11}>
            <div className="leftSideContainer">
              <img src={logoWhite} className="logLatinadWhite" alt="" />
              <p>
                {t('We look everywhere for the page')} <br />
                <span style={{ fontSize: "26px"}}>{t('(404 error)')}</span>
              </p>
              <Button
                className='goToHome'
                onClick={() => {}}
                onClick={() => this.props.history.replace(`/`)}
              >
                {t('Go to LatinAd')}
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={14} md={14} lg={14} xl={13} style={{textAlign: "right"}}>
            <img src={imageRobot} className="imageBg" alt="" />
          </Col>
        </div>
        <Footer />
      </NotFoundComponent>
    );
  }
}

HomeComponent.contextTypes = {
  router: PropTypes.object
};

export default withTranslation()(HomeComponent);
