import styled from "styled-components";
import Colors from "../../themes/Colors";

const CheckoutStepsHolder = styled.div`
  min-height: 85px;
  padding: 15px 10px;


  .backButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;

    @media only screen and (min-width: 576px) {
      margin-bottom: 0px;
    }
  }

  .continueButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    border-radius: 8px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;

    @media only screen and (min-width: 576px) {
      margin-bottom: 0px;
    }
  }

  .checkoutInfo {
    font-size: 16px;
    color: ${Colors.primary};
    margin-bottom: 5px;
    background-color: ${Colors.snow};
    border-radius: 8px;
    border: 1px solid ${Colors.lightPrimary};
    margin-right: 5pxpx;
    margin-left: 5px;
    text-align: center;
  }

  .checkoutButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .date {
    cursor: pointer;
    margin: 0 0 10px;
    color: ${Colors.snow};
    @media only screen and (max-width: 425px) {
      margin: 0 0 6px 0px;
    }
  }
  .date.final-date {
    cursor: default;
  }
  .date p {
    color: ${Colors.primary};
  }
  .option {
    margin: 0 0 10px;
    color: ${Colors.snow};
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      margin: 0;
    }
  }
  .option p {
    color: ${Colors.snow};
  }

  p {
    margin: 0;
  }
  .filterButton .anticon {
    color: ${Colors.snow} !important;
  }
  .filterButton {
    background-color: #fff;
    color: ${Colors.primary};
    padding: 2px 5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${Colors.lightPrimary};

    @media only screen and (min-width: 768px) {
      justify-content: center;
    }
  }
  .filterButton:hover {
    background-color: #fff;
    .filterButtonDates {
      color: ${Colors.primary};
    }
    .filterButtonDates p {
      color: ${Colors.primary};
    }
    .anticon {
      color: ${Colors.primary} !important;
    }
  }
  .filterButton.ant-popover-open {
    background-color: #fff;
    .filterButtonDates {
      color: ${Colors.primary};
    }
    .filterButtonDates p {
      color: ${Colors.primary};
    }
    .anticon {
      color: ${Colors.primary} !important;
    }
  }
  .filterButtonDates {
    display: flex;
    align-items: center;
    color: ${Colors.primary};
  }
  .filterButtonDate {
    margin-right: 15px;
  }

  .displaysQuantity {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export default CheckoutStepsHolder;
