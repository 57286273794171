import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { withTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

class GenderChartComponent extends Component {

  constructor(props) {
    super(props);
  }
  setting = (data) => {
    const { t } = this.props;
    return {
      labels: [
        t("Male")+" "+"%",
        t("Female")+" "+"%",
      ],
      datasets: [
        {
          label: "qqq",
          data: data,
          backgroundColor: [
            "rgba(53, 162, 235, 0.7)",
            "rgba(255, 99, 132, 0.7)",
          ],
          borderColor: ["rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)"],
          borderWidth: 1,
        },
      ],
    };
  };

  render() {
    var configChart = this.setting(this.props.data);
    return <Pie redraw={false} data={configChart} />;
  }
}
export default withTranslation()(GenderChartComponent);