import React, { Component } from "react";
import { Modal, Icon, Radio, Row, Collapse, Alert } from "antd";
import storage from "redux-persist/lib/storage";
import { withTranslation } from "react-i18next";

const { Panel } = Collapse;

class LanguageCurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.props.getCountries();
    this.props.getlocation();
    this.state = {
      language: this.props.i18n.language,
      currencies: [],
      selectedCountry: this.props.billingCountry,
      selectedCodeCountry: this.props.invoiceIssuingCountry,
      selectedCurrency: [],
    };
    document.documentElement.lang = 'es';
    this.defaultCurrency();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.editCampaignActive !== this.props.editCampaignActive){
      this.defaultCurrency("initCurrency");
    } else if (
      nextProps.location !== this.props.location ||
      nextProps.countries !== this.props.countries ||
      nextProps.dsp !== this.props.dsp){
      this.defaultCurrency();
    }

  }

  defaultCurrency(prevBilling=null) {
    const { countries,
            location,
            user ,
            setBilling,
            billingCountry,
            currentCampaign,
            editCampaignActive,
            dsp,
            programmatic,
            isSuperUser
            } = this.props;

    let matchCountry, currencySelected;

    if ((dsp && dsp.id) && !(programmatic == "traditional" && !isSuperUser)) {
      // Configure international currency and disable other options when the DPS is not Latin.
      matchCountry = countries.filter((country) => country.alpha_2_code === "F")
    } else {
      if(editCampaignActive && currentCampaign){
        matchCountry = countries.filter((country) => country.name === (currentCampaign.invoice_issuing && currentCampaign.invoice_issuing.name ?
                                                                        currentCampaign.invoice_issuing.name:
                                                                        currentCampaign.company.country
                                                                      ));
        currencySelected = currentCampaign.currency;
      } else {
        if(billingCountry && prevBilling != "initCurrency" ){
          matchCountry = countries.filter((country) => country.name === billingCountry);
          currencySelected = this.props.currency;
        }else if(user && user.company){
          //setting currency by default and not currency of de company because this can change
          matchCountry = countries.filter((country) => country.name === user.company.country);
        }else if(location){
          matchCountry = countries.filter((country) => country.alpha_2_code === location.country_code)
        }else{
          matchCountry = countries.filter((country) => country.alpha_2_code === "AR")
        }
      }
    }
    if(matchCountry && matchCountry.length > 0){
      setBilling(currencySelected ? currencySelected : matchCountry[0].default_currency.code,
                 matchCountry[0].name,
                 matchCountry[0].alpha_2_code);
      this.settingCurrency(matchCountry[0].currencies, matchCountry[0].default_currency, currencySelected)
      this.setState({ selectedCountry: matchCountry[0].name, selectedCodeCountry: matchCountry[0].alpha_2_code});
    }
  }

  settingCurrency = (currencies, defaultCurrency, selectedCurrency=null) => {
    var listCurrencies = [...currencies];
    listCurrencies.push({code: defaultCurrency.code,
                         description: defaultCurrency.description
                        });
    this.setState({
      selectedCurrency: selectedCurrency ? selectedCurrency : defaultCurrency.code,
      currencies: listCurrencies,
    });
  }

  handleCountrySelection = ({ target }) => {
    let matchCountry = this.props.countries.filter((country) => country.name === target.value.name)
    this.settingCurrency(matchCountry[0].currencies, matchCountry[0].default_currency);
    this.setState({
      selectedCountry: target.value.name,
      selectedCodeCountry: matchCountry[0].alpha_2_code,
    });
  };

  handleCurrencySelection = ({ target }) => {
    this.setState({
      selectedCurrency: target.value,
    });
  };

  handleOk = () => {
    const { selectedCountry, selectedCodeCountry, selectedCurrency, language } = this.state;
    this.props.setBilling(selectedCurrency, selectedCountry, selectedCodeCountry)
    this.props.i18n.changeLanguage(language);
    storage.setItem("language", language);
    document.documentElement.lang = language;
    this.props.onOk();
  };

  changeLanguage = ({ target }) => {
    this.setState({
      ...this.state, language: target.value,
    });
  };

  // Initializa state
  closeModal = () => {
    let matchCountry = this.props.countries.filter((country) => country.name === this.props.billingCountry)
    this.settingCurrency(matchCountry[0].currencies, matchCountry[0].default_currency);
    this.setState({
      selectedCountry: matchCountry[0].name,
      selectedCodeCountry: matchCountry[0].alpha_2_code,
      language: this.props.i18n.language,
    });
  };

  render() {

    const { visible, onCancel, cancelText, t, countries , dsp, programmatic, isSuperUser} = this.props;
    const {
      language,
      selectedCountry,
      selectedCurrency,
      currencies} = this.state;

    let diseabledOptions = (!!dsp && dsp.id) && ( programmatic == "programmatic" || (programmatic == "traditional" && !isSuperUser) )

    const customPanelStyle = {
      background: "#fff",
      borderRadius: 4,
      marginBottom: 10,
      border: 0,
      overflow: "hidden",
    };
    // var selectedCurrencys = "ARS"
    return (
      <Modal
        bodyStyle={{ padding: 20 }}
        title={t("Language & Currency configuration")}
        visible={visible}
        width={600}
        okText={t("Apply settings")}
        okButtonProps={{
          disabled:
            this.props.currency === selectedCurrency &&
            this.props.i18n.language === language &&
            this.props.billingCountry === selectedCountry,
        }}
        cancelText={cancelText}
        onOk={this.handleOk}
        onCancel={onCancel}
        afterClose={this.closeModal}
      >
        <Row>
          <div>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ backgroundColor: "#FFF" }}
            >
              <Panel
                header={t("Language")}
                key="1"
                style={(customPanelStyle, { marginBottom: "10px" })}
                showArrow={false}
              >
                <Radio.Group
                  defaultValue={`${language}`}
                  buttonStyle="solid"
                  onChange={this.changeLanguage}
                >
                  <Radio.Button checked={language === 'en'} value="en">
                    English
                  </Radio.Button>
                  <Radio.Button checked={language === 'es'} value="es">
                    Español (Latino)
                  </Radio.Button>
                  <Radio.Button checked={language === 'es_ar'} value="es_ar">
                    Español (Argentina)
                  </Radio.Button>
                </Radio.Group>
              </Panel>
            </Collapse>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ backgroundColor: "#FFF" }}
            >
              <Panel
              header={(
                <div>
                  {t('Billing Country')}
                  <br />
                  {/* <span className="info-fi">Solo se puede configurar FI cuando el DSP no es Latinad</span> */}
                </div>
              )}
                key="1"
                style={customPanelStyle}
                showArrow={false}
              >
                <Radio.Group
                  buttonStyle="solid"
                  onChange={this.handleCountrySelection}
                  style={{ paddingRight:"10px" , paddingLeft: "10px" }}
                >
                  {countries.map((item) => {
                    return (
                      <Radio.Button
                        checked={selectedCountry == item.name}
                        key={item.id}
                        value={item}
                        style={{ margin: "3px" }}
                        disabled={diseabledOptions && item.name != 'Factura Internacional'}
                      >
                        {item.name === 'Factura Internacional' ? t('International billing') : item.name}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
               { diseabledOptions ? (
                  <Alert style={{marginRight: "20px", marginLeft: "20px", marginTop: "10px", paddingTop: "10px", paddingBottom: "10px"}}
                    description={t("If you choose a DSP that is not LatinAd, you will only be able to make purchases in USD through an international invoice.")}
                    type="info" showIcon
                    />
                  ) : null}
              </Panel>
            </Collapse>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ backgroundColor: "#FFF" }}
            >
              <Panel
                header={t("Currency")}
                key="1"
                style={customPanelStyle}
                isActive={selectedCountry}
              >
              {currencies.length > 0 ? (
                <Radio.Group
                  buttonStyle="solid"
                  onChange={this.handleCurrencySelection}
                >
                  {currencies.map((item) => {
                    return (
                      <Radio.Button
                        checked={selectedCurrency == item.code}
                        key={item.code}
                        value={item.code}
                        style={{ margin: "3px" }}
                      >
                        {item.code}
                      </Radio.Button>
                    );
                  }
                  )}
                </Radio.Group>
              ) : null}
              </Panel>
            </Collapse>
            {selectedCountry == 'Factura Internacional' ? (
              <Alert description={t("You will receive an international invoice without local taxes and the payment must be in USD (American Dollars) internationally.")} type="info" showIcon />
            ) : null}
          </div>
        </Row>
      </Modal>
    );
  }
}
export default withTranslation()(LanguageCurrencyModal);
// export default LanguageCurrencyModal;
