import styled from "styled-components";

const CampaingDetailHolder = styled.div`
  font-weight: "bold";

  .campaignItem span{
    margin-top: 10px;
  }
`;

export default CampaingDetailHolder;
