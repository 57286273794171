import React from "react";
import { Modal, Button, Table } from 'antd';
import CardOdcModalHolder from "./Styles/cardOdcModalStyle";

class CardOdcModal extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };


  render() {
    const {t, data, title} = this.props;
    const columns = [{
      title: t("File"),
      dataIndex: 'file',
      render: (text, record) =>
        <a className="link" href= {record.file} target="_blank">
          {t("view")}
        </a>
      },{
        title: t('Date creation'),
        dataIndex: 'created_at',
      }];
    return (
        <>
          <Button type="ant-btn" onClick={this.showModal}>
            {t("ODC")}
          </Button>
          <Modal
            title={title}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="blue" onClick={this.handleCancel}>
                OK
              </Button>,
            ]}
            >
            <CardOdcModalHolder>
                {data &&  data.length > 0 ?
                  <div className="contentTable" style={{paddingRight: "5%"}}>
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        size="Small"/>
                  </div>:
                  <p className="contentTable textContent">{t('There are no purchase orders uploaded')}</p>
                }
            </CardOdcModalHolder>
          </Modal>
        </>
    );
  }
}

export default CardOdcModal;