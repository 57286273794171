import { connect } from 'react-redux'
import CheckoutStepsComponent from '../components/CheckoutStepsComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount } ) => ({
  user: userAccount.user,
  campaignName: display.campaignName,
  campaignDescription: display.campaignDescription,
  isCampaignTest: display.isCampaignTest,
  date_from: display.date_from,
  date_to: display.date_to,
  cart: display.cart,
  quote: display.quote,
  customContents: display.customContents,
  defaultContent: display.defaultContent,
  campaign: display.campaign,
  creatingCampaign: display.creatingCampaign,
  client: display.client,
  brand: display.brand,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  realAmount: display.realAmount,
  defaultRules: display.defaultRules,
  customProgrammaticData: display.customProgrammaticData,
  currentCampaign: display.currentCampaign,
  editCampaignActive: display.editCampaignActive,
  editingCampaign: display.editingCampaign,
  currency: userAccount.currency,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  dsp: display.dsp,
  listBundle: display.listBundle,
  isSuperUser: userAccount.isSuperUser,
  categoriesId: display.categoriesId,
})

const mapStateToDispatch = (dispatch) => ({
  createCampaign: (params) => dispatch(DisplayActions.createCampaignRequest(params)),
  editCampaign: (campaignId, params) => dispatch(DisplayActions.editCampaignRequest(campaignId, params)),
  setDates: (from, to) => dispatch(DisplayActions.setDates(from, to)),
  updatePriceCartByDiscount: (displayId, price) => dispatch(DisplayActions.updatePriceCartByDiscount(displayId, price)),
})

export default connect(mapStateToProps, mapStateToDispatch)(CheckoutStepsComponent)
