import styled from "styled-components";
import Colors from "../../themes/Colors";

const OnboardingHolder = styled.div`
  background-color: ${Colors.snow};
  height: calc(100% - 90px);
  border-radius: 25px;
  padding: 20px 20px 25px;
  border-style: solid !important;
  border-color: #e8e8e8 !important;
  border-width: 1px !important;
  margin-bottom: 20px;

  .sliderContainer {
    height: 100%;
  }

  .slick-slider {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-list {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
  }

  .slick-slide div {
    height: 100%;
  }

  .slick-dots {
    display: none !important;
    margin: 0 0 10px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: ${Colors.primary};
  }

  .sliderCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sliderCardContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 25px;
  }

  .sliderCard:focus {
    outline: none;
  }

  .sliderCard h2 {
    font-size: 28px;
    font-weight: 600;
    // color: ${Colors.snow};
    margin: 0;
  }

  .sliderCard p {
    font-size: 18px;
    // color: ${Colors.snow};
  }

  .buttonSegmentCheck {
    color: ${Colors.primary};
    border-color: ${Colors.primary};
  }
  .buttonSegmentUncheck {
    color: #636363;
    border-color: #dadada;
  }
  .iconAudience {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .dataAudience {
    fontSize: 17px;
    color: ${Colors.primary};;
    margin-right: 10px;
  }
  .containerIconAudience{
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 1400px) {
      display: grid;
    }
  }

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.audience-info {
  text-align: "center";
  font-size: "40px";
  margin-bottom: 0;
  color: ${Colors.primary};
}
`;

export default OnboardingHolder;
