import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

class GenderByAgeChartComponent extends Component {
  constructor(props) {
    super(props);
  }
  setting = (female, male) => {
    const {t} = this.props
    return {
      labels: [
        t("from 0 to 14"),
        t("from 15 to 19"),
        t("from 20 to 29"),
        t("from 30 to 44"),
        t("from 45 to 54"),
        t("more than 55"),
      ],
      datasets: [
        {
          label: t("Male")+" "+"%",
          data: male,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.7)",
        },
        {
          label: t("Female")+" "+"%",
          data: female,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.7)",
        },
      ],
    };
  }

  render() {
    var configChart = this.setting( this.props.data[0], this.props.data[1]);
    return <Bar options={options} data={configChart} />;
  }
}
export default withTranslation()(GenderByAgeChartComponent);

