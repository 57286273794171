import styled from "styled-components";
import Colors from "../../themes/Colors";

const InfoHolder = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 5px;
    color: ${Colors.primary};
  }

  h2 {
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 5px;
    color: ${Colors.primary};
  }

  p {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 25px;
    line-height: 1.2;
  }

  .videoImage {
    max-width: 90%;
    border-radius: 15px;
    object-fit: contain;
    background-color: white;
    cursor: pointer;
    margin-bottom: 45px;
    height: 100%;
  }
  .our {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 0 15px;
  }
  .ant-card-cover {
    // background-image: linear-gradient(100deg,#2E8EE5,#1ADCFC);
    border-radius: 10px 10px 0 0;
    height: 250px;
    border: solid;
    border-color: whitesmoke;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1px;
  }
  .ant-card-meta-title {
    text-align: left;
    font-size: 20px;
  }
  .our .ant-card-cover img {
    border-radius: 10px 10px 0 0;
  }
  .sliderCardContent {
    filter: grayscale(100%);
  }
  .sliderCard {
    padding-top: 20px;
  }
  .imagePartner {
    width: 110px;
  }
  .sliderCardContent {
    filter: grayscale(100%);
  }
  .sliderCard {
    padding-top: 20px;
  }
  .imagePartner {
    width: 110px;
  }
`;

export default InfoHolder;
