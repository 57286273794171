import { connect } from 'react-redux'
import RequestSpecialPriceComponent from "../components/RequestSpecialPriceComponent";
import DisplayActions from '../redux/DisplayRedux';

const mapStateToProps = ({ display, userAccount }) => ({
  requestingSpecialPrice: display.requestingSpecialPrice,
  requestSpecialPriceSuccessful: display.requestSpecialPriceSuccessful,
  errors: display.errors,
  currency: userAccount.currency
})

const mapStateToDispatch = (dispatch) => ({
  requestSpecialPrice: (displayId, requestedPrice, currency) => dispatch(DisplayActions.requestSpecialPriceRequest(displayId, requestedPrice, currency)),
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(RequestSpecialPriceComponent);
