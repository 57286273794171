import { connect } from 'react-redux'
import PaymentCampaignComponent from '../components/PaymentCampaignComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ display, userAccount }) => {
  return {
    user: userAccount.user,
    token: userAccount.token,
    campaign: display.currentCampaign,
    campaignCreated: display.campaign,
    fetching: display.fetching,
    paymentData: display.paymentData,
    isSuperUser: userAccount.isSuperUser,
    creatingCampaign: display.creatingCampaign,
    payment: display.payment,
    creatingPayment: display.creatingPayment,
    creatingPaymentNew: display.creatingPaymentNew,
    errors: display.errors,
    couponErrors: display.couponErrors,
    paymentMethods: display.paymentMethods,
    fetchingCampaign: display.fetchingCampaign,
    lastBillingInfo: display.lastBillingInfo,
    gettingBiilingInfo: display.gettingBiilingInfo,
    proposalToken: display.proposalToken,
    loadingProposal: display.loadingProposal,
    currency: userAccount.currency,
    countries: userAccount.countries,
    invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
    external_dsp_id: display.external_dsp_id,
    isSuperUser: userAccount.isSuperUser,
    fetchingCampaign: display.fetchingCampaign,
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCampaign: (id) => dispatch(DisplayActions.getCampaignRequest(id)),
  getProposalRequest: (proposalData) => dispatch(DisplayActions.getProposalRequest(proposalData)),
  createCoupon: (campaignId, code) => dispatch(DisplayActions.createCouponRequest(campaignId, code)),
  deleteCoupon: (campaignId, couponId) => dispatch(DisplayActions.deleteCouponRequest(campaignId, couponId)),
  createPayment: (campaignId, paymentData) => dispatch(DisplayActions.createPaymentRequest(campaignId, paymentData)),
  getMercadoPagoPaymentMethods: () => dispatch(DisplayActions.getMercadoPagoPaymentMethods()),
  getMpInitPointRequest: (campaignId, paymentData) => dispatch(DisplayActions.getMpInitPointRequest(campaignId, paymentData)),
  getLastBillingInfoRequest: (userId) => dispatch(DisplayActions.getLastBillingInfoRequest(userId)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
})

export default connect(mapStateToProps, mapStateToDispatch)(PaymentCampaignComponent)
