import styled from "styled-components";
import Colors from "../../themes/Colors";

const AdScreenCardHolder = styled.div`
  .adScreenCard {
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0 0 15px;
  }
  h4 {
    color: ${Colors.primary};
    font-weight: 600;
  }
  .adScreenCard .ant-card-body {
    padding: 0;
  }
  .screenStats {
    margin-right: 15px
    background-color: ${Colors.secondary};
    color: ${Colors.snow};
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 10px;
    border-radius: 4px;
  }
  .adScreenCardImage {
    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
  }
  .detailButton {
    width: -webkit-fill-available;
    margin-right: 15px;
  }
  .primary {
    color: ${Colors.primary};
  }
  .screenReportButton {
    border-color: #fff;
    background-color: #ffffff00;
    height: 21px;
    color: #fff
  }
  .screenInfo {
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .screenContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .screenContainerContent {
    width: 150px;
    max-width: 100%;
    margin: 5px 0;
  }

  .screen {
    background-color: ${Colors.black};
    border: 4px solid #6f6f70;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .screenPreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .screenPreviewFilled {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #fff;
    border-color: ${Colors.primary} !important;
  }
  .checkboxDay {
    color: rgba(0, 0, 0, 0.65) !important
  }
  .camTrafficViewButton {
    border-radius: 20px;
  }
  .camCertificationButton {
    border-radius: 20px;
  }
  .evidencesButton {
    border-radius: 20px;
  }
  .changeSpotButton {
    margin-top: 10px;
    background: ${Colors.primary};
    border-color: ${Colors.primary};
    color: white;
    border-radius: 20px !important;
  }
  .screenWarningNoAutomateView {
    z-index: 1;
    background-color: ${Colors.snow};
    border-color: #ffc107;
    color: ${Colors.snow};
  }
  .slick-next:before {
    color: #c8c8c8
  }
  .slick-prev:before {
    color: #c8c8c8
  }
`;

export default AdScreenCardHolder;
