/* eslint-disable max-len */
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import R from 'ramda';
import { routerMiddleware } from 'connected-react-router';
import Config from '../config/DebugSettings';
import AppConfig from '../config/AppConfig';
import googleAnalyticsMiddleware from '../middlewares/google-analytics.js';

export default (rootReducer, rootSaga, history) => {

  /* ---------- Redux configuration ---------- */
  const middleware = [];
  const enhancers = [];

  /* ---------- Saga Middleware ---------- */
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  /* ---------- Google Analytics Middleware ---------- */
  middleware.push(googleAnalyticsMiddleware);

  /* ---------- Logger Middleware ---------- */
  if (AppConfig.DEV) {
    const USE_LOGGING = Config.reduxLogging;
    const SAGA_LOGGING_BLACKLIST = ['EFFECT_TRIGGERED', 'EFFECT_RESOLVED', 'EFFECT_REJECTED'];
    const logger = createLogger({
      predicate: (getState, { type }) => USE_LOGGING && R.not(R.contains(type, SAGA_LOGGING_BLACKLIST)),
    });
    middleware.push(logger);
  }

  /* ---------- Routing Middleware ---------- */
  middleware.push(routerMiddleware(history));

  /* ---------- Assemble Middleware ---------- */
  enhancers.push(applyMiddleware(...middleware));

  /* ---------- Create Store ---------- */
  const store = createStore(rootReducer, compose(...enhancers));
  sagaMiddleware.run(rootSaga);

  return store;
};
