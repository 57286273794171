import { connect } from 'react-redux'
import PaymentStatusComponent from '../components/PaymentStatusComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ display, userAccount }) => {
  return {
    payment: display.payment,
    fetchingPayment: display.fetchingPayment,
    errors: display.errors,
    currency: userAccount.currency,
    user: userAccount.user,
    token: userAccount.token,
    fetchingOrders: display.fetchingOrders,
    errorsOdc: display.errorsOdc,
    invoiceIssuingCountry: display.invoiceIssuingCountry,
  }
}

const mapStateToDispatch = (dispatch) => ({
  getPayment: (id) => dispatch(DisplayActions.getPaymentRequest(id)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  createPurchaseOrdersRequest: (companyId, campaignId) => dispatch(DisplayActions.createPurchaseOrdersRequest(companyId, campaignId)),
})

export default connect(mapStateToProps, mapStateToDispatch)(PaymentStatusComponent)
