export const markerCircuitIndoorOnCart = () => {
  const primary = getComputedStyle(document.documentElement).getPropertyValue('--primaryPins').trim();
  const svgString = `
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 61.97">
      <defs>
        <style>
          .cls-1 {
              stroke-width: .79px;
            }

            .cls-1, .cls-2 {
              stroke-miterlimit: 10;
            }

            .cls-1, .cls-3 {
              fill: #fff;
              stroke: #192a39;
            }

            .cls-2 {
              fill: ${primary};
              stroke: #1d71b8;
            }

            .cls-3 {
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: .79px;
            }
        </style>
      </defs>
      <g id="Group_14">
        <g id="Group_13">
          <path id="Path_8" class="cls-2" d="M25.37,1.32c-13.29,0-24.07,10.78-24.08,24.08,0,12.07,15,27.95,21.33,34.1,1.54,1.51,4,1.54,5.57.06,6.36-5.93,21.26-21.2,21.26-34.16,0-13.3-10.79-24.08-24.09-24.08Z"/>
        </g>
      </g>
      <g>
        <g>
          <line class="cls-3" x1="13.62" y1="30.44" x2="13.62" y2="20.45"/>
          <rect class="cls-1" x="6.23" y="15.29" width="14.8" height="8.84" rx="1.73" ry="1.73" transform="translate(33.34 6.07) rotate(90)"/>
        </g>
        <g>
          <line class="cls-3" x1="37.11" y1="30.44" x2="37.11" y2="20.45"/>
          <rect class="cls-1" x="29.72" y="15.29" width="14.8" height="8.84" rx="1.73" ry="1.73" transform="translate(56.83 -17.42) rotate(90)"/>
        </g>
      </g>
      <g>
        <line class="cls-3" x1="25.37" y1="51.1" x2="25.37" y2="41.1"/>
        <rect class="cls-1" x="17.98" y="35.94" width="14.8" height="8.84" rx="1.73" ry="1.73" transform="translate(65.74 14.98) rotate(90)"/>
      </g>
      <g>
        <line class="cls-3" x1="37.14" y1="36.96" x2="35.46" y2="40.34"/>
        <line class="cls-3" x1="13.62" y1="36.96" x2="15.3" y2="40.34"/>
      </g>
      <line class="cls-3" x1="27.11" y1="20.55" x2="23.65" y2="20.57"/>
    </svg>`;

  const svgDataUri = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return svgDataUri;
};