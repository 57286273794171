import React, { Component } from "react";
import UserSettingsHolder from "./Styles/userSettingsComponentStyle";
import {
  Form,
  Input,
  Icon,
  Button,
  Modal,
  Card,
  Row,
  Col,
  notification
} from "antd";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import UserRoleEnum from '../constants/UserRoleEnum';
import VerifiedCheck from './customComponents/VerifiedCheck/VerifiedCheck';


class UserSettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    mailModalVisible: false,
    passwordModalVisible: false,
    lastFormSubmitted: "",
    errors: {}
  };

  componentDidMount() {
    if (!this.props.user) {
      this.props.setShouldPromptLogin(true, window.location.pathname);
    }
  }

  showMailModal = () => {
    this.setState({
      mailModalVisible: true
    });
  };

  handleOkMailModal = () => {
    this.setState({
      mailModalVisible: false
    });
  };

  handleCancelMailModal = () => {
    this.setState({
      mailModalVisible: false
    });
  };

  showPasswordModal = () => {
    this.setState({
      passwordModalVisible: true
    });
  };

  handleOkPasswordModal = () => {
    this.setState({
      passwordModalVisible: false
    });
  };

  handleCancelPasswordModal = () => {
    this.setState({
      passwordModalVisible: false
    });
  };

  handleInputChange(event) {
    const target = event.target;

    this.setState({
      [target.name]: target.value
    });
  }

  handleChangeEmail() {
    const errors = {};

    if (!this.state.email) {
      errors["email"] = this.props.t('Can not be blank');
    }

    this.setState({
      errors
    });

    if (Object.keys(errors).length > 0) {
      return;
    }

    this.props.userEmailUpdateRequest(
      this.props.user.id,
      this.props.user.first_name,
      this.props.user.last_name,
      this.props.user.username,
      this.state.email
    );
    this.setState({ lastFormSubmitted: "emailUpdate" });
  }

  handleChangePassword() {
    const errors = {};

    if (!this.state.currentPassword) {
      errors["currentPassword"] = this.props.t('Can not be blank');
    }
    if (!this.state.newPassword) {
      errors["newPassword"] = this.props.t('Can not be blank');
    }
    if (!this.state.repeatNewPassword) {
      errors["repeatNewPassword"] = this.props.t('Can not be blank');
    }
    if (this.state.newPassword !== this.state.repeatNewPassword) {
      errors["repeatNewPassword"] = this.props.t('Repeat the old password');
    }

    this.setState({
      errors
    });

    if (Object.keys(errors).length > 0) {
      return;
    }

    this.props.userPasswordUpdateRequest(
      this.props.user.id,
      this.state.currentPassword,
      this.state.newPassword,
      this.state.repeatNewPassword
    );
    this.setState({ lastFormSubmitted: "passwordChange" });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.closeModal && this.props.closeModal) {
      this.handleOkPasswordModal();
      this.handleOkMailModal();
    }

    if (
      prevProps.fetching &&
      !this.props.fetching &&
      Object.keys(this.props.errors).length === 0
    ) {
      notification.success({
        duration: 2,
        placement: "bottomRight",
        message: this.props.t('Good!'),
        description: this.props.t('Changes successfully saved')
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <UserSettingsHolder>
        {this.props.user ? (
          <Row
            className="userSettingsContainer"
            type="flex"
            justify="center"
            align="center"
          >
            <Col xs={23} sm={22} md={20} lg={18} xl={16}>
              <h1>{t('My profile')}</h1>
              <Card className="settingsCard">
                <div className="cardInfo">
                  {this.props.user.roles.map(role => {
                    if (role === UserRoleEnum.agencyMember || role === UserRoleEnum.companyMember) {
                      return (
                        <div>
                          <span className="cardInfoPrimary">{t('Account')}</span> <br />
                          <h4 style={{ 'display': 'flex', 'alignItems': 'center'}}>
                            {t('Agency')} - {`${this.props.user.company.name}`}
                            {this.props.user.company.is_verified ? <VerifiedCheck/> : <></>}
                          </h4>
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="cardInfo">
                  <span className="cardInfoPrimary">{t('Name')}</span> <br />
                  {`${this.props.user.first_name} ${this.props.user.last_name}`}
                </div>
                <div className="cardInfo">
                  <span className="cardInfoPrimary">{t('Mail')}</span>
                  <br />
                  {this.props.user ? this.props.user.email : ""}
                </div>
                <div className="cardInfoLink">
                  <a onClick={this.showMailModal}>{t('Change Mail')}</a>
                </div>
                <div className="cardInfoLink">
                  <a onClick={this.showPasswordModal}>{t('Change Password')}</a>
                </div>
              </Card>
            </Col>
            <Modal
              className="modalSettings"
              visible={this.state.mailModalVisible}
              onOk={this.handleOkMailModal}
              onCancel={this.handleCancelMailModal}
              footer={null}
            >
              <div className="modalSettingsHeader">
                <h2>{t('Change Mail')}</h2>
              </div>
              <div className="modalSettingsContent">
                <div className="cardInfo">
                  <span className="cardInfoPrimary">Email</span> <br />{" "}
                  {this.props.user.email}
                </div>
                <Form.Item
                  validateStatus={!!this.state.errors["email"] && "error"}
                  help={this.state.errors["email"]}
                >
                  <Input
                    className="modalInput"
                    name="email"
                    autoComplete="username"
                    onChange={this.handleInputChange}
                    onPressEnter={() => this.handleChangeEmail()}
                    placeholder={t('New Mail')}
                    prefix={<Icon type="mail" className="inputIcon" />}
                  />
                </Form.Item>
                <div className="cardInfoError">
                  {this.props.errors &&
                  this.state.lastFormSubmitted === "emailUpdate"
                    ? this.props.errors
                    : null}
                </div>
                <div className="modalSettingsButtonContainer">
                  <Button
                    className="modalSettingsButton"
                    onClick={() => this.handleChangeEmail()}
                    loading={this.props.fetching}
                  >
                    {t('Change Mail')}
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              className="modalSettings"
              visible={this.state.passwordModalVisible}
              onOk={this.handleOkPasswordModal}
              onCancel={this.handleCancelPasswordModal}
              footer={null}
            >
              <div className="modalSettingsHeader">
                <h2>{t('Change Password')}</h2>
              </div>
              <div className="modalSettingsContent">
                <Form.Item
                  validateStatus={
                    !!this.state.errors["currentPassword"] && "error"
                  }
                  help={this.state.errors["currentPassword"]}
                >
                  <Input
                    className="modalInput"
                    name="currentPassword"
                    autoComplete="current-password"
                    onChange={this.handleInputChange}
                    onPressEnter={() => this.handleChangePassword()}
                    placeholder={t('Current Password')}
                    type="password"
                    prefix={<Icon type="lock" className="inputIcon" />}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!this.state.errors["newPassword"] && "error"}
                  help={this.state.errors["newPassword"]}
                >
                  <Input
                    className="modalInput"
                    name="newPassword"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    onPressEnter={() => this.handleChangePassword()}
                    placeholder={t('New Password')}
                    type="password"
                    prefix={<Icon type="lock" className="inputIcon" />}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={
                    !!this.state.errors["repeatNewPassword"] && "error"
                  }
                  help={this.state.errors["repeatNewPassword"]}
                >
                  <Input
                    className="modalInput"
                    name="repeatNewPassword"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    onPressEnter={() => this.handleChangePassword()}
                    placeholder={t('Repeat New Password')}
                    type="password"
                    prefix={<Icon type="lock" className="inputIcon" />}
                  />
                </Form.Item>
                <div className="cardInfo">
                  {this.props.errors &&
                  this.state.lastFormSubmitted === "passwordChange"
                    ? this.props.errors
                    : null}
                </div>
                <div className="modalSettingsButtonContainer">
                  <Button
                    className="modalSettingsButton"
                    onClick={() => this.handleChangePassword()}
                    loading={this.props.fetching}
                  >
                    {t('Change Password')}
                  </Button>
                </div>
              </div>
            </Modal>
          </Row>
        ) : null}
      </UserSettingsHolder>
    );
  }
}
const Extended = withTranslation()(UserSettingsComponent);
Extended.static = UserSettingsComponent.static;

export default withRouter(Extended);
