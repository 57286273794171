import styled from "styled-components";

const CSSEffect = styled.div`
  .my-node-enter {
      opacity: 0;
    }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 800ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export default CSSEffect;
